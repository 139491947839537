import { ContentChild, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ChangeDetectionStrategy, Component, ContentChildren, Input, OnInit, QueryList, ViewEncapsulation } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { SwiperComponent, SwiperSlideDirective } from 'swiper/angular';
import { ShowcaseSwiperSlideComponent } from '../showcase-swiper-slide/showcase-swiper-slide.component';

@Component({
  selector: 'app-showcase-swiper',
  templateUrl: './showcase-swiper.component.html',
  styleUrls: ['./showcase-swiper.component.scss'],
  // encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.Default
})
export class ShowcaseSwiperComponent implements OnInit {

  _maxDispayLimit = 4;

  @Input()
  get maxDisplayLimit(): number {
    return this._maxDispayLimit;
  }

  set maxDisplayLimit(value: number) {
    this._maxDispayLimit = value;
    if (this._maxDispayLimit > 0) {
      this.updateBreakpoints(this._maxDispayLimit);
    }
  }


  // @ContentChildren(ShowcaseSwiperSlideComponent) slideList: QueryList<ShowcaseSwiperSlideComponent>;
  @ContentChildren(ShowcaseSwiperSlideComponent) slideList: QueryList<ShowcaseSwiperSlideComponent>;
  // @ContentChildren(SwiperSlideDirective, { read: TemplateRef }) transcludeTemplate;
  // @ViewChild(SwiperComponent) swiper: SwiperComponent;

  uniqueId = Math.random().toString(36).substring(2);

  swiperConfig: SwiperOptions = {
    observer: true,
    initialSlide: 0,
    slidesPerView: 2.5,
    breakpoints: {
      0: {
        slidesPerView: 1.5,
        slidesPerGroup: 1,
      },
      350: {
        slidesPerView: 2.5,
        slidesPerGroup: 2,
      },
      500: {
        slidesPerView: 2.3,
        slidesPerGroup: 2,
      },
      730: {
        slidesPerView: 3.5,
        slidesPerGroup: 3,
      },
      960: {
        slidesPerView: 4.5,
        slidesPerGroup: 4,
      },
      1200: {
        slidesPerView: 5.5,
        slidesPerGroup: 5,
        // },
        // 2400: {
        //   slidesPerView: 5,
        //   slidesPerGroup: 5,
      }
    },
    // centeredSlides: true,
    zoom: false,
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
      loadOnTransitionStart: true,
    },
    watchSlidesVisibility: true,
    // loop: true,
    navigation: {
      nextEl: `.swiper-${this.uniqueId}.swiper-button-next`,
      prevEl: `.swiper-${this.uniqueId}.swiper-button-prev`,
      hideOnClick: true
    },
    mousewheel: false,
    centerInsufficientSlides: true,
    watchOverflow: true,
    resizeObserver: true,
  };


  constructor() { }

  ngOnInit(): void {
    // const breakpoints = this.swiperConfig.breakpoints;
    // for (const [key, value] of Object.entries(breakpoints)) {
    //   const breakpoint = value;
    //   this.slidesPerView = Number(breakpoint.slidesPerView);
    // }
  }

  updateBreakpoints(maxDisplayLimit: number) {
    const breakpoints = this.swiperConfig.breakpoints;
    for (const [key, value] of Object.entries(breakpoints)) {
      const breakpoint = value;
      // console.log('key', key);
      // console.log('value', value);
      if (breakpoint.slidesPerView > maxDisplayLimit) {
        breakpoint.slidesPerView = maxDisplayLimit + 0.5;
        breakpoint.slidesPerGroup = maxDisplayLimit;
      }
    }
    // console.log('this.swiper', this.swiper);
    // this.swiper.updateSwiper({ 'breakpoints': breakpoints });
  }
}
