import { HttpErrorResponse, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError, EMPTY } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { HttpCancelService } from './http-cancel.service';
import { toISOLocal } from 'src/app/shared/util/util';

@Injectable()
export class CommonErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router, private httpCancelService: HttpCancelService) { }

    private handleError(err: HttpErrorResponse): Observable<any> {
        if (err instanceof HttpErrorResponse) {
            // this.router.navigateByUrl('/login');
            console.error('CommonErrorInterceptor', err);
            this.httpCancelService.cancelPendingRequests();
            if (err.status > 0 && err.status !== 404) {
                // Safari throw xhr error when reload, therefore don't show alert
                alert(`Error: ${err.status}, \nStatus Text: ${err.statusText}, \nTime:${toISOLocal(new Date())}, \nMessage: ${err.message},`);
            }

            // for avoid rxjs EmptyError: no elements in sequence, wait for angular fix
            // return of(new HttpResponse({ body: err.message }));

            return EMPTY;
        }
        return throwError(err);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request to add the new header.
        // const authReq = req.clone({ headers: req.headers.set(Cookie.tokenKey, Cookie.getToken()) });
        // catch the error, make specific functions for catching specific errors and you can chain through them with more catch operators

        console.log('insert CommonErrorInterceptor');

        return next.handle(req).pipe(
            takeUntil(this.httpCancelService.onCancelPendingRequests()),
            catchError((err) => this.handleError(err)),
        );

        // here use an arrow function,
        //  otherwise you may get "Cannot read property 'navigate' of undefined" on angular 4.4.2/net core 2/webpack 2.70

    }

}
