import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, CanLoad, Route } from '@angular/router';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { MemberCouponService } from '../api/member-coupon.service';

@Injectable()
export class CouponGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private router: Router, private memberCouponService: MemberCouponService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const couponId = route.params.id;
    return this.checkCoupon(couponId);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): Observable<boolean> {
    return this.checkCoupon();
  }

  checkCoupon(couponId?): Observable<boolean> {
    console.log('checkCoupon', couponId);

    return this.memberCouponService.checkCoupon(couponId).pipe(map(data => {
      if (!data.valid) {
        this.router.navigateByUrl('404', { state: { 'redirectUrl': '/' }, replaceUrl: true, skipLocationChange: true });
        return false;
      }
      return true;
    }));
  }

}
