// import { MainModule } from './main/main.module';
import { PermissionInterceptor } from './api/interceptor/permission-interceptor';
import { LoginInterceptor } from './api/interceptor/login-interceptor';
import { CommonInterceptor } from './api/interceptor/common-interceptor';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG, DomSanitizer } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, DEFAULT_CURRENCY_CODE, InjectionToken, Injector, LOCALE_ID, NgModule, Renderer2, RendererFactory2, inject } from '@angular/core';

import { AppComponent } from './app.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpBackend, HttpClient, HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule, HttpXsrfTokenExtractor } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { CommonErrorInterceptor } from './api/interceptor/common-error-interceptor';
import { SharedCommonImportModule } from './shared/import/shared-common-import.module';
import { HttpCancelInterceptor } from './api/interceptor/http-cancel-interceptor';

import { RouteReuseStrategy } from '@angular/router';
import { AppRouteReuseStrategy } from './routing-resolve/app-route-reuse-strategy';

import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { InfoPageComponent } from './info-page/info-page.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PaymentResponseComponent } from './payment-response/payment-response.component';
import { PaymentResultComponent } from './payment-result/payment-result.component';

import { ProductShowcaseComponent } from './product-showcase/product-showcase/product-showcase.component';
import { ProductShowcaseItemComponent } from './product-showcase/_product-showcase-item/product-showcase-item.component';
import { HomeComponent } from './home/home.component';
import { GoodsSeriesComponent } from './goods-series/goods-series.component';
import { RegisterComponent } from './register/register.component';
import { BlogComponent } from './blog/blog.component';
import { BlogArticleComponent } from './blog-article/blog-article.component';
import { CategoryComponent } from './category/category.component';
import { BrandComponent } from './brand/brand.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { PaymentComponent } from './payment/payment.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ShoppingCartLoginComponent } from './shopping-cart-login/shopping-cart-login.component';
import { ShoppingFlowComponent } from './shopping-flow/shopping-flow.component';
import { BlogCategoryComponent } from './blog-category/blog-category.component';
import { BrandGoodsSeriesComponent } from './brand-goods-series/brand-goods-series.component';
import { SearchGoodsSeriesComponent } from './search-goods-series/search-goods-series.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { FaqComponent } from './faq/faq.component';
import { AboutDeliveryComponent } from './about-delivery/about-delivery.component';
import { AboutRefundComponent } from './about-refund/about-refund.component';
import { RetailStoreComponent } from './retail-store/retail-store.component';
import { InfoPageContentComponent } from './info-page-content/info-page-content.component';


import { GoodsDialogComponent } from './goods-dialog/goods-dialog.component';
import { GoodsReviewDialogComponent } from './goods-review-dialog/goods-review-dialog.component';
import { GoodsComponent } from './goods/goods.component';
import { GoodsShowcaseItemComponent } from './goods-showcase-item/goods-showcase-item.component';
import { GoodsShowcaseSwiperComponent } from './goods-showcase-swiper/goods-showcase-swiper.component';
import { MemberActivateComponent } from './member-activate/member-activate.component';
import { RegisterCompleteComponent } from './register-complete/register-complete.component';
import { ForgotPasswordNotificationComponent } from './forgot-password-notification/forgot-password-notification.component';
import { ShoppingCartGiftBottomSheetComponent } from './_shopping-cart-gift-bottom-sheet/shopping-cart-gift-bottom-sheet.component';
import { GoodsShowcaseComponent } from './goods-showcase/goods-showcase.component';
import { ShowcaseSwiperComponent } from './showcase/showcase-swiper/showcase-swiper.component';
import { ShowcaseSwiperSlideComponent } from './showcase/showcase-swiper-slide/showcase-swiper-slide.component';

import { SearchGoodsComponent } from './search-goods/search-goods.component';
import { FacebookLoginButtonComponent } from './facebook-login-button/facebook-login-button.component';
import { AppInit } from './app-init';
import { SnackBarComponent } from './snack-bar/snack-bar.component';

import { TextSwiperComponent } from './text-swiper/text-swiper.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { OrderNavigationTopBarComponent } from './order-navigation-top-bar/order-navigation-top-bar.component';
import { CategoryShowcaseItemComponent } from './category-showcase-item/category-showcase-item.component';
import { ShopAddressComponent } from './shop-address/shop-address.component';
import { EmailComponent } from './email/email.component';
import { ReturnPolicyComponent } from './return-policy/return-policy.component';
import { CustomerServiceComponent } from './customer-service/customer-service.component';
import { PaymentInfoComponent } from './payment-info/payment-info.component';
import { ShippingInfoComponent } from './shipping-info/shipping-info.component';
import { AddCartSnackBarComponent } from './add-cart-snack-bar/add-cart-snack-bar.component';
import { AdvPopUpDialogComponent } from './adv-pop-up-dialog/adv-pop-up-dialog.component';
import { BrandListComponent } from './brand-list/brand-list.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { MembersComponent } from './members/members.component';
import { ShippingComponent } from './shipping/shipping.component';
import { OrdersComponent } from './orders/orders.component';
import { ReturnAndExchangesComponent } from './return-and-exchanges/return-and-exchanges.component';
import { AddOnPromotionComponent } from './add-on-promotion/add-on-promotion.component';
import { AdvertisementGoodsComponent } from './advertisement-goods/advertisement-goods.component';
import { Angulartics2Module } from 'angulartics2';
import { SideNavContentComponent } from './side-nav-content/side-nav-content.component';

import { BrandShowcaseItemComponent } from './brand-showcase-item/brand-showcase-item.component';
import { BlogShowcaseItemComponent } from './blog-showcase-item/blog-showcase-item.component';
import { SearchBlogComponent } from './search-blog/search-blog.component';
import { RegisterFormComponent } from './register-form/register-form.component';
import { ScrollingModule, ScrollDispatcher } from '@angular/cdk/scrolling';
import { PromotionDialogComponent } from './promotion-dialog/promotion-dialog.component';
import { PromotionComponent } from './promotion/promotion.component';
import { PromotionDetailComponent } from './promotion-detail/promotion-detail.component';
import { BundlePromotionComponent } from './bundle-promotion/bundle-promotion.component';
import { BundlePriceGoodsPromotionItemComponent } from './bundle-price-goods-promotion-item/bundle-price-goods-promotion-item.component';
import { BundleGoodsPromotionItemComponent } from './bundle-goods-promotion-item/bundle-goods-promotion-item.component';
import { PromotionCodeComponent } from './promotion-code/promotion-code.component';
import { ClipboardModule } from 'ngx-clipboard';
import { CouponDialogComponent } from './coupon-dialog/coupon-dialog.component';
import { MatIconRegistry } from '@angular/material/icon';
import { CouponComponent } from './coupon/coupon.component';
import { InformationBottomSheetComponent } from './shared/component/information-bottom-sheet/information-bottom-sheet.component';
import { BundlePriceProductVariantPromotionItemComponent } from './bundle-price-product-variant-promotion-item/bundle-price-product-variant-promotion-item.component';
import { NgVarDirective } from './shared/directive/ng-var.directive';
import { InformationDialogComponent } from './shared/component/information-dialog/information-dialog.component';
import { setAppInjector } from './shared/service/app-injector';
import { TokenInterceptor } from './api/interceptor/token-interceptor';
import { NewsAndEventsPageItemComponent } from './news-and-events-page/news-and-events-page-item/news-and-events-page-item.component';
import { NewsAndEventsPageListComponent } from './news-and-events-page/news-and-events-page-list/news-and-events-page-list.component';
import { PhotoGalleryPageListComponent } from './photo-gallery-page/photo-gallery-page-list/photo-gallery-page-list.component';
import { PhotoGalleryPageItemComponent } from './photo-gallery-page/photo-gallery-page-item/photo-gallery-page-item.component';
import { VideoGalleryPageComponent } from './video-gallery-page/video-gallery-page.component';
import { FileDownloadPageComponent } from './file-download-page/file-download-page.component';
import { NewsAndEventsPageSearchListComponent } from './news-and-events-page/news-and-events-page-search-list/news-and-events-page-search-list.component';
import { LightboxModule } from 'ngx-lightbox';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { VideoGalleryDialogComponent } from './video-gallery-page/video-gallery-dialog/video-gallery-dialog.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { GoogleLoginService } from './shared/service/google-login.service';
// import { HttpXsrfCookieExtractor, HttpXsrfInterceptor } from './api/interceptor/http-xsrf-interceptor';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PageNotFoundComponent,
    InfoPageContentComponent,
    TermsAndConditionsComponent,
    // MemberAccountComponent,
    // LinkExpiredComponent,
    AboutUsComponent,
    ContactUsComponent,
    FaqComponent,
    AboutDeliveryComponent,
    AboutRefundComponent,
    RetailStoreComponent,
    InfoPageComponent,
    PrivacyPolicyComponent,
    PaymentResponseComponent,
    PaymentResultComponent,
    ProductShowcaseComponent,
    ProductShowcaseItemComponent,
    HomeComponent,
    GoodsSeriesComponent,
    GoodsComponent,
    RegisterComponent,
    BlogComponent,
    BlogArticleComponent,
    CategoryComponent,
    BrandComponent,
    BrandGoodsSeriesComponent,
    ShoppingCartComponent,
    PaymentComponent,
    CheckoutComponent,
    ShoppingCartLoginComponent,
    ShoppingFlowComponent,
    BlogCategoryComponent,
    SearchGoodsSeriesComponent,
    SearchGoodsComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    // MatStarRatingComponent,
    GoodsDialogComponent,
    GoodsReviewDialogComponent,
    GoodsComponent,
    GoodsShowcaseItemComponent,
    GoodsShowcaseComponent,
    GoodsShowcaseSwiperComponent,
    MemberActivateComponent,
    RegisterCompleteComponent,
    ForgotPasswordNotificationComponent,
    ShoppingCartGiftBottomSheetComponent,
    FacebookLoginButtonComponent,
    SnackBarComponent,
    TextSwiperComponent,
    NavigationBarComponent,
    OrderNavigationTopBarComponent,
    CategoryShowcaseItemComponent,
    ShopAddressComponent,
    EmailComponent,
    ReturnPolicyComponent,
    CustomerServiceComponent,
    PaymentInfoComponent,
    ShippingInfoComponent,
    AddCartSnackBarComponent,
    AdvPopUpDialogComponent,
    BrandListComponent,
    PromotionsComponent,
    MembersComponent,
    ShippingComponent,
    OrdersComponent,
    ReturnAndExchangesComponent,
    AddOnPromotionComponent,
    AdvertisementGoodsComponent,
    SideNavContentComponent,
    BrandShowcaseItemComponent,
    BlogShowcaseItemComponent,
    SearchBlogComponent,
    RegisterFormComponent,
    PromotionDialogComponent,
    PromotionComponent,
    PromotionDetailComponent,
    BundlePromotionComponent,
    BundlePriceGoodsPromotionItemComponent,
    BundleGoodsPromotionItemComponent,
    PromotionCodeComponent,
    CouponDialogComponent,
    CouponComponent,
    BundlePriceProductVariantPromotionItemComponent,
    NgVarDirective,
    InformationBottomSheetComponent,
    InformationDialogComponent,
    NewsAndEventsPageItemComponent,
    NewsAndEventsPageListComponent,
    PhotoGalleryPageListComponent,
    PhotoGalleryPageItemComponent,
    VideoGalleryPageComponent,
    FileDownloadPageComponent,
    NewsAndEventsPageSearchListComponent,
    VideoGalleryDialogComponent,
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    BrowserModule,
    HttpClientModule,
    SharedCommonImportModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    Angulartics2Module.forRoot(),
    ScrollingModule,
    ClipboardModule,
    LightboxModule,
    NgxYoutubePlayerModule.forRoot(),
    FontAwesomeModule,
    SocialLoginModule,
    // HttpClientXsrfModule.withOptions()
  ],
  providers: [
    // { provide: APP_BASE_HREF, useValue: Project.baseHref },
    // first in first execute intercept method, last execute pipe catchError
    // { provide: HttpXsrfTokenExtractor, useClass: HttpXsrfCookieExtractor },
    // { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CommonInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CommonErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PermissionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoginInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpCancelInterceptor, multi: true },
    { provide: RouteReuseStrategy, useExisting: AppRouteReuseStrategy },
    AppInit,
    { provide: APP_INITIALIZER, useFactory: (appInit: AppInit) => (): Promise<any> => appInit.init(), deps: [AppInit], multi: true },
    // { provide: LOCALE_ID, useValue: 'zh-Hant-HK' } // https://github.com/angular/angular/blob/master/packages/common/locales/closure-locale.ts
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'HKD' },
    {
      provide: 'SocialAuthServiceConfig',
      useFactory: (googleLoginService: GoogleLoginService) => {
        return {
          autoLogin: false,
          providers: googleLoginService.providers,
          onError: (err) => {
            console.error(err);
          },
        } as SocialAuthServiceConfig
      },
      deps: [GoogleLoginService],
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(
    injector: Injector,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    setAppInjector(injector);

    this.matIconRegistry.addSvgIcon('mt_filter', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/filter-icon.svg'));
    this.matIconRegistry.addSvgIcon('mt_coupon', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/coupon-icon.svg'));
  }
}

// required for AOT compilation
export function HttpLoaderFactory(httpBackend: HttpBackend) {
  const httpClient: HttpClient = new HttpClient(httpBackend);
  return new TranslateHttpLoader(httpClient, 'assets/i18n/');
}




