import { Injectable } from '@angular/core';
import { SystemSettingService } from 'src/app/api/system-setting.service';


declare var WhWidgetSendButton;

@Injectable({
  providedIn: 'root'
})
export class InstantMessageService {

  constructor(
    private systemSettingService: SystemSettingService
  ) { }

  init() {

    this.systemSettingService.getInstantMessage().subscribe(instantMessage => {

      const facebookEnabled = instantMessage.facebook;
      const facebookId = instantMessage.facebookId;

      const whatsappEnabled = instantMessage.whatsapp;
      const whatsappId = instantMessage.whatsappId;

      const enabled = facebookEnabled || whatsappEnabled;

      if (enabled) {
        const options: any = {
          call_to_action: '', // Call to action
          button_color: '#666666', // Color of button
          position: 'right', // Position may be 'right' or 'left'
          order: 'whatsapp facebook', // Order of buttons
        };

        if (facebookEnabled) {
          options.facebook = facebookId;
        }

        if (whatsappEnabled) {
          options.whatsapp = whatsappId;
        }

        try {

          (function () {
            // const options = {
            //   // facebook: "217884378787918", // Facebook page ID
            //   whatsapp: '+85296968242', // WhatsApp number
            //   call_to_action: '', // Call to action
            //   button_color: '#666666', // Color of button
            //   position: 'right', // Position may be 'right' or 'left'
            //   order: 'whatsapp', // Order of buttons
            // };
            const proto = document.location.protocol, host = 'getbutton.io', url = proto + '//static.' + host;
            const s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = url + '/widget-send-button/js/init.js';
            s.defer = true;
            s.onload = function () { WhWidgetSendButton.init(host, proto, options); };
            const x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x);
          })();



        } catch (error) {
          console.error(error);
        }
      }
    });


  }
}
