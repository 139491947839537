import { Component, OnInit, Inject } from '@angular/core';
import { MemberCouponService } from '../api/member-coupon.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BasicViewComponent } from '../basic-view-component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FullScreenLoadingService } from '../shared/service/full-screen-loading.service';
import { ShoppingCartService } from '../api/shopping-cart.service';
import { finalize, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'rxjs';

@Component({
  selector: 'app-coupon-dialog',
  templateUrl: './coupon-dialog.component.html',
  styleUrls: ['./coupon-dialog.component.scss']
})
export class CouponDialogComponent extends BasicViewComponent implements OnInit {

  couponErrorMap = {
    'not_exist': 'coupon.error.not.exist',
    'duplicate_coupon': 'coupon.error.duplicate',
    'expired': 'coupon.error.expired',
    'order_coupon_over_amount': 'coupon.error.over.amount',
    'unavailable': 'coupon.error.unavailable',
    'cart_session_timeout': 'cart.session.timeout'
  };

  couponList = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CouponDialogComponent>,
    snackBar: MatSnackBar,
    private memberCouponService: MemberCouponService,
    private loadingService: FullScreenLoadingService,
    private shoppingCartService: ShoppingCartService,
    private translate: TranslateService) {
    super(snackBar);
  }

  ngOnInit(): void {
    this.memberCouponService.queryAvailable().subscribe(data => {
      this.couponList = data;
    });
  }

  addCoupon(couponId) {
    // this.loadingService.show();
    this.shoppingCartService.addCoupon(couponId)
      .pipe(
        switchMap(result => {
          if (result.success) {
            this.dialogRef.close(true);
          } else {
            // const msgKey = this.couponErrorMap[result.errorCode] || 'error';
            // this.showError(`${this.translate.instant('warning')}: ${this.translate.instant(msgKey)}`);
            this.showRespResultError(result, this.couponErrorMap);
            if (result.errorCode === 'cart_session_timeout') {
              this.dialogRef.close(true);
            }
          }
          return EMPTY;
        }),
        // finalize(() => this.loadingService.hide())
      )
      .subscribe();
  }

}
