import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GoogleLoginService } from '../shared/service/google-login.service';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { BasicViewComponent } from '../basic-view-component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, distinctUntilChanged, take, takeUntil } from 'rxjs';


declare var google;

@Component({
  selector: 'app-google-login-button',
  templateUrl: './google-login-button.component.html',
  styleUrls: ['./google-login-button.component.scss']
})
export class GoogleLoginButtonComponent extends BasicViewComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() action: 'login' | 'connect' = 'login';

  errorMap = {
    'unexpected_exception': 'unexpected.error',
    'invalid_input': 'invalid.input',
    'email_required': 'single.sign.on.login.error.email.required',
  };

  loading: boolean;

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy$ = new Subject<void>();

  @ViewChild('googleSigninButton', { read: ElementRef }) googleSigninButton: ElementRef;

  constructor(
    snackBar: MatSnackBar,
    protected googleLoginService: GoogleLoginService,
    private readonly socialAuthService: SocialAuthService,
  ) {
    super(snackBar);
  }

  ngOnInit() {
    this.googleLoginService.loading$.pipe(
      distinctUntilChanged(),
      takeUntil(this._onDestroy$)
    ).subscribe(async loading => {
      this.loading = loading;
    });
  }

  ngAfterViewInit(): void {
    this.socialAuthService.initState.pipe(
      take(1),
      takeUntil(this._onDestroy$)
    ).subscribe(() => {
      google.accounts.id.renderButton(this.googleSigninButton.nativeElement, {
        type: 'standard',
        size: 'large',
        text: this.action == 'login' ? 'signin_with' : 'continue_with',
        width: '',
        shape: 'rectangular',
        theme: 'outline',
        logo_alignment: 'center',
        locale: '',
        click_listener: this.onClick
      });
    })
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  onClick = async () => {
    this.googleLoginService.action = this.action;
  }

}
