import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[whenErrorCode]',
    exportAs: 'whenErrorCode',
})
export class InputErrorMessageWhenErrorCodeDirective {

    private errorCodeList = [];

    @Input() set whenErrorCode(val: string | string[]) {

        if (val == null || val === undefined) {
            return;
        }

        let newErrorCodeList = null;
        if (Array.isArray(val)) {
            newErrorCodeList = val;
        } else {
            newErrorCodeList = [val];
        }

        const changed = this.errorCodeList.some(errorCode => !newErrorCodeList.includes(errorCode));

        this.errorCodeList = newErrorCodeList;

        if (changed) {
            this.updateView();
        }
    }

    private _errors: { [key: string]: any } = {};

    @Input() set errors(val: { [key: string]: any }) {
        const newErros = val ?? {};
        const oldKeys = Object.keys(this._errors);
        const newKeys = Object.keys(newErros);
        const changed = oldKeys.length !== newKeys.length || oldKeys.some(key => !newKeys.includes(key));

        this._errors = newErros;

        if (changed) {
            this.updateView();
        }

    }

    private _viewRef = null;

    constructor(private vcRef: ViewContainerRef, private templateRef: TemplateRef<any>) { }

    updateView() {
        console.log('whenErrorCode updateView', this.errorCodeList, this._errors);

        if (this.errorCodeList?.length === 0) {
            return;
        }

        const hasError = Object.keys(this._errors)?.some(error => this.errorCodeList.includes(error));

        if (hasError) {
            if (!this._viewRef) {
                this._viewRef = this.vcRef.createEmbeddedView(this.templateRef);
            }
        } else {
            this.vcRef.clear();
            this._viewRef = null;
        }
    }

}