import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HeaderSizeService {

  private height$ = new BehaviorSubject(174);

  constructor() { }

  public setHeight(height) {
    this.height$.next(height);
  }
  
  public getHeight(): Observable<number> {
    return this.height$.pipe(distinctUntilChanged());
  }
}
