import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SystemSettingService } from '../api/system-setting.service';

@Component({
  selector: 'app-register-complete',
  templateUrl: './register-complete.component.html',
  styleUrls: ['./register-complete.component.scss']
})
export class RegisterCompleteComponent implements OnInit {

  emailAddress: string;
  authCodeResetTimeInDay;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private systemSettingService: SystemSettingService) {
      const state = router.getCurrentNavigation().extras.state;
      if (state && state.emailAddress) {
        this.emailAddress = state.emailAddress;
        sessionStorage.setItem('emailAddress', this.emailAddress);
      } else {
        this.emailAddress = sessionStorage.getItem('emailAddress');
      }

    }

  ngOnInit(): void {
    // this.emailAddress = this.route.snapshot.paramMap.get('emailAddress');
    this.systemSettingService.getMemberSetting().subscribe(data => {
      this.authCodeResetTimeInDay = data.authCodeResetTimeInDay;
    });
  }

}
