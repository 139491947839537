import { Component, OnInit } from '@angular/core';
import { GoodsSeriesService } from '../api/goods-series.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CategoryService } from '../api/category.service';
import { switchMap, tap, finalize } from 'rxjs/operators';
import { WarehouseService } from '../api/warehouse.service';
import { ShoppingCartService } from '../api/shopping-cart.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicViewComponent } from '../basic-view-component';
import { TranslateService } from '@ngx-translate/core';
import { FullScreenLoadingService } from '../shared/service/full-screen-loading.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-goods-series',
  templateUrl: './goods-series.component.html',
  styleUrls: ['./goods-series.component.scss']
})
export class GoodsSeriesComponent extends BasicViewComponent implements OnInit {

  errorMap = { 'not_enough_stock': 'Not enough Stock' };

  loading = false;

  goodsSeries: any = {};
  categoryList;
  warehouseList;

  destinationGroupList$: Observable<any[]>;

  qty = 1;

  swiperConfig = {
    observer: true,
    initialSlide: 0,
    slidesPerView: 1,
    // centeredSlides: true,
    zoom: true,
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
      loadOnTransitionStart: true,
    },
    // loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
      hideOnClick: true
    },
    mousewheel: {

    },
    resizeObserver: true,
  };

  swiperThumbsConfig = {
    direction: 'vertical',
    observer: true,
    initialSlide: 0,
    slidesPerView: 'auto',
    spaceBetween: 20,
    centeredSlides: true,
    slideToClickedSlide: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    mousewheel: {

    },
    resizeObserver: true,
  };

  constructor(private router: Router,
    private route: ActivatedRoute,
    snackBar: MatSnackBar,
    private translate: TranslateService,
    private loadingService: FullScreenLoadingService,
    private goodsSeriesService: GoodsSeriesService,
    private categoryService: CategoryService,
    private warehouseService: WarehouseService,
    private shoppingCartService: ShoppingCartService) {

    super(snackBar);
  }

  ngOnInit() {
    this.route.params
      .pipe(
        switchMap(params => {
          this.loading = true;
          const id = params.id;
          this.destinationGroupList$ = this.goodsSeriesService.queryDestinationGroup(id);
          return this.getGoodsSeries(id);
        }),
        switchMap(goodsSeries => {
          return this.categoryService.queryRootPath(goodsSeries.categoryId);
        }),
        tap(rootPath => {
          this.categoryList = rootPath;
        }),
        tap(() => {
          this.loading = false;
        })
      ).subscribe();

    this.warehouseService.query().subscribe(data => {
      this.warehouseList = data;
    });

  }

  getGoodsSeries(goodsSeriesId): Observable<any> {
    return this.goodsSeriesService.get(goodsSeriesId)
      .pipe(
        tap(goodsSeries => {
          this.goodsSeries = goodsSeries;
          goodsSeries.goodsList.forEach(goods => {
            let initQty = 1;
            if (goods.qtyLimitPerOrder) {
              if (goods.minQtyPerOrder > 0) {
                initQty = goods.minQtyPerOrder;
              }
              if (initQty > goods.maxQtyPerOrder) {
                initQty = goods.maxQtyPerOrder;
              }
            }
            goods.qty = initQty;
          });
          return goodsSeries;
        })
      );
  }

  refreshGoodsSeries() {
    const goodsSeriesId = this.route.snapshot.params.id;
    this.getGoodsSeries(goodsSeriesId).subscribe();
  }

  minusQty(goods) {
    if (this.canMinusQty(goods)) {
      goods.qty--;
    }
  }

  plusQty(goods) {
    if (this.canPlusQty(goods)) {
      goods.qty++;
    }
  }

  canMinusQty(goods) {
    return goods.qty > 1 && (!goods.qtyLimitPerOrder || (goods.qty > goods.minQtyPerOrder));
  }

  canPlusQty(goods) {
    return !goods.qtyLimitPerOrder || (goods.qty < goods.maxQtyPerOrder);
  }

  onGoodsQtyInputBlur(goods) {
    if (goods.qty === null) {
      goods.qty = 1;
    }
    // console.log('goods.qty', goods.qty);
    // console.log('goods.qtyLimitPerOrder', goods.qtyLimitPerOrder);
    // console.log('goods.minQtyPerOrder', goods.minQtyPerOrder);
    // console.log('goods.maxQtyPerOrder', goods.maxQtyPerOrder);

    if (goods.qtyLimitPerOrder) {
      if (goods.qty < goods.minQtyPerOrder) {
        goods.qty = goods.minQtyPerOrder;
      } else if (goods.qty > goods.maxQtyPerOrder) {
        goods.qty = goods.maxQtyPerOrder;
      }
    }
  }

  findStock(warehouseId: number, stockList: [any]) {
    return stockList.find(d => d.warehouseId === warehouseId);
  }

  // isOutOfStock(stockList: [any]): boolean {
  //   return stockList.every(d => d.stock <= 0);
  // }

  addGoods(goodsSeriesId, goodsId, qty, productId, productVariantId) {
    this.loadingService.show();
    this.shoppingCartService.addGoodsWithSeries(goodsSeriesId, goodsId, qty, productId, productVariantId)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(result => {
        if (result.success) {
          this.showMessage(this.translate.instant('add.product.successfully'));
          this.shoppingCartService.refreshCount();
          this.refreshGoodsSeries();

        } else {
          // const msgKey = this.errorMap[result.errorCode] || 'error';
          // this.showError(`${this.translate.instant('error')}: ${result.msg || ''}`); // ${this.translate.instant(msgKey)}, 
          this.showRespResultError(result, this.errorMap);
        }
      });
  }

  range(start, end): number[] {
    return Array.from(new Array(end - start + 1), (x, i) => start + i);
  }

  rangeByGoods(goods): number[] {
    let start = 1, end = 5;
    if (goods.qtyLimitPerOrder) {
      start = goods.minQtyPerOrder;
      end = goods.maxQtyPerOrder;
    }
    return this.range(start, end);
  }

}
