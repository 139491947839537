import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InfoPageService } from '../api/info-page.service';
import { Location } from '@angular/common';
import { LocalSettingService } from '../shared/service/local-setting.service';
import { switchMap } from 'rxjs/operators';
import { Project } from '../app-config';
import { PageMetaService } from '../shared/service/page-meta.service';

@Component({
  selector: 'app-info-page',
  templateUrl: './info-page.component.html',
  styleUrls: ['./info-page.component.scss']
})
export class InfoPageComponent implements OnInit {

  id: number;
  infoPageId;

  infoPage: any = {};

  constructor(private router: Router,
    private route: ActivatedRoute,
    private infoPageService: InfoPageService,
    private pageMetaService: PageMetaService,) {

  }

  ngOnInit(): void {
    // this.route.params.subscribe(params => {
    //   this.id = params.id;
    // });

    this.route.params.subscribe(params => {
      const paramList = params.idWithSlug.split('-');
      this.infoPageId = paramList[0];
      const slug = params.idWithSlug.substring(this.infoPageId.length + 1);

      if (paramList.length < 2) {
        this.redirect404Page();
        return;
      }

      this.infoPageService.get(this.infoPageId).subscribe(data => {
        if (data.slug !== slug) {
          this.redirect404Page();
          return;
        }
        this.infoPage = data;

        const title = this.infoPage.name + ' | ' + Project.title;
        this.pageMetaService.generateTags({
          title: title,
          description: this.infoPage.description,
          slug: slug
        });
      });
    });
  }

  redirect404Page() {
    this.router.navigateByUrl('404', { replaceUrl: true, skipLocationChange: true });
  }

}
