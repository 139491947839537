import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { FullScreenLoadingService } from '../shared/service/full-screen-loading.service';
import { MemberService } from '../api/member.service';
import { finalize } from 'rxjs/operators';
import { BasicViewComponent } from '../basic-view-component';

@Component({
  selector: 'app-member-activate',
  templateUrl: './member-activate.component.html',
  styleUrls: ['./member-activate.component.scss']
})
export class MemberActivateComponent extends BasicViewComponent implements OnInit {

  loading = true;

  memberId;
  authCode: string;

  isActivatedSuccess = false;
  isExpired = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    protected snackBar: MatSnackBar,
    private translate: TranslateService,
    private memberService: MemberService,
    private loadingService: FullScreenLoadingService) {

    super(snackBar);
    // this.route.queryParams.subscribe(params => {
    //   this.memberId = params['memberId'];
    //   this.authCode = params['authCode'];
    // });
    const fragment = this.route.snapshot.fragment;
    const urlHash = new URLSearchParams(fragment);
    this.memberId = urlHash.get('memberId') || '';
    this.authCode = urlHash.get('authCode') || '';

  }

  ngOnInit(): void {
    this.memberService.verifyAuthCode(this.memberId, this.authCode)
      .pipe(finalize(() => this.loading = false))
      .subscribe(valid => {
        this.isExpired = !valid;
        if (valid) {
          this.activate();
        }
      });
  }

  activate() {
    this.loading = true;
    const data = { 'authCode': this.authCode };
    const api = this.memberService.activate(this.memberId, data);
    api.pipe(finalize(() => this.loading = false)).subscribe(result => {
      if (result.success) {
        this.isActivatedSuccess = true;
      } else {
        if (result.errorCode === 'expired') {
          this.isExpired = true;
        }
      }

    });
  }

}
