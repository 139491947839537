import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { FullScreenLoadingService } from '../shared/service/full-screen-loading.service';
import { MemberService } from '../api/member.service';
import { finalize } from 'rxjs/operators';
import { LogoutService } from '../api/logout.service';
import { BasicViewComponent } from '../basic-view-component';
import { ForgotPasswordService } from '../api/forgot-password.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends BasicViewComponent implements OnInit {

  form: UntypedFormGroup;

  errorMap = { 'invalid_input': 'invalid.input' };

  memberId;
  emailAddress;
  authCode;

  loading = false;
  isExpired = false;

  constructor(private router: Router,
    protected snackBar: MatSnackBar,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private loadingService: FullScreenLoadingService,
    private forgotPasswordService: ForgotPasswordService,
    private logoutService: LogoutService,
    private route: ActivatedRoute,
    private memberService: MemberService) {
    super(snackBar);

    const fragment = this.route.snapshot.fragment;
    const urlHash = new URLSearchParams(fragment);
    this.memberId = urlHash.get('memberId') || '';
    this.emailAddress = urlHash.get('emailAddress') || '';
    this.authCode = urlHash.get('authCode') || '';


    this.form = fb.group({
      password: ['', [Validators.minLength(6), Validators.maxLength(16)]],
      confirmPassword: [''],
    }, {
      validators: checkIfMatchingPasswords('password', 'confirmPassword')
    });
  }


  ngOnInit() {
    this.loading = true;
    this.memberService.verifyAuthCode(this.memberId, this.authCode)
      .pipe(finalize(() => this.loading = false))
      .subscribe(valid => {
        this.isExpired = !valid;
      });
  }

  save() {
    this.loadingService.show();
    const formData = this.form.getRawValue();

    const password = formData.password;

    const api = this.forgotPasswordService.resetPassword(this.memberId, this.authCode, password);
    api.pipe(finalize(() => this.loadingService.hide())).subscribe(result => {
      // console.log(result);
      if (result.success) {
        this.showMessage(this.translate.instant('reset.password.success'));
        this.router.navigate(['/login']);
      } else {
        // const msgKey = this.errorMap[result.errorCode] || 'error';
        // this.showError(`${this.translate.instant('error')}: ${this.translate.instant(msgKey)}, ${result.msg}`);
        this.showError(this.translate.instant('reset.password.fail'));
      }

    });
  }

}


const checkIfMatchingPasswords = (passwordKey: string, passwordConfirmationKey: string): ValidatorFn => {
  return (group: UntypedFormGroup): ValidationErrors => {
    const passwordInput = group.controls[passwordKey],
      passwordConfirmationInput = group.controls[passwordConfirmationKey];
    if (passwordInput.value !== passwordConfirmationInput.value) {
      passwordConfirmationInput.setErrors({ notEquivalent: true, reason: passwordConfirmationInput });
    } else {
      passwordConfirmationInput.setErrors(null);
    }
    return null;
  };
};
