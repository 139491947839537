import { Injectable, Injector } from '@angular/core';
import { HttpBackend, HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { ApiService } from './api.service';
import { API } from '../app-config';
import { LocalSettingService } from '../shared/service/local-setting.service';
import { Observable } from 'rxjs';
import { TokenInterceptor } from './interceptor/token-interceptor';

@Injectable({
    providedIn: 'root'
})
export class VisitLogService extends ApiService {
    private httpClient: HttpClient;
    constructor(private httpBackend: HttpBackend, private localSetting: LocalSettingService, private injector: Injector) {
        super();
        this.httpClient = new HttpClient(httpBackend);


    }

    log(type: string, data: string) {


        const url = `${API.rootPath}/visit-log`;
        // const params = { 'lang': this.localSetting.getLangForApi() };
        // this.httpClient.post<any[]>(url, { 'type': type, 'data': data }, { params: params, withCredentials: true }).subscribe();

        const params = new HttpParams({ fromObject: { 'lang': this.localSetting.getLangForApi() } });

        const req = new HttpRequest('POST', url, { 'type': type, 'data': data }, { params: params, withCredentials: true });

        const tokenHandler = new HttpInterceptorHandler(this.httpBackend, new TokenInterceptor());

        tokenHandler.handle(req).subscribe();
    }

}


export class HttpInterceptorHandler implements HttpHandler {
    constructor(private next: HttpHandler, private interceptor: HttpInterceptor) { }

    handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
        return this.interceptor.intercept(req, this.next);
    }
}
