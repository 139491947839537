import { Directive, Input, ViewContainerRef, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appNgVar]'
})
export class NgVarDirective {
  @Input('appNgVar')
  set ngVar(context: any) {
    this.context.$implicit = this.context.appNgVar = context;
    this.updateView();
  }

  private context: {
    $implicit: unknown;
    appNgVar: unknown;
  } = {
      $implicit: null,
      appNgVar: null,
    };

  private _viewRef = null;

  constructor(private vcRef: ViewContainerRef, private templateRef: TemplateRef<any>) { }

  updateView() {
    if (!this._viewRef) {
      this.vcRef.clear();
      this._viewRef = this.vcRef.createEmbeddedView(this.templateRef, this.context);
    }
  }

}
