import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { InfoPageService } from '../api/info-page.service';
import { Location } from '@angular/common';
import { LocalSettingService } from '../shared/service/local-setting.service';
import { Title, Meta } from '@angular/platform-browser';
import { Project } from '../app-config';
import { PageMetaService } from '../shared/service/page-meta.service';

@Component({
  selector: 'app-info-page-content',
  templateUrl: './info-page-content.component.html',
  styleUrls: ['./info-page-content.component.scss']
})
export class InfoPageContentComponent implements OnInit {

  currentLang: string;

  // _id: number;

  @Input()
  set id(value: number) {
    this.loadInfoPageContent(value);
  }

  infoPage: { name, content };

  constructor(private infoPageService: InfoPageService,
    private location: Location,
    private localSetting: LocalSettingService,
    private title: Title,
    private pageMetaService: PageMetaService) { }

  ngOnInit() {
    this.currentLang = this.localSetting.getLang();
  }

  loadInfoPageContent(id) {
    this.infoPageService.get(id).subscribe(data => {
      this.infoPage = data;

      const title = this.infoPage.name + ' | ' + Project.title;
      this.pageMetaService.generateTags({
        title: title
      });
    });
  }
}
