import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { LoginService } from './login.service';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LogoutService {

    constructor(private httpClient: HttpClient, private loginService: LoginService) {

    }

    logout(): Observable<any> {
        return this.httpClient.post('/logout', {}).pipe(tap(result => {
            if (result.success) {
                this.loginService.setLoggedIn(false);
            }
        }));
    }
}
