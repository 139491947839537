import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';

export class ReactiveFormUtil {
    public static removeErrors(keys: string[], control: AbstractControl) {
        if (!control || !keys || keys.length === 0) {
            return;
        }

        const remainingErrors = keys.reduce((errors, key) => {
            delete errors[key];
            return errors;
        }, { ...control.errors });

        control.setErrors(remainingErrors);

        if (Object.keys(control.errors || {}).length === 0) {
            control.setErrors(null);
        }
    }


    public static addErrors(errors: { [key: string]: any }, control: AbstractControl) {
        if (!control || !errors) {
            return;
        }

        control.setErrors({ ...control.errors, ...errors });
    }


    public static addFormControl(form: UntypedFormGroup, formControlPath: string, formControl: AbstractControl) {
        // const formControl = this._tmpFormControl[formControlPath];
        if (formControl) {
            const parentFormGroupName = formControlPath.substring(0, formControlPath.lastIndexOf('.'));
            let parentFormGroup: UntypedFormGroup = form;
            if (parentFormGroupName) {
                parentFormGroup = form.get(parentFormGroupName) as UntypedFormGroup;
            }
            const formControlName = formControlPath.substring(formControlPath.lastIndexOf('.') + 1, formControlPath.length);
            console.log(`addFormControl, parentFormGroupName:${parentFormGroupName}, formControlName:${formControlName}`);

            parentFormGroup.addControl(formControlName, formControl);
        }
    }

    public static removeFormControl(form: UntypedFormGroup, formControlPath: string): AbstractControl {
        const formControl = form.get(formControlPath);
        if (formControl) {
            const parentFormGroupName = formControlPath.substring(0, formControlPath.lastIndexOf('.'));
            let parentFormGroup: UntypedFormGroup = form;
            if (parentFormGroupName) {
                parentFormGroup = form.get(parentFormGroupName) as UntypedFormGroup;
            }
            const formControlName = formControlPath.substring(formControlPath.lastIndexOf('.') + 1, formControlPath.length);
            console.log(`removeFormControl, parentFormGroupName:${parentFormGroupName}, formControlName:${formControlName}`);
            parentFormGroup.removeControl(formControlName);
        }
        return formControl;
    }

    public static isFormGroup(control: AbstractControl): control is UntypedFormGroup {
        return !!(<UntypedFormGroup>control).controls;
    }

    public static collectErrors(control: AbstractControl): any | null {
        if (this.isFormGroup(control)) {
            return Object.entries(control.controls)
                .reduce(
                    (acc, [key, childControl]) => {
                        const childErrors = this.collectErrors(childControl);
                        if (childErrors) {
                            acc = { ...acc, [key]: childErrors };
                        }
                        return acc;
                    },
                    null
                );
        } else {
            return control.errors;
        }
    }

    /* 
   Returns an array of invalid control/group names, or a zero-length array if 
   no invalid controls/groups where found 
*/
    public static findInvalidControlsRecursive(formToInvestigate: UntypedFormGroup | UntypedFormArray): string[] {
        var invalidControls: string[] = [];
        let recursiveFunc = (form: UntypedFormGroup | UntypedFormArray) => {
            Object.keys(form.controls).forEach(field => {
                const control = form.get(field);
                if (control.invalid) {
                    invalidControls.push(field);
                    console.log('invalid control', field, control)
                    console.log('control errors', control.errors)
                }
                if (control instanceof UntypedFormGroup) {
                    recursiveFunc(control);
                } else if (control instanceof UntypedFormArray) {
                    recursiveFunc(control);
                }
            });
        }
        recursiveFunc(formToInvestigate);
        return invalidControls;
    }
}
