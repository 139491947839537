import { Component, OnInit, ViewChild, OnDestroy, HostListener, ElementRef, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GoodsSeriesService } from '../api/goods-series.service';
import { BrandService } from '../api/brand.service';
import { fromEvent, Observable, Subject } from 'rxjs';
import { Options, LabelType, ChangeContext } from '@angular-slider/ngx-slider';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatSelectionListChange } from '@angular/material/list';
import { MatDrawer } from '@angular/material/sidenav';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Title, Meta } from '@angular/platform-browser';
import { debounceTime, takeUntil, tap, switchMap, finalize } from 'rxjs/operators';
import { CategoryService } from '../api/category.service';
import { GoodsService } from '../api/goods.service';
import { Project } from '../app-config';
import { PageMetaService } from '../shared/service/page-meta.service';
import { SystemSettingService } from '../api/system-setting.service';
import { HeaderSizeService } from '../shared/service/header-size.service';

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit, OnDestroy {

  private _onDestroy = new Subject<void>();

  @ViewChild('filterDrawer') filterDrawer: MatDrawer;
  @ViewChild('filterDialog') filterDialog: ElementRef;

  private refresh$ = new Subject<void>();

  brandId;
  goodsList = [];

  brandList: any[];
  tagList: any[];

  page = 1;
  loading = false;
  noMore = false;
  limit = 30;

  filter;

  brand: any = {};

  showBrandFilter = false;

  categoryTreeControl = new NestedTreeControl<any>(node => node.subcategoryList, { trackBy: node => node.id });
  categoryTreeDataSource = new MatTreeNestedDataSource<any>();

  showFilter = false;

  priceRangeSliderOption: Options = {
    animate: false,
    hideLimitLabels: true,
    floor: 0,
    ceil: 100_000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '$' + value;
        case LabelType.High:
          return '$' + value;
        default:
          return '$' + value;
      }
    }

  };

  sortByOptionList = [{
    label: 'bestselling',
    field: 'selling',
    icon: 'fa-fire',
    ascending: false,
  },
  {
    label: 'name.ascending',
    icon: 'fa-sort-alpha-down',
    field: 'name',
    ascending: true,
  },
  {
    label: 'name.descending',
    field: 'name',
    icon: 'fa-sort-alpha-up',
    ascending: false,
  },
  {
    label: 'price.ascending',
    field: 'price',
    icon: 'fa-sort-amount-down-alt',
    ascending: true,
  },
  {
    label: 'price.descending',
    field: 'price',
    icon: 'fa-sort-amount-up-alt',
    ascending: false,
  },
  {
    label: 'date.ascending',
    field: 'date',
    icon: 'fa-clock',
    ascending: true,
  },
  {
    label: 'date.descending',
    field: 'date',
    icon: 'fa-history',
    ascending: false,
  }];

  sortBy: { field, ascending } = this.sortByOptionList[0];

  scrolled = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private goodsService: GoodsService,
    private categoryService: CategoryService,
    private brandService: BrandService,
    private pageMetaService: PageMetaService,
    private systemSettingService: SystemSettingService,
    private headerSizeService: HeaderSizeService,
    private renderer: Renderer2,
  ) { }


  ngOnInit() {

    // this.sortBy = this.sortByOptionList[0];

    this.categoryService.queryTree().subscribe(data => {
      this.categoryTreeDataSource.data = data;
      // this.categoryTreeControl.;
    });

    this.route.params.subscribe(params => {
      const paramList = params.idWithSlug.split('-');
      this.brandId = paramList[0];
      const slug = params.idWithSlug.substring(this.brandId.length + 1);
      this.initFilter();
      this.goodsList = [];
      this.page = 1;
      this.noMore = false;
      this.loadGoods().subscribe();

      if (paramList.length < 2) {
        this.redirect404Page();
        return;
      }

      this.brandService.get(this.brandId).subscribe(data => {
        if (data.slug !== slug) {
          this.redirect404Page();
          return;
        }
        this.brand = data;

        const title = this.brand.name + ' | ' + Project.title;
        this.pageMetaService.generateTags({
          title: title,
          description: this.brand.description,
          slug: slug
        });
      });

      this.brandService.getGoodsFilterOptions(this.brandId).subscribe(data => {
        this.brandList = data.brandList;
        this.tagList = data.tagList;

        this.priceRangeSliderOption = Object.assign({},
          this.priceRangeSliderOption,
          { floor: data.priceRange.minLimit, ceil: data.priceRange.maxLimit });

        this.filter.priceRange.min = data.priceRange.minLimit;
        this.filter.priceRange.max = data.priceRange.maxLimit;
      });
    });

    this.systemSettingService.getGoodsSetting().subscribe(data => {
      this.showBrandFilter = data.showBrandName;
    });

    fromEvent(window, 'resize').pipe(
      debounceTime(100),
      takeUntil(this._onDestroy)
    ).subscribe(() => this.loading || this.noMore || this.loadMore());


    this.refresh$.pipe(
      debounceTime(500),
      tap(() => this.loading = true),
      switchMap(() => {

        this.scrollToTop();

        console.log('refresh', this.filter, this.sortBy);
        this.goodsList = [];
        this.page = 1;
        this.noMore = false;

        return this.queryGoods();
      }),
      tap(() => this.loading = false),
      takeUntil(this._onDestroy)
    ).subscribe();

    this.headerSizeService.getHeight().subscribe(height => {
      if (this.filterDialog) {
        const filterDialogDom = this.filterDialog.nativeElement;

        this.renderer.setStyle(filterDialogDom, 'max-height', `calc(100vh - ${height}px)`);
      }
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  // queryGoodsSeries() {
  //   this.loading = true;

  //   this.categoryService.queryGoodsSeries(this.categoryId, this.page, this.limit).pipe(finalize(() => this.loading = false))
  //     .subscribe(data => {
  //       if (data && data.length) {
  //         this.goodsSeriesList.push(...data);
  //       }
  //       if (!data || data.length < this.limit) {
  //         this.noMore = true;
  //       }
  //     });
  // }

  queryGoods(): Observable<any> {
    const filterData = JSON.parse(JSON.stringify(this.filter));

    // no min price if filter by min price
    if (filterData.priceRange.min <= this.priceRangeSliderOption.floor) {
      filterData.priceRange.min = null;
    }

    // no max price if filter by max price
    if (filterData.priceRange.max >= this.priceRangeSliderOption.ceil) {
      filterData.priceRange.max = null;
    }

    return this.brandService.queryGoods(this.brandId, filterData, this.sortBy, this.page, this.limit)
      .pipe(
        tap(data => {
          if (data && data.length) {
            this.goodsList.push(...data);
          }
          if (!data || data.length < this.limit) {
            this.noMore = true;
          }
        })
      );
  }

  loadGoods(): Observable<any> {
    this.loading = true;
    return this.queryGoods().pipe(finalize(() => this.loading = false));
  }

  loadMore() {
    this.page++;
    this.loadGoods().subscribe();
  }

  // initFilter() {
  //   this.filter = {
  //     brandIdList: [],
  //     tagIdList: [],
  //     hasDiscount: false,
  //     isHot: false,
  //     priceRange: {
  //       min: null,
  //       max: null,
  //     }
  //   };
  // }

  initFilter() {
    this.filter = {
      brandIdList: [],
      tagIdList: [],
      hasDiscount: false,
      isHot: false,
      priceRange: {
        min: 0,
        max: 100_000,
      }
    };
  }

  refresh() {
    this.refresh$.next();
  }

  hasSubcategory = (_: number, node: any) => !!node.subcategoryList && node.subcategoryList.length > 0;

  addCategoryFilter(categoryId) {
    console.log('add to filter categoryId', categoryId);  // add category to filter
  }


  // Brand
  brandFilterChange(event: MatSelectionListChange) {
    const brandIdList = event.source.selectedOptions.selected.map(option => option.value.id);

    this.filter.brandIdList = brandIdList;
    this.refresh();
  }

  isBrandSelected(brand) {
    return this.filter.brandIdList.some(brandId => brandId === brand.id);
  }

  findBrandById(brandId) {
    return this.brandList.find(brand => brand.id === brandId);
  }

  // removeBrandFilterOption(brandId) {
  //   const index = this.filter.brandIdList.indexOf(brandId);
  //   if (index > -1) {
  //     this.filter.brandIdList.splice(index, 1);
  //     this.refresh();
  //   }
  // }

  isFilterByBrand() {
    return this.filter?.brandIdList?.length > 0;
  }
  removeBrandFilter() {
    this.filter.brandIdList = [];
    this.refresh();
  }


  // Tag
  tagFilterChange(event: MatSelectionListChange) {
    const tagIdList = event.source.selectedOptions.selected.map(option => option.value.id);

    this.filter.tagIdList = tagIdList;
    this.refresh();
  }

  isTagSelected(tag) {
    return this.filter.tagIdList.some(tagId => tagId === tag.id);
  }

  findTagById(tagId) {
    return this.tagList.find(tag => tag.id === tagId);
  }

  // removeTagFilterOption(tagId) {
  //   const index = this.filter.tagIdList.indexOf(tagId);
  //   if (index > -1) {
  //     this.filter.tagIdList.splice(index, 1);
  //     this.refresh();
  //   }
  // }

  isFilterByTag() {
    return this.filter?.tagIdList?.length > 0;
  }
  removeTagFilter() {
    this.filter.tagIdList = [];
    this.refresh();
  }


  // Special
  hasDiscountChange() {
    this.filter.hasDiscount = !this.filter.hasDiscount;
    this.refresh();
  }

  isHotChange() {
    this.filter.isHot = !this.filter.isHot;
    this.refresh();
  }



  isFilterBySpecialOffer() {
    return this.filter?.hasDiscount || this.filter?.isHot;
  }

  removeSpecialOfferFilter() {
    this.filter.hasDiscount = false;
    this.filter.isHot = false;
    this.refresh();
  }

  priceRangeChange(event: ChangeContext) {
    console.log('priceRangeChange', event);
    if (this.filter.priceRange.min !== event.value || this.filter.priceRange.max !== event.highValue) {
      this.filter.priceRange.min = event.value;
      this.filter.priceRange.max = event.highValue;
      this.refresh();
    }
  }

  isFilterByPriceRange() {
    return this.filter.priceRange.min !== this.priceRangeSliderOption.floor
      || this.filter.priceRange.max !== this.priceRangeSliderOption.ceil;
  }

  removePriceRangeFilter() {
    this.filter.priceRange.min = this.priceRangeSliderOption.floor;
    this.filter.priceRange.max = this.priceRangeSliderOption.ceil;
    this.refresh();
  }

  isFilter() {
    return this.isFilterByBrand() || this.isFilterBySpecialOffer() || this.isFilterByPriceRange() || this.isFilterByTag();
  }

  resetFilter() {
    this.initFilter();
    this.filter.priceRange.min = this.priceRangeSliderOption.floor;
    this.filter.priceRange.max = this.priceRangeSliderOption.ceil;
    this.refresh();
  }

  sortByChange() {
    this.refresh();
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
    this.filterDrawer.opened ? this.filterDrawer.close() : this.filterDrawer.open();
  }

  redirect404Page() {
    this.router.navigateByUrl('404', { replaceUrl: true, skipLocationChange: true });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    try {

      const scrollOffsetTop = (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop);

      this.scrolled = scrollOffsetTop > 0;

      // console.log('showScrollToTop', this.showScrollToTop);
    } catch (error) {
      console.error(error);
    }
  }

  scrollToTop() {
    (function smoothscroll() {
      const scrollContainer = window;
      const currentScroll = scrollContainer.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        scrollContainer.scrollTo({
          top: 0,
          behavior: 'auto'
        });
      }
    })();
  }
}
