import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericTableApiService } from './generic-table-api.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService extends GenericTableApiService {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'navigation');
  }

  getTermsAndCondition(): Observable<any> {
    return this.httpClient.get<any[]>(`${this.endpoint}/terms-and-condition`);
}
}
