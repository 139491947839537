import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericTableApiService } from './generic-table-api.service';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class PaymentService extends ApiService {

    constructor(private httpClient: HttpClient) {
        super();
    }

    queryPaymentMethod(): Observable<any> {
        return this.httpClient.get(`payment/method`);
    }

    getStatus(paymentId: number, authCode: string, rspCode: string = ''): Observable<any> {
        return this.httpClient.get(`payment/${paymentId}/status`, { headers: { 'authCode': authCode, 'rspCode': rspCode } });
    }

    /** @deprecated */
    getOrderStatus(orderId: number, authCode: string): Observable<any> {
        return this.httpClient.get(`payment/order/${orderId}/status`, { headers: { 'authCode': authCode } });
    }

    createPayment(paymentMethodId: string): Observable<any> {
        return this.httpClient.post(`payment`, { 'paymentMethodId': paymentMethodId });
    }

    getOrder(paymentId: number, authCode: string): Observable<any> {
        return this.httpClient.get(`payment/${paymentId}/order`, { headers: { 'authCode': authCode } });
    }
}
