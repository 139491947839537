import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class InformationDialogModel {
  constructor(public title: string, public content: string | string[]) {
  }
}

@Component({
  selector: 'app-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.css']
})
export class InformationDialogComponent implements OnInit {

  title;
  content;
  isContentArray;

  constructor(private dialogRef: MatDialogRef<InformationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.title = data.title;
      this.content = data.content;

      this.isContentArray = Array.isArray(data.content);
    }

  ngOnInit() {
  }

}
