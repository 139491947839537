import { NgModule } from '@angular/core';
// import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
// import { MatDatepickerModule, MAT_DATE_LOCALE } from '@angular/material';
// import { MomentDateAdapter, MatMomentDateAdapterOptions } from '@angular/material-moment-adapter';
import { BehaviorSubject } from 'rxjs';
import { Moment } from 'moment';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import {MatDatepickerModule} from '@angular/material/datepicker';

// import {
//     MatDatepickerModule, MAT_DATE_LOCALE, MatMomentDateModule, MAT_MOMENT_DATE_FORMATS,
//     MomentDateAdapter, DateAdapter, MAT_DATE_FORMATS, MatDateFormats
// } from '@coachcare/datepicker';


// export const MY_DATEPICKER_MOMENT_DATE_FORMATS: MatDateFormats = {
//     parse: {
//         dateInput: 'YYYY-MM-DD'
//     },
//     display: {
//         dateInput: 'YYYY-MM-DD',
//         monthYearLabel: 'YYYY-MM',
//         dateA11yLabel: 'YYYY-MM-DD',
//         monthYearA11yLabel: 'YYYY-MM'
//     }
// };


export const MY_DATEPICKER_MOMENT_DATE_FORMATS: MatDateFormats = {
    parse: {
        // date: ['YYYY-MM-DD', 'YYYY/MM/DD', 'll'],
        // datetime: ['YYYY-MM-DD HH:mm', 'YYYY/MM/DD HH:mm', 'll h:mma'],
        // time: ['H:mm', 'HH:mm', 'h:mm a', 'hh:mm a']
        dateInput: ['YYYY-MM-DD', 'YYYY/MM/DD', 'll']
    },
    display: {
        // date: 'YYYY-MM-DD',
        // datetime: 'YYYY-MM-DD HH:mm',
        // time: 'HH:mm',
        // dateA11yLabel: 'LL',
        // monthDayLabel: 'MMM Do',
        // monthDayA11yLabel: 'MMMM Do',
        // monthYearLabel: 'YYYY MMMM',
        // monthYearA11yLabel: 'YYYY MMMM',
        // timeLabel: 'HH:mm',

        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'YYYY MMMM',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY MMMM'

    }
};

export const datePickerLocale: BehaviorSubject<string> = new BehaviorSubject('');


// other way for output string instead of Date type
// How to change output format of material 2 date-picker
// https://stackoverflow.com/questions/47262449/how-to-change-output-format-of-material-2-date-picker
// https://stackblitz.com/edit/angular-dlxnmx?file=app%2Fcva-date.component.ts

export class LazyLoadingMomentDateAdapter extends MomentDateAdapter {
    subscription: any;

    // constructor(dateLocale: string, options?: MatMomentDateAdapterOptions) {
    //     super(dateLocale, options);

    //     // console.log('subscription', this.subscription);
    //     if (!this.subscription) {
    //         this.subscription = datePickerLocale.subscribe(locale => {
    //             this.setLocale(locale);
    //         });
    //     }
    // }

    constructor(dateLocale: string) {
        super(dateLocale);

        // console.log('subscription', this.subscription);
        if (!this.subscription) {
            this.subscription = datePickerLocale.subscribe(locale => {
                this.setLocale(locale);
            });
        }
    }

    // format(date: Moment, displayFormat: string): string {
    //     // console.log('subscription', this.subscription);

    //     // if (!this.subscription) {
    //     //     this.subscription = datePickerLocale.subscribe(locale => {
    //     //         console.log(Date.now());
    //     //         this.setLocale(locale);
    //     //     });
    //     // }
    //     return super.format(date, displayFormat);
    // }

    // // set sunday as first day of week;
    // getFirstDayOfWeek() {
    //     return 7;
    // }
}

@NgModule({
    imports: [MatDatepickerModule],
    // providers: [
    //     { provide: MAT_DATE_LOCALE, useValue: 'zh-HK' },
    //     {
    //         provide: DateAdapter,
    //         useClass: LazyLoadingMomentDateAdapter,
    //         deps: [MAT_DATE_LOCALE]
    //     },
    //     { provide: MAT_DATE_FORMATS, useValue: MY_DATEPICKER_MOMENT_DATE_FORMATS },
    // ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'zh-HK' },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
          },

          {provide: MAT_DATE_FORMATS, useValue: MY_DATEPICKER_MOMENT_DATE_FORMATS},
    ],
    exports: [
        MatDatepickerModule
    ]
})
export class SharedAngularMaterialDatepickerImportModule {
}
