import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export const integer: ValidatorFn = (control: AbstractControl): ValidationErrors => {

    const emptyError = Validators.required(control);

    if (emptyError !== undefined && emptyError !== null) {
        return null;
    }

    const v: any = control.value;
    const regex = /^-?[1-9]\d*$/;

    return regex.test(v) ? null : { 'integer': true };
};
