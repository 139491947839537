import { Directive, Output, EventEmitter, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective {

  // private _tag = '';

  // tslint:disable-next-line:no-input-rename
  @Input('appClickOutsideDisabled') clickOutsideDisabled = false;
  // tslint:disable-next-line:no-input-rename
  @Input('appClickOutsideIgnore') clickOutsideIgnore = '';

  @Output('appClickOutside')
  public clickOutside = new EventEmitter<MouseEvent>();

  constructor(private _elementRef: ElementRef) {
    // this._tag = 'ClickOutsideDirective ' + _elementRef.nativeElement.id;
  }

  @HostListener('document:click', ['$event', '$event.target'])
  @HostListener('document:touchstart', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    // console.log(this._tag);
    if (this.clickOutsideDisabled) {
      return;
    }

    if (!targetElement) {
      return;
    }

    if (this.clickOutsideIgnore) {
      const ignoreElementList = document.querySelectorAll(this.clickOutsideIgnore);
      for (let index = 0; index < ignoreElementList.length; index++) {
        const ignoreElement = ignoreElementList[index];
        if (ignoreElement.contains(targetElement)) {
          return;
        }
      }
    }

    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.clickOutside.emit(event);
    }
  }
}
