import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericTableApiService } from './generic-table-api.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AddOnPromotionService extends GenericTableApiService {

    constructor(httpClient: HttpClient) {
        super(httpClient, '');
    }

    getGoodsAddOnPromotion(goodsId: number): Observable<any> {
        return this.httpClient.get<any>(`goods/${goodsId}/add-on-promotion`);
    }

    getCartAddOnPromotion(): Observable<any> {
        return this.httpClient.get<any>(`shopping-cart/add-on-promotion`);
    }
}
