import { LoginGuard } from './routing-resolve/login-guard';
import { PermissionGuard } from './routing-resolve/permission-guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { NgModule } from '@angular/core';
import { CacheGrantedPermissionGuard } from './routing-resolve/cache-granted-permission-resolver';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PaymentResponseComponent } from './payment-response/payment-response.component';
import { PaymentResultComponent } from './payment-result/payment-result.component';
import { HomeComponent } from './home/home.component';
import { GoodsSeriesComponent } from './goods-series/goods-series.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { BlogArticleComponent } from './blog-article/blog-article.component';
import { CategoryComponent } from './category/category.component';
import { BrandComponent } from './brand/brand.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { PaymentComponent } from './payment/payment.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ShoppingCartLoginComponent } from './shopping-cart-login/shopping-cart-login.component';
import { ShoppingCartLoginGuard } from './routing-resolve/shopping-cart-login-guard';
import { BlogComponent } from './blog/blog.component';
import { BlogCategoryComponent } from './blog-category/blog-category.component';
import { BrandGoodsSeriesComponent } from './brand-goods-series/brand-goods-series.component';
import { SearchGoodsSeriesComponent } from './search-goods-series/search-goods-series.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { FaqComponent } from './faq/faq.component';
import { AboutDeliveryComponent } from './about-delivery/about-delivery.component';
import { AboutRefundComponent } from './about-refund/about-refund.component';
import { RetailStoreComponent } from './retail-store/retail-store.component';
import { InfoPageComponent } from './info-page/info-page.component';
import { GoodsComponent } from './goods/goods.component';

import { MemberActivateComponent } from './member-activate/member-activate.component';
import { RegisterCompleteComponent } from './register-complete/register-complete.component';
import { ForgotPasswordNotificationComponent } from './forgot-password-notification/forgot-password-notification.component';
import { SearchGoodsComponent } from './search-goods/search-goods.component';
import { EmailComponent } from './email/email.component';
import { CustomerServiceComponent } from './customer-service/customer-service.component';
import { PaymentInfoComponent } from './payment-info/payment-info.component';
import { ReturnPolicyComponent } from './return-policy/return-policy.component';
import { ShippingInfoComponent } from './shipping-info/shipping-info.component';
import { ShopAddressComponent } from './shop-address/shop-address.component';
import { BrandListComponent } from './brand-list/brand-list.component';
import { AdvertisementGoodsComponent } from './advertisement-goods/advertisement-goods.component';
import { SearchBlogComponent } from './search-blog/search-blog.component';
import { PromotionComponent } from './promotion/promotion.component';
import { PromotionDetailComponent } from './promotion-detail/promotion-detail.component';
import { PromotionCodeComponent } from './promotion-code/promotion-code.component';
import { CouponGuard } from './routing-resolve/coupon-guard';
import { CouponComponent } from './coupon/coupon.component';
import { NewsAndEventsPageItemComponent } from './news-and-events-page/news-and-events-page-item/news-and-events-page-item.component';
import { NewsAndEventsPageListComponent } from './news-and-events-page/news-and-events-page-list/news-and-events-page-list.component';
import { NewsAndEventsPageSearchListComponent } from './news-and-events-page/news-and-events-page-search-list/news-and-events-page-search-list.component';
import { VideoGalleryPageComponent } from './video-gallery-page/video-gallery-page.component';
import { FileDownloadPageComponent } from './file-download-page/file-download-page.component';
import { PhotoGalleryPageItemComponent } from './photo-gallery-page/photo-gallery-page-item/photo-gallery-page-item.component';
import { PhotoGalleryPageListComponent } from './photo-gallery-page/photo-gallery-page-list/photo-gallery-page-list.component';

// import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
// import { MemberAccountComponent } from './member-account/member-account.component';
// import { LinkExpiredComponent } from './link-expired/link-expired.component';

const routes: Routes = [
    // {
    //     path: 'main',
    //     loadChildren: './main/main.module#MainModule',
    //     // component: MainComponent,
    //     canLoad: [LoginGuard]
    // },
    {
        path: 'info-page/terms-and-conditions',
        component: TermsAndConditionsComponent
    },
    // {
    //     path: 'member-account/:id',
    //     component: MemberAccountComponent
    // },
    // {
    //     path: 'link-expired',
    //     component: LinkExpiredComponent
    // },
    // {
    //     path: 'login',
    //     component: LoginComponent
    // },
    { path: 'info-page/about-us', component: AboutUsComponent },
    { path: 'info-page/contact-us', component: ContactUsComponent },
    { path: 'info-page/faq', component: FaqComponent },
    { path: 'about-delivery', component: AboutDeliveryComponent },
    { path: 'about-refund', component: AboutRefundComponent },
    { path: 'retail-store', component: RetailStoreComponent },
    // { path: 'info-page/privacy-policy', component: PrivacyPolicyComponent },
    // { path: 'info-page/promotion', component: PromotionsComponent },
    // { path: 'info-page/members', component: MembersComponent },
    // { path: 'info-page/shipping', component: ShippingComponent },
    // { path: 'info-page/orders', component: OrdersComponent },
    // { path: 'info-page/returns-or-exchanges', component: ReturnAndExchangesComponent },
    { path: 'info-page/:idWithSlug', component: InfoPageComponent },
    { path: 'payment-response/:id', component: PaymentResponseComponent },
    { path: 'payment-result', component: PaymentResultComponent },
    { path: 'payment-result/order', component: PaymentResultComponent },
    { path: 'home', component: HomeComponent },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'register-complete', component: RegisterCompleteComponent },
    { path: 'blog-article/:id', component: BlogArticleComponent },
    { path: 'goods-series/:id', component: GoodsSeriesComponent },
    // { path: 'goods/:type/:id', component: GoodsComponent },
    { path: 'goods/:idWithSlug', component: GoodsComponent },
    { path: 'category/:idWithSlug', component: CategoryComponent },
    { path: 'brand', component: BrandListComponent },
    { path: 'brand/:idWithSlug', component: BrandComponent },
    { path: 'brand/:id/goods-series', component: BrandGoodsSeriesComponent },
    { path: 'shopping-cart-login', component: ShoppingCartLoginComponent },
    { path: 'shopping-cart', component: ShoppingCartComponent },
    { path: 'checkout', component: CheckoutComponent, canActivate: [ShoppingCartLoginGuard] },
    { path: 'payment', component: PaymentComponent, canActivate: [ShoppingCartLoginGuard] },
    // {
    //     path: 'member',
    //     component: MemberComponent,
    //     children: [
    //         { path: '', redirectTo: 'profile', pathMatch: 'full' },
    //         { path: 'profile', component: MemberProfileComponent },
    //         { path: 'order', component: MemberOrderComponent },
    //         { path: 'order/:id', component: MemberOrderDetailComponent },
    //         { path: 'address-book-list', component: MemberAddressBookListComponent },
    //         { path: 'rewards-points', component: MemberPointsRewardsComponent },
    //         { path: 'purchased-item', component: MemberPurchasedItemComponent },
    //         { path: 'reviews', component: MemberReviewComponent },
    //         { path: 'wish-list', component: MemberWishListComponent },
    //         { path: 'gift-redemption', component: MemberZoneComponent },
    //         { path: 'coupon', component: MemberCouponComponent },
    //         { path: 'coupon/:id', component: MemberCouponDetailComponent, canActivate: [CouponGuard] },
    //     ],
    //     canActivate: [LoginGuard]
    // },
    { path: 'activate', component: MemberActivateComponent },
    // { path: 'member/profile', component: MemberProfileComponent, canActivate: [LoginGuard] },
    // { path: 'member/order', component: MemberOrderComponent, canActivate: [LoginGuard] },
    // { path: 'member/order/:id', component: MemberOrderDetailComponent, canActivate: [LoginGuard] },
    // { path: 'member/address-book-list', component: MemberAddressBookListComponent, canActivate: [LoginGuard] },
    { path: 'blog', component: BlogComponent },
    { path: 'blog/:idWithSlug', component: BlogArticleComponent },
    { path: 'blog/category/:idWithSlug', component: BlogCategoryComponent },
    { path: 'search/goods-series', component: SearchGoodsSeriesComponent },
    { path: 'search/goods', component: SearchGoodsComponent },
    { path: 'search/blog', component: SearchBlogComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'forgot-password-notification', component: ForgotPasswordNotificationComponent },
    { path: 'reset-password', component: ResetPasswordComponent },

    { path: 'email', component: EmailComponent },
    { path: 'customer-service', component: CustomerServiceComponent },
    { path: 'payment-info', component: PaymentInfoComponent },
    { path: 'return-policy', component: ReturnPolicyComponent },
    { path: 'shipping-info', component: ShippingInfoComponent },
    { path: 'shop-address', component: ShopAddressComponent },

    { path: 'advertisement-goods/:id', component: AdvertisementGoodsComponent },
    { path: 'promotion/:idWithSlug', component: PromotionComponent },
    { path: 'promotion/:idWithSlug/detail', component: PromotionDetailComponent },
    { path: 'promotion/code/:code', component: PromotionCodeComponent },

    { path: 'coupon', component: CouponComponent },

    { path: 'news-and-events-page/:idWithSlug', component: NewsAndEventsPageListComponent },
    { path: 'news-and-events-page/:idWithSlug/item/:iIdWithSlug', component: NewsAndEventsPageItemComponent },
    { path: 'photo-gallery-page', component: PhotoGalleryPageListComponent },
    { path: 'photo-gallery-page/:idWithSlug', component: PhotoGalleryPageItemComponent },
    { path: 'video-gallery-page', component: VideoGalleryPageComponent },
    { path: 'file-download-page/:idWithSlug', component: FileDownloadPageComponent },
    { path: 'search/news-and-events-page', component: NewsAndEventsPageSearchListComponent },

    { path: '', component: HomeComponent },
    // { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: '404', component: PageNotFoundComponent },
    { path: '500', component: PageNotFoundComponent },
    {
        path: 'member',
        loadChildren: () => import('./member/member.module').then(m => m.MemberModule),
        canLoad: [LoginGuard]
    },
    { path: '**', component: PageNotFoundComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            routes,
            {
                onSameUrlNavigation: 'reload',
                scrollPositionRestoration: 'enabled',
                enableTracing: false, // <-- debugging purposes only
                preloadingStrategy: PreloadAllModules,
                anchorScrolling: 'enabled',
                scrollOffset: [0, 200],
            }
        )
    ],
    exports: [RouterModule],
    providers: [PermissionGuard, LoginGuard, ShoppingCartLoginGuard, CacheGrantedPermissionGuard, CouponGuard]
})
export class AppRoutingModule { }
