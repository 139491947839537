// for import external module

import { SharedAngularMaterialDatepickerImportModule } from './shared-angular-material-datepicker-import.module';
import { NgModule } from '@angular/core';
import { SharedAngularMaterialImportModule } from './shared-angular-material-import.module';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FileUploadModule } from 'ng2-file-upload';
import { EditorModule } from '@tinymce/tinymce-angular';
import { FileInputAccessorModule } from 'file-input-accessor';
import { StarRatingModule } from 'angular-star-rating';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';

import { LazyLoadImageModule, Attributes, IntersectionObserverHooks, LAZYLOAD_IMAGE_HOOKS } from 'ng-lazyload-image';

import { SwiperModule } from 'swiper/angular';

import { NgxImageZoomModule } from 'ngx-image-zoom';

import { Observable, of, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Lazy, Zoom, Autoplay } from 'swiper/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { RouterModule } from '@angular/router';

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Lazy, Zoom, Autoplay]);

// // ng-lazyload-image
// interface ObserverOptions {
//     root: Element | null;
//     rootMargin?: string;
// }
// export class LazyLoadImageHooks extends IntersectionObserverHooks {

//     private readonly _observers = new Map<Element | {} | string, IntersectionObserver>();
//     private readonly _intersectionSubject = new Subject<IntersectionObserverEntry>();
//     private readonly _uniqKey = {};

//     getObservable(attributes: Attributes<{ isIntersecting: boolean }>): Observable<{ isIntersecting: boolean }> {


//         if (this.skipLazyLoading(attributes)) {
//             return of({ isIntersecting: true });
//         }
//         if (attributes.customObservable) {
//             return attributes.customObservable;
//         }
//         let scrollContainerKey = this._uniqKey;
//         const scrollContainer = attributes.scrollContainer;
//         if (scrollContainer) {
//             scrollContainerKey = scrollContainer.tagName + '#' + scrollContainer.id + scrollContainer.className;
//         }

//         if (attributes.offset) {
//             scrollContainerKey = scrollContainerKey + '[' + attributes.offset + ']';
//         }

//         const options: ObserverOptions = {
//             root: attributes.scrollContainer || null,
//         };

//         if (attributes.offset) {
//             options.rootMargin = `${attributes.offset}px`;
//         }

//         let observer = this._observers.get(scrollContainerKey);

//         if (!observer) {
//             observer = new IntersectionObserver((entrys) => this._loadingCallback(entrys), options);
//             this._observers.set(scrollContainerKey, observer);
//         }

//         observer.observe(attributes.element);

//         return Observable.create((obs: Subject<IntersectionObserverEntry>) => {
//             const subscription = this._intersectionSubject.pipe(filter((entry) => entry.target === attributes.element)).subscribe(obs);
//             return () => {
//                 subscription.unsubscribe();
//                 observer.unobserve(attributes.element);
//             };
//         });
//     }

//     _loadingCallback(entrys: IntersectionObserverEntry[]) {
//         entrys.forEach((entry) => this._intersectionSubject.next(entry));
//     }
// }

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        StarRatingModule.forRoot(),
        ReactiveFormsModule,
        NgxMatSelectSearchModule,
        SwiperModule,
        NgxMatIntlTelInputComponent,
    ],
    providers: [
        // { provide: LAZYLOAD_IMAGE_HOOKS, useClass: LazyLoadImageHooks }
    ],
    declarations: [

    ],
    exports: [
        CommonModule,
        SharedAngularMaterialImportModule,
        SharedAngularMaterialDatepickerImportModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FileUploadModule,
        EditorModule,
        FileInputAccessorModule,
        StarRatingModule,
        InfiniteScrollModule,
        NgxPaginationModule,
        Ng2SearchPipeModule,
        SwiperModule,
        NgxSliderModule,
        LazyLoadImageModule,
        NgxImageZoomModule,
        RouterModule,
        NgxMatIntlTelInputComponent,
    ]
})
export class SharedAngularImportModule {
}
