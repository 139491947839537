import { Component, OnInit } from '@angular/core';
import { FacebookLoginService } from '../shared/service/facebook-login.service';
import { take, finalize, filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BasicViewComponent } from '../basic-view-component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-facebook-login-button',
  templateUrl: './facebook-login-button.component.html',
  styleUrls: ['./facebook-login-button.component.scss']
})
export class FacebookLoginButtonComponent extends BasicViewComponent implements OnInit {

  loading = false;

  errorMap = {
    'unexpected_exception': 'unexpected.error',
    'invalid_input': 'invalid.input',
    'email_required': 'single.sign.on.login.error.email.required',
  };

  constructor(
    snackBar: MatSnackBar,
    private translate: TranslateService,
    private facebookLoginService: FacebookLoginService,
  ) {
    super(snackBar);
  }

  ngOnInit() {
  }

  reload() {
    location.reload();
  }

  loginViaFacebook() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.facebookLoginService.onEnabled()
      .subscribe({
        next: () => {
          this.loading = true;
          this.facebookLoginService.login()
            .subscribe({
              next: resp => {
                if (resp.success) {
                  this.reload();
                } else {
                  // const errorCode = resp.errorCode;
                  // const msgKey = this.errorMap[errorCode] || 'error';
                  // const message = this.translate.instant(msgKey);
                  // this.showError(`${this.translate.instant('warning')}: ${message}`);
                  this.showRespResultError(resp, this.errorMap);
                }
              },
              complete: () => {
                this.loading = false;
              }
            });
        },
        error: () => {
          this.loading = false;
        }
      });
  }
}
