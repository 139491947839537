import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { BasicViewComponent } from '../basic-view-component';
import { ShoppingCartService } from '../api/shopping-cart.service';
import { FullScreenLoadingService } from '../shared/service/full-screen-loading.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of, Subject } from 'rxjs';
import { GoodsService } from '../api/goods.service';
import { AddCartSnackBarComponent } from 'src/app/add-cart-snack-bar/add-cart-snack-bar.component';

@Component({
  selector: 'app-bundle-goods-promotion-item',
  templateUrl: './bundle-goods-promotion-item.component.html',
  styleUrls: ['./bundle-goods-promotion-item.component.scss']
})
export class BundleGoodsPromotionItemComponent extends BasicViewComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() set promotion(pricePromotion) {
    this._promotion = pricePromotion;
    this.initPromotion(this._promotion);
  }
  get promotion() {
    return this._promotion;
  }
  _promotion;

  @Input() showHeader = true;
  @Output() addedToCart: EventEmitter<void> = new EventEmitter<void>();

  errorMap = {
    'not_enough_stock': 'not.enough.stock',
    'invalid_input': 'invalid.input'
  };

  showPromotion = true;

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(snackBar: MatSnackBar,
    private shoppingCartService: ShoppingCartService,
    private loadingService: FullScreenLoadingService,
    private translate: TranslateService,
    private goodsService: GoodsService) {

    super(snackBar);
  }

  ngOnInit() {
    // this.initPromotion(this.promotion);
  }

  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getPromotionGoodsList() {
    return this.promotion.bundleGoodsList.map(bundleGoods => bundleGoods.goods);
  }

  initPromotion(promotion) {
    if (promotion.bundleGoodsList) {
      this.getPromotionGoodsList().forEach((goods, itemIndex) => {
        const productVariant = goods.productList[0].productVariantList[0];
        goods.imageList = productVariant.imageList;
        goods.outOfStock = productVariant.outOfStock;
      });
    }
  }

  addGoods() {
    this.loadingService.show();

    const goodsList: Array<any> = [];
    this.promotion.bundleGoodsList.forEach(bundleGoods => {
      const goods = bundleGoods.goods;
      const goodsId = goods.id;
      const qty = bundleGoods.qty;

      const goodsProductList = goods.productList.map(product => {
        const productVariantId = product.productVariantList[0].id;
        return { productId: product.id, productVariantId: productVariantId };
      });

      goodsList.push({
        goodsId: goodsId,
        qty: qty,
        productList: goodsProductList
      });
    });

    this.shoppingCartService.addBundleGoods(goodsList, this.promotion.promotionId)
      .subscribe({
        next: result => {
          if (result.success) {
            this.showMessage(this.promotion.name + ' '
              + this.translate.instant('added.to.the'), AddCartSnackBarComponent);
            this.shoppingCartService.refreshCount();
            this.shoppingCartService.refresh();
            // this.refresh();
            this.addedToCart.emit();
          } else {
            // const msgKey = this.errorMap[result.errorCode] || 'error';
            // const message = this.translate.instant(msgKey, { goodsName: result.data?.goods });
            // this.showError(`${this.translate.instant('warning')}: ${message}`);
            this.showRespResultError(result, this.errorMap, { goodsName: result.data?.goods });
          }
        },
        complete: () => this.loadingService.hide()
      });
  }

  canAddToCart() {
    return !this.getPromotionGoodsList().some(goods => goods.outOfStock);
  }

  togglePromotion() {
    this.showPromotion = !this.showPromotion;
  }

  trackById(index, item) {
    return item.id;
  }

}
