import { Injectable } from '@angular/core';
import {
  CanLoad,
  Route,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild
} from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../api/login.service';
import { tap, map } from 'rxjs/operators';

@Injectable()
export class PermissionGuard implements CanActivate {



  constructor(private loginService: LoginService) {

  }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    console.log('PermissionGuard canActivate');
    const permission = route.data.permission;
    console.log('permission ID:' + JSON.stringify(permission));
    return this.loginService.hasPermission(permission);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    console.log('PermissionGuard canActivateChild');
    return this.canActivate(route, state);
  }
}
