import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PromotionService } from '../api/promotion.service';
import { tap } from 'rxjs/operators';
import { BasicViewComponent } from '../basic-view-component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { FullScreenLoadingService } from '../shared/service/full-screen-loading.service';
import { ShoppingCartService } from '../api/shopping-cart.service';
import { AddCartSnackBarComponent } from 'src/app/add-cart-snack-bar/add-cart-snack-bar.component';

@Component({
  selector: 'app-promotion-dialog',
  templateUrl: './promotion-dialog.component.html',
  styleUrls: ['./promotion-dialog.component.scss']
})
export class PromotionDialogComponent extends BasicViewComponent implements OnInit {

  errorMap = { 'not_enough_stock': 'not.enough.stock' };

  promotion;
  isSpecificGoodsDiscount = false;
  goods;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PromotionDialogComponent>,
    snackBar: MatSnackBar,
    private translate: TranslateService,
    private loadingService: FullScreenLoadingService,
    private promotionService: PromotionService,
    private shoppingCartService: ShoppingCartService) {
    super(snackBar);

    this.promotion = this.data.promotion;
  }

  ngOnInit(): void {
    this.isSpecificGoodsDiscount = this.promotion.actionList.some(action => action.type === 'specific_goods_discount');
    if (this.isSpecificGoodsDiscount) {
      this.promotionService.getPromotedSpecificGoods(this.promotion.id).pipe(
        tap(goods => {
          this.initGoods(goods);
        })
      ).subscribe(data => {
        this.goods = data;
      });
    }
  }

  minusQty(goods) {
    if (this.canMinusQty(goods)) {
      goods.qty--;
    }
  }

  plusQty(goods) {
    if (this.canPlusQty(goods)) {
      goods.qty++;
    }
  }

  canMinusQty(goods) {
    return goods.qty > 1 && (!goods.qtyLimitPerOrder || (goods.qty > goods.minQtyPerOrder));
  }

  canPlusQty(goods) {
    return (!goods.qtyLimitPerOrder || (goods.qty < goods.maxQtyPerOrder)) && goods.qty < goods.stock;
  }

  onGoodsQtyInputBlur(goods) {
    if (goods.qty === null) {
      goods.qty = 1;
    }

    if (goods.qtyLimitPerOrder) {
      if (goods.qty < goods.minQtyPerOrder) {
        goods.qty = goods.minQtyPerOrder;
      } else if (goods.qty > goods.maxQtyPerOrder) {
        goods.qty = goods.maxQtyPerOrder;
      }
    }

    if (goods.qty > goods.stock) {
      goods.qty = goods.stock;
    }
  }

  range(start, end): number[] {
    return Array.from(new Array(end - start + 1), (x, i) => start + i);
  }

  rangeByGoods(goods): number[] {
    let start = 1, end = goods.stock;
    if (goods.qtyLimitPerOrder) {
      start = goods.minQtyPerOrder;
      if (goods.maxQtyPerOrder < goods.stock) {
        end = goods.maxQtyPerOrder;
      }
    }
    return this.range(start, end);
  }

  initGoods(goods) {
    goods.qty = 1;
    // image
    const imageList = goods.imageList;
    // Select fist option of attribute in product by default
    goods.productList.forEach(product => {
      product.attributeList.forEach(attribute => {
        attribute.optionList[0].selected = true;
      });
      const productVariantList = product.productVariantList;
      productVariantList.forEach(productVariant => {
        let pvImageList = productVariant.imageList;
        pvImageList = pvImageList.filter(pvImg => imageList.every(img => img.url !== pvImg.url));
        imageList.push(...pvImageList);
      });
      this.onAttributeChanged(goods, product);
    });
  }

  addGoods() {
    this.loadingService.show();
    const productList = this.goods.productList.map(product => {
      const productVariant = this.findMatchedProductVariant(product);
      return { productId: product.id, productVariantId: productVariant.id };
    });
    const goodsId = this.goods.id;
    const qty = this.goods.qty;
    this.shoppingCartService.addGoods(goodsId, qty, productList)
      .subscribe({
        next: result => {
          if (result.success) {
            this.showMessage(this.goods.name + ' ' + this.translate.instant('added.to.the'), AddCartSnackBarComponent);
            this.shoppingCartService.refreshCount();
            this.shoppingCartService.refresh();
            this.dialogRef.close(true);
          } else {
            // const msgKey = this.errorMap[result.errorCode] || 'error';
            // const message = this.translate.instant(msgKey, { goodsName: this.goods.name });
            // this.showError(`${this.translate.instant('warning')}: ${message}`);
            this.showRespResultError(result, this.errorMap, { goodsName: this.goods.name });
          }
        },
        complete: () => this.loadingService.hide()
      });
  }

  findSelectedAttributeOption(attribute) {
    return attribute.optionList.find(o => o.selected === true);
  }
  changeAttributeOption(goods, product, attribute, option) {
    attribute.optionList.forEach(o => o.selected = false);
    option.selected = true;
    this.onAttributeChanged(goods, product);
  }
  onAttributeChanged(goods, product) {
    const matchedProductVariant = this.findMatchedProductVariant(product);
    console.log('matchedProductVariant', matchedProductVariant);
    if (matchedProductVariant) {
      goods.price = matchedProductVariant.price;
      goods.listPrice = matchedProductVariant.listPrice;
      const outOfStock = matchedProductVariant.outOfStock;
      goods.outOfStock = outOfStock;
      goods.stock = matchedProductVariant.stock;
      goods.selectedProductVariant = matchedProductVariant.id;

      if (outOfStock) {
        goods.selected = false;
      }

      if (goods.qty > goods.stock) {
        goods.qty = goods.stock;
      }

      console.log('matchedProductVariant.imageList', matchedProductVariant.imageList);

      if (matchedProductVariant.imageList && matchedProductVariant.imageList.length > 0) {
        const imageUrl = matchedProductVariant.imageList[0].url;
        console.log('imageUrl', imageUrl);

        const matchedImageIndex = this.findMatchedImageIndex(goods, imageUrl);
        if (matchedImageIndex > -1) {
          goods.imageIndex = matchedImageIndex;
        }
      }
    } else {
      goods.price = 0;
      goods.listPrice = 0;
      goods.outOfStock = true;
    }
  }

  generateAttributeMatchKey(optionIdList: string[]) {
    console.log('optionIdList', optionIdList);

    return optionIdList.sort().join('-');
  }
  findMatchedProductVariant(product) {
    const optionIdList = product.attributeList
      .flatMap(attribute => attribute.optionList.filter(option => option.selected))
      .map(option => option.id);
    const optionIdKey = this.generateAttributeMatchKey(optionIdList);
    console.log('optionIdKey', optionIdKey);

    const matchedProductVariant = product.productVariantList.find(productVariant =>
      this.generateAttributeMatchKey(productVariant.attributeOptionIdList) === optionIdKey);
    return matchedProductVariant;
  }

  findMatchedImageIndex(goods, imageUrl) {
    return goods.imageList.findIndex(image => image.url === imageUrl);
  }

}
