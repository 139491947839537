import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { FullScreenLoadingService } from '../shared/service/full-screen-loading.service';
import { EventEnrollmentService } from '../api/event-enrollment.service';
import { Subject, interval, timer, merge } from 'rxjs';
import { PaymentService } from '../api/payment.service';
import { concatMap, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-payment-response',
  templateUrl: './payment-response.component.html',
  styleUrls: ['./payment-response.component.scss']
})
export class PaymentResponseComponent implements OnInit, OnDestroy {
  id: any;
  authCode: string;
  rspCode: string;

  completed = false;
  success = false;
  type = '';

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(private router: Router,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private loadingService: FullScreenLoadingService,
    private paymentService: PaymentService
  ) {
    this.id = this.route.snapshot.params.id;
    const fragment = this.route.snapshot.fragment;

    this.authCode = new URLSearchParams(fragment).get('authCode') || '';

    this.rspCode = this.route.snapshot.queryParams['rspcode'];

  }

  ngOnInit() {
    const stopPolling = new Subject<void>();
    timer(0, 1000).pipe(
      concatMap(() => this.paymentService.getStatus(this.id, this.authCode, this.rspCode)),
      map(resp => {
        console.log('resp', resp);
        const data = resp.data;
        this.completed = data.completed;
        this.success = data.success;
        this.type = data.type;

        const csEmail = data.csEmail;

        if (this.completed) {
          stopPolling.next();
          stopPolling.complete();
          // if (this.success) {
          this.router.navigate([`payment-result/${this.type}`],
            {
              replaceUrl: true,
              state: {
                'success': this.success,
                'paymentId': this.id,
                'authCode': this.authCode,
                'csEmail': csEmail
              }
            });
          // }
        }
      }),
      takeUntil(merge(stopPolling, this._onDestroy)),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
