import {
  Component, OnInit, Input, Self, Optional, AfterViewInit, ChangeDetectionStrategy,
  ChangeDetectorRef,
  ContentChildren, QueryList, OnDestroy
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, } from '@angular/forms';
import { Subject } from 'rxjs';
import { delay, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { InputErrorMessageWhenErrorCodeDirective } from './input-error-message-when-error-code.directive';

@Component({
  selector: 'app-input-error-message',
  templateUrl: './input-error-message.component.html',
  styleUrls: ['./input-error-message.component.css'],
  exportAs: 'appInputErrorMessage',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputErrorMessageComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {

  @Input() control: AbstractControl;

  public errors: { [key: string]: any };

  @ContentChildren(InputErrorMessageWhenErrorCodeDirective) contentList: QueryList<InputErrorMessageWhenErrorCodeDirective>;

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy$ = new Subject<void>();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private cdr: ChangeDetectorRef
  ) {
    if (ngControl != null) {
      this.ngControl.valueAccessor = this;
      this.control = ngControl.control;
      this.errors = this.control?.errors;
    }
  }


  ngOnInit() {
  }

  ngAfterViewInit(): void {
    if (!this.control) {
      this.control = this.ngControl?.control;
      this.cdr.detectChanges();
    }

    // setTimeout(() => {
    this.errors = this.control.errors;

    // this.control.valueChanges.pipe(
    //   startWith(<string>null),
    //   distinctUntilChanged(),
    //   takeUntil(this._onDestroy$),
    // ).subscribe((value) => {
    //   this.errors = this.control.errors;
    //   this.cdr.markForCheck();
    // });

    this.contentList.changes.pipe(
      delay(0),
      takeUntil(this._onDestroy$)
    ).subscribe((content: InputErrorMessageWhenErrorCodeDirective) => {
      content.errors = this.control.errors;
    });

    this.control.statusChanges.pipe(
      startWith(<string>null),
      map(status => this.control.errors),
      distinctUntilChanged((a, b) => this._equal(a, b)),
      delay(0),
      takeUntil(this._onDestroy$),
    ).subscribe(errors => {

      // console.log('control errors change', errors);

      // const errors = this.control.errors;

      // console.log('control errors', errors);

      this.contentList.forEach(content => {
        // console.log('content', content);
        content.errors = errors;
      });

      this.errors = errors;
      this.cdr.markForCheck();
    });
    // }, 0);

  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  writeValue(obj: any): void {

  }
  registerOnChange(fn: any): void {

  }
  registerOnTouched(fn: any): void {

  }
  setDisabledState?(isDisabled: boolean): void {

  }

  _equal(a, b): boolean {
    a = a ?? {};
    b = b ?? {};

    if (Object.keys(a).length !== Object.keys(b).length) {
      return false;
    }

    return Object.entries(a).every(([key, value]) => value === b[key]);
  }

}
