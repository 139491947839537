import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CurrencyService } from 'src/app/api/currency.service';
import { SystemSettingService } from 'src/app/api/system-setting.service';
import { LocalSettingService } from './local-setting.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencySettingService {

  currencyList: Currency[];
  default: Currency;

  private _current$ = new BehaviorSubject<string>(null);
  get current$(): Observable<string> {
    return this._current$;
  }

  constructor(private currencyService: CurrencyService,
    private systemSettingService: SystemSettingService,
    private localSettingService: LocalSettingService
  ) { }

  async init() {
    this.currencyList = await this.currencyService.query().toPromise();
    this.default = this.currencyList.find(cur => cur.default);
    if (this.default == null || this.default == undefined) {
      this.default = this.currencyList?.[0];
    }
    if (this.default == null || this.default == undefined) {
      console.error('Can\'t set default currency');
    }
  }

  get(): Currency[] {
    return this.currencyList;
  }

  getCodeList(): string[] {
    return this.currencyList.map(currency => currency.code);
  }

  getDefault(): Currency {
    return this.default;
  }

  getDefaultSymbol(): string {
    return this.default.symbolLeft;
  }

  getCurrent(): Currency {
    const currentCurrencyCode = this.localSettingService.getCurrency();
    const currentCurrency = this.currencyList.find(cur => cur.code == currentCurrencyCode);
    if (currentCurrency == null || currentCurrency == undefined) {
      return this.default;
    }
    return currentCurrency;
  }

  setCurrent(currency: string) {
    this.localSettingService.setCurrency(currency);
    this._current$.next(currency);
  }

}

export interface Currency {
  id: number;
  code: string;
  symbolLeft: string;
  name: string;
  default: boolean;
  ratio: number;
  digits: string;
}
