import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicViewComponent } from 'src/app/basic-view-component';

export class InformationBottomSheetModel {

  constructor(public title: string, public content: string) {
  }
}

@Component({
  selector: 'app-information-bottom-sheet',
  templateUrl: './information-bottom-sheet.component.html',
  styleUrls: ['./information-bottom-sheet.component.scss']
})
export class InformationBottomSheetComponent implements OnInit {

  title;
  content;

  constructor(private bottomSheetRef: MatBottomSheetRef<InformationBottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, ) {
      this.title = data.title;
      this.content = data.content;
    }

  ngOnInit() {
  }

  dismiss() {
    this.bottomSheetRef.dismiss();
  }

}
