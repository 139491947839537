import { Component, EventEmitter, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BrandService } from '../api/brand.service';

@Component({
  selector: 'app-brand-list',
  templateUrl: './brand-list.component.html',
  styleUrls: ['./brand-list.component.scss']
})
export class BrandListComponent implements OnInit {

  brandMap;
  brandList;

  searchKeyword = '';

  private keywordChange = new EventEmitter<string>();
  private _onDestroy$ = new Subject<void>();

  constructor(private brandService: BrandService, ) { }

  ngOnInit() {
    this.brandService.query().subscribe(data => {
      this.brandList = data;
      this.brandMap = this.groupBy(data);
    });

    this.keywordChange
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        takeUntil(this._onDestroy$),
      )
      .subscribe(() => {
        this.searchBrand();
      });
  }

  onInputChange(keyword: string) {
    this.searchKeyword = keyword;
    this.keywordChange.emit(keyword);
  }

  searchBrand() {
    // console.log('searchBrand', this.searchBrand);
    const filterBrandList = this.brandList.filter(brand => brand?.name?.toLowerCase().includes(this.searchKeyword.toLowerCase()));
    this.brandMap = this.groupBy(filterBrandList);
  }

  groupBy(xs) {
    return xs.reduce((rv, x) => {
      const key = x.name.substring(0, 1).toUpperCase();

      (rv[key] = rv[key] || []).push(x);
      return rv;
    }, {});
  }

}
