export enum InputFieldId {
    Salutation = 1,
    IdNoPassportNo = 2,
    PhoneNumber = 3,
    Address = 4,
    Subscription = 5,
    TacSignup = 6,
    TacCheckout = 7,
    Remarks = 8,
    FullName = 9,
    EmailAddress = 10,
    Dob = 11,
    BecomeAMember = 12,
}
