import { Component, Input, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-goods-showcase-swiper',
  templateUrl: './goods-showcase-swiper.component.html',
  styleUrls: ['./goods-showcase-swiper.component.scss']
})
export class GoodsShowcaseSwiperComponent implements OnInit {

  @Input() goodsList = [];
  @Input() maxDisplayLimit = 4;
  constructor() { }

  ngOnInit(): void {
  }

}
