import { Observable } from 'rxjs';

export interface TableApiService {
    getSearchField(): Observable<SearchField[]>;

    getBody(queryBean: QueryBean): Observable<any[]>;

    getBodyCount(queryBean: QueryBean): Observable<number>;

    query(queryBean: QueryBean): Observable<any[]>;

    add(data: any): Observable<RespResult>;

    get(id: any): Observable<any>;

    update(id: any, data: any): Observable<RespResult>;

    delete(id: any): Observable<RespResult>;

    exists(id: any): Observable<boolean>;
}


export interface TableColumn {
    displayNameKey: string;
    dbColumn: string;
    width: string;
    align: string;
    sortable: boolean;
}


export enum SEARCH_FIELD_TYPE {
    DATE = 'date',
    TEXT = 'text',
    OPTION = 'option',
    NUMBER = 'number'
}



export interface SearchField {
    name: string;
    value: string;
    type: SEARCH_FIELD_TYPE;
    searchOperators: SearchOperator[];
    searchKeywords: SearchKeyword[];
}



export interface SearchOperator {
    name: string;
    value: string;
}

export interface SearchKeyword {
    name: string;
    value: string;
}

export interface RespResult {
    success: boolean;
    errorCode: string;
    msg: string;
    data: any;
}

// export class QueryBean {
//     fields: string[] = [];
//     operators: string[] = [];
//     keywords: string[] = [];
//     orderByField = '';
//     orderByAscending = true;
//     page = 1;
//     limit = 0;
// }

export class Pagination {
    // tslint:disable-next-line:no-inferrable-types
    page?: number = 0;
    // tslint:disable-next-line:no-inferrable-types
    limit?: number = 0;
}

export class QueryBean extends Pagination {
    fieldList?: string[] = [];
    operatorList?: string[] = [];
    keywordList?: string[] = [];
    // tslint:disable-next-line:no-inferrable-types
    orderByField?: string = '';
    // tslint:disable-next-line:no-inferrable-types
    orderByAscending?: boolean = true;
}

// export class QueryBean {
//     conditions: QueryCondition[] = [];
//     orderByField = '';
//     orderByAscending = true;
//     page = 1;
//     limit = 0;

//     addCondition(condition: QueryCondition) {
//         this.conditions.push(condition);
//         return this;
//     }
// }

// export interface QueryCondition {
//     field: string;
//     operator: string;
//     keywords: string[];
// }
