import { Injectable } from '@angular/core';
import { GenericTableApiService } from './generic-table-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class SearchService extends ApiService {

    endpoint = 'search';

    constructor(private httpClient: HttpClient) {
        super();
    }

    searchGoodsSeries(keyword, page, limit): Observable<any> {
        return this.httpClient.get(`${this.endpoint}/goods-series`, { params: { 'keyword': keyword, 'page': page, 'limit': limit } });
    }

    searchGoods(keyword, page, limit): Observable<any> {
        return this.httpClient.get(`${this.endpoint}/goods`, { params: { 'keyword': keyword, 'page': page, 'limit': limit } });
    }

    searchBlogArticle(keyword, page, limit): Observable<any> {
        return this.httpClient.get(`${this.endpoint}/blog-article`, { params: { 'keyword': keyword, 'page': page, 'limit': limit } });
    }

    searchNewsAndEventsPage(keyword, page, limit): Observable<any> {
        return this.httpClient.get(`${this.endpoint}/news-and-events-page`, { params: { 'keyword': keyword, 'page': page, 'limit': limit } });
    }

    searchNewsAndEventsPageCount(keyword): Observable<any> {
        return this.httpClient.get(`${this.endpoint}/news-and-events-page/count`, { responseType: 'text', params: { 'keyword': keyword } });
      }
}
