import { LoginService } from '../api/login.service';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, CanLoad, Route } from '@angular/router';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable()
export class LoginGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(private router: Router, private loginService: LoginService) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        console.log('canActivate', route.toString());
        console.log('canActivate', state.url);
        return this.checkLogin();
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }

    canLoad(route: Route): Observable<boolean> {
        return this.checkLogin();
    }

    checkLogin(): Observable<boolean> {
        console.log('checkLogin', this.router.url);

        return this.loginService.getStatus().pipe(map(data => {
            if (!data.isLoggedIn) {
                this.router.navigateByUrl('login', { state: { 'redirectUrl': '/' }, replaceUrl: true, skipLocationChange: true });
                return false;
            }
            return true;
        }));
    }

}
