// for import internal

import { InjectionToken, NgModule, Renderer2, RendererFactory2, inject } from '@angular/core';

import { ClickOutsideDirective } from '../directive/click-outside.directive';

import { InputErrorMessageComponent } from '../component/input-error-message/input-error-message.component';
import { SelectSearchComponent } from '../component/select-search/select-search.component';
import { AppRouterOutletDirective } from '../directive/app-router-outlet.directive';
import { DefaultImageDirective } from '../directive/default-image.directive';
import { LocationBackDirective } from '../directive/location-back.directive';
import { CurrencyProxyPipe } from '../pipe/currency-proxy.pipe';
import { SafeHtmlPipe } from '../pipe/safe-html.pipe';
import { CharacterCounterComponent } from '../component/character-counter/character-counter.component';
import { AbsValuePipe } from '../pipe/abs-value.pipe';
import { ShowcaseItemComponent } from 'src/app/showcase/showcase-item/showcase-item.component';
import { ShowcaseComponent } from 'src/app/showcase/showcase/showcase.component';
import { ShowcaseItemDescriptionComponent } from 'src/app/showcase/showcase-item-description/showcase-item-description.component';
import { ShowcaseItemImageComponent } from 'src/app/showcase/showcase-item-image/showcase-item-image.component';
import { ShowcaseItemNameComponent } from 'src/app/showcase/showcase-item-name/showcase-item-name.component';
import { ShowcaseItemPriceComponent } from 'src/app/showcase/showcase-item-price/showcase-item-price.component';
import { ShowcaseSwiperSlideComponent } from 'src/app/showcase/showcase-swiper-slide/showcase-swiper-slide.component';
import { ShowcaseSwiperComponent } from 'src/app/showcase/showcase-swiper/showcase-swiper.component';
import { AutofocusDirective } from '../directive/autofocus.directive';
import { SharedAngularImportModule } from './shared-angular-import.module';
import { HyperlinkDirective } from '../directive/hyperlink.directive';
import { MemberPunchCardComponent } from '../../member/member-punch-card/member-punch-card.component';
import { InvalidControlScrollDirective } from '../directive/invalid-control-scroll.directive';
import { InvalidControlScrollContainerDirective } from '../directive/invalid-control-scroll-container.directive';
import { ReferenceCurrencyPipe } from '../pipe/reference-currency.pipe';
import { ReferenceCurrencyContainerDirective } from '../directive/reference-currency-container.directive';
import { InputErrorMessageWhenErrorCodeDirective } from '../component/input-error-message/input-error-message-when-error-code.directive';
import { GoogleLoginButtonComponent } from 'src/app/google-login-button/google-login-button.component';


export const DEFAULT_RENDERER = new InjectionToken<Renderer2>(
    'A Renderer2 for global services',
    {
        factory: () => inject(RendererFactory2).createRenderer(null, null),
    },
);

@NgModule({
    imports: [
        SharedAngularImportModule,
    ],
    providers: [],
    declarations: [
        SafeHtmlPipe,
        CurrencyProxyPipe,
        AbsValuePipe,
        ReferenceCurrencyPipe,

        DefaultImageDirective,
        LocationBackDirective,
        ClickOutsideDirective,
        AppRouterOutletDirective,
        AutofocusDirective,
        HyperlinkDirective,
        InvalidControlScrollDirective,
        InvalidControlScrollContainerDirective,
        ReferenceCurrencyContainerDirective,
        InputErrorMessageWhenErrorCodeDirective,


        InputErrorMessageComponent,
        SelectSearchComponent,
        CharacterCounterComponent,

        ShowcaseComponent,
        ShowcaseItemComponent,
        ShowcaseItemImageComponent,
        ShowcaseItemNameComponent,
        ShowcaseItemDescriptionComponent,
        ShowcaseItemPriceComponent,
        ShowcaseSwiperComponent,
        ShowcaseSwiperSlideComponent,
        MemberPunchCardComponent,

        GoogleLoginButtonComponent,
    ],
    exports: [
        SharedAngularImportModule,

        SafeHtmlPipe,
        CurrencyProxyPipe,
        AbsValuePipe,
        ReferenceCurrencyPipe,

        DefaultImageDirective,
        LocationBackDirective,
        ClickOutsideDirective,
        AppRouterOutletDirective,
        AutofocusDirective,
        HyperlinkDirective,
        InvalidControlScrollDirective,
        InvalidControlScrollContainerDirective,
        ReferenceCurrencyContainerDirective,
        InputErrorMessageWhenErrorCodeDirective,

        InputErrorMessageComponent,
        SelectSearchComponent,
        CharacterCounterComponent,

        ShowcaseComponent,
        ShowcaseItemComponent,
        ShowcaseItemImageComponent,
        ShowcaseItemNameComponent,
        ShowcaseItemDescriptionComponent,
        ShowcaseItemPriceComponent,
        ShowcaseSwiperComponent,
        ShowcaseSwiperSlideComponent,
        MemberPunchCardComponent,

        GoogleLoginButtonComponent,
    ]
})
export class SharedCommonImportModule {
}
