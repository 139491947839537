import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-showcase-item-description',
  templateUrl: './showcase-item-description.component.html',
  styleUrls: ['./showcase-item-description.component.scss']
})
export class ShowcaseItemDescriptionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
