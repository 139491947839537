import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PhotoGalleryPageService } from 'src/app/api/photo-gallery-page.service';
import { Project } from 'src/app/app-config';
import { PageMetaService } from 'src/app/shared/service/page-meta.service';
import { Lightbox, LightboxEvent, LIGHTBOX_EVENT } from 'ngx-lightbox';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-photo-gallery-page-item',
  templateUrl: './photo-gallery-page-item.component.html',
  styleUrls: ['./photo-gallery-page-item.component.scss']
})
export class PhotoGalleryPageItemComponent implements OnInit {

  id;
  loading = false;

  photoGalleryPage;
  photoGalleryPageItemList = [];
  lightBoxAlbum = [];
  navigationName;

  private _subscription: Subscription;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private photoGalleryPageService: PhotoGalleryPageService,
    private pageMetaService: PageMetaService,
    private translate: TranslateService,
    private lightbox: Lightbox,
    private _lightboxEvent: LightboxEvent,) {


  }

  ngOnInit() {

    this.route.params.subscribe(params => {
      const paramList = params.idWithSlug.split('-');
      this.id = paramList[0];
      const slug = params.idWithSlug.substring(this.id.length + 1);

      if (paramList.length < 2) {
        this.redirect404Page();
        return;
      }

      this.photoGalleryPageService.get(this.id).subscribe(data => {
        if (data.slug !== slug) {
          this.redirect404Page();
          return;
        }
        this.photoGalleryPage = data;

        const title = this.photoGalleryPage.name + ' | ' + Project.title;
        this.pageMetaService.generateTags({
          title: title,
          description: this.photoGalleryPage.description,
          slug: slug
        });

        data.photoGalleryPageItemList.forEach(item => {
          const src = item.image.url;
          const thumb = item.image.url;
          const caption = item.description;
          const image = {
            src: src,
            caption: caption,
            thumb: thumb
          };

          this.lightBoxAlbum.push(image);
        });
      });
    });

    this.route.queryParams.subscribe(params => {
      this.navigationName = params.navigationName;
    });
  }

  redirect404Page() {
    this.router.navigateByUrl('404', { replaceUrl: true, skipLocationChange: true });
  }

  open(index: number): void {
    const albumLabel = this.translate.instant('photo.gallery.full.screen.album.label');

    this.lightbox.open(this.lightBoxAlbum, index, { wrapAround: true, showImageNumberLabel: true, albumLabel: albumLabel, });

    // this._subscription = this._lightboxEvent.lightboxEvent$
    //   .subscribe(event => this._onReceivedEvent(event));
  }

  close(): void {
    // close lightbox programmatically
    this.lightbox.close();
  }

  // private _onReceivedEvent(event: any): void {
  //   // remember to unsubscribe the event when lightbox is closed
  //   if (event.id === LIGHTBOX_EVENT.CLOSE) {
  //     // event CLOSED is fired
  //     this._subscription.unsubscribe();
  //   }

  //   if (event.id === LIGHTBOX_EVENT.OPEN) {
  //     // event OPEN is fired
  //   }

  //   if (event.id === LIGHTBOX_EVENT.CHANGE_PAGE) {
  //     // event change page is fired
  //     console.log(event.data); // -> image index that lightbox is switched to
  //   }
  // }

}
