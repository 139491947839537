import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-navigation-top-bar',
  templateUrl: './order-navigation-top-bar.component.html',
  styleUrls: ['./order-navigation-top-bar.component.css']
})
export class OrderNavigationTopBarComponent implements OnInit {

  @Input('title') public title: any;
  
  constructor() { }

  ngOnInit(): void {
  }

}
