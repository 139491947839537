import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericTableApiService } from './generic-table-api.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BlogArticleCategoryService extends GenericTableApiService {

    constructor(httpClient: HttpClient) {
        super(httpClient, 'blog-category');
    }
    queryBlog(): Observable<any[]> {
        return this.httpClient.get<any[]>(`${this.endpoint}/blog`);
    }
    queryBlogArticleList(id, page, limit): Observable<any> {
        return this.httpClient.get(`${this.endpoint}/${id}/blog-article-list`, { params: { 'page': page, 'limit': limit } });
    }
}
