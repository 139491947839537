import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AdvertisementService } from '../api/advertisement.service';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.css']
})
export class NavigationBarComponent implements OnInit {

  advertisement1;
  advertisementList: any[];

  constructor(private advertisementService: AdvertisementService, ) { }

  @Input('title') public title: any;

  ngOnInit(): void {

    this.advertisementService.queryById([1]).subscribe(data => {
      this.advertisementList = data;
      // this.advertisement2 = this.getAdvertisement(2);
      // this.advertisement3 = this.getAdvertisement(3);
      this.advertisement1 = this.getAdvertisement(1);
    });
  }

  getAdvertisement(id: number) {
    return this.advertisementList.find(advertisement => advertisement.id === id);
  }

}
