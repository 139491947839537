import { Injectable } from '@angular/core';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { SystemSettingService } from 'src/app/api/system-setting.service';

@Injectable({
    providedIn: 'root'
})
export class GoogleTagManagerService {

    constructor(
        private systemSettingService: SystemSettingService,
        private angulartics2GoogleTagManager: Angulartics2GoogleTagManager
    ) { }

    init() {

        this.systemSettingService.getWebsiteTracking().subscribe(websiteTracking => {

            const googleTagManagerEnabled: boolean = websiteTracking.googleTagManager;
            const googleTagManagerId = websiteTracking.googleTagManagerId;

            if (googleTagManagerEnabled) {
                try {
                    (function (w, d, s, l, i) {
                        w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                        const f = d.getElementsByTagName(s)[0],
                            j = d.createElement(s) as HTMLScriptElement, dl = l !== 'dataLayer' ? '&l=' + l : '';
                        j.async = true;
                        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                        j.defer = true;
                        f.parentNode.insertBefore(j, f);
                    })(window, document, 'script', 'dataLayer', googleTagManagerId);

                    const noScript = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-${googleTagManagerId}" \
                    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
                    const e = document.createElement('noscript');
                    e.innerHTML = noScript;
                    document.body.appendChild(e);
                } catch (error) {
                    console.error(error);
                }
                this.angulartics2GoogleTagManager.startTracking();
            }
        });
    }
}
