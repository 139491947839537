import { Injectable } from '@angular/core';
import { GenericTableApiService } from './generic-table-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RespResult } from './table-api-service';

@Injectable({
    providedIn: 'root'
})
export class MemberWishService extends GenericTableApiService {

    constructor(httpClient: HttpClient) {
        super(httpClient, 'member/wish');
    }

    getCount(): Observable<any> {
        return this.httpClient.get(`${this.endpoint}/count`, { responseType: 'text' });
    }

    removeWish(goodsIdList: any): Observable<RespResult> {
        return this.httpClient.post<RespResult>(`${this.endpoint}/remove`, goodsIdList);
    }

    wishGoods(gId: number): Observable<RespResult> {
        return this.httpClient.put<RespResult>(`${this.endpoint}/goods/${gId}`, {});
    }

    unwishGoods(gId: number): Observable<RespResult> {
        return this.httpClient.delete<RespResult>(`${this.endpoint}/goods/${gId}`);
    }
}
