import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GoodsSeriesService } from '../api/goods-series.service';
import { CategoryService } from '../api/category.service';
import { Observable, fromEvent, Subject } from 'rxjs';
import { SearchService } from '../api/search.service';
import { finalize, takeUntil, debounceTime } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Project } from '../app-config';
import { PageMetaService } from '../shared/service/page-meta.service';

@Component({
  selector: 'app-search-goods',
  templateUrl: './search-goods.component.html',
  styleUrls: ['./search-goods.component.scss']
})
export class SearchGoodsComponent implements OnInit, OnDestroy {

  private _onDestroy = new Subject<void>();

  // goodsSeriesList$: Observable<any[]>;
  goodsList = [];
  keyword = '';

  page = 1;
  limit = 20;
  loading = false;
  noMore = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private searchService: SearchService,
    private translate: TranslateService,
    private title: Title,
    private pageMetaService: PageMetaService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.goodsList = [];
      this.page = 1;

      console.log('parmas', params);
      this.keyword = params.keyword;
      this.page = params.page || 1;
      console.log('keyword', this.keyword);
      this.search();

      const title = this.translate.instant('search') + ' ' + this.keyword + ' | ' + Project.title;
      this.pageMetaService.generateTags({
        title: title
      });
    });

    fromEvent(window, 'resize').pipe(
      debounceTime(100),
      takeUntil(this._onDestroy),
    ).subscribe(() => this.loadMore());

  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  search() {
    this.loading = true;
    this.searchService.searchGoods(this.keyword, this.page, this.limit)
      .pipe(finalize(() => this.loading = false))
      .subscribe(data => {
        if (data && data.length) {
          this.goodsList.push(...data);
        }

        if (!data || data.length < this.limit) {
          this.noMore = true;
        }
      });
  }

  loadMore() {
    this.page++;
    this.search();
  }

}
