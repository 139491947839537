import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class CountryService extends ApiService {

    constructor(private httpClient: HttpClient) {
        super();
    }

    query(): Observable<any[]> {
        return this.httpClient.get<any[]>(`country`);
    }
}
