import { Injectable } from '@angular/core';
import { GenericTableApiService } from './generic-table-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LanguageService extends GenericTableApiService {

    constructor(httpClient: HttpClient) {
        super(httpClient, 'language');
    }

    queryEnabled(): Observable<any[]> {
        return this.httpClient.get<any[]>(`${this.endpoint}/enabled`);
    }

}
