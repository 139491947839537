export enum Language {
    EnUS = 'en-US',
    ZhHK = 'zh-HK',
    ZhCN = 'zh-CN',
    JaJP = 'ja-JP',
    KoKR = 'ko-KR'
}

// type LanguageDisplay = (typeof Language)[keyof typeof Language];
// export const LanguageDisplay = {
//     [Language.EnUS]: 'EN',
//     [Language.ZhHK]: '繁體',
//     [Language.ZhCN]: '简体',
//     [Language.JaJP]: '日本語',
//     [Language.KoKR]: '한국어'
// } as const;
