import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { BasicViewComponent } from '../basic-view-component';
import { ShoppingCartService } from '../api/shopping-cart.service';
import { FullScreenLoadingService } from '../shared/service/full-screen-loading.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of, Subject } from 'rxjs';
import { GoodsService } from '../api/goods.service';
import { AddCartSnackBarComponent } from 'src/app/add-cart-snack-bar/add-cart-snack-bar.component';

@Component({
  selector: 'app-bundle-price-goods-promotion-item',
  templateUrl: './bundle-price-goods-promotion-item.component.html',
  styleUrls: ['./bundle-price-goods-promotion-item.component.scss']
})
export class BundlePriceGoodsPromotionItemComponent extends BasicViewComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() set promotion(pricePromotion) {
    this._promotion = pricePromotion;
    this.initPromotion(this._promotion);
  }
  get promotion() {
    return this._promotion;
  }

  _promotion;

  @Input() showHeader = true;

  @Output() addedToCart: EventEmitter<void> = new EventEmitter<void>();

  errorMap = {
    'not_enough_stock': 'not.enough.stock',
    'invalid_input': 'invalid.input'
  };

  showPromotion = true;

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(snackBar: MatSnackBar,
    private shoppingCartService: ShoppingCartService,
    private loadingService: FullScreenLoadingService,
    private translate: TranslateService,
    private goodsService: GoodsService) {

    super(snackBar);
  }

  ngOnInit() {
    // this.init();
  }

  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getPromotionGoodsList() {
    return this.promotion.bundleGoodsList.map(bundleGoods => bundleGoods.goods);
  }

  toggleGoods(goods) {
    goods.selected = !goods.selected;
  }

  minusQty(goods) {
    if (this.canMinusQty(goods)) {
      goods.qty--;
    }
  }

  plusQty(goods) {
    if (this.canPlusQty(goods)) {
      goods.qty++;
    }
  }

  canMinusQty(goods) {
    return goods.qty > 1;
  }

  canPlusQty(goods) {
    return goods.qty < goods.stock;
  }

  onGoodsQtyInputBlur(goods) {
    if (goods.qty === null || goods.qty < 1) {
      goods.qty = 1;
    }

    if (goods.qty > goods.stock) {
      goods.qty = goods.stock;
    }
  }

  range(start, end): number[] {
    return Array.from(new Array(end - start + 1), (x, i) => start + i);
  }

  rangeByGoods(goods): number[] {
    const start = 1;
    const end = goods.stock;
    return this.range(start, end);
  }

  canBuy(goods) {
    return !goods.outOfStock;
  }

  initPromotion(promotion) {
    if (promotion.bundleGoodsList) {
      const qty = promotion.qty;
      this.getPromotionGoodsList().forEach((goods, itemIndex) => {
        let initQty = 1;
        if (initQty > qty) {
          initQty = qty;
        }
        goods.qty = initQty;
        goods.selected = false;

        // image
        const imageList = goods.imageList;
        // Select fist option of attribute in product by default
        goods.productList.forEach(product => {
          product.attributeList.forEach(attribute => {
            attribute.optionList[0].selected = true;
          });
          const productVariantList = product.productVariantList;
          productVariantList.forEach(productVariant => {
            let pvImageList = productVariant.imageList;
            pvImageList = pvImageList.filter(pvImg => imageList.every(img => img.url !== pvImg.url));
            imageList.push(...pvImageList);
          });
          this.onAttributeChanged(goods, product);
        });
      });
    }
  }

  addGoods() {
    this.loadingService.show();

    const goodsList: Array<any> = [];
    const selectedGoodsList = this.getPromotionGoodsList().filter(goods => goods.selected);
    selectedGoodsList.forEach(goods => {
      const goodsId = goods.id;
      const qty = goods.qty;

      const goodsProductList = goods.productList.map(product => {
        const productVariant = this.findMatchedProductVariant(product);
        return { productId: product.id, productVariantId: productVariant.id };
      });

      goodsList.push({
        goodsId: goodsId,
        qty: qty,
        productList: goodsProductList,
      });
    });

    this.shoppingCartService.addBundlePriceGoods(goodsList, this.promotion.promotionId)
      .subscribe({
        next: result => {
          if (result.success) {
            this.showMessage(this.promotion.name + ' '
              + this.translate.instant('added.to.the'), AddCartSnackBarComponent);
            this.shoppingCartService.refreshCount();
            this.shoppingCartService.refresh();
            // this.refresh();
            this.addedToCart.emit();
          } else {
            // const msgKey = this.errorMap[result.errorCode] || 'error';
            // const message = this.translate.instant(msgKey, { goodsName: result.data?.goods });
            // this.showError(`${this.translate.instant('warning')}: ${message}`);
            this.showRespResultError(result, this.errorMap, { goodsName: result.data?.goods });
          }
        },
        complete: () => this.loadingService.hide()
      });
  }

  canAddToCart() {
    const goodsList = this.getPromotionGoodsList();
    const hasSelectedGoods = goodsList.filter(goods => goods.selected).length >= 1;
    const selectedQty = goodsList.filter(goods => goods.selected).map(goods => goods.qty).reduce((a, b) => a + b, 0);
    return hasSelectedGoods && (selectedQty % this.promotion.qty === 0);
  }

  findSelectedAttributeOption(attribute) {
    return attribute.optionList.find(o => o.selected === true);
  }
  changeAttributeOption(goods, product, attribute, option) {
    attribute.optionList.forEach(o => o.selected = false);
    option.selected = true;
    this.onAttributeChanged(goods, product);
  }
  onAttributeChanged(goods, product) {
    const matchedProductVariant = this.findMatchedProductVariant(product);
    console.log('matchedProductVariant', matchedProductVariant);
    if (matchedProductVariant) {
      goods.price = matchedProductVariant.price;
      goods.listPrice = matchedProductVariant.listPrice;
      const outOfStock = matchedProductVariant.outOfStock;
      goods.outOfStock = outOfStock;
      goods.stock = matchedProductVariant.stock;
      goods.selectedProductVariantId = matchedProductVariant.id;

      if (outOfStock) {
        goods.selected = false;
      }

      if (goods.qty > goods.stock) {
        goods.qty = goods.stock;
      }

      console.log('matchedProductVariant.imageList', matchedProductVariant.imageList);

      if (matchedProductVariant.imageList && matchedProductVariant.imageList.length > 0) {
        const imageUrl = matchedProductVariant.imageList[0].url;
        console.log('imageUrl', imageUrl);

        const matchedImageIndex = this.findMatchedImageIndex(goods, imageUrl);
        if (matchedImageIndex > -1) {
          goods.imageIndex = matchedImageIndex;
        }
      }
    } else {
      goods.price = 0;
      goods.listPrice = 0;
      goods.outOfStock = true;
    }
  }
  generateAttributeMatchKey(optionIdList: string[]) {
    console.log('optionIdList', optionIdList);

    return optionIdList.sort().join('-');
  }
  findMatchedProductVariant(product) {
    const optionIdList = product.attributeList
      .flatMap(attribute => attribute.optionList.filter(option => option.selected))
      .map(option => option.id);
    const optionIdKey = this.generateAttributeMatchKey(optionIdList);
    console.log('optionIdKey', optionIdKey);

    const matchedProductVariant = product.productVariantList.find(productVariant =>
      this.generateAttributeMatchKey(productVariant.attributeOptionIdList) === optionIdKey);
    return matchedProductVariant;
  }

  findMatchedImageIndex(goods, imageUrl) {
    return goods.imageList.findIndex(image => image.url === imageUrl);
  }

  togglePromotion() {
    this.showPromotion = !this.showPromotion;
  }

  trackById(index, item) {
    return item.id;
  }

}
