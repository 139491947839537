import { Observable, Subject } from 'rxjs';
import { LoginService } from '../api/login.service';
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { FacebookLoginService } from '../shared/service/facebook-login.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpCancelService } from '../api/interceptor/http-cancel.service';
import { FullScreenLoadingService } from '../shared/service/full-screen-loading.service';
import { MemberService } from '../api/member.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SystemSettingService } from '../api/system-setting.service';
import { GoogleLoginService } from '../shared/service/google-login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() redirectUrl = '';

  // @ViewChild('idDom') idElement: ElementRef;
  hidePwd = true;

  errorMap: {};

  msg = '';

  ip$: Observable<{ ip: string }>;

  loading;

  invalidLogin = false;

  loginForm: UntypedFormGroup;

  storeBasicInfo;

  private _onDestroy = new Subject<void>();

  constructor(
    private router: Router,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private loginService: LoginService,
    private httpCancelService: HttpCancelService,
    private loadingService: FullScreenLoadingService,
    private memberService: MemberService,
    private snackBar: MatSnackBar,
    private systemSettingService: SystemSettingService,
    protected facebookLoginService: FacebookLoginService,
    protected googleLoginService: GoogleLoginService,
  ) {


    this.loginForm = fb.group({
      id: ['', [Validators.required, Validators.email]],
      pwd: ['', Validators.required]
    });

  }

  ngOnInit() {
    // this.loginService.getStatus().subscribe(data => console.log(data));
    this.ip$ = this.loginService.getIp();
    this.loginService.onLoggedIn().pipe(takeUntil(this._onDestroy)).subscribe(isLogin => {
      if (isLogin) {
        this.loadingService.show();
        this.router.navigateByUrl(this.redirectUrl, { replaceUrl: true })
          .then(() => {
            this.loadingService.show();
            location.reload();
          });
      }
    });

    this.systemSettingService.getStoreBasicInfo().subscribe(data => {
      this.storeBasicInfo = data;
    });

  }

  ngAfterViewInit(): void {

    // window.setTimeout(() => {
    //   this.idElement.nativeElement.focus();
    //    // For SSR (server side rendering) this is not safe. Use: https://github.com/angular/angular/issues/15008#issuecomment-285141070)
    // });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  login() {
    this.invalidLogin = null;

    // if (this.loginCheck()) {
    this.loading = true;

    const user = { id: '', password: '' };
    user.id = this.loginForm.get('id').value;
    user.password = this.loginForm.get('pwd').value;
    this.loginService.login(user).pipe(finalize(() => this.loading = false)).subscribe(data => {
      console.log('Login result:', data);
      if (data.success) {
        // this.router.navigateByUrl(this.redirectUrl, { replaceUrl: true })
        //   .then(() => location.reload());

        this.invalidLogin = false;
      } else {
        // this.user.password = '';
        this.loginForm.get('pwd').setValue('');
        this.msg = this.translate.instant(data.msg);

        this.invalidLogin = true;
      }
    }, error => console.log(error));
    // }

  }

  // loginStatus() {
  //   this.loginService.getStatus().subscribe(data => console.log(data));
  // }




  // showError(message: string) {
  //   this.snackBar.open(message, 'OK', { verticalPosition: 'top', panelClass: ['background-color-warn'] });
  // }

}
