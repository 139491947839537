import { HttpErrorResponse, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError, EMPTY } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { HttpCancelService } from './http-cancel.service';

@Injectable()
export class PermissionInterceptor implements HttpInterceptor {
    constructor(private router: Router, private httpCancelService: HttpCancelService) { }

    private handle403Error(err: HttpErrorResponse): Observable<any> {
        if (err instanceof HttpErrorResponse && err.status === 403) {
            // this.router.navigateByUrl('/login');
            alert('Error 403, No permission');
            this.httpCancelService.cancelPendingRequests();

            // this.router.navigateByUrl('error-403', { replaceUrl: true, skipLocationChange: true });
            // for avoid rxjs EmptyError: no elements in sequence, wait for angular fix
            // return of(new HttpResponse({ body: err.message }));

            return EMPTY;
        }
        return throwError(err);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request to add the new header.
        // const authReq = req.clone({ headers: req.headers.set(Cookie.tokenKey, Cookie.getToken()) });
        // catch the error, make specific functions for catching specific errors and you can chain through them with more catch operators

        console.log('insert PermissionInterceptor');

        return next.handle(req).pipe(
            takeUntil(this.httpCancelService.onCancelPendingRequests()),
            catchError((err) => this.handle403Error(err)),
        );

        // here use an arrow function,
        //  otherwise you may get "Cannot read property 'navigate' of undefined" on angular 4.4.2/net core 2/webpack 2.70

    }

}
