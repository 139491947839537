import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GoodsDialogComponent } from '../goods-dialog/goods-dialog.component';
import { GoodsService } from '../api/goods.service';
import { LocalSettingService } from '../shared/service/local-setting.service';
import { AddCartSnackBarComponent } from '../add-cart-snack-bar/add-cart-snack-bar.component';
import { TranslateService } from '@ngx-translate/core';
import { ShoppingCartService } from '../api/shopping-cart.service';
import { BasicViewComponent } from '../basic-view-component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CurrencySettingService } from '../shared/service/currency-setting.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export interface GoodsShowcaseItem {
  id: number;
  imageList: [{ url }];
  name;
  description;
  rating;
  reviewCount;
  price;
  listPrice;
  hot;
  brandName;
  outOfStock;
  productList;
  slug;
  newProduct;
  showRating;
  maxQtyPerOrder;
  canAddToCart;
  lowStock;
  lastStock;
  oneClickCheckout;
  showMemberDiscountLabel;
  imageTagList;
  percentageOff;
  dollarOff;
}

@Component({
  selector: 'app-goods-showcase-item',
  templateUrl: './goods-showcase-item.component.html',
  styleUrls: ['./goods-showcase-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoodsShowcaseItemComponent extends BasicViewComponent implements OnInit, OnDestroy {

  @Input() goods: GoodsShowcaseItem;

  errorMap = { 'not_enough_stock': 'Not enough Stock' };


  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(private dialog: MatDialog,
    private goodsService: GoodsService,
    private localSetting: LocalSettingService,
    private translate: TranslateService,
    private shoppingCartService: ShoppingCartService,
    snackBar: MatSnackBar,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private currencySettingService: CurrencySettingService,
  ) {
    super(snackBar);
  }

  ngOnInit(): void {
    this.currencySettingService.current$.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.cdr.markForCheck();
    });

  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  addToCart(event) {
    if (this.goods.productList[0].productVariantList.length == 1) {
      this.addGoods(event);
    } else {
      this.openAddToCartDialog(event);
    }
  }

  openAddToCartDialog(event) {
    this.stopPropagation(event);
    const dialogRef = this.dialog.open(GoodsDialogComponent, { data: { id: this.goods.id }, maxWidth: '60vw', minWidth: '600px' });
    dialogRef.afterClosed().subscribe(respData => {
      console.log('respData', respData);
    });
  }

  addGoods(event) {
    this.stopPropagation(event);
    const productList = this.goods.productList.map(product => {
      return { productId: product.id, productVariantId: product.productVariantList[0].id };
    });
    const goodsId = this.goods.id;
    const qty = 1;
    this.shoppingCartService.addGoods(goodsId, qty, productList)
      .subscribe({
        next: result => {
          if (result.success) {
            this.showMessage(this.goods.name + ' ' + this.translate.instant('added.to.the'), AddCartSnackBarComponent);
            this.shoppingCartService.refreshCount();
            this.shoppingCartService.refresh();

          } else {
            if (result.errorCode === 'out_of_quota') {
              // this.showMessage(this.translate.instant('max.qty.per.order.is') + this.goods.maxQtyPerOrder);
              this.showMessage('!' + this.goods.name + this.translate.instant('reach.max.order.qty'));
            } else {
              // const msgKey = this.errorMap[result.errorCode] || 'error';
              // const message = this.translate.instant(msgKey, { goodsName: this.goods.name });
              // this.showError(`${this.translate.instant('warning')}: ${message}`);
              this.showRespResultError(result, this.errorMap, { goodsName: this.goods.name });
            }
          }
        }
      });
  }

  oneClickCheckout(event) {
    this.addGoods(event);
    this.router.navigateByUrl('/shopping-cart');
  }

  stopPropagation(event) {
    event.preventDefault();
    event.stopPropagation();
  }

}

