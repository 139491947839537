import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { LoginService } from 'src/app/api/login.service';
import { SystemSettingService } from 'src/app/api/system-setting.service';
import { MemberService } from '../../api/member.service';
import { RespResult } from '../../api/table-api-service';

declare var FB;
declare var window;

@Injectable({
  providedIn: 'root'
})
export class FacebookLoginService {

  _enabled$ = new ReplaySubject<boolean>(1);

  constructor(
    private loginService: LoginService,
    private systemSettingService: SystemSettingService,
    private memberService: MemberService
  ) { }


  init() {
    this.systemSettingService.getSingleSignOn().subscribe(singleSignOn => {

      const facebookEnabled = singleSignOn.facebook;
      const facebookId = singleSignOn.facebookId;

      if (facebookEnabled) {

        const self = this;
        try {
          window.fbAsyncInit = function () {
            FB.init({
              appId: facebookId,
              cookie: true,
              xfbml: true,
              version: 'v10.0',
            });

            FB.AppEvents.logPageView();

            self._enabled$.next(true);
            self._enabled$.complete();
          };

          (function (d, s, id) {
            let js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js';
            js.defer = true;
            fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'));
        } catch (error) {
          console.error(error);
          self._enabled$.next(false);
          self._enabled$.complete();
        }

      } else {
        this._enabled$.next(false);
        this._enabled$.complete();
      }

    });

  }

  login(): Observable<RespResult> {
    const resp = new Subject<RespResult>();
    FB.login(response => {
      // console.log('fb response', response);
      if (response.status === 'connected') {
        // Logged into your webpage and Facebook.
        const accessToken = response.authResponse.accessToken;
        this.loginService.loginViaFacebook(accessToken).subscribe(data => {
          resp.next(data);
          resp.complete();
        });
      } else {
        // The person is not logged into your webpage or we are unable to tell.
        resp.complete();
      }
    }, { scope: 'email,user_birthday' });

    return resp;
  }

  connect(): Observable<RespResult> {
    const resp = new Subject<RespResult>();
    FB.login(response => {
      console.log('fb response', response);
      if (response.status === 'connected') {
        // Logged into your webpage and Facebook.
        const accessToken = response.authResponse.accessToken;
        this.memberService.connectFacebook(accessToken).subscribe(data => {
          resp.next(data);
          resp.complete();
        });
      } else {
        resp.complete();
      }
    }, { scope: 'email,user_birthday' });

    return resp;
  }

  syncFacebookInfo(): Observable<any> {
    const resp = new Subject<void>();
    FB.getLoginStatus(response => {
      console.log('fb response', response);
      if (response.status === 'connected') {
        const accessToken = response.authResponse.accessToken;
        this.memberService.syncFacebook(accessToken).subscribe(data => {
          resp.next(data);
          resp.complete();
        });
      } else {
        resp.next();
        resp.complete();
      }
    }, true);

    return resp;
  }

  onEnabled(): Observable<boolean> {
    return this._enabled$;
  }

  get onEnabled$(): Observable<boolean> {
    return this._enabled$;
  }

}
