import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SystemSettingService } from 'src/app/api/system-setting.service';
import { Project } from 'src/app/app-config';

export interface PageMeta {
  title?: string;
  keywords?: string;
  description?: string;
  image?: string;
  url?: string;
  slug?: string;
}


@Injectable({
  providedIn: 'root'
})
export class PageMetaService {

  defaultMeta;

  constructor(private meta: Meta,
    private title: Title,
    private systemSettingService: SystemSettingService
  ) {
    this.defaultMeta = {
      title: Project.title,
      keywords: Project.title,
      description: Project.title,
      image: Project.url + 'assets/img/logo.png'
    };

  }

  init() {
    this.systemSettingService.getMeta().subscribe(meta => {
      Project.title = meta.title;
      this.defaultMeta = {
        ...this.defaultMeta,
        ...meta
      };
      this.generateTags(this.defaultMeta);
    });
  }

  generateTags(pageMeta: PageMeta) {
    const config = {
      ...this.defaultMeta,
      ...pageMeta
    };

    this.title.setTitle(config.title);

    this.meta.updateTag({ name: 'title', content: config.title });
    this.meta.updateTag({ name: 'keywords', content: config.keywords ?? '' });
    this.meta.updateTag({ name: 'description', content: config.description ?? '' });

    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({ property: 'og:description', content: config.description ?? '' });
    this.meta.updateTag({ property: 'og:image', content: config.image ?? '' });
    this.meta.updateTag({ property: 'og:site_name', content: config.title });

    if (config.url) {
      this.meta.updateTag({ property: 'og:url', content: config.url });
    }

  }
}
