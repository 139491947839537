import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TopNavBarManagerService {

  private showTopNavBar$ = new BehaviorSubject(true);

  constructor() { }

  public show() {
    this.showTopNavBar$.next(true);
  }

  public hide() {
    this.showTopNavBar$.next(false);
  }

  public onVisableChanged(): Observable<boolean> {
    return this.showTopNavBar$.pipe(distinctUntilChanged());
  }
}
