import { LabelType, Options } from '@angular-slider/ngx-slider';
import { Component, ElementRef, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { PromotionService } from 'src/app/api/promotion.service';
import { BasicViewComponent } from 'src/app/basic-view-component';
import { CurrencySettingService } from 'src/app/shared/service/currency-setting.service';
import { FullScreenLoadingService } from 'src/app/shared/service/full-screen-loading.service';

@Component({
  selector: 'app-member-punch-card',
  templateUrl: './member-punch-card.component.html',
  styleUrls: ['./member-punch-card.component.scss']
})
export class MemberPunchCardComponent extends BasicViewComponent implements OnInit {

  errorMap = {
    'not_exist': 'punch.card.campaign.not.exist',
    'unavailable': 'punch.card.campaign.unavailable',
  };

  punchCardCampaign;

  goalPurchaseSliderOption: Options;
  totalAmountSliderOption: Options;

  defaultCurrencySymbol;

  constructor(snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService,
    private promotionService: PromotionService,
    private loadingService: FullScreenLoadingService,
    private currencySettingService: CurrencySettingService) {
    super(snackBar);
  }

  ngOnInit(): void {
    this.defaultCurrencySymbol = this.currencySettingService.getDefaultSymbol();
    this.initPunchCard();
  }

  initPunchCard() {
    this.promotionService.getPunchCardCampaign()
      .pipe(
        tap(data => {
          if (data.currentPurchase > data.goalPurchase) {
            data.currentPurchase = data.goalPurchase;
          }
          if (data.currentAmount > data.totalAmount) {
            data.currentAmount = data.totalAmount;
          }
          this.punchCardCampaign = data;
        }),
        tap(() => {
          this.goalPurchaseSliderOption = {
            animate: false,
            floor: 0,
            ceil: this.punchCardCampaign.goalPurchase,
            autoHideLimitLabels: true,
            readOnly: true,
            showTicksValues: true,
            ticksArray: [0, this.punchCardCampaign.goalPurchase],
            combineLabels: (minValue: string, maxValue: string): string => {
              return maxValue;
            },
            translate: (value: number, label: LabelType): string => {
              switch (label) {
                case LabelType.High:
                  const goalPurchase = this.punchCardCampaign.goalPurchase;
                  const currentPurchase = this.punchCardCampaign.currentPurchase;
                  let textAlignClass = '';
                  let labelClass = '';
                  if (currentPurchase >= goalPurchase) {
                    textAlignClass = 'text-right';
                    labelClass = 'label-at-the-end';
                  }
                  if (currentPurchase === 0) {
                    textAlignClass = 'text-left';
                    labelClass = 'label-in-beginning';
                  }
                  return '<div class="' + labelClass + '">' +
                    value + '<span class="material-icons ' + textAlignClass + '">location_on</span>' +
                    '</div>';
                case LabelType.Low:
                  return '';
                default:
                  return '' + value;
              }
            }
          };

          this.totalAmountSliderOption = {
            animate: false,
            floor: 0,
            ceil: this.punchCardCampaign.totalAmount,
            autoHideLimitLabels: true,
            readOnly: true,
            showTicksValues: true,
            ticksArray: [1, this.punchCardCampaign.totalAmount],
            combineLabels: (minValue: string, maxValue: string): string => {
              return maxValue;
            },
            translate: (value: number, label: LabelType): string => {
              switch (label) {
                case LabelType.High:
                  const totalAmount = this.punchCardCampaign.totalAmount;
                  const currentAmount = this.punchCardCampaign.currentAmount;
                  let textAlignClass = '';
                  let labelClass = '';
                  if (currentAmount >= totalAmount) {
                    textAlignClass = 'text-right';
                    labelClass = 'label-at-the-end';
                  }
                  if (currentAmount === 0) {
                    textAlignClass = 'text-left';
                    labelClass = 'label-in-beginning';
                  }
                  return '<div class="' + labelClass + '">' +
                    this.defaultCurrencySymbol + value + '<span class="material-icons ' + textAlignClass + '">location_on</span>' +
                    '</div>';
                case LabelType.Low:
                  return '';
                default:
                  return this.defaultCurrencySymbol + value;
              }
            }
          };
        })
      ).subscribe();
  }

  redeem() {
    this.loadingService.show();
    this.promotionService.redeemPunchCard(this.punchCardCampaign.id)
      .subscribe({
        next: result => {
          if (result.success) {
            if (result.data?.couponName) {
              this.showMessage(this.translate.instant('punch.card.redeem.coupon.description', { couponName: result.data?.couponName }));
            }
            if (result.data?.points) {
              this.showMessage(this.translate.instant('punch.card.redemm.points.reward.description', { points: result.data?.points }));
            }
            this.initPunchCard();
          } else {
            // const msgKey = this.errorMap[result.errorCode] || 'error';
            // this.showError(`${this.translate.instant('warning')}: ${this.translate.instant(msgKey)}`);
            this.showRespResultError(result, this.errorMap);
          }
        },
        complete: () => this.loadingService.hide()
      });
  }

}
