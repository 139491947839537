import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericTableApiService } from './generic-table-api.service';

@Injectable({
    providedIn: 'root'
})
export class WarehouseService extends GenericTableApiService {

    constructor(httpClient: HttpClient) {
        super(httpClient, 'warehouse');
    }
}
