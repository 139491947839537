import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PhotoGalleryPageService } from 'src/app/api/photo-gallery-page.service';

@Component({
  selector: 'app-photo-gallery-page-list',
  templateUrl: './photo-gallery-page-list.component.html',
  styleUrls: ['./photo-gallery-page-list.component.scss']
})
export class PhotoGalleryPageListComponent implements OnInit {

  loading = false;
  
  photoGalleryPageList = [];
  navigationName;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private photoGalleryPageService: PhotoGalleryPageService, ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.navigationName = params.navigationName;
    });

    this.photoGalleryPageService.query().subscribe(data => {
      this.photoGalleryPageList = data;
    });
  }

}
