import { Observable } from 'rxjs';
import { ForgotPasswordService } from '../api/forgot-password.service';
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BasicViewComponent } from '../basic-view-component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent extends BasicViewComponent implements OnInit, AfterViewInit {

  @Input() redirectUrl = '';

  // @ViewChild('idDom') idElement: ElementRef;
  emailAddress = '';
  user = { id: '', password: '' };
  hidePwd = true;

  msg = '';

  loading;

  errorMap = {
    'invalid_input': 'email.error.invalid',
  };

  constructor(private router: Router,
    snackBar: MatSnackBar,
    private translate: TranslateService,
    private forgotPasswordService: ForgotPasswordService) {

    super(snackBar);

    const state = router.getCurrentNavigation().extras.state;
    if (state && state.emailAddress) {
      this.emailAddress = state.emailAddress;
    }
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {

  }

  forgotPassword() {
    this.loading = true;
    this.forgotPasswordService.forgotPassword(this.emailAddress).pipe(finalize(() => this.loading = false)).subscribe(respResult => {
      console.log('ForgotPassword result:', respResult);
      if (respResult.success) {
        // this.router.navigateByUrl(this.redirectUrl, { replaceUrl: true });
        // this.showMessage(this.translate.instant('success'));
        // this.msg = this.translate.instant('forgot.password.success.msg');
        this.router.navigate(['/forgot-password-notification']);
      } else {
        this.user.password = '';
        this.msg = this.translate.instant(this.errorMap[respResult.errorCode]);
      }
    }, error => console.log(error));

  }

}
