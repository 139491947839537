import { Component, OnInit, Input } from '@angular/core';

export interface Currency {
  code;
  digits;
  symbolLeft;
}

@Component({
  selector: 'app-showcase-item-price',
  templateUrl: './showcase-item-price.component.html',
  styleUrls: ['./showcase-item-price.component.scss']
})
export class ShowcaseItemPriceComponent implements OnInit {

  @Input() price: number;
  @Input() listPrice: number;

  constructor() { }

  ngOnInit() {
  }

}
