import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewsAndEventsPageService } from 'src/app/api/news-and-events-page.service';

@Component({
  selector: 'app-news-and-events-page-item',
  templateUrl: './news-and-events-page-item.component.html',
  styleUrls: ['./news-and-events-page-item.component.css']
})
export class NewsAndEventsPageItemComponent implements OnInit {

  loading = false;

  newsAndEventsPageId;
  newsAndEventsPageItemId;

  newsAndEventsPageItem: any = {};

  constructor(private router: Router,
    private route: ActivatedRoute,
    private newsAndEventsPageService: NewsAndEventsPageService,) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.newsAndEventsPageId = params.idWithSlug.split('-')[0];
      
      const paramList = params.iIdWithSlug.split('-');
      this.newsAndEventsPageItemId = paramList[0];
      const slug = params.iIdWithSlug.substring(this.newsAndEventsPageId.length + 1);

      if (paramList.legnth < 2) {
        this.redirect404Page();
        return;
      }

      this.newsAndEventsPageService.getItem(this.newsAndEventsPageId, this.newsAndEventsPageItemId).subscribe(data => {
        this.newsAndEventsPageItem = data;
      });
    })
  }

  redirect404Page() {
    this.router.navigateByUrl('404', { replaceUrl: true, skipLocationChange: true });
  }

}
