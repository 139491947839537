import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormArray, FormGroupDirective, ValidationErrors, ValidatorFn, UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Subject } from 'rxjs';
import { debounceTime, delay, finalize, switchMap, takeUntil } from 'rxjs/operators';
import { FormService, InputField } from '../api/form.service';
import { HttpCancelService } from '../api/interceptor/http-cancel.service';
import { LoginService } from '../api/login.service';
import { MemberService } from '../api/member.service';
import { SystemSettingService } from '../api/system-setting.service';
import { BasicViewComponent } from '../basic-view-component';
import { FormId } from '../shared/enum/form-id.enum';
import { InputFieldId } from '../shared/enum/input-field-id.enum';
import { FullScreenLoadingService } from '../shared/service/full-screen-loading.service';
import { CustomFieldUtil } from '../shared/util/custom-field-util';
import { ReactiveFormUtil } from '../shared/util/reactive-form-util';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent extends BasicViewComponent implements OnInit, OnDestroy {
  errorMap: { 'email_duplicate': 'email.associated' };

  loading;

  yearOfBirthOptionList;

  form: UntypedFormGroup;

  preDefinedFieldList = [];
  subscriptionAndTac = [];
  customFieldList = [];

  telephoneCountryCodeList = [];

  inputFieldId = InputFieldId;
  predefinedIdList = [InputFieldId.Salutation, InputFieldId.IdNoPassportNo, InputFieldId.PhoneNumber, InputFieldId.Address, InputFieldId.Subscription,
  InputFieldId.TacSignup, InputFieldId.FullName, InputFieldId.EmailAddress, InputFieldId.Dob];

  private _destroyed = new Subject<void>();

  constructor(
    private router: Router,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private loginService: LoginService,
    private httpCancelService: HttpCancelService,
    private loadingService: FullScreenLoadingService,
    private memberService: MemberService,
    protected snackBar: MatSnackBar,
    private systemSettingService: SystemSettingService,
    private formService: FormService,
  ) {

    super(snackBar);

    this.form = fb.group({
      phoneNumber: [''], // , [Validators.required, Validators.maxLength(45)]
      lastName: [''], // , [Validators.required, Validators.maxLength(45)]
      firstName: [''], // , [Validators.required, Validators.maxLength(100)]
      emailAddress: [''], // Validators.required, [Validators.email],
      monthOfBirth: [''], // , Validators.required
      yearOfBirth: [''], // , Validators.required
      password: ['', [Validators.minLength(6), Validators.pattern(/^((.)*([0-9])(.)*)*$/)]], // Validators.required,
      confirmPassword: ['', Validators.required],
      // subscription: [false],
      notSubscription: [false],
      title: [''], // , Validators.required
      agreeTac: ['', Validators.requiredTrue],
      address: [''],
      idNoPassportNo: [''],
      // customFieldList: fb.array([]),
      customField: fb.group({}),
    }, {
      validators: checkIfMatchingPasswords('password', 'confirmPassword')
    });

    this.loading = true;

    this.yearOfBirthOptionList = this.rangeForYearOfBirth();
    this.systemSettingService.getTelephoneCountryCode().pipe(
      finalize(() => this.loading = false)
    ).subscribe(data => this.telephoneCountryCodeList = data);
  }

  ngOnInit() {

    this.form.get('emailAddress').valueChanges.pipe(
      debounceTime(500),
      switchMap((value, index) => {
        if (value) {
          return this.memberService.emailAddressExist(value);
        } else {
          return EMPTY;
        }
      }),
      takeUntil(this._destroyed)
    ).subscribe(exist => {
      if (exist) {
        // this.form.get('emailAddress').setErrors({ 'duplicateEmailAddress': true });
        ReactiveFormUtil.addErrors({ 'duplicateEmailAddress': true }, this.form.get('emailAddress'));
      }
      // else {
      //   this.form.get('emailAddress').setErrors(null);
      //   // this.form.get('emailAddress').updateValueAndValidity({ emitEvent: false });
      // }
    });

    let uniquePhoneNumber = false;
    this.formService.get(FormId.MemberProfile).subscribe(data => {
      const preDefinedFieldList = data.preDefinedFieldInfoList;
      preDefinedFieldList.forEach(pdf => {
        const pdfId = pdf.id;
        if (pdfId !== 5 && pdfId !== 6) {
          this.preDefinedFieldList.push(pdf);
        } else {
          this.subscriptionAndTac.push(pdf);
        }
        if (pdfId === 3) {
          if (pdf.unique) {
            uniquePhoneNumber = true;
          }
        }

        switch (pdfId) {
          case InputFieldId.Salutation:
            this.assignValidators(this.form, 'title', pdf);
            break;
          case InputFieldId.IdNoPassportNo:
            this.assignValidators(this.form, 'idNoPassportNo', pdf);
            break;
          case InputFieldId.PhoneNumber:
            this.assignValidators(this.form, 'phoneNumber', pdf);
            break;
          case InputFieldId.Address:
            this.assignValidators(this.form, 'address', pdf);
            break;
          case InputFieldId.FullName:
            this.assignValidators(this.form, 'lastName', pdf);
            this.assignValidators(this.form, 'firstName', pdf);
            break;
          case InputFieldId.EmailAddress:
            this.assignValidators(this.form, 'emailAddress', pdf, [Validators.email]);
            break;
          case InputFieldId.Dob:
            this.assignValidators(this.form, 'monthOfBirth', pdf);
            this.assignValidators(this.form, 'yearOfBirth', pdf);
            break;
        }
      });
      this.predefinedIdList.forEach(predefinedId => {
        const hasFieldData = preDefinedFieldList.find(preDefinedField => preDefinedField.id === predefinedId);
        if (!hasFieldData) {
          switch (predefinedId) {
            case InputFieldId.Salutation:
              ReactiveFormUtil.removeFormControl(this.form, 'title');
              break;
            case InputFieldId.IdNoPassportNo:
              ReactiveFormUtil.removeFormControl(this.form, 'idNoPassportNo');
              break;
            case InputFieldId.PhoneNumber:
              ReactiveFormUtil.removeFormControl(this.form, 'phoneNumber');
              break;
            case InputFieldId.Address:
              ReactiveFormUtil.removeFormControl(this.form, 'address');
              break;
            case InputFieldId.FullName:
              ReactiveFormUtil.removeFormControl(this.form, 'lastName');
              ReactiveFormUtil.removeFormControl(this.form, 'firstName');
              break;
            case InputFieldId.EmailAddress:
              ReactiveFormUtil.removeFormControl(this.form, 'emailAddress');
              break;
            case InputFieldId.Dob:
              ReactiveFormUtil.removeFormControl(this.form, 'monthOfBirth');
              ReactiveFormUtil.removeFormControl(this.form, 'yearOfBirth');
              break;
          }
        }
      });

      this.customFieldList = data.customFieldInfoList;
      data.customFieldInfoList.forEach(inputField => {
        this.addCustomField(inputField);
      });
    });

    // if (uniquePhoneNumber) {
    this.form.get('phoneNumber').valueChanges.pipe(debounceTime(500),
      switchMap((value, index) => {
        if (value && uniquePhoneNumber) {
          return this.memberService.phoneNumberExist(value);
        } else {
          return EMPTY;
        }
      }),
      takeUntil(this._destroyed)
    ).subscribe(exist => {
      if (exist) {
        this.form.get('phoneNumber').setErrors({ 'duplicatePhoneNumber': true });
      } else {
        this.form.get('phoneNumber').setErrors(null);
      }
    });
    // }
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }


  createAccount() {
    if (this.form.valid) {
      this.loadingService.show();
      const { confirmPassword, agreeTac, ...formData } = this.form.value;

      const api = this.memberService.register(formData);
      api.pipe(finalize(() => this.loadingService.hide())).subscribe(result => {
        // console.log(result);
        // console.log(result.success);
        if (result.success) {
          if (result.data.requiredActivationByEmail) {
            this.router.navigate(['/register-complete'], { state: { emailAddress: formData.emailAddress } });
          } else {
            const user = {
              id: this.form.get('emailAddress').value,
              password: this.form.get('confirmPassword').value
            };
            this.loginService.login(user).pipe(finalize(() => this.loadingService.hide())).subscribe(data => {
              console.log('Login result:', data);
              // if (data.success) {
              this.loadingService.show();
              this.router.navigateByUrl('/home', { replaceUrl: true });
              // } else {
              //   this.invalidLogin = true;
              // }
            }, error => console.log(error));
          }
          // alert("success");
        } else {
          // const msgKey = this.errorMap[result.errorCode] || 'error';
          // this.showError(`${this.translate.instant('error')}: ${result.msg}`); //  ${this.translate.instant(msgKey)},
          this.showRespResultError(result, this.errorMap);
        }
      });
    } else {
      this.form.markAllAsTouched();
      const formErrors = ReactiveFormUtil.collectErrors(this.form);
      console.log('register form errors', formErrors);
    }
  }

  rangeForYearOfBirth(): string[] {
    const currentYear = new Date().getUTCFullYear();
    return Array.from(new Array(100), (x, i) => (currentYear - i).toString());
  }

  // changeValueOfCustomFields(id: number, value: any) {
  //   // console.log('id', id);
  //   // console.log('value', value);

  //   let changeCustomField = this.customFieldData.find(d => d.id == id);
  //   changeCustomField.value = value;

  //   console.log('this.customFieldData', this.customFieldData);
  // }

  addCustomField(inputField) {
    const customField = CustomFieldUtil.buildCustomField(inputField);
    // (this.form.get('customFieldList') as FormArray).push(customField);
    (this.form.get('customField') as UntypedFormGroup).addControl(inputField.key, customField);
    return customField;
  }

  assignValidators(form: UntypedFormGroup, formControlName: string, inputField: InputField, basicValidators = []) {
    const fc = form.get(formControlName) as UntypedFormControl;
    CustomFieldUtil.assignValidators(fc, inputField, basicValidators);
  }

  optionValueChange(key, value, event) {
    const checked = event.checked;
    const customFieldFc = this.form.get('customField.' + key);
    const cfValue = customFieldFc.value;
    if (checked) {
      cfValue.push(value);
      customFieldFc.patchValue(cfValue);
    } else {
      cfValue.splice(cfValue.indexOf(value), 1);
    }
  }
}

const checkIfMatchingPasswords = (passwordKey: string, passwordConfirmationKey: string) => {
  return (group: UntypedFormGroup) => {
    const passwordInput = group.controls[passwordKey],
      passwordConfirmationInput = group.controls[passwordConfirmationKey];
    if (passwordInput.value !== passwordConfirmationInput.value) {
      return passwordConfirmationInput.setErrors({ notEquivalent: true, reason: passwordConfirmationInput });
    } else {
      return passwordConfirmationInput.setErrors(null);
    }
  };
};


