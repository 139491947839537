import { MatSnackBar } from '@angular/material/snack-bar';
import { Input, OnInit, OnDestroy, Injectable, Component, Inject, Optional } from '@angular/core';
import { SnackBarComponent } from './snack-bar/snack-bar.component';
import { AddCartSnackBarComponent } from './add-cart-snack-bar/add-cart-snack-bar.component';
import { ComponentType } from '@angular/cdk/portal';
import { RespResult } from './api/table-api-service';
import { TranslateService } from '@ngx-translate/core';
import { AppInjector } from './shared/service/app-injector';

@Injectable()
export class BasicViewComponent {

    @Input() showBackBtn = true;

    constructor(protected snackBar: MatSnackBar) { }

    // ngOnInit(): void {
    //     console.log('BasicViewComponent ngOnInit');
    // }

    // ngOnDestroy(): void {
    //     console.log('BasicViewComponent ngOnDestroy');
    // }


    showMessage(message: string, component: ComponentType<any> = SnackBarComponent) {
        // this.snackBar.open(message, 'OK', { duration: 3000, verticalPosition: 'top', panelClass: ['message']});
        const snackBar = this.snackBar.openFromComponent(component, {
            duration: 3000,
            verticalPosition: 'top',
            data: { preClose: () => { snackBar.dismiss(); }, type: 'success', message: message },
        });
    }

    // showAddGoodsSuccessMessage(message: string) {
    //     const snackBar = this.snackBar.openFromComponent(AddCartSnackBarComponent, {
    //         duration: 3000,
    //         verticalPosition: 'top',
    //         data: { preClose: () => { snackBar.dismiss(); }, type: 'success', message: message },
    //     });
    // }

    showError(message: string, component: ComponentType<any> = SnackBarComponent) {
        // this.snackBar.open(message, 'OK', { verticalPosition: 'top', panelClass: ['background-color-warn', 'error'] });
        const snackBar = this.snackBar.openFromComponent(component, {
            // duration: 3000,
            verticalPosition: 'top',
            data: { preClose: () => { snackBar.dismiss(); }, type: 'error', message: message },
            panelClass: ['background-color-warn'],
        });
    }

    showRespResultError(respResult: RespResult, errorMap: { [key: string]: string }, interpolateParams?: any) {
        const translate = AppInjector.get(TranslateService);
        const msgKey = errorMap[respResult.errorCode];
        let msg: string;
        if (msgKey) {
            msg = translate.instant(msgKey, interpolateParams);
        } else {
            msg = translate.instant('common.error.message') + `(${respResult.errorCode})`;
        }
        let respMsg = respResult.msg;
        if (respMsg) {
            respMsg = translate.instant(respResult.msg);
        }
        this.showError(`${msg}. ${respMsg || ''}`);
    }
}
