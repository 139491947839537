import { Component, OnInit, OnDestroy, TemplateRef, ViewChild, Renderer2, HostListener, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ShoppingCartService } from '../api/shopping-cart.service';
import { BasicViewComponent } from '../basic-view-component';
import { Subject, Observable, Subscription, empty, EMPTY, throwError, interval } from 'rxjs';
import { debounceTime, map, take, tap, finalize, switchMap, takeUntil, skip, distinctUntilChanged, distinct, catchError } from 'rxjs/operators';
import { LoginService } from '../api/login.service';
import { FullScreenLoadingService } from '../shared/service/full-screen-loading.service';
import { GoodsService } from '../api/goods.service';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ShoppingCartGiftBottomSheetComponent } from '../_shopping-cart-gift-bottom-sheet/shopping-cart-gift-bottom-sheet.component';
import { InfoPageService } from '../api/info-page.service';
import { SystemSettingService } from '../api/system-setting.service';
import { CouponDialogComponent } from '../coupon-dialog/coupon-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TopNavBarManagerService } from '../shared/service/top-nav-bar-manager.service';
import { InformationBottomSheetService } from '../shared/service/information-bottom-sheet.service';
import { OrderNavigationTopBarComponent } from '../order-navigation-top-bar/order-navigation-top-bar.component';
import * as moment from 'moment';
import { HeaderSizeService } from '../shared/service/header-size.service';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent extends BasicViewComponent implements OnInit, OnDestroy {

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy$ = new Subject<void>();

  @ViewChild('giftSelect') giftSelectTemplate: TemplateRef<any>;
  @ViewChild(OrderNavigationTopBarComponent, { read: ElementRef }) public topBar;

  // showProductList = null;
  showProductList = true;

  errorMap = {
    'not_enough_stock': 'not.enough.stock',
    'out_of_quota': 'out.of.quota',
    'cart_session_timeout': 'cart.session.timeout',
  };

  promotionCodeErrorMap = {
    'not_exist': 'promotion.code.error.not.exist',
    'duplicate_code': 'promotion.code.error.duplicate',
    'expired': 'promotion.code.error.expired',
    'unavailable': 'promotion.code.error.unavailable',
    'cart_session_timeout': 'cart.session.timeout'
  };

  isLogin: boolean;

  shoppingCart: any;

  qtyChangeEventMap: { [key: number]: Subject<any> } = {};

  promotionCode: any;

  goodsGiftPromotionList = [];
  goodsGiftPromotion;
  // goodsGiftList = [];

  productVariantGiftPromotionList = [];
  productVariantGiftPromotion;

  disableGiftSelectionClose = false;

  canCheckout = false;

  // isShowGiftSelect = true;
  giftSelectBottomSheetRef: MatBottomSheetRef;

  showShoppingCartMessage = false;
  shoppingCartMessage;

  hideAddOnPromotion = false;

  currentScrollPoistion = 0;

  navBarHeight;

  private newCart$ = new Subject<void>();

  headerSize$: Observable<number>;

  constructor(private router: Router,
    private route: ActivatedRoute,
    snackBar: MatSnackBar,
    private translate: TranslateService,
    private loadingService: FullScreenLoadingService,
    private shoppingCartService: ShoppingCartService,
    private loginService: LoginService,
    private goodsService: GoodsService,
    private bottomSheet: MatBottomSheet,
    private infoPageService: InfoPageService,
    private systemSettingService: SystemSettingService,
    private dialog: MatDialog,
    private headerSizeService: HeaderSizeService,
    private topNavBarManagerService: TopNavBarManagerService,
    private informationBottomSheetService: InformationBottomSheetService,
    private renderer: Renderer2) {
    super(snackBar);

    this.headerSize$ = this.headerSizeService.getHeight();
  }

  ngOnInit() {
    // this.refreshShoppingCart().subscribe();



    this.loginService.onLoggedIn().pipe(takeUntil(this._onDestroy$)).subscribe(isLogin => this.isLogin = isLogin);

    // this.refreshShoppingCart();
    this.shoppingCartService.cart()
      .pipe(
        // distinct(),
        // skip(1),
        tap({
          next: data => {

            // clean subscription
            // Object.values(this.qtyChangeEventMap).forEach(subscription => subscription.unsubscribe());

            // Stop previous gift goods query
            this.newCart$.next();
            this.newCart$.complete();
            this.newCart$ = new Subject<void>();

            this.shoppingCart = data;

            if (this.shoppingCart) {
              const subCartList = this.shoppingCart.subCartList;
              if (subCartList) {
                // // Binding Cart Goods qty update event for update server
                // subCartList.forEach(subCart => {
                //   subCart.cartGoodsList.forEach(cartGoods => {
                //     const qtyChange$: Subject<any> = new Subject<void>();
                //     this.qtyChangeEventMap[cartGoods.id] = qtyChange$;
                //     qtyChange$.pipe(
                //       debounceTime(500),
                //       takeUntil(this._onDestroy$)
                //     ).subscribe(cartgoods => {
                //       this.loadingService.show();
                //       this.shoppingCartService.updateGoods(cartGoods)
                //         .pipe(
                //           switchMap(result => {
                //             if (!result.success) {
                //               const msgKey = this.errorMap[result.errorCode] || 'error';
                //               this.showError(`${this.translate.instant('error')}: ${this.translate.instant(msgKey)}, ${result.msg || ''}`);
                //             }
                //             this.refreshShoppingCart();
                //             return EMPTY;
                //           }),
                //           finalize(() => this.loadingService.hide())
                //         )
                //         .subscribe();
                //     });
                //   });
                // });

                // query goods gift option
                // this.goodsGiftList = [];
                // subCartList.forEach(subCart => {
                //   subCart.cartGoodsList.filter(cartGoods => cartGoods.gift).forEach(cartGoods => {
                //     const goodsId = cartGoods.goodsId;
                //     const cartGoodsId = cartGoods.id;
                //     const cartProduct = cartGoods.cartProductList[0];
                //     const selectedProductVariantId = cartProduct.productVariantId;
                //     const cartProductId = cartProduct.id;

                //     this.loadingService.show();
                //     this.goodsService.get(goodsId)
                //       .pipe(
                //         takeUntil(this.newCart$),
                //         finalize(() => this.loadingService.hide())
                //       )
                //       .subscribe(goods => {

                //         const optionList = goods.productList[0].productVariantList.map(productVariant => {
                //           let image = productVariant.imageList[0];
                //           if (!image) {
                //             image = {};
                //           }
                //           // const selected = productVariantId === productVariant.id;
                //           // const stockList = productVariant.stockList;
                //           const disabled = productVariant.outOfStock;

                //           const option = {
                //             id: productVariant.id,
                //             name: productVariant.name,
                //             image: image,
                //             disabled: disabled
                //           };
                //           return option;
                //         });

                //         const promotion = this.getPromotion(this.shoppingCart, cartGoodsId);

                //         const goodsGift = {
                //           name: goods.name,
                //           cartGoodsId: cartGoodsId,
                //           cartProductId: cartProductId,
                //           optionList: optionList,
                //           selectedId: selectedProductVariantId,
                //           promotion: promotion
                //         };

                //         this.goodsGiftList.push(goodsGift);
                //       });
                //   });
                // });

                // Check fulfill qty restriction
                const unfulfilledQtyRestriction = subCartList
                  .flatMap(subCart => subCart.cartGoodsList.map(cartGoods => cartGoods.unfulfilledMinQtyPerOrder))
                  .some(unfulfilledMinQtyPerOrder => unfulfilledMinQtyPerOrder);
                this.canCheckout = !unfulfilledQtyRestriction;

                // Check if exist cart goods
                const cartGoodsList = subCartList.flatMap(subCart => subCart.cartGoodsList);
                this.hideAddOnPromotion = cartGoodsList.length === 0;
              }

              // promotion countdown & promotion goods
              this.shoppingCart.cartPromotionList.forEach(cartPromotion => {
                cartPromotion.promotedGoodsList = this.getCartPromotionGoodsList(cartPromotion.id);
                if (cartPromotion.expiryDatetime) {
                  cartPromotion.countDown = this.promotionExpiryCount(cartPromotion.expiryDatetime);
                }
              });

              if (this.shoppingCart.cartPendingPromotionList) {
                this.shoppingCart.cartPendingPromotionList.forEach(promotion => {
                  promotion.countDown = this.promotionExpiryCount(promotion.expiryDatetime);
                });
              }
            }
            this.loadingService.hide();
          },
          error: err => {
            this.loadingService.hide();
          },
          complete: () => {
            console.log('complete');
          }
        }),
        switchMap(() => {
          return this.refreshGoodsGiftPromotion();
        }),
        switchMap(() => {
          return this.refreshProductVariantGiftPromotion();
        }),
        catchError((err) => {
          this.loadingService.hide();
          return throwError(err);
        }),
        takeUntil(this._onDestroy$),
      ).subscribe();

    // this.refreshShoppingCart();

    this.systemSettingService.getShoppingCartAndCheckoutSetting().subscribe(data => {
      this.showShoppingCartMessage = data.shoppingCartShowMessage;
      this.shoppingCartMessage = data.shoppingCartMessage;
    });

    this.topNavBarManagerService.hide();

    interval(1000).subscribe(() => {
      if (this.shoppingCart) {
        const pendingPromotionList = this.shoppingCart.cartPendingPromotionList;
        pendingPromotionList.forEach(promotion => {
          promotion.countDown = this.promotionExpiryCount(promotion.expiryDatetime);
        });

        const cartPromotionList = this.shoppingCart.cartPromotionList;
        cartPromotionList.forEach(promotion => {
          if (promotion.countDown) {
            promotion.countDown = this.promotionExpiryCount(promotion.expiryDatetime);
          }
        });
      }
    });

    setTimeout(() => {
      this.navBarHeight = this.topBar.nativeElement.querySelector('app-navigation-bar').offsetHeight;
    });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();

    this.topNavBarManagerService.show();
  }

  // getShppingCart() {
  //   return this.shoppingCartService.get();
  // }

  refreshShoppingCart() {
    this.loadingService.show();
    this.shoppingCartService.refreshCount();
    this.shoppingCartService.refresh();
  }

  refreshGoodsGiftPromotion() {
    return this.shoppingCartService.queryPromotionWithGiftGoods()
      .pipe(tap((goodsGiftPromotionList) => {
        goodsGiftPromotionList.forEach(goodsGiftPromotion => {
          goodsGiftPromotion.giftList.forEach(goodsGift => {
            goodsGift.optionList.forEach(giftOption => {
              giftOption.image = giftOption.image || {};
              giftOption.disabled = giftOption.outOfStock;
            });
          });
        });
        this.goodsGiftPromotionList = goodsGiftPromotionList;

      }));
  }

  refreshProductVariantGiftPromotion() {
    return this.shoppingCartService.queryPromotionWithGiftProductVariant()
      .pipe(tap((productVariantGiftPromotionList) => {
        productVariantGiftPromotionList.forEach(productVariantGiftPromotion => {

          const optionList = productVariantGiftPromotion.optionList;
          optionList.forEach(giftOption => {
            giftOption.image = giftOption.image || {};
            giftOption.disabled = giftOption.outOfStock;
          });


          let choiceQty = productVariantGiftPromotion.choiceQty;
          const availableCount = optionList.filter(o => !o.disabled).length;
          if (availableCount < choiceQty) {
            choiceQty = availableCount;
          }

          productVariantGiftPromotion.choiceQty = choiceQty;

          const selectedCount = optionList.filter(o => o.selected).length;
          productVariantGiftPromotion.selectedCount = selectedCount;


        });
        this.productVariantGiftPromotionList = productVariantGiftPromotionList;

      }));
  }

  minusQty(cartGoods) {
    if (this.canMinusQty(cartGoods)) {
      cartGoods.qty--;
      this.updateQty(cartGoods);
    }
  }

  plusQty(cartGoods) {
    if (this.canPlusQty(cartGoods)) {
      cartGoods.qty++;
      this.updateQty(cartGoods);
    }
  }

  canMinusQty(cartGoods) {
    return cartGoods.qty > 1 && (!cartGoods.qtyLimitPerOrder || (cartGoods.qty > cartGoods.minQtyPerOrder));
  }

  canPlusQty(cartGoods) {
    return !cartGoods.qtyLimitPerOrder || (cartGoods.qty < cartGoods.maxQtyPerOrder);
  }

  onGoodsQtyInputBlur(cartGoods) {
    if (cartGoods.qty === null || cartGoods.qty < 1) {
      cartGoods.qty = 1;
    }

    console.log('cartGoods.qty', cartGoods.qty);
    console.log('cartGoods.qtyLimitPerOrder', cartGoods.qtyLimitPerOrder);
    console.log('cartGoods.minQtyPerOrder', cartGoods.minQtyPerOrder);
    console.log('cartGoods.maxQtyPerOrder', cartGoods.maxQtyPerOrder);

    // if (cartGoods.qtyLimitPerOrder) {
    //   const productName = cartGoods.cartProductList.map(cartProduct => cartProduct.name).join(', ');
    //   const goodsName = cartGoods.name;
    //   const name = goodsName + ' ' + productName;
    //   if (cartGoods.qty < cartGoods.minQtyPerOrder) {
    //     cartGoods.qty = cartGoods.minQtyPerOrder;
    //     this.showMessage('!' + name + this.translate.instant('min.qty.per.order.is') + cartGoods.minQtyPerOrder);
    //   } else if (cartGoods.qty > cartGoods.maxQtyPerOrder) {
    //     cartGoods.qty = cartGoods.maxQtyPerOrder;
    //     // this.showMessage(this.translate.instant('max.qty.per.order.is') + cartGoods.maxQtyPerOrder);

    //     this.showMessage('!' + name + this.translate.instant('reach.max.order.qty'));
    //   }
    // }

    this.updateQty(cartGoods);
  }

  onGoodsQtyChanged(cartGoods, qty) {
    cartGoods.qty = qty;
  }

  updateQty(cartGoods) {

    let qtyChange$: Subject<any> = this.qtyChangeEventMap[cartGoods.id];

    if (!qtyChange$) {
      qtyChange$ = new Subject<void>();
      this.qtyChangeEventMap[cartGoods.id] = qtyChange$;
      qtyChange$.pipe(
        debounceTime(500),
        takeUntil(this._onDestroy$)
      ).subscribe(newCartGoods => {
        this.loadingService.show();
        this.shoppingCartService.updateGoods(newCartGoods)
          .pipe(
            switchMap(result => {
              if (!result.success) {
                // const msgKey = this.errorMap[result.errorCode] || 'error';
                // const message = this.translate.instant(msgKey, { goodsName: newCartGoods.name });
                // // this.showError(`${this.translate.instant('warning')}: ${this.translate.instant(msgKey)}`);
                // this.showError(`${this.translate.instant('warning')}: ${message}`);
                // // this.showError(`${this.translate.instant('error')}: ${this.translate.instant(msgKey)}, ${result.msg || ''}`);
                this.showRespResultError(result, this.errorMap, { goodsName: newCartGoods.name });
              }
              this.refreshShoppingCart();
              return EMPTY;
            }),
            finalize(() => this.loadingService.hide())
          )
          .subscribe();
      });
    }

    qtyChange$.next(cartGoods);
  }

  deleteGoodsOrPromotion(cartGoods) {
    if (cartGoods.bundle) {
      cartGoods.cartPromotionList.forEach(cartGoodsPromotion => {
        if (this.isBundlePromotion(cartGoodsPromotion)) {
          this.deleteBundlePromotion(cartGoodsPromotion.id);
        }
      });
    } else {
      this.deleteGoods(cartGoods.id);
    }
  }

  deleteGoods(cartGoodsId: number) {
    this.loadingService.show();
    this.shoppingCartService.deleteGoods(cartGoodsId)
      .pipe(
        switchMap(result => {
          if (result.success) {
            this.refreshShoppingCart();
          } else {
            // const msgKey = this.errorMap[result.errorCode] || 'error';
            // this.showError(`${this.translate.instant('error')}: ${this.translate.instant(msgKey)}, ${result.msg}`);
            this.showRespResultError(result, this.errorMap);
            if (result.errorCode === 'cart_session_timeout') {
              this.refreshShoppingCart();
            }
          }
          return EMPTY;
        }),
        finalize(() => this.loadingService.hide())
      )
      .subscribe();
  }

  deleteBundlePromotion(cartPromotionId) {
    this.loadingService.show();
    this.shoppingCartService.deleteBundlePromotion(cartPromotionId).pipe(
      switchMap(result => {
        if (result.success) {
          this.refreshShoppingCart();
        } else {
          // const msgKey = this.errorMap[result.errorCode] || 'error';
          // this.showError(`${this.translate.instant('error')}: ${result.msg}`);//  ${this.translate.instant(msgKey)},
          this.showRespResultError(result, this.errorMap);
          if (result.errorCode === 'cart_session_timeout') {
            this.refreshShoppingCart();
          }
        }
        return EMPTY;
      }),
      finalize(() => this.loadingService.hide())
    ).subscribe();
  }


  // checkout() {
  //   this.loadingService.show();
  //   this.loginService.getStatus().pipe(finalize(() => this.loadingService.hide())).subscribe(data => {
  //     const isLoggedIn = data.isLoggedIn;
  //     if (isLoggedIn) {
  //       this.router.navigateByUrl('checkout');
  //     } else {
  //       this.router.navigateByUrl('shopping-cart-login');
  //     }
  //   });
  // }

  addPromotionCode() {
    const promotionCode = this.promotionCode;
    console.log('use promotion code:', promotionCode);
    if (promotionCode) {
      this.loadingService.show();
      this.shoppingCartService.addPromotion(promotionCode)
        .pipe(
          switchMap(result => {
            if (result.success) {
              this.refreshShoppingCart();
            } else {
              // const msgKey = this.promotionCodeErrorMap[result.errorCode] || 'error';
              // // this.showError(`${this.translate.instant('error')}: ${this.translate.instant(msgKey)}, ${result.msg || ''}`);
              // this.showError(`${this.translate.instant('warning')}: ${this.translate.instant(msgKey)}`);
              this.showRespResultError(result, this.promotionCodeErrorMap);
              if (result.errorCode === 'cart_session_timeout') {
                this.refreshShoppingCart();
              }
            }
            return EMPTY;
          }),
          finalize(() => this.loadingService.hide())
        )
        .subscribe();
    }
    this.promotionCode = '';
  }

  openCouponDialog() {
    const dialogRef = this.dialog.open(CouponDialogComponent);
    dialogRef.afterClosed().subscribe(success => {
      if (success) {
        this.refreshShoppingCart();
      }
    });
  }

  deleteCoupon(cartPromotion) {
    this.loadingService.show();

    const cartPromotionId = cartPromotion.id;
    const promotionId = cartPromotion.promotionId;
    this.shoppingCartService.deleteCoupon(cartPromotionId).pipe(
      switchMap(result => {
        if (result.success) {
          if (cartPromotion.expiryDatetime) {
            this.shoppingCartService.deletePendingPromotion(promotionId).toPromise()
              .then(() => this.refreshShoppingCart());
          } else {
            this.refreshShoppingCart();
          }
        } else {
          // const msgKey = this.errorMap[result.errorCode] || 'error';
          // this.showError(`${this.translate.instant('error')}: ${result.msg}`);//  ${this.translate.instant(msgKey)},
          this.showRespResultError(result, this.errorMap);
          if (result.errorCode === 'cart_session_timeout') {
            this.refreshShoppingCart();
          }
        }
        return EMPTY;
      }),
      finalize(() => this.loadingService.hide())
    ).subscribe();
  }

  deletePendingPromotion(promotionId) {
    this.loadingService.show();

    this.shoppingCartService.deletePendingPromotion(promotionId).pipe(
      switchMap(result => {
        if (result.success) {
          this.refreshShoppingCart();
        } else {
          // const msgKey = this.errorMap[result.errorCode] || 'error';
          // this.showError(`${this.translate.instant('error')}: ${result.msg}`);//  ${this.translate.instant(msgKey)},
          this.showRespResultError(result, this.errorMap);
          if (result.errorCode === 'cart_session_timeout') {
            this.refreshShoppingCart();
          }
        }
        return EMPTY;
      }),
      finalize(() => this.loadingService.hide())
    ).subscribe();
  }

  // updatePromotion(cartPromotionId: number, disable: boolean) {
  //   this.shoppingCartService.updatePromotion(cartPromotionId, disable).pipe(
  //     switchMap(result => {
  //       if (result.success) {
  //         this.refreshShoppingCart();
  //       } else {
  //         const msgKey = this.errorMap[result.errorCode] || 'error';
  //         this.showError(`${this.translate.instant('error')}: ${this.translate.instant(msgKey)}, ${result.msg}`);
  //       }
  //       return EMPTY;
  //     }),
  //     finalize(() => this.loadingService.hide())
  //   ).subscribe();
  // }

  // disablePromotion(cartPromotionId: number) {
  //   this.updatePromotion(cartPromotionId, true);
  // }

  // enablePromotion(cartPromotionId: number) {
  //   this.updatePromotion(cartPromotionId, false);
  // }



  changeGoodsGift(goodsGiftPromotion: any, goodsGift: any, option: any) {
    console.log('goodsGift', goodsGift);
    console.log('option', option);
    if (!option.disabled && !option.selected) {

      goodsGift.optionList.forEach(o => o.selected = false);
      option.selected = true;

      const cartPromotionId = goodsGiftPromotion.cartPromotionId;
      const cartGoodsId = goodsGift.cartGoodsId;
      // const cartProductId = goodsGift.cartProductId;
      const productVariantId = option.productVariantId;

      // goodsGift.selectedId = productVariantId;

      this.loadingService.show();
      this.shoppingCartService.updateGoodsGift(cartPromotionId, cartGoodsId, productVariantId)
        .pipe(
          switchMap(result => {
            if (result.success) {
              this.refreshShoppingCart();
            } else {
              // const msgKey = this.errorMap[result.errorCode] || 'error';
              // this.showError(`${this.translate.instant('error')}: ${result.msg || ''}`);//  ${this.translate.instant(msgKey)},
              this.showRespResultError(result, this.errorMap, { goodsName: option.name });
              if (result.errorCode === 'cart_session_timeout') {
                this.closeGiftSelect();
                this.refreshShoppingCart();
              }

              if (result.errorCode === 'not_enough_stock') {
                this.loadingService.show();
                this.refreshGoodsGiftPromotion().pipe(finalize(() => this.loadingService.hide())).subscribe(() => {
                  this.goodsGiftPromotion = this.findGoodsGiftPromotion(cartPromotionId);
                });
              }
            }
            return EMPTY;
          }),
          finalize(() => this.loadingService.hide())
        )
        .subscribe();
    }
  }


  changeProductVariantGift(productVariantGiftPromotion: any, option: any) {
    console.log('productVariantGiftPromotion', productVariantGiftPromotion);
    console.log('option', option);

    if (!option.disabled) {
      const choiceQty = productVariantGiftPromotion.choiceQty;
      const optionList = productVariantGiftPromotion.optionList;
      let selectedCount = optionList.filter(o => o.selected).length;
      console.log('choiceQty:', choiceQty);
      console.log('selectedCount:', selectedCount);


      if (choiceQty <= selectedCount) {
        if (!option.selected) {
          productVariantGiftPromotion.optionList.forEach(o => o.selected = false);
        }
      }

      option.selected = !option.selected;

      // option.selected = true;

      const cartPromotionId = productVariantGiftPromotion.cartPromotionId;

      const productVariantIdList = optionList.filter(o => o.selected).map(o => o.productVariantId);
      selectedCount = productVariantIdList.length;

      this.disableGiftSelectionClose = selectedCount === 0 && choiceQty > 0;
      productVariantGiftPromotion.selectedCount = selectedCount;

    }
  }

  range(start, end): number[] {
    return Array.from(new Array(end - start + 1), (x, i) => start + i);
  }

  rangeByGoods(cartGoods): number[] {
    let start = 1, end = 5;
    if (cartGoods.qtyLimitPerOrder) {
      start = cartGoods.minQtyPerOrder || 1;
      end = cartGoods.maxQtyPerOrder || 5;
    }
    return this.range(start, end);
  }

  toggleProductList() {
    this.showProductList = !this.showProductList;
  }

  // openGiftBottomSheet(type: string, cartPromotionId: number): void {


  //   const goodsGiftPromotion = this.findGoodsGiftPromotion(cartPromotionId);
  //   if (goodsGiftPromotion) {
  //     const bottomSheetRef = this.bottomSheet.open(ShoppingCartGiftBottomSheetComponent, {
  //       data: { type: type, goodsGiftPromotion: goodsGiftPromotion }
  //     });

  //     bottomSheetRef.afterDismissed().subscribe((goodsGiftListFromChild) => {
  //       // console.log(goodsGiftListFromChild);
  //       goodsGiftListFromChild.forEach(goodsGift => {
  //         // const index = goodsGift.selectedIndex;
  //         // this.changeGoodsGift(goodsGift, goodsGift.optionList[index]);
  //         goodsGift.optionList.forEach(option => {
  //           if (option.id === goodsGift.selectedId) {
  //             // this.changeGoodsGift(goodsGift, option);
  //           }
  //         });
  //       });
  //     });
  //   } else {
  //     this.router.navigateByUrl('checkout');
  //   }
  // }

  findGoodsGiftPromotion(cartPromotionId: number) {
    return this.goodsGiftPromotionList.find(goodsGiftPromotion => goodsGiftPromotion.cartPromotionId === cartPromotionId);
  }

  findProductVariantGiftPromotion(cartPromotionId: number) {
    return this.productVariantGiftPromotionList
      .find(productVariantGiftPromotion => productVariantGiftPromotion.cartPromotionId === cartPromotionId);
  }

  openGiftSelect(cartPromotionId: number) {

    this.goodsGiftPromotion = null;
    this.goodsGiftPromotion = this.findGoodsGiftPromotion(cartPromotionId);
    console.log('goodsGiftPromotion', this.goodsGiftPromotion);

    this.productVariantGiftPromotion = null;
    this.productVariantGiftPromotion = this.findProductVariantGiftPromotion(cartPromotionId);
    console.log('productVariantGiftPromotion', this.productVariantGiftPromotion);

    this.productVariantGiftPromotion.previousSelected = this.productVariantGiftPromotion.optionList
      .filter(o => o.selected)
      .map(o => o.productVariantId);

    this.giftSelectBottomSheetRef = this.bottomSheet.open(this.giftSelectTemplate, {
      panelClass: ['no-padding', 'bottom-sheet-xs-max-90-vh'],
      disableClose: true,
    });

    this.giftSelectBottomSheetRef.afterDismissed().subscribe(() => {
      this.goodsGiftPromotion = null;
      this.productVariantGiftPromotion = null;
    });
  }

  closeGiftSelect() {
    const cartPromotionId = this.productVariantGiftPromotion.cartPromotionId;
    const choiceQty = this.productVariantGiftPromotion.choiceQty;
    const optionList = this.productVariantGiftPromotion.optionList;
    const productVariantIdList = optionList.filter(o => o.selected).map(o => o.productVariantId);
    const selectedCount = productVariantIdList.length;
    const isOptionChanged = JSON.stringify(productVariantIdList) !== JSON.stringify(this.productVariantGiftPromotion.previousSelected);

    if (choiceQty === 0 || !isOptionChanged) {
      this.giftSelectBottomSheetRef.dismiss();
    } else if (selectedCount > 0 && selectedCount <= choiceQty) {
      this.loadingService.show();
      this.shoppingCartService.updateProductVariantGift(cartPromotionId, productVariantIdList)
        .pipe(
          switchMap(result => {
            if (result.success) {
              this.giftSelectBottomSheetRef.dismiss();
              this.refreshShoppingCart();
            } else {
              // const msgKey = this.errorMap[result.errorCode] || 'error';
              // this.showError(`${this.translate.instant('error')}: ${result.msg || ''}`);//  ${this.translate.instant(msgKey)},
              this.showRespResultError(result, this.errorMap);
              if (result.errorCode === 'cart_session_timeout') {
                this.giftSelectBottomSheetRef.dismiss();
                this.refreshShoppingCart();
              }

              if (result.errorCode === 'not_enough_stock') {
                this.loadingService.show();
                this.refreshGoodsGiftPromotion().pipe(finalize(() => this.loadingService.hide())).subscribe(() => {
                  this.goodsGiftPromotion = this.findGoodsGiftPromotion(cartPromotionId);
                });
              }
            }
            return EMPTY;
          }),
          finalize(() => this.loadingService.hide())
        )
        .subscribe();
    }
  }

  getPromotion(shoppingCart, cartGoodsId: number): any {
    let promotion = null;
    shoppingCart.cartPromotionList.forEach(cartPromotion => {
      cartPromotion.actionList.forEach(action => {
        const cartGoodsGiftList = action.data.cartGoodsGiftList;
        if (cartGoodsGiftList) {
          cartGoodsGiftList.forEach(cartGoodsGift => {
            // console.log('check', (cartGoodsGift.cartGoodsId == cartGoodsId));
            if (cartGoodsGift.cartGoodsId === cartGoodsId) {
              // console.log('cartPromotion', cartPromotion);
              promotion = cartPromotion;
            }
          });
        }
      });
    });
    return promotion;
  }

  getCartPromotionGoodsList(cartPromotionId) {
    const cartGoodsListWithPromotion = this.shoppingCart.subCartList
      .flatMap(subCart => subCart.cartGoodsList)
      .filter(cartGoods => cartGoods.cartPromotionList.length > 0);

    const matchedCartGoodsList = cartGoodsListWithPromotion.filter(cartGoods => {
      const cartGoodsPromotionList = cartGoods.cartPromotionList;
      return cartGoodsPromotionList.some(cartGoodsPromotion => cartGoodsPromotion.id === cartPromotionId);
    });

    // const matchedCartGoodsNameList = matchedCartGoodsList.map(cartGoods => {
    //   let goodsName = cartGoods.name;
    //   const productVariantName = cartGoods.cartProductList[0].name;
    //   if (productVariantName) {
    //     goodsName = goodsName + '-' + productVariantName;
    //   }

    //   return goodsName;
    // });

    return matchedCartGoodsList;
  }

  isBundlePromotion(cartPromotion) {
    return cartPromotion.actionList.some(action => 'bundle_price_goods_discount' === action.type ||
      'bundle_price_product_variant_discount' === action.type || 'bundle_goods_discount' === action.type);
  }

  isPurchasedGoods(cartGoods) {
    return !cartGoods.addOn && !cartGoods.gift && !cartGoods.bundle;
  }

  promotionExpiryCount(expiryDatetime) {
    return moment(expiryDatetime).diff(moment());
  }

  countDownFormat(countDownInMilliSecord) {
    return moment(countDownInMilliSecord).format('mm:ss');
  }

  viewShoppingCartMessage() {
    const bottomSheetRef = this.informationBottomSheetService.open(this.shoppingCartMessage.title, this.shoppingCartMessage.content);
  }

  isValidCart() {
    const cartGoodsList = this.shoppingCart.subCartList.flatMap(subCart => subCart.cartGoodsList);
    return !cartGoodsList.some(cartGoods => cartGoods.outOfStock || cartGoods.unavailable);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    const scrollOffsetTop = (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop);

    const topBarDom = this.topBar.nativeElement;
    // const topBarHeight = topBarDom.offsetHeight;
    // const topBarMinTop = 0 - topBarHeight;
    // const topBarMaxTop = 0;

    // const scrolledValue = scrollOffsetTop - this.currentScrollPoistion;

    // const cssStyle = topBarDom.style;
    // const transformStyle = cssStyle.transform || cssStyle.webkitTransform || cssStyle.mozTransform || '0';
    // const translateY = Number(transformStyle.replace(/[^\d\-.]/g, ''));

    // let topValue = translateY - scrolledValue;
    // if (topValue < topBarMinTop) {
    //   topValue = topBarMinTop;
    // } else if (topValue > topBarMaxTop) {
    //   topValue = topBarMaxTop;
    // }

    // if (scrollOffsetTop <= 0) {
    //   topValue = 0;
    // }

    // if (topValue !== translateY) {
    //   this.renderer.setStyle(topBarDom, 'transform', `translateY(${topValue}px)`);
    // }

    // this.currentScrollPoistion = scrollOffsetTop;
    const navBarDom = topBarDom.querySelector('app-navigation-bar');
    const navBarHeight = this.navBarHeight;
    const navBarMinHeight = 0;
    const navBarMaxHeight = navBarHeight;

    const scrolledValue = scrollOffsetTop - this.currentScrollPoistion;

    const cssStyle = navBarDom.style;
    const cssHeight = Number(cssStyle.height.replace(/[^\d\-.]/g, ''));

    let height = cssHeight - scrolledValue;
    if (scrolledValue > 0) {
      if (height < navBarMinHeight) {
        height = navBarMinHeight;
      } else if (height > navBarMaxHeight) {
        height = navBarMaxHeight;
      }
    }
    if (scrolledValue <= 0) {
      height = navBarMaxHeight;
    }

    this.renderer.setStyle(navBarDom, 'height', `${height}px`);

    this.currentScrollPoistion = scrollOffsetTop;
  }
}
