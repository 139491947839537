import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shop-address',
  templateUrl: './shop-address.component.html',
  styleUrls: ['./shop-address.component.css']
})
export class ShopAddressComponent implements OnInit {

  loading;

  infoPage: { name, content };
  
  constructor() { }

  ngOnInit(): void {
  }

}
