import { Component, OnInit, Input, ElementRef, AfterViewInit, Renderer2, Output, EventEmitter, OnDestroy, DoCheck } from '@angular/core';
import { BasicViewComponent } from '../basic-view-component';
import { AddOnPromotionService } from '../api/add-on-promotion.service';
import { CategoryShowcaseItemComponent } from '../category-showcase-item/category-showcase-item.component';
import { ShoppingCartService } from '../api/shopping-cart.service';
import { FullScreenLoadingService } from '../shared/service/full-screen-loading.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { deepMerge } from '../shared/util/util';
import { Observable, of, Subject } from 'rxjs';
import { GoodsService } from '../api/goods.service';
import { takeUntil, tap, filter } from 'rxjs/operators';
import { SwiperComponent } from 'swiper/angular';
import { AddCartSnackBarComponent } from 'src/app/add-cart-snack-bar/add-cart-snack-bar.component';

export interface AddOnPromotionMasterGoodsInfomation {
  goodsId: number;
}

@Component({
  selector: 'app-add-on-promotion',
  templateUrl: './add-on-promotion.component.html',
  styleUrls: ['./add-on-promotion.component.scss']
})
export class AddOnPromotionComponent extends BasicViewComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  set goodsQty(value) {
    if (this.addOnPromotion) {
      this.refreshItemQty();
    }
  }
  @Input() goods: any = {};
  @Input() type: string;
  @Input() masterOutOfStock: any = false;
  @Output() addedToCart: EventEmitter<void> = new EventEmitter<void>();

  errorMap = {
    'not_enough_stock': 'not.enough.stock',
    'add_on_unavailable': 'add.on.unavailable',
    'add_on_item_unavailable': 'add.on.item.unavailable',
    'out_of_quota': 'out.of.quota.with.goods'
  };

  addOnPromotion;
  // addOnPromotionItemList = [];
  selectedAddOnItemIdList = [];

  // @ViewChild(SwiperComponent) swiperComponent: SwiperComponent;

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(private addOnPromotionService: AddOnPromotionService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    snackBar: MatSnackBar,
    private shoppingCartService: ShoppingCartService,
    private loadingService: FullScreenLoadingService,
    private translate: TranslateService,
    private goodsService: GoodsService) {

    super(snackBar);
  }

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  isGoodsType() {
    return this.type === 'goods';
  }

  isCartType() {
    return this.type === 'cart';
  }

  getAddOnPromotionItemList() {
    return this.addOnPromotion.addOnPromotionItemList;
  }

  getTotalQtyWithoutCurrent(item) {
    return this.getAddOnPromotionItemList()
      .filter(promotionItem => promotionItem.selected)
      .filter(promotionItem => promotionItem.id !== item.id)
      .reduce((sum, current) => sum + current.qty, 0);
  }

  getQuotaLimit(item): number | null {
    // const hasQuotaLimit = this.addOnPromotion.quotaLimit;
    let quotaLimit = null;
    if (this.addOnPromotion.quotaLimitType === 'max_master_goods_qty') {
      // const extraQuotaInCart = this.goods.qtyInCart - this.addOnPromotion.usedQuotaInCart;
      quotaLimit = this.goods.qty - this.getTotalQtyWithoutCurrent(item);
    }
    if (this.addOnPromotion.quotaLimitType === 'max_quota') {
      quotaLimit = this.addOnPromotion.maxQuota - this.getTotalQtyWithoutCurrent(item);
    }
    return quotaLimit;
  }

  getMaxQty(item) {
    let maxQty = item.maxQty;
    const hasQuotaLimit = this.addOnPromotion.quotaLimit;
    if (hasQuotaLimit) {
      const quotaLimit = this.getQuotaLimit(item);
      maxQty = Math.min(quotaLimit, item.maxQty, item.goods.stock);
    }
    return maxQty;
  }

  toggleItem(item) {
    if (this.addOnPromotion.singleItem) {
      this.getAddOnPromotionItemList()
        .filter(promotionItem => promotionItem !== item)
        .forEach(promotionItem => {
          promotionItem.selected = false;
        });
    }
    item.selected = !item.selected;
    this.refreshItemQty();
  }

  minusQty(item) {
    if (this.canMinusQty(item)) {
      item.qty--;
    }
  }

  plusQty(item) {
    if (this.canPlusQty(item)) {
      item.qty++;
    }
  }

  canMinusQty(item) {
    return item.qty > 1;
  }

  canPlusQty(item) {
    const maxQty = this.getMaxQty(item);
    return item.qty < maxQty;
  }

  onItemQtyInputBlur(item) {
    if (item.qty === null || item.qty < 1) {
      item.qty = 1;
    }

    const maxQty = this.getMaxQty(item);
    if (item.qty > maxQty) {
      item.qty = maxQty;
    }
  }

  refreshItemQty() {
    const selectedItemList = this.getAddOnPromotionItemList()?.filter(item => item.selected) ?? [];
    selectedItemList.forEach(item => {
      const maxQty = this.getMaxQty(item);
      if (maxQty <= 0) {
        item.selected = false;
        return;
      }
      if (item.qty > maxQty) {
        item.qty = maxQty;
      }
    });

    const unSelectedItemList = this.getAddOnPromotionItemList()?.filter(item => !item.selected) ?? [];
    unSelectedItemList.forEach(item => {
      const maxQty = this.getMaxQty(item);
      if (item.qty > maxQty) {
        item.qty = 1;
      }
    });
  }

  range(start, end): number[] {
    return Array.from(new Array(end - start + 1), (x, i) => start + i);
  }

  rangeByItem(item): number[] {
    const maxQty = this.getMaxQty(item);
    const start = maxQty > 0 ? 1 : 0;
    const end = maxQty;
    return this.range(start, end);
  }

  canBuy(item) {
    return !item.goods.outOfStock && !item.outOfQuota && this.getMaxQty(item) > 0;
  }

  loadAddOnPromotion(): Observable<any> {
    let addOnPromotion$: Observable<any[]>;
    if (this.type === 'goods' || this.goods.id) {
      addOnPromotion$ = this.addOnPromotionService.getGoodsAddOnPromotion(this.goods.id);
    }
    if (this.type === 'cart') {
      addOnPromotion$ = this.addOnPromotionService.getCartAddOnPromotion();
    }
    return addOnPromotion$
      .pipe(
        tap(addOnPromotion => {
          // this.addOnPromotion = addOnPromotion;
          if (addOnPromotion.addOnPromotionItemList) {
            addOnPromotion.addOnPromotionItemList.forEach((item, itemIndex) => {
              let initQty = 1;
              if (initQty > item.maxQty) {
                initQty = item.maxQty;
              }
              item.qty = initQty;
              item.selected = false;

              // image
              const imageList = item.goods.imageList;
              // Select fist option of attribute in product by default
              item.goods.productList.forEach(product => {
                product.attributeList.forEach(attribute => {
                  attribute.optionList[0].selected = true;
                });
                const productVariantList = product.productVariantList;
                productVariantList.forEach(productVariant => {
                  let pvImageList = productVariant.imageList;
                  pvImageList = pvImageList.filter(pvImg => imageList.every(img => img.url !== pvImg.url));
                  imageList.push(...pvImageList);
                });
                this.onAttributeChanged(item, product);
              });
            });
          }

          return addOnPromotion;
        })
      );

  }

  refresh() {
    this.loadAddOnPromotion().subscribe(addOnPromotion => {
      this.addOnPromotion = deepMerge(this.addOnPromotion || {}, addOnPromotion);
    });
  }

  init() {
    this.shoppingCartService.cart().pipe(
      takeUntil(this._onDestroy)
    ).subscribe(_ => this.refresh());
  }

  addGoodsAddOn() {
    this.loadingService.show();

    const goodsList: Array<any> = [];
    const goodsProductList = this.goods.productList.map(product => {
      const productVariant = this.findMatchedProductVariant(product);
      return { productId: product.id, productVariantId: productVariant.id };
    });
    const goodsId = this.goods.id;
    const qty = this.goods.qty;
    goodsList.push({
      goodsId: goodsId,
      qty: qty,
      addOn: false,
      productList: goodsProductList,

      goodsAddOn: {
        itemId: null
      }
    });

    const selectedItemList = this.getAddOnPromotionItemList().filter(item => item.selected);
    selectedItemList.forEach(item => {
      const itemId = item.id;
      const itemGoodsId = item.goods.id;
      const itemQty = item.qty;

      const itemGoodsProductList = item.goods.productList.map(product => {
        const productVariant = this.findMatchedProductVariant(product);
        return { productId: product.id, productVariantId: productVariant.id };
      });

      goodsList.push({
        goodsId: itemGoodsId,
        qty: itemQty,
        addOn: true,
        productList: itemGoodsProductList,

        goodsAddOn: {
          itemId: itemId
        }
      });
    });

    this.shoppingCartService.addGoodsWithAddOnItem(goodsList)
      .subscribe({
        next: result => {
          if (result.success) {
            this.showMessage(this.goods.name + ' ' + this.translate.instant('and.add.on.item') + ' '
              + this.translate.instant('added.to.the'), AddCartSnackBarComponent);
            this.shoppingCartService.refreshCount();
            this.shoppingCartService.refresh();
            this.refresh();
            // this.addedToCart.emit();
          } else {
            // const msgKey = this.errorMap[result.errorCode] || 'error';
            // const message = this.translate.instant(msgKey, { goodsName: result.data?.goods });
            // this.showError(`${this.translate.instant('warning')}: ${message}`);
            this.showRespResultError(result, this.errorMap, { goodsName: result.data?.goods });
          }
        },
        complete: () => this.loadingService.hide()
      });
  }

  addCartAddOn(item) {
    this.loadingService.show();

    const itemId = item.id;
    const itemGoodsId = item.goods.id;
    const itemQty = item.qty;

    const itemGoodsProductList = item.goods.productList.map(product => {
      const productVariant = this.findMatchedProductVariant(product);
      return { productId: product.id, productVariantId: productVariant.id };
    });

    const goodsAddOn = {
      itemId: itemId
    };

    this.shoppingCartService.addCartAddOnItem(itemGoodsId, itemQty, true, itemGoodsProductList, goodsAddOn)
      .subscribe({
        next: result => {
          if (result.success) {
            this.showMessage(item.goods.name + ' ' + this.translate.instant('added.to.the'), AddCartSnackBarComponent);
            this.shoppingCartService.refreshCount();
            this.shoppingCartService.refresh();
            this.refresh();
            this.addedToCart.emit();
          } else {
            // const msgKey = this.errorMap[result.errorCode] || 'error';
            // const message = this.translate.instant(msgKey, { goodsName: item.goods.name });
            // this.showError(`${this.translate.instant('warning')}: ${message}`);
            this.showRespResultError(result, this.errorMap, { goodsName: item.goods.name });
          }
        },
        complete: () => this.loadingService.hide()
      });
  }

  hasSelectedItem() {
    return this.getAddOnPromotionItemList().filter(item => item.selected).length >= 1;
  }

  findSelectedAttributeOption(attribute) {
    return attribute.optionList.find(o => o.selected === true);
  }
  changeAttributeOption(addOnPromotionitem, product, attribute, option) {
    attribute.optionList.forEach(o => o.selected = false);
    option.selected = true;
    this.onAttributeChanged(addOnPromotionitem, product);
  }
  onAttributeChanged(item, product) {
    const matchedProductVariant = this.findMatchedProductVariant(product);
    console.log('matchedProductVariant', matchedProductVariant);
    const goods = item.goods;
    if (matchedProductVariant) {
      goods.price = matchedProductVariant.price;
      goods.listPrice = matchedProductVariant.listPrice;
      const outOfStock = matchedProductVariant.outOfStock;
      goods.outOfStock = outOfStock;
      goods.stock = matchedProductVariant.stock;

      if (outOfStock) {
        item.selected = false;
      }

      console.log('matchedProductVariant.imageList', matchedProductVariant.imageList);

      if (matchedProductVariant.imageList && matchedProductVariant.imageList.length > 0) {
        const imageUrl = matchedProductVariant.imageList[0].url;
        console.log('imageUrl', imageUrl);

        const matchedImageIndex = this.findMatchedImageIndex(item, imageUrl);
        if (matchedImageIndex > -1) {
          goods.imageIndex = matchedImageIndex;
        }
      }
    } else {
      goods.price = 0;
      goods.listPrice = 0;
      goods.outOfStock = true;
    }
  }
  generateAttributeMatchKey(optionIdList: string[]) {
    console.log('optionIdList', optionIdList);

    return optionIdList.sort().join('-');
  }
  findMatchedProductVariant(product) {
    const optionIdList = product.attributeList
      .flatMap(attribute => attribute.optionList.filter(option => option.selected))
      .map(option => option.id);
    const optionIdKey = this.generateAttributeMatchKey(optionIdList);
    console.log('optionIdKey', optionIdKey);

    const matchedProductVariant = product.productVariantList.find(productVariant =>
      this.generateAttributeMatchKey(productVariant.attributeOptionIdList) === optionIdKey);
    return matchedProductVariant;
  }

  findMatchedImageIndex(addOnPromotionItem, imageUrl) {
    return addOnPromotionItem.goods.imageList.findIndex(image => image.url === imageUrl);
  }

  trackById(index, item) {
    return item.id;
  }
}
