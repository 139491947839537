import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-showcase-item',
  templateUrl: './blog-showcase-item.component.html',
  styleUrls: ['./blog-showcase-item.component.scss']
})
export class BlogShowcaseItemComponent implements OnInit {

  @Input() blog: { id: number, icon: { url }, name, slug: string };

  constructor() { }

  ngOnInit(): void {
  }

}
