import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InfoPageService } from '../api/info-page.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-about-refund',
  templateUrl: './about-refund.component.html',
  styleUrls: ['./about-refund.component.scss']
})
export class AboutRefundComponent implements OnInit {
  constructor(private router: Router) { }

  ngOnInit() {
  }

}
