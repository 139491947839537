import { APP_BASE_HREF, LocationStrategy } from '@angular/common';
import { Directive, ElementRef, HostBinding, HostListener, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Project } from 'src/app/app-config';

@Directive({
  selector: 'a[appHyperlink]'
})
export class HyperlinkDirective implements OnInit {

  // tslint:disable-next-line:no-input-rename
  @Input('appHyperlink') hyperlink: string;

  routePath = null;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private locationStrategy: LocationStrategy,
    private router: Router,
  ) { }

  ngOnInit() {
    let hyperlink = this.hyperlink;

    if (Project.url && hyperlink?.startsWith(Project.url)) {
      hyperlink = hyperlink?.replace(Project.url, '');

      this.routePath = hyperlink;

      const baseHref = this.locationStrategy.getBaseHref();

      if (baseHref?.endsWith('/') && hyperlink?.startsWith('/')) {
        hyperlink = baseHref + hyperlink.replace('/', '');
      } else if (!baseHref?.endsWith('/') && !hyperlink?.startsWith('/')) {
        hyperlink = baseHref + '/' + hyperlink;
      } else {
        hyperlink = baseHref + hyperlink;
      }
      this.renderer.setAttribute(this.elementRef.nativeElement, 'href', hyperlink);

    } else {

      this.renderer.setAttribute(this.elementRef.nativeElement, 'href', hyperlink);
      this.renderer.setAttribute(this.elementRef.nativeElement, 'target', '_blank');
      this.routePath = null;
    }


  }

  @HostListener('click', ['$event']) onClick(event) {
    if (this.routePath != null) {
      event.preventDefault();
      this.router.navigateByUrl(this.routePath);
    }
  }
}
