import { Component, OnInit } from '@angular/core';
import { BlogArticleService } from '../api/blog-article.service';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { Observable } from 'rxjs';
import { BlogHomeService } from '../api/blog-home.service';
import { finalize } from 'rxjs/operators';
import { CategoryService } from '../api/category.service';
import { SearchService } from '../api/search.service';
import { Router } from '@angular/router';
import { BlogArticleCategoryService } from '../api/blog-article-category.service';
import * as moment from 'moment';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  // blogList$: Observable<any[]>;
  // pageNo = 1;
  // p: number;
  // itemsNo: number;
  // categoryNameList$: Observable<any[]>;
  page = 1;
  limit = 5;
  loading = false;
  noMore = false;

  showCategory;
  showPopularPosts;
  name;
  content;
  popularPostsList = [];
  blogArticleList = [];
  blogArticleCategoryList = [];

  searchText: '';
  keyword = '';

  postDateFormat;

  constructor(private blogArticleService: BlogArticleService,
    private blogHomeService: BlogHomeService,
    private blogArticleCategoryService: BlogArticleCategoryService,
    public translate: TranslateService,
    private router: Router, ) {
    }

  ngOnInit() {
    this.blogHomeService.getDetails().subscribe(data => {
      this.showCategory = data.showCategory;
      this.showPopularPosts = data.showPopularPosts;
      this.name = data.name;
      this.content = data.content;
      this.popularPostsList = data.popularPostsList;
    });

    this.blogArticleCategoryService.query().subscribe(data => {
      this.blogArticleCategoryList = data;
    });

    this.queryBlogArticleList();
  }

  loadMore() {
    this.page++;
    this.queryBlogArticleList();
  }

  queryBlogArticleList() {
    this.loading = true;
    this.blogArticleService.queryBlogArticleList(this.page, this.limit)
      .pipe(finalize(() => this.loading = false))
      .subscribe(data => {
        if (data && data.length) {
          this.blogArticleList.push(...data);
        }

        if (!data || data.length < this.limit) {
          this.noMore = true;
        }
      });
  }

  search() {
    if (!!this.keyword) {
      console.log('click search, keyword:', this.keyword);
      this.router.navigate(['search/blog'], { queryParams: { keyword: this.keyword } });
      this.keyword = '';
    }
  }

  formatPostDate(postDate) {
    const postDateFormat = this.translate.instant('blog.article.post.date.format');
    return moment(postDate).format(postDateFormat);
  }

}
