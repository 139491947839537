import { Component, OnInit, Input } from '@angular/core';
import { GoodsShowcaseItem } from '../goods-showcase-item/goods-showcase-item.component';

@Component({
  selector: 'app-goods-showcase',
  templateUrl: './goods-showcase.component.html',
  styleUrls: ['./goods-showcase.component.scss']
})
export class GoodsShowcaseComponent implements OnInit {

  @Input() goodsList: [GoodsShowcaseItem];
  constructor() { }

  ngOnInit() {
  }

  trackBy(index, item) {
    return item.id;
  }
}
