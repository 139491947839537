import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shopping-cart-login',
  templateUrl: './shopping-cart-login.component.html',
  styleUrls: ['./shopping-cart-login.component.scss']
})
export class ShoppingCartLoginComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  continueShopping() {
    
  }

}
