import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { FullScreenLoadingService } from '../shared/service/full-screen-loading.service';
import { PaymentService } from '../api/payment.service';
import { BasicViewComponent } from '../basic-view-component';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent extends BasicViewComponent implements OnInit {

  errorMap = {};

  paymentMethodList = [];

  @ViewChild('paymentForm') paymentForm: ElementRef<HTMLFormElement>;

  constructor(private router: Router,
    private route: ActivatedRoute,
    // private fb: FormBuilder,
    protected snackBar: MatSnackBar,
    private translate: TranslateService,
    private loadingService: FullScreenLoadingService,
    private paymentService: PaymentService) {
    super(snackBar);
  }

  ngOnInit() {
    this.paymentService.queryPaymentMethod().subscribe(data => {
      this.paymentMethodList = data;
    });
  }

  createPayment(paymentMethodId) {

    this.loadingService.show();
    const api = this.paymentService.createPayment(paymentMethodId);

    api.pipe(finalize(() => this.loadingService.hide()))
      .subscribe(result => {
        if (result.success) {
          const paymentData = result.data;
          // this.pay(paymentData);
        } else {
          // const msgKey = this.errorMap[result.errorCode] || 'error';
          // this.showError(`${this.translate.instant('error')}: ${result.msg || ''}`);//  ${this.translate.instant(msgKey)},
          this.showRespResultError(result, this.errorMap);
        }
      });
  }

  pay(paymentData) {
    const form = this.paymentForm.nativeElement;
    form.method = 'POST';
    form.action = paymentData.paymentUrl;
    form.target = '_self';

    let entries = Object.entries(paymentData);
    entries = entries.filter(([key, value]) => key !== 'paymentUrl');
    for (const [key, value] of entries) {
      const elem = document.createElement('input');
      elem.type = 'hidden';
      elem.name = key;
      elem.value = String(value);
      form.appendChild(elem);
    }
    form.submit();
  }
}
