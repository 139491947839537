import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericTableApiService } from './generic-table-api.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BlogArticleService extends GenericTableApiService {

    constructor(httpClient: HttpClient) {
        super(httpClient, 'blog-article');
    }
    getCategoryName(): Observable<any[]> {
        return this.httpClient.get<any[]>(`${this.endpoint}/blog-category-name`);
    }

    queryBlogArticleList(page, limit): Observable<any> {
        return this.httpClient.get(`${this.endpoint}`, { params: { 'page': page, 'limit': limit } });
    }
}
