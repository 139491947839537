import { Inject, Injectable, Renderer2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CurrencyService } from 'src/app/api/currency.service';
import { SystemSettingService } from 'src/app/api/system-setting.service';
import { LocalSettingService } from './local-setting.service';
import { Language as UiLanguage } from '../enum/language.enum';
import { LanguageService } from 'src/app/api/language.service';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { datePickerLocale } from '../import/shared-angular-material-datepicker-import.module';
import { DEFAULT_RENDERER } from '../import/shared-common-import.module';

@Injectable({
  providedIn: 'root'
})
export class LanguageSettingService {

  enabledLangList: string[];
  default: string;

  private _current$ = new BehaviorSubject<string>(null);
  get current$(): BehaviorSubject<string> {
    return this._current$;
  }

  constructor(
    private languageService: LanguageService,
    private localSettingService: LocalSettingService,
    @Inject(DEFAULT_RENDERER) private renderer: Renderer2,
    public dateAdapter: DateAdapter<any>,
    public translate: TranslateService,
  ) { }

  async init() {
    return this.initLang();
  }

  getEnabledList(): string[] {
    return this.enabledLangList;
  }

  getDefault(): string {
    return this.default;
  }

  // getCurrent(): Language {
  //   const currentLangCode = this.localSettingService.getLang();
  //   const currentLangConfig = this.enabledLangConfigList.find(enabledLangConfig => enabledLangConfig.code == currentLangCode);
  //   if (currentLangConfig == null || currentLangConfig == undefined) {
  //     return this.default;
  //   }
  //   return currentLangConfig;
  // }

  async initLang() {

    console.log('initLang');

    // load previous setting
    let lang = this.localSettingService.getLang();
    // console.log("lang:"+lang);

    const enabledLangConfigList: LanguageConfig[] = await this.languageService.queryEnabled().toPromise();

    const enbabledLangConfigCodeList = enabledLangConfigList.map(enabledLangConfig => enabledLangConfig.code.toLowerCase().replace('-', ''));

    const uiLangCodeList: string[] = Object.values(UiLanguage);
    const enabledLangList = uiLangCodeList.filter(uiLangCode => enbabledLangConfigCodeList.includes(uiLangCode.toLowerCase().replace('-', '')));

    const defaultEnabledLangConfig = enabledLangConfigList.find(enbaledLangConfig => enbaledLangConfig.default);

    const defaultLang = uiLangCodeList.find(uiLangCode => {
      return uiLangCode.toLowerCase().replace('-', '') === defaultEnabledLangConfig.code.toLowerCase().replace('-', '');
    });

    this.enabledLangList = enabledLangList;
    this.default = defaultLang ?? UiLanguage.EnUS;

    if (!enabledLangList.includes(lang)) {
      lang = null;
    }

    // get lang query parameter from url
    const url = new URL(window.location.href);
    if (url.searchParams.has('lang')) {
      const newLang = url.searchParams.get('lang');
      enabledLangList.some((optLang) => {
        const valid = (optLang.toLowerCase() === newLang?.toLowerCase() || optLang.toLowerCase().replace('-', '') === newLang?.toLowerCase());
        if (valid) {
          lang = optLang;
          // remove lang from url
          url.searchParams.delete('lang');
          // const success = this.router.navigateByUrl(url.toString(), { replaceUrl: true, skipLocationChange: true });
          // success.then((value) => console.log('navigateByUrl', value));
          history.replaceState({}, '', url.toString());
        }
        return valid;
      });
    }

    if (!lang) {
      // set default language same as browser's preference language
      let userAcceptLangList = navigator.languages || [];
      if (navigator.language && !userAcceptLangList.length) {
        userAcceptLangList = [navigator.language];
      }
      for (let i = 0; i < userAcceptLangList.length; i++) {
        const userAcceptLang = userAcceptLangList[i];
        if (userAcceptLang === UiLanguage.ZhCN && enabledLangList.includes(UiLanguage.ZhCN)) {
          lang = UiLanguage.ZhCN;
          break;
        } else if (userAcceptLang.startsWith('zh') && enabledLangList.includes(UiLanguage.ZhHK)) {
          lang = UiLanguage.ZhHK;
          break;
        } else if (userAcceptLang.startsWith('en') && enabledLangList.includes(UiLanguage.EnUS)) {
          lang = UiLanguage.EnUS;
          break;
        } else if (userAcceptLang.startsWith('jp') && enabledLangList.includes(UiLanguage.JaJP)) {
          lang = UiLanguage.JaJP;
          break;
        } else if (userAcceptLang.startsWith('ko') && enabledLangList.includes(UiLanguage.KoKR)) {
          lang = UiLanguage.KoKR;
          break;
        }
      }
    }

    if (!lang) {
      lang = defaultLang;
    }

    this.translate.setDefaultLang(lang);

    this.changeLang(lang);

  }

  changeLang(lang: string) {
    console.log('Set language to:' + lang);
    if (lang) {
      this.localSettingService.setLang(lang);
      this._current$.next(lang);
      this.translate.use(lang);
      this.dateAdapter.setLocale(lang);
      datePickerLocale.next(lang);



      this.renderer.removeClass(document.body, 'app-lang-en-US');
      this.renderer.removeClass(document.body, 'app-lang-zh-HK');
      this.renderer.removeClass(document.body, 'app-lang-zh-CN');
      this.renderer.removeClass(document.body, 'app-lang-ja-JP');
      this.renderer.removeClass(document.body, 'app-lang-ko-KR');

      this.renderer.addClass(document.body, `app-lang-${lang}`);

      this.renderer.setAttribute(document.documentElement, 'lang', lang);
    }
  }

}

export interface LanguageConfig {
  id: number;
  name: string;
  code: string;
  status: string;
  default: boolean;
}
