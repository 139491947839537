import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericTableApiService } from './generic-table-api.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class InfoPageService extends GenericTableApiService {

    constructor(httpClient: HttpClient) {
        super(httpClient, 'info-page');
    }

    get(id: number): Observable<any> {
        return this.httpClient.get(`info-page/${id}`);
    }
}
