import { Injectable } from '@angular/core';
import { LoginService } from './api/login.service';
import { VisitLogService } from './api/visit-log.service';
import { InstantMessageService } from './shared/service/instant-message.service';
import { PageMetaService } from './shared/service/page-meta.service';
import { GoogleTagManagerService } from './shared/service/google-tag-manager.service';
import { FacebookPixelService } from './shared/service/facebook-pixel.service';
import { FacebookLoginService } from './shared/service/facebook-login.service';
import { FaviconImageService } from './shared/service/favicon-image.service';
import { CurrencySettingService } from './shared/service/currency-setting.service';
import { LanguageSettingService } from './shared/service/language-setting.service';
import { GoogleLoginService } from './shared/service/google-login.service';

@Injectable()
export class AppInit {

    constructor(
        private loginService: LoginService,
        private visitLogService: VisitLogService,
        private pageMetaService: PageMetaService,
        private instantMessageService: InstantMessageService,
        private googleTagManagerService: GoogleTagManagerService,
        private facebookPixelService: FacebookPixelService,
        private facebookLoginService: FacebookLoginService,
        private faviconImageService: FaviconImageService,
        private currencySettingService: CurrencySettingService,
        private languageSettingService: LanguageSettingService,
        private googleLoginService: GoogleLoginService,
    ) {

    }

    async init(): Promise<void> {

        console.log('AppInit.init() called');
        //// do your initialisation stuff here
        // setTimeout(() => {
        //     console.log('AppInit Finished');
        //     resolve();
        // }, 0);

        // --IMPORTANT should call loginService getStatus first, because TokenInterceptor wait for login status api response 
        this.loginService.getStatus().subscribe(result => {
            console.log('result:', result);
            // if (result.isLoggedIn) {
            //     resolve();
            // }
        });

        await this.languageSettingService.init();

        this.instantMessageService.init();

        this.googleTagManagerService.init();

        this.facebookPixelService.init();

        this.facebookLoginService.init();

        this.googleLoginService.init();

        this.faviconImageService.init();

        await this.currencySettingService.init();

        console.log('AppInit Finished');

        // Check Cookie support
        const supportCookie = this.checkCookieSupport();
        if (!supportCookie) {
            alert('This site requires the use of Cookies.\nTo access our website you must enable Cookies in your browser.\n\n本網站需要使用Cookies\n要訪問我們的網站，您必須在瀏覽器中啟用Cookies。');
        }

        // Visit log
        try {
            this.visitLogService.log('browse', '');
        } catch (error) {
            console.error(error);
        }

        return;
    }

    checkCookieSupport(): boolean {
        let cookieEnabled = navigator.cookieEnabled;
        if (!cookieEnabled) {
            document.cookie = 'testcookie';
            cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
        }
        return cookieEnabled;
    }
}

