import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, Optional, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-character-counter',
  templateUrl: './character-counter.component.html',
  styleUrls: ['./character-counter.component.scss']
})
export class CharacterCounterComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @Input() control: AbstractControl;
  @Input() maxLength: any;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private cdr: ChangeDetectorRef
  ) {
    if (ngControl != null) {
      this.ngControl.valueAccessor = this;
      this.control = ngControl.control;
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    if (!this.control) {
      this.control = this.ngControl?.control;
      this.cdr.detectChanges();
    }
  }

  writeValue(obj: any): void {

  }
  registerOnChange(fn: any): void {

  }
  registerOnTouched(fn: any): void {

  }
  setDisabledState?(isDisabled: boolean): void {

  }
}
