import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, OutletContext, RouteReuseStrategy, DetachedRouteHandle } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AppRouteReuseStrategy implements RouteReuseStrategy {
    private handlers: { [key: string]: DetachedRouteHandle } = {};

    allowRetriveCache = {
        'category/:id': { 'goods/:id': true },
        'category/:idWithSlug': { 'goods/:idWithSlug': true }
    };

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        const path = this.getPath(route);
        return this.allowRetriveCache.hasOwnProperty(path);
    }
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        this.handlers[this.getPathWithParams(route)] = handle;
    }
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return !!this.getPathWithParams(route) && !!this.handlers[this.getPathWithParams(route)];
    }
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        if (!this.getPathWithParams(route)) {
            return null;
        }
        return this.handlers[this.getPathWithParams(route)];
    }
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        console.log('future', future);
        console.log('curr', curr);

        return future.routeConfig === curr.routeConfig;
    }

    private getPathWithParams(route: ActivatedRouteSnapshot) {
        // route.pathFromRoot.forEach(segment => {
        //     console.log(segment.url[0]);
        //     console.log(segment.toString());

        // });

        let url = route.pathFromRoot.map(segment => segment.url).reduce((accumulator, currentValue) => {
            return accumulator.concat(currentValue);
        }).join('/');

        if (route.queryParams && route.queryParams.length > 0) {
            url += '?' + route.queryParams.join('&');
        }

        return url;
    }

    private getPath(route: ActivatedRouteSnapshot): string {
        if (route.routeConfig !== null && route.routeConfig.path !== null) {
            return route.routeConfig.path;
        }
        return '';
    }
}
