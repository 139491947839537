import { LoginService } from '../api/login.service';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, CanLoad, Route } from '@angular/router';
import { Injectable } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { Observable, of, EMPTY, empty } from 'rxjs';
import { SystemSettingService } from '../api/system-setting.service';

@Injectable()
export class ShoppingCartLoginGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(private router: Router,
        private loginService: LoginService,
        private systemSettingService: SystemSettingService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.checkLogin();
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }

    canLoad(route: Route): Observable<boolean> {
        return this.checkLogin();
    }

    checkLogin(): Observable<boolean> {
        console.log('ShoppingCartLoginGuard checkLogin', this.router.url);

        return this.systemSettingService.getShoppingCartAndCheckoutSetting()
        .pipe(
            switchMap(setting => {
                if (setting.checkoutMemberRequired) {
                    return this.loginService.getStatus();
                }
                return of({isLoggedIn: true});
            }),
            map((loginData) => {
                if (loginData && !loginData.isLoggedIn) {
                    this.router.navigateByUrl('shopping-cart-login', { replaceUrl: true });
                    return false;
                }
                return true;
            })
        );
    }

}
