import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Currency, CurrencySettingService } from '../service/currency-setting.service';

@Pipe({
  name: 'currency'
})
export class CurrencyProxyPipe implements PipeTransform {

  defaultCurrency: Currency;
  constructor(private currencySettingService: CurrencySettingService) {
    this.defaultCurrency = this.currencySettingService.getDefault();
  }

  currencyPipe = new CurrencyPipe('en');
  transform(value,
    code = this.defaultCurrency.symbolLeft,
    display = 'symbol',
    digites = this.defaultCurrency.digits,
    local = '') {
    return this.currencyPipe.transform(value, code, display, digites, local);
  }

}
