import { HttpClient } from '@angular/common/http';
import { GenericTableApiService } from './generic-table-api.service';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';
import { shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SystemSettingService extends ApiService {

    private endpoint = 'system-setting';

    storeBasicInfo$: Observable<any>;
    goodsSetting$: Observable<any>;
    shoppingCartAndCheckoutSetting$: Observable<any>;
    memberSetting$: Observable<any>;
    restockNotificationSetting$: Observable<any>;
    rewardAndGiftRedemptionSetting$: Observable<any>;
    paymentMethodSetting$: Observable<any>;
    pointsSetting$: Observable<any>;
    imageSetting$: Observable<any>;
    telephoneCountryCode$: Observable<any[]>;
    currencySetting$: Observable<any>;

    constructor(private httpClient: HttpClient) {
        super();
        this.initStoreBasicInfo();
        this.initGoodsSetting();
        this.initShoppingCartAndCheckoutSetting();
        this.initMemberSetting();
        this.initRestockNotificationSetting();
        this.initRewardAndGiftRedemptionSetting();
        this.initPaymentMethodSetting();
        this.initPointsSetting();
        this.initImageSetting();
        this.initTelephoneCountryCode();
        this.initCurrencySetting();
    }

    getMeta(): Observable<any> {
        return this.httpClient.get<any>(`${this.endpoint}/meta`);
    }

    getInstantMessage(): Observable<any> {
        return this.httpClient.get<any>(`${this.endpoint}/instant-message`);
    }

    getSingleSignOn(): Observable<any> {
        return this.httpClient.get<any>(`${this.endpoint}/single-sign-on`);
    }

    getSocialNetwork(): Observable<any> {
        return this.httpClient.get<any>(`${this.endpoint}/social-network`);
    }

    initStoreBasicInfo() {
        this.storeBasicInfo$ = this.httpClient.get<any>(`${this.endpoint}/store-basic-info`)
            .pipe(shareReplay(1));
    }

    getStoreBasicInfo(): Observable<any> {
        return this.storeBasicInfo$;
    }

    getWebsiteTracking(): Observable<any> {
        return this.httpClient.get<any>(`${this.endpoint}/website-tracking`);
    }

    initGoodsSetting() {
        this.goodsSetting$ = this.httpClient.get<any>(`${this.endpoint}/goods`).pipe(
            shareReplay(1),
        );
    }

    getGoodsSetting(): Observable<any> {
        return this.goodsSetting$;
    }

    initShoppingCartAndCheckoutSetting() {
        this.shoppingCartAndCheckoutSetting$ = this.httpClient.get<any>(`${this.endpoint}/shopping-cart-and-checkout`)
            .pipe(shareReplay(1));
    }

    getShoppingCartAndCheckoutSetting(): Observable<any> {
        return this.shoppingCartAndCheckoutSetting$;
    }

    initMemberSetting() {
        this.memberSetting$ = this.httpClient.get<any>(`${this.endpoint}/member`).pipe(shareReplay(1));
    }

    getMemberSetting(): Observable<any> {
        return this.memberSetting$;
    }

    initRestockNotificationSetting() {
        this.restockNotificationSetting$ = this.httpClient.get<any>(`${this.endpoint}/restock-notification`).pipe(shareReplay(1));
    }

    getRestockNotificationSetting(): Observable<any> {
        return this.restockNotificationSetting$;
    }

    initRewardAndGiftRedemptionSetting() {
        this.rewardAndGiftRedemptionSetting$ = this.httpClient.get<any>(`${this.endpoint}/rewards-and-gift`).pipe(shareReplay(1));
    }

    getRewardAndGiftRedemptionSetting(): Observable<any> {
        return this.rewardAndGiftRedemptionSetting$;
    }

    initPaymentMethodSetting() {
        this.paymentMethodSetting$ = this.httpClient.get<any>(`${this.endpoint}/payment-method`).pipe(shareReplay(1));
    }

    getPaymentMethodSetting(): Observable<any> {
        return this.paymentMethodSetting$;
    }

    initPointsSetting() {
        this.pointsSetting$ = this.httpClient.get<any>(`${this.endpoint}/points`).pipe(shareReplay(1));
    }

    getPointsSetting(): Observable<any> {
        return this.pointsSetting$;
    }

    initImageSetting() {
        this.imageSetting$ = this.httpClient.get<any>(`${this.endpoint}/image`).pipe(shareReplay(1));
    }

    getImageSetting(): Observable<any> {
        return this.imageSetting$;
    }

    initTelephoneCountryCode() {
        this.telephoneCountryCode$ = this.httpClient.get<any>(`${this.endpoint}/telephone-country-code`).pipe(shareReplay(1));
    }

    getTelephoneCountryCode(): Observable<any[]> {
        return this.telephoneCountryCode$;
    }

    initCurrencySetting() {
        this.currencySetting$ = this.httpClient.get<any>(`${this.endpoint}/currency`).pipe(shareReplay(1));
    }

    getCurrencySetting(): Observable<any> {
        return this.currencySetting$;
    }

}
