import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-product-showcase-item',
  templateUrl: './product-showcase-item.component.html',
  styleUrls: ['./product-showcase-item.component.scss']
})
export class ProductShowcaseItemComponent implements OnInit {

  @Input() product: { imageList: [{ url }], name, description, rating, price };

  constructor() { }

  ngOnInit() {
  }

}
