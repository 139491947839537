import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-brand-showcase-item',
  templateUrl: './brand-showcase-item.component.html',
  styleUrls: ['./brand-showcase-item.component.scss']
})
export class BrandShowcaseItemComponent implements OnInit {

  @Input() brand: { id: number, icon: { url }, name, slug: string };

  constructor() { }

  ngOnInit(): void {
  }

}
