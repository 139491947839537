import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { GoodsService } from '../api/goods.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CategoryService } from '../api/category.service';
import { switchMap, tap, finalize, skip, filter, takeUntil, delay } from 'rxjs/operators';
import { WarehouseService } from '../api/warehouse.service';
import { ShoppingCartService } from '../api/shopping-cart.service';
import { BasicViewComponent } from '../basic-view-component';
import { TranslateService } from '@ngx-translate/core';
import { FullScreenLoadingService } from '../shared/service/full-screen-loading.service';
import { Observable, Subject } from 'rxjs';
import { combinationsOf2dArray, deepMerge } from '../shared/util/util';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwiperOptions } from 'swiper';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { GoodsReviewDialogComponent } from '../goods-review-dialog/goods-review-dialog.component';
import { MemberWishService } from '../api/member-wish-service';
import { LoginService } from '../api/login.service';
import { SnackBarComponent } from '../snack-bar/snack-bar.component';
import { Title, Meta } from '@angular/platform-browser';
import { Project } from '../app-config';
import { VisitLogService } from '../api/visit-log.service';
import { SwiperComponent } from 'swiper/angular';
import { PageMetaService } from '../shared/service/page-meta.service';
import { AddCartSnackBarComponent } from 'src/app/add-cart-snack-bar/add-cart-snack-bar.component';
import { SystemSettingService } from '../api/system-setting.service';
import * as moment from 'moment';
import { PromotionDialogComponent } from '../promotion-dialog/promotion-dialog.component';
import { InformationDialogService } from '../shared/service/information-dialog.service';
import { MemberService } from '../api/member.service';
import { CurrencySettingService } from '../shared/service/currency-setting.service';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-goods',
  templateUrl: './goods.component.html',
  styleUrls: ['./goods.component.scss']
})
export class GoodsComponent extends BasicViewComponent implements OnInit, AfterViewInit, OnDestroy {

  private _onDestroy = new Subject<void>();

  @ViewChild('attribute') attributeTemplate: TemplateRef<any>;
  @ViewChild('deliveryOptions') deliveryOptionsTemplate: TemplateRef<any>;
  @ViewChild('goodsDetails') goodsDetailsTemplate: TemplateRef<any>;
  @ViewChild(SwiperComponent) swiperConmponent: SwiperComponent;

  @ViewChild('anchorBar') public anchorBar;
  @ViewChild('mobileAnchorBar') public mobileAnchorBar;


  errorMap = { 'not_enough_stock': 'not.enough.stock' };

  loading = false;

  goods: any = {};
  categoryList;
  warehouseList;

  // destinationGroupList$: Observable<any[]>;

  deliveryMethodList$: Observable<any[]>;

  qty = 1;

  showBuyOptions = false;

  showBottomSheet: boolean;

  showGoodsDetails = true;
  showBottomSheetHeight = 400;
  hideScrollHeight = 200;

  enableProductsImageZoom;
  showSupportedPaymentMethods;

  swiperConfig: SwiperOptions = {
    observer: true,
    initialSlide: 0,
    slidesPerView: 1,
    // centeredSlides: true,
    zoom: false,
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
      loadOnTransitionStart: true,
    },
    watchSlidesVisibility: true,
    // loop: true,
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev',
    //   hideOnClick: true
    // },
    mousewheel: false,
    resizeObserver: true,
  };

  reviewSummary: any = {};
  reviewList = [];
  reviewPage = 0;
  reviewListLoading = false;

  isLogin;
  showRestockNotification = false;

  goodsId;
  goodsSlug;

  defaultProductVariantId;

  supportedPaymentMethodList;

  showSelectOptionsArea = false;

  relatedPromotionList;
  relatedGoodsList;

  memberGoodsReviewEnable = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    snackBar: MatSnackBar,
    private translate: TranslateService,
    private loadingService: FullScreenLoadingService,
    private goodsService: GoodsService,
    private categoryService: CategoryService,
    private warehouseService: WarehouseService,
    private shoppingCartService: ShoppingCartService,
    private memberWishService: MemberWishService,
    private loginService: LoginService,
    private visitLogService: VisitLogService,
    private dialog: MatDialog,
    private title: Title,
    private pageMetaService: PageMetaService,
    public systemSettingService: SystemSettingService,
    private renderer: Renderer2,
    private informationDialogService: InformationDialogService,
    private memberService: MemberService,
    private currencySettingService: CurrencySettingService,
    private viewportScroller: ViewportScroller,) {

    super(snackBar);
  }

  genArraySize(i) {
    return i ? this.genArraySize(i - 1).concat(i) : [];
  }

  ngOnInit() {

    this.route.params
      .pipe(
        tap(params => {
          const paramList = params.idWithSlug.split('-');
          this.goodsId = paramList[0];
          this.goodsSlug = params.idWithSlug.substring(this.goodsId.length + 1);
          if (paramList.length < 2) {
            this.redirect404Page();
            return;
          }

          const state = history.state;
          if (state) {
            this.defaultProductVariantId = state.productVariantId;
          }

          // Visit log
          const id = paramList[0];
          try {
            this.visitLogService.log('goods', `${id}`);
          } catch (error) {
            console.error(error);
          }
        }),
        switchMap(params => {
          this.loading = true;
          const id = this.goodsId;
          this.goods.id = id;
          // this.destinationGroupList$ = this.goodsService.queryDestinationGroup(id);
          return this.getGoods(id);
        }),
        tap(goods => {
          if (goods.slug !== this.goodsSlug) {
            this.redirect404Page();
            return;
          }
          this.goods = goods;
          // const imageList = goods.imageList;
          const imageList = goods.imageSetList;
          // Select fist option of attribute in product by default
          goods.productList.forEach(product => {
            const productVariantList = product.productVariantList;


            productVariantList.forEach(productVariant => {
              let pvImageList = productVariant.imageSetList;
              pvImageList = pvImageList?.filter(pvImg => imageList.every(img => img.thumbnail.url !== pvImg.thumbnail.url));
              imageList.push(...pvImageList);
            });

            let defaultAttributeOptionIdList = [];
            if (this.defaultProductVariantId) {
              const defaultProductVariant = productVariantList.find(pv => pv.id === this.defaultProductVariantId);
              defaultAttributeOptionIdList = defaultProductVariant.attributeOptionIdList;
            }

            if (defaultAttributeOptionIdList?.length === 0) {
              const firstHaveStockProductVariant = this.findFirstHaveStockProductVariant(product);
              if (firstHaveStockProductVariant) {
                defaultAttributeOptionIdList = firstHaveStockProductVariant.attributeOptionIdList;
              }
            }

            const attributeList = product.attributeList;

            attributeList.forEach(attribute => {
              if (defaultAttributeOptionIdList && defaultAttributeOptionIdList.length) {
                attribute.optionList.forEach(option => {
                  option.selected = defaultAttributeOptionIdList.includes(option.id);
                });
              } else {
                // const firstHaveStockOption = attribute.optionList.find(option => !option.outOfStock);
                // if (firstHaveStockOption) {
                //   firstHaveStockOption.selected = true;
                // } else {
                attribute.optionList[0].selected = true;
                // }
              }
            });

            this.checkAttributeOptionaNoProductVariant(product);
            this.checkAttributeOptionOutOfStock(product);
            this.onAttributeChanged(product);
          });
        }),
        switchMap(goods => {
          return this.categoryService.queryRootPath(goods.categoryId);
        }),
        tap(rootPath => {
          this.categoryList = rootPath;
        }),
        tap(() => {
          this.loading = false;
        }),
        tap(() => {
          this.refreshReviewSummary();
          this.queryRelatedPromotion(this.goodsId);
          this.queryDeliveryMethod(this.goodsId);
          this.querySupportPaymentMethod(this.goodsId);
          this.refreshReviewList();
          this.queryRelatedGoods(this.goodsId);
        }),
        tap(() => {
          let title = this.goods.name;
          if (this.categoryList && this.categoryList.length) {
            title = title + ' - ' + this.categoryList[this.categoryList.length - 1].name;
          }
          title = title + ' | ' + Project.title;
          const tagList = this.goods.tagList?.map(tag => tag.name) ?? [];
          const description = this.goods.description;
          const image = this.goods.imageSetList ? this.goods.imageSetList[0]?.thumbnail.url : '';

          const skuList = this.goods.productList
            .flatMap(product => product.productVariantList)
            .map(productVariant => productVariant.sku)
            ?? [];

          const keywords = [...skuList, ...tagList].join(',') ?? '';

          this.pageMetaService.generateTags({
            title: title,
            keywords: keywords,
            description: description,
            image: image,
            slug: this.goodsSlug
          });

        }),
      ).subscribe();

    this.warehouseService.query().subscribe(data => {
      this.warehouseList = data;
    });

    this.loginService.onLoggedIn()
      .pipe(
        takeUntil(this._onDestroy)
      ).subscribe(isLogin => {
        this.isLogin = isLogin;
      });

    this.loginService.onLoggedIn()
      .pipe(
        skip(1), // Avoid refresh goods on init
        takeUntil(this._onDestroy)
      ).subscribe(isLogin => {
        this.refreshGoods();
      });

    this.shoppingCartService.onDeleteGoods()
      .pipe(
        filter(cartGoods => cartGoods.goodsId === this.goods.id),
        takeUntil(this._onDestroy)
      ).subscribe(_ => this.refreshGoods());

    this.systemSettingService.getRestockNotificationSetting().subscribe(data => {
      this.showRestockNotification = data.showRestockNotification;
    });

    this.systemSettingService.getImageSetting().subscribe(data => {
      this.enableProductsImageZoom = data.enableProductsImageZoom;
    });

    this.systemSettingService.getGoodsSetting().subscribe(data => {
      this.showSupportedPaymentMethods = data.showSupportedPaymentMethods;
    });

    this.memberService.checkGoodsReviewEnable().subscribe(data => {
      this.memberGoodsReviewEnable = data;
    });

  }

  ngAfterViewInit() {
    // document.querySelector('#main-sidenav-content').addEventListener('scroll', () => this.onScroll(), true);

  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getGoods(goodsId): Observable<any> {
    return this.goodsService.get(goodsId);
  }

  refreshGoods() {
    this.getGoods(this.goods.id).subscribe(goods => {
      this.goods = deepMerge(this.goods, goods);
      this.goods.productList.forEach(product => this.onAttributeChanged(product));

      setTimeout(() => {
        this.swiperConmponent?.updateSwiper({});
        this.swiperConmponent?.swiperRef.lazy?.load();
      });
    });
  }

  minusQty(goods) {
    if (this.canMinusQty(goods)) {
      goods.qty--;
    }
  }

  plusQty(goods) {
    if (this.canPlusQty(goods)) {
      goods.qty++;
    }
  }

  canMinusQty(goods) {
    return goods.qty > 1 && (!goods.qtyLimitPerOrder || (goods.qty > goods.minQty));
  }

  canPlusQty(goods) {
    return (!goods.qtyLimitPerOrder || (goods.qty < goods.maxQty)) && !goods.lastStock;
  }

  onGoodsQtyInputBlur(goods) {
    if (goods.qty === null) {
      goods.qty = 1;
    }
    // console.log('goods.qty', goods.qty);
    // console.log('goods.qtyLimitPerOrder', goods.qtyLimitPerOrder);
    // console.log('goods.minQtyPerOrder', goods.minQtyPerOrder);
    // console.log('goods.maxQtyPerOrder', goods.maxQtyPerOrder);
    // console.log('goods.qtyInCart', goods.qtyInCart);
    // console.log('goods.minQty', goods.minQty);
    // console.log('goods.maxQty', goods.maxQty);

    if (goods.qtyLimitPerOrder) {
      if (goods.qty < goods.minQty) {
        goods.qty = goods.minQty;
      } else if (goods.qty > goods.maxQty) {
        goods.qty = goods.maxQty;
      }
    }
  }

  findStock(warehouseId: number, stockList: [any]) {
    return stockList.find(d => d.warehouseId === warehouseId);
  }

  // isOutOfStock(stockList: [any]): boolean {
  //   return stockList.every(d => d.stock <= 0);
  // }

  addGoods(goToCart = false) {
    this.loadingService.show();
    const productList = this.goods.productList.map(product => {
      const productVariant = this.findSelectedProductVariant(product);
      return { productId: product.id, productVariantId: productVariant.id };
    });
    const goodsId = this.goods.id;
    const qty = this.goods.qty;
    this.shoppingCartService.addGoods(goodsId, qty, productList)
      .subscribe({
        next: result => {
          if (result.success) {
            // this.showMessage(this.translate.instant('add.product.successfully'));
            // this.showMessage(this.goods.name + ' ' + this.translate.instant('added.to.the.cart'));
            this.showMessage(this.goods.name + ' ' + this.translate.instant('added.to.the'), AddCartSnackBarComponent);
            this.shoppingCartService.refreshCount();
            this.shoppingCartService.refresh();
            this.refreshGoods();
            if (goToCart) {
              this.router.navigateByUrl('/shopping-cart');
            }

          } else {
            // const msgKey = this.errorMap[result.errorCode] || 'error';
            // const message = this.translate.instant(msgKey, { goodsName: this.goods.name });
            // this.showError(`${this.translate.instant('warning')}: ${message}`);
            this.showRespResultError(result, this.errorMap, { goodsName: this.goods.name });
          }
        },
        complete: () => this.loadingService.hide()
      });
  }

  range(start, end): number[] {
    return Array.from(new Array(end - start + 1), (x, i) => start + i);
  }

  rangeByGoods(goods): number[] {
    let start = 1, end = 5;
    if (goods.qtyLimitPerOrder) {
      start = goods.minQty;
      end = goods.maxQty;
    }
    return this.range(start, end);
  }

  // isOutOfStockAttribute(product) {
  //   const selectedProductVariant = this.findSelectedProductVariant(product);
  //   return selectedProductVariant.outOfStock;
  // }

  findSelectedAttributeOption(attribute) {
    return attribute.optionList.find(o => o.selected === true);
  }
  changeAttributeOption(product, attribute, option) {
    attribute.optionList.forEach(o => o.selected = false);
    option.selected = true;

    // const attributeList = product.attributeList;
    // if (attributeList.length > 1) {
    //   const attributeWithoutLastList = attributeList.slice(0, attributeList.length - 1);
    //   if (attributeWithoutLastList.includes(attribute)) {
    //     const optionIdList = attributeList
    //       .flatMap(attr => attr.optionList.filter(opt => opt.selected))
    //       .map(opt => opt.id);

    //     attribute.optionList.forEach(opt => {
    //       const checkOutOfStockOptionIdList = [...optionIdList, opt.id];
    //       const optionIdKey = this.generateAttributeMatchKey(checkOutOfStockOptionIdList);
    //       console.log('optionIdKey', optionIdKey);

    //       const selectedProductVariant = product.productVariantList
    //        .find(productVariant => this.generateAttributeMatchKey(productVariant.attributeOptionIdList) === optionIdKey);
    //       console.log('attribute.optionList.selectedProductVariant', selectedProductVariant);
    //       opt.outOfStock = selectedProductVariant.outOfStock;
    //       console.log('opt', opt);
    //     });
    //   }
    // } else {
    //   attribute.optionList.forEach(opt => {
    //     const checkOutOfStockOptionIdList = [opt.id];
    //     const optionIdKey = this.generateAttributeMatchKey(checkOutOfStockOptionIdList);

    //     const selectedProductVariant = product.productVariantList
    //      .find(productVariant => this.generateAttributeMatchKey(productVariant.attributeOptionIdList) === optionIdKey);
    //     opt.outOfStock = selectedProductVariant.outOfStock;
    //   });
    // }

    this.checkAttributeOptionaNoProductVariant(product);
    this.checkAttributeOptionOutOfStock(product);
    this.selectAvailabelAttributeOption(product, attribute);
    this.onAttributeChanged(product);
  }

  onAttributeChanged(product) {
    const selectedProductVariant = this.findSelectedProductVariant(product);
    console.log('selectedProductVariant', selectedProductVariant);
    if (selectedProductVariant) {
      this.goods.price = selectedProductVariant.price;
      this.goods.listPrice = selectedProductVariant.listPrice;
      this.goods.outOfStock = selectedProductVariant.outOfStock;
      this.goods.canRestockNotify = selectedProductVariant.canRestockNotify;
      this.goods.hasRestockNotify = selectedProductVariant.hasRestockNotify;
      this.goods.barcode = selectedProductVariant.barcode;
      this.goods.lowStock = selectedProductVariant.lowStock;
      this.goods.lastStock = selectedProductVariant.lastStock;
      this.goods.qtyLimitPerOrder = selectedProductVariant.qtyLimitPerOrder;
      this.goods.minQtyPerOrder = selectedProductVariant.minQtyPerOrder;
      this.goods.maxQtyPerOrder = selectedProductVariant.maxQtyPerOrder;
      this.goods.qtyInCart = selectedProductVariant.qtyInCart;
      this.goods.percentageOff = selectedProductVariant.percentageOff;
      this.goods.dollarOff = selectedProductVariant.dollarOff;

      let initQty = this.goods.qty || 1;
      if (this.goods.qtyLimitPerOrder) {

        const qtyInCart = this.goods.qtyInCart;
        let maxQty = this.goods.maxQtyPerOrder - qtyInCart;
        let minQty = this.goods.minQtyPerOrder - qtyInCart;

        if (maxQty < 0) {
          maxQty = 0;
        }

        if (minQty < 1) {
          minQty = maxQty > 0 ? 1 : 0;
        }

        this.goods.minQty = minQty;
        this.goods.maxQty = maxQty;

        if (minQty > 0) {
          initQty = minQty;
        }
        if (initQty > maxQty) {
          initQty = maxQty;
        }
      }
      this.goods.qty = initQty;

      console.log('selectedProductVariant.imageSetList', selectedProductVariant.imageSetList);

      if (selectedProductVariant.imageSetList && selectedProductVariant.imageSetList.length > 0) {
        const imageUrl = selectedProductVariant.imageSetList[0].thumbnail.url;
        console.log('imageUrl', imageUrl);

        const matchedImageIndex = this.findMatchedImageIndex(imageUrl);
        if (matchedImageIndex > -1) {
          this.goods.imageIndex = matchedImageIndex;
        }
      }
    } else {
      this.goods.price = 0;
      this.goods.listPrice = 0;
      this.goods.outOfStock = true;
      this.goods.lowStock = false;
      this.goods.lastStock = false;
    }
  }
  generateAttributeMatchKey(optionIdList: string[]) {
    console.log('optionIdList', optionIdList);

    return optionIdList.sort().join('-');
  }
  findSelectedProductVariant(product) {
    const optionIdList = product.attributeList
      .flatMap(attribute => attribute.optionList.filter(option => option.selected))
      .map(option => option.id);
    const optionIdKey = this.generateAttributeMatchKey(optionIdList);
    console.log('optionIdKey', optionIdKey);

    const selectedProductVariant = product.productVariantList.find(productVariant =>
      this.generateAttributeMatchKey(productVariant.attributeOptionIdList) === optionIdKey);
    return selectedProductVariant;
  }

  findMatchedProductVariant(product, optionIdList) {
    const optionIdKey = this.generateAttributeMatchKey(optionIdList);
    console.log('optionIdKey', optionIdKey);

    const matchedProductVariant = product.productVariantList.find(productVariant =>
      this.generateAttributeMatchKey(productVariant.attributeOptionIdList) === optionIdKey);
    return matchedProductVariant;
  }

  findFirstHaveStockProductVariant(product) {
    const allAttributeOptionIdList: [][] = product.attributeList.map(attribute => attribute.optionList.map(option => option.id));
    const attributeOptionIdCombinationsList = combinationsOf2dArray(allAttributeOptionIdList);

    for (let index = 0; index < attributeOptionIdCombinationsList.length; index++) {
      const optionIdList = attributeOptionIdCombinationsList[index];
      const matchedProductVariant = this.findMatchedProductVariant(product, optionIdList);
      if (matchedProductVariant) {
        const hasStock = !matchedProductVariant.outOfStock;
        if (hasStock) {
          return matchedProductVariant;
        }
      }
    }
  }

  selectAvailabelAttributeOption(product, currentAttribute) {
    const attributeList = product.attributeList;

    console.log('currentAttribute', currentAttribute);

    const attributeIndex = attributeList.findIndex(item => item === currentAttribute) + 1;
    console.log('attributeIndex', attributeIndex);
    const childrenAttributeList = attributeList.slice(attributeIndex, attributeList.length);

    childrenAttributeList.forEach(attribute => {
      attribute.optionList.forEach(option => option.selected = false);
      let availableOption = attribute.optionList.find(option => !option.hidden && !option.outOfStock);

      if (!availableOption) {
        availableOption = attribute.optionList.find(option => !option.hidden);
      }

      if (!availableOption) {
        availableOption = attribute.optionList[0];
      }

      if (availableOption) {
        availableOption.selected = true;
      }
    });

  }

  findMatchedImageIndex(imageUrl) {
    return this.goods.imageSetList.findIndex(image => image.thumbnail.url === imageUrl);
  }

  checkAttributeOptionaNoProductVariant(product) {
    const attributeList = product.attributeList;
    if (attributeList?.length > 0) {
      const attributeWithoutLastList = attributeList.slice(0, attributeList.length - 1);
      const mainOptionIdList = attributeWithoutLastList
        .flatMap(attribute => attribute.optionList.filter(option => option.selected))
        .map(option => option.id);

      const lastAttribute = attributeList[attributeList.length - 1];
      lastAttribute.optionList.forEach(option => {
        const optionIdList = [...mainOptionIdList, option.id];
        const matchedProductVariant = this.findMatchedProductVariant(product, optionIdList);
        option.hidden = !matchedProductVariant;
      });
    }
  }

  checkAttributeOptionOutOfStock(product) {
    const attributeList = product.attributeList;
    if (attributeList?.length > 0) {
      const attributeWithoutLastList = attributeList.slice(0, attributeList.length - 1);
      const mainOptionIdList = attributeWithoutLastList
        .flatMap(attribute => attribute.optionList.filter(option => option.selected))
        .map(option => option.id);

      const lastAttribute = attributeList[attributeList.length - 1];
      lastAttribute.optionList.forEach(option => {
        const optionIdList = [...mainOptionIdList, option.id];
        const matchedProductVariant = this.findMatchedProductVariant(product, optionIdList);
        option.outOfStock = !matchedProductVariant || matchedProductVariant?.outOfStock;
      });
    }
  }

  scrollTo(id) {
    // document.getElementById(id).scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'center',
    //   inline: 'start'
    // });
    (function smoothscroll() {
      const scrollContainer = window;

      const topNavBarElement = document.getElementById('top-nav-bar');
      const headerElement = document.getElementById('header');
      const scrollToElement = document.getElementById(id);
      const anchorBar = document.getElementById('anchor-bar');

      const top = scrollToElement.offsetTop - (topNavBarElement.offsetHeight + headerElement.offsetHeight + anchorBar.offsetHeight);

      scrollContainer.scrollTo({
        top: top,
        behavior: 'smooth'
      });
    })();

    // const offsetHeight = topNavBarElement.offsetHeight + headerElement.offsetHeight + anchorBar.offsetHeight;
    // this.viewportScroller.setOffset([0, offsetHeight]);
    // this.viewportScroller.scrollToAnchor(id);

  }

  openBuyOptions() {
    this.showBuyOptions = true;
  }
  closeBuyOptions() {
    this.showBuyOptions = false;
  }

  addToCart() {
    if (this.showBuyOptions) {
      this.closeBuyOptions();
      this.addGoods();
    } else {
      this.openBuyOptions();
    }
  }

  openDeliveryOptions() {
    this.dialog.open(this.deliveryOptionsTemplate, {
      autoFocus: false,
      panelClass: 'full-screen-dialog',
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '100%',
      height: '100%'
    });
  }

  openGoodsDetails() {
    // this.dialog.open(this.goodsDetailsTemplate, {
    //   autoFocus: false,
    //   panelClass: 'full-screen-dialog',
    //   maxWidth: '100vw',
    //   maxHeight: '100vh',
    //   width: '100%',
    //   height: '100%'
    // });
    this.showGoodsDetails = !this.showGoodsDetails;
  }

  openReviewDialog() {
    const dialogRef = this.dialog.open(GoodsReviewDialogComponent, {
      autoFocus: false,
      data: this.goods
    });
    dialogRef.afterClosed().subscribe(success => {
      if (success) {
        // refresh review
        this.refreshGoods();
        this.refreshReviewList();
        this.refreshReviewSummary();
      }
    });
  }

  toggleWish() {

    if (!this.isLogin) {
      this.router.navigate(['login']);
      return;
    }

    const api = this.goods.wish ? this.memberWishService.unwishGoods(this.goods.id) : this.memberWishService.wishGoods(this.goods.id);
    api.pipe(finalize(() => this.loading = false)).subscribe(result => {
      if (result.success) {
        this.goods.wish = !this.goods.wish;

        let message = '';
        if (this.goods.wish) {
          message = 'add.to.wish.list';
        } else {
          message = 'remove.from.wish.list';
        }
        this.showMessage(this.translate.instant(message) + ' ' + this.translate.instant('success'));
      } else {
        // this.showError(`Error: ${result.errorCode}, ${result.msg}`);
        this.showRespResultError(result, this.errorMap);
      }
    });
  }

  refreshReviewList() {
    this.reviewPage = 0;
    this.reviewList = [];
    this.loadMoreReview();
  }

  loadMoreReview() {
    this.reviewPage++;
    this.reviewListLoading = true;
    this.goodsService.queryReview(this.goods.id, { page: this.reviewPage, limit: 5 })
      .pipe(finalize(() => this.reviewListLoading = false))
      .subscribe(data => {
        data.forEach(element => {
          element.createDatetime = moment(element.createDatetime).format('YYYY-MM-DD');

          const memberEmailAddress = element.memberEmailAddress;
          const visibleSection = memberEmailAddress.slice(0, 4);
          const maskedSection = memberEmailAddress.slice(4);
          element.memberEmailAddress = visibleSection + maskedSection.replace(/./g, '*');

        });

        this.reviewList.push(...data);
      });
  }

  refreshReviewSummary() {
    this.goodsService.getReviewSummary(this.goods.id).subscribe(data => this.reviewSummary = data);
  }

  toggleStockNotify() {
    this.goods.productList.map(product => {
      const productVariant = this.findSelectedProductVariant(product);
      const api = this.goods.hasRestockNotify ?
        this.goodsService.cancelStockNotify(this.goods.id, productVariant.id) :
        this.goodsService.stockNotify(this.goods.id, productVariant.id);
      api.pipe(finalize(() => this.loading = false)).subscribe(result => {
        if (result.success) {
          this.goods.hasRestockNotify = !this.goods.hasRestockNotify;

          if (this.goods.hasRestockNotify) {
            this.showMessage(this.translate.instant('add.product.variant.stock.restock.notification.success'));
          } else {
            this.showMessage(this.translate.instant('remove.product.variant.stock.restock.notification.success'));
          }
        } else {
          // this.showError(`Error: ${result.errorCode}, ${result.msg}`);
          this.showRespResultError(result, this.errorMap);
        }
      });

    });
  }

  openPromotionDialog(promotion) {
    const dialogRef = this.dialog.open(PromotionDialogComponent, {
      width: '50vw',
      maxWidth: '800px',
      data: {
        promotion: promotion
      }
    });
    dialogRef.afterClosed().subscribe(success => {
      if (success) {
        // refresh review
        // this.refreshGoods();
        // this.refreshReviewList();
        // this.refreshReviewSummary();
      }
    });
  }

  openShippingDialog() {
    const dialogRef = this.informationDialogService.open(this.translate.instant('shipping'), this.goods.deliveryDescription);
  }

  openSupportedPaymentMethodDialog() {
    const dialogRef = this.informationDialogService.open(this.translate.instant('payment.methods'), this.supportedPaymentMethodList);
  }

  redirect404Page() {
    this.router.navigateByUrl('404', { replaceUrl: true, skipLocationChange: true });
  }

  toggleSelectOptionArea() {
    this.showSelectOptionsArea = !this.showSelectOptionsArea;
  }

  querySupportPaymentMethod(goodsId) {
    this.goodsService.querySupportPaymentMethod(goodsId).subscribe(data => {
      // const supportedPaymentMethod = data;
      // let supportedPaymentMethodStr = '';
      // data.forEach(pm => {
      //   supportedPaymentMethodStr += '\r\n' + pm;
      // });
      // this.supportedPaymentMethodStr = supportedPaymentMethodStr.substring(2);
      this.supportedPaymentMethodList = data;
    });
  }

  queryDeliveryMethod(goodsId) {
    this.deliveryMethodList$ = this.goodsService.queryDelieryMethod(goodsId);
  }

  queryRelatedPromotion(goodsId) {
    this.goodsService.queryRelatedPromotion(goodsId)
      .subscribe(data => {
        this.relatedPromotionList = data;
      });
  }

  queryRelatedGoods(goodsId) {
    this.goodsService.queryRelatedGoods(goodsId)
      .subscribe(data => {
        this.relatedGoodsList = data;
      });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    try {

      const scrollOffsetTop = (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop);
      // console.log('scrollOffsetTop', scrollOffsetTop);

      this.showBottomSheet = scrollOffsetTop > this.showBottomSheetHeight;

      // console.log('showScrollToTop', this.showScrollToTop);

      if (this.anchorBar) {

        const headerHeight = document.getElementById('header').offsetHeight;
        const anchorBarDom = this.anchorBar.nativeElement;

        this.renderer.setStyle(anchorBarDom, 'top', `${headerHeight}px`);
      }

      if (this.mobileAnchorBar) {
        const topNavBarElement = document.getElementById('top-nav-bar');

        const topNavBarHeight = topNavBarElement.offsetHeight;
        const mobileAnchorBarDom = this.mobileAnchorBar.nativeElement;

        const topNavBarTransform = topNavBarElement.style.transform;
        const matrix = new WebKitCSSMatrix(topNavBarTransform);
        const topNavBarTranslateY = matrix.m42;

        // const mobileAnchorBarTopValue = topNavBarTranslateY < 0 ? 0 : topNavBarHeight;
        const mobileAnchorBarTopValue = topNavBarHeight + topNavBarTranslateY;

        this.renderer.setStyle(mobileAnchorBarDom, 'top', `${mobileAnchorBarTopValue}px`);
      }

    } catch (error) {
      console.error(error);
    }
  }
}
