import { datePickerLocale } from './shared/import/shared-angular-material-datepicker-import.module';
import { Router, NavigationEnd, NavigationStart, NavigationCancel, ActivatedRoute } from '@angular/router';
import { Component, OnInit, AfterViewInit, Renderer2, OnDestroy, ViewChild, HostListener, ElementRef, NgZone } from '@angular/core';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
// import { DateAdapter } from '@angular/material/core';

import { LogoutService } from './api/logout.service';
import { LocalSettingService } from './shared/service/local-setting.service';
import { Language } from './shared/enum/language.enum';
import { Project } from './app-config';
import { Subject, Observable, EMPTY, fromEvent } from 'rxjs';
import { takeUntil, map, finalize, switchMap, distinctUntilChanged, skip, auditTime, debounceTime, tap, filter } from 'rxjs/operators';


// import * as Hammer from 'hammerjs';
import { trigger, transition, query, style, animate, state } from '@angular/animations';
import { LoginService } from './api/login.service';
import { CategoryService } from './api/category.service';
import { BrandService } from './api/brand.service';
import { ShoppingCartService } from './api/shopping-cart.service';
import { FullScreenLoadingService } from './shared/service/full-screen-loading.service';
import { PaymentService } from './api/payment.service';
import { BlogArticleCategoryService } from './api/blog-article-category.service';
import { AdvertisementService } from './api/advertisement.service';
import { ShopService } from './api/shop.service';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { MemberService } from './api/member.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { FacebookLoginService } from './shared/service/facebook-login.service';
import { GoogleLoginService } from './shared/service/google-login.service';
import { Title, Meta, DomSanitizer } from '@angular/platform-browser';
import { InfoPageService } from './api/info-page.service';
import { AdvPopUpDialogComponent } from './adv-pop-up-dialog/adv-pop-up-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { GoodsService } from './api/goods.service';
import { PageMetaService } from './shared/service/page-meta.service';
import { DateAdapter } from '@angular/material/core';
import { SystemSettingService } from './api/system-setting.service';
import { PaymentMethodService } from './api/payment-method.service';
import { NavigationService } from './api/navigation.service';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/overlay';
import { TopNavBarManagerService } from './shared/service/top-nav-bar-manager.service';
import { HeaderSizeService } from './shared/service/header-size.service';
import { SearchService } from './api/search.service';
import { HreflangTagService } from './shared/service/hreflang-tag.service';
import { LanguageService } from './api/language.service';
import { CurrencyService } from './api/currency.service';
import { CurrencySettingService } from './shared/service/currency-setting.service';
import { LanguageSettingService } from './shared/service/language-setting.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [],
    animations: [
        trigger('minHeader', [
            state('false', style({ opacity: 1, maxHeight: '10rem' })),
            state('true', style({ opacity: 0, maxHeight: '0', visibility: 'hidden' })),
            transition('1 => 0', [
                animate(300)
            ]),
            transition('0 => 1', [
                animate(300)
            ])
        ])
    ]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

    // projectTitle = Project.title;

    showLogout = false;
    showLangOptions = true;

    showAboutUs = false;
    showPolicy = false;
    showCs = false;
    showHelp = false;

    currentLang: string = Language.EnUS;
    enabledLangList;

    currencyCodeList: string[];
    currentCurrencyCode: string;

    loading;

    user = { id: '', password: '' };
    hidePwd = true;

    @ViewChild('mainSidenavContent') mainSidenavContent: MatSidenavContent;

    @ViewChild('sidenav') public sidenav: MatSidenav;
    @ViewChild('memberSidenav') public memberSidenav: MatSidenav;
    // @ViewChild('catSidenav') public catSidenav: MatSidenav;

    // @ContentChild(OrderDetailComponent) public orderDetail: any;

    @ViewChild('topNavBar') public topNavBar;
    @ViewChild('header') public header;

    showScrollToTop: boolean;
    showScrollHeight = 1000;
    hideScrollHeight = 500;


    categoryList$: Observable<any[]>;
    brandList$: Observable<any[]>;
    blogCategoryList$: Observable<any[]>;
    categoryNameList$: Observable<any[]>;

    paymentMethodList$: Observable<any[]>;

    advertisementList: any[];
    advertisement20;
    advertisement2;
    advertisement3;

    // shopList$: Observable<any[]>;

    count: Observable<number>;
    shoppingCart: any;

    isLogin: boolean;

    showLoginDialog = false;
    showMemberMenu = false;
    showCart = false;
    showMemberSideMenu = false;
    // showBrandMenu = false;
    // showCategoryMenu = false;
    // showPromotionsMenu = false;
    // showPriceMenu = false;
    // showSidenav = false;
    showLang = false;
    showCurrency = false;
    showCurrencySelector = false;

    mobileSearch = false;

    appleWatchList;
    mobilePrinterList;
    smartcardReaderList;

    isCategoryPage = false;
    isHomePage = false;
    showCartBtn = false;

    emailPattern = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    emptyEmail = false;
    invalidEmailFormat = false;
    emptyPw = false;
    invalidLogin = false;

    mobileShowProduct = false;

    msg = '';

    member: any = {};

    categoryTree: any[];

    isWebsiteContentWidth = false;

    isCategorySideNav = false;

    // @HostListener('panright')
    // openSidenav() {
    //     // open the sidenav
    //     if (this.sidenav) {
    //         this.sidenav.open();
    //     }
    // }

    // @HostListener('panleft')
    // closeSidenav() {
    //     // close the sidenav
    //     if (this.sidenav) {
    //         this.sidenav.close();
    //     }
    // }

    isShowSearchBar = false;
    isFocusSearchBar = false;
    isFocusMobileSearchBar = false;
    isFocusEmailLogin = false;
    keyword = '';

    currentYear;

    infoPageList = [];

    minHeader = false;
    scrolled = false;
    currentScrollPoistion = 0;
    scrollDown = false;

    categoryBrandId = 0;  // type the id here
    categorySkinCareId = 0;  // type the id here

    searchBrand = '';

    brandMap;
    brandList;

    initSubNavHover;

    socialNetwork;

    // sideNavCategoryLayer = 0;
    // sideNavCategory = {name: '', subcategoryList: []};
    // sideNavCategoryList = [];

    storeBasicInfo;

    // langMap = Language;
    paymentMethodLogos;

    navigation;

    enableStorefrontStorePoints;
    enableStorefrontGiftRedemption;

    innerWidth;

    opacity = 1;

    showTopNavBar;

    searchQuickRecommendMinLimit = 3;
    searchQuickRecommendGoodsList = [];
    goodsRecommendLimit = 8;

    @ViewChild('keywordInput') keywordInput: ElementRef;
    @ViewChild('mobileKeywordInput') mobileKeywordInput: ElementRef;
    @ViewChild('emailLogin') emailLogin: ElementRef;

    // categoryTreeControl = new NestedTreeControl<any>(node => node.subcategoryList, { trackBy: node => node.id });
    // categoryTreeDataSource = new MatTreeNestedDataSource<any>();

    _searching = false;
    private search$ = new Subject<string>();


    /** Subject that emits when the component has been destroyed. */
    private _onDestroy$ = new Subject<void>();

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = event.target.innerWidth;
    }

    constructor(
        // elementRef: ElementRef,
        private loadingService: FullScreenLoadingService,
        public dateAdapter: DateAdapter<any>,
        public translate: TranslateService,
        private loginService: LoginService,
        private logoutService: LogoutService,
        private router: Router,
        private localSetting: LocalSettingService,
        private renderer: Renderer2,
        private categoryService: CategoryService,
        private brandService: BrandService,
        private shoppingCartService: ShoppingCartService,
        private paymentService: PaymentService,
        private blogArticleCategory: BlogArticleCategoryService,
        private advertisementService: AdvertisementService,
        private shopService: ShopService,
        private memberService: MemberService,
        private goodsService: GoodsService,
        protected facebookLoginService: FacebookLoginService,
        protected googleLoginService: GoogleLoginService,
        private fb: UntypedFormBuilder,
        private title: Title,
        private pageMetaService: PageMetaService,
        private hreflangTagService: HreflangTagService,
        private infoPageService: InfoPageService,
        public dialog: MatDialog,
        public systemSettingService: SystemSettingService,
        private paymentMethodService: PaymentMethodService,
        private navigationService: NavigationService,
        private scrollDispatcher: ScrollDispatcher,
        private ngZone: NgZone,
        private topNavBarManagerService: TopNavBarManagerService,
        private headerSizeService: HeaderSizeService,
        private searchService: SearchService,
        private languageService: LanguageService,
        private languageSettingService: LanguageSettingService,
        private currencySettingService: CurrencySettingService,
    ) {

        // // swipe guesture for toggle side menu
        // const hammertime = new Hammer(document.body, {
        //     cssProps: {
        //         userSelect: true
        //     }
        // });
        // hammertime.get('pan').set({ direction: Hammer.DIRECTION_ALL });
        // hammertime.on('panright', event => {
        //     if (
        //         event.pointerType !== 'mouse'
        //         && event.center.x >= 1
        //         && event.center.x <= 50
        //     ) {
        //         this.sidenav.open();
        //     }
        //     return true;
        // });
        // hammertime.on('panleft', event => {
        //     // console.log('panleft', event);

        //     if (event.pointerType !== 'mouse'
        //         && event.deltaX < -130
        //         && Math.abs(event.angle) > 125) {
        //         // console.log('close side menu');
        //         this.sidenav.close();
        //     }
        //     return true;
        // });
        // hammertime.on('panup', event => false);
        // hammertime.on('pandown', event => false);

        if (window.innerWidth > 600) {
            this.router.events.subscribe(() => {
                this.initSubNavHover = true;
                setTimeout(() => {
                    this.initSubNavHover = false;
                }, 500);
            });
        }
    }

    async ngOnInit(): Promise<void> {

        this.printConsoleLogo();

        await this.initLang();
        await this.initCurrency();

        // Meta and SEO
        this.hreflangTagService.setHreflangTags();
        this.title.setTitle(Project.title);
        this.pageMetaService.init();

        // show/hidden logout button detection
        this.router.events
            .pipe(takeUntil(this._onDestroy$))
            .subscribe((val) => {

                if (val instanceof NavigationEnd) {
                    this.pageMetaService.generateTags({ url: Project.url + val.url.replace('/', '') });
                }

                if (val instanceof NavigationEnd) {
                    // console.log('router change url:' + val.url);
                    const isLoginPage: boolean = (val.url === '/login');
                    this.showLogout = isLoginPage;
                    this.showLangOptions = !isLoginPage;
                    const isGoodsPage: boolean = (val.url.includes('goods') && !val.url.includes('search/goods'));
                    // console.log('isCategoryPage', isCategoryPage);
                    this.showCartBtn = isGoodsPage;
                    const isHomePage: boolean = (val.url === '');
                    this.isHomePage = isHomePage;
                    const isCategoryPage: boolean = (val.url.includes('category'));
                    this.isCategoryPage = isCategoryPage;
                    this.isWebsiteContentWidth = (isCategoryPage || isHomePage);

                    this.loginService.refreshStatus();
                } else if (val instanceof NavigationStart) {
                    this.closeLoginDialog();
                }


                if (this.sidenav) {
                    this.sidenav.close();
                }

                if (this.memberSidenav) {
                    this.memberSidenav.close();
                }


            });

        this.count = this.shoppingCartService.count();
        this.shoppingCartService.cart().subscribe(shoppingCart => this.shoppingCart = shoppingCart);

        this.categoryList$ = this.categoryService.query();
        this.categoryService.queryTree().subscribe(data => {
            this.categoryTree = data;

            // if (Project.url) {
            //     this.categoryTree?.forEach(category => {
            //         category?.tipsAdviceList?.forEach(tipsAdvice => {
            //             let hyperlink = tipsAdvice.hyperlink;
            //             if (hyperlink?.startsWith(Project.url)) {
            //                 hyperlink = hyperlink?.replace(Project.url, '');
            //                 if (Project.url.endsWith('/')) {
            //                     hyperlink = '/' + hyperlink;
            //                 }
            //             }
            //             tipsAdvice.hyperlink = hyperlink;
            //         });
            //     });
            // }
            // this.categoryTreeDataSource.data = data;
        });
        this.brandList$ = this.brandService.query();

        this.brandList$.subscribe(data => {
            this.brandList = data;
            this.brandMap = this.groupBy(data);
        });

        this.loginService.refreshStatus();
        this.loginService.onLoggedIn()
            .subscribe(isLogin => {
                this.isLogin = isLogin;

                if (this.isLogin) {
                    this.memberService.getProfile().subscribe(data => this.member = data);
                    this.closeLoginDialog();
                } else {
                    this.member = {};
                }

                this.shoppingCartService.refresh();
                this.shoppingCartService.refreshCount();
            });

        this.queryPaymentMethodList();

        this.advertisementService.queryById([20, 1]).subscribe(data => {
            this.advertisementList = data;
            // this.advertisement2 = this.getAdvertisement(2);
            // this.advertisement3 = this.getAdvertisement(3);
            this.advertisement20 = this.getAdvertisement(20);

            const popUpAdvertisement = this.getAdvertisement(1);
            if (popUpAdvertisement) {
                const popUpAdvertisementLastTimestamp = this.localSetting.get('popUpAdvertisementLastTimestamp');
                let showPopUpAdv = false;
                if (popUpAdvertisementLastTimestamp) {
                    showPopUpAdv = ((new Date().getTime() - Number(popUpAdvertisementLastTimestamp)) > 60 * 60 * 1000);
                } else {
                    showPopUpAdv = true;
                }
                if (showPopUpAdv) {
                    this.popUpAdv(popUpAdvertisement);
                    this.localSetting.set('popUpAdvertisementLastTimestamp', String(new Date().getTime()));
                }
            }
        });

        // this.shopList$ = this.shopService.query();

        this.currentYear = new Date().getFullYear();

        this.infoPageService.query().subscribe(data => {
            this.infoPageList = data;
        });

        this.systemSettingService.getSocialNetwork().subscribe(data => {
            this.socialNetwork = data;
        });

        this.systemSettingService.getStoreBasicInfo().subscribe(data => {
            this.storeBasicInfo = data;
        });

        this.paymentMethodService.getAllPaymentMethodLogo().subscribe(data => {
            this.paymentMethodLogos = data;
        });

        this.navigationService.query().subscribe(data => {
            this.navigation = data;
        });

        this.systemSettingService.getPointsSetting().subscribe(pointsSetting => {
            this.enableStorefrontStorePoints = pointsSetting.enableStorefrontStorePoints;
            this.enableStorefrontGiftRedemption = pointsSetting.enableStorefrontGiftRedemption;
        });

        this.innerWidth = window.innerWidth;

        this.topNavBarManagerService.onVisableChanged().pipe(debounceTime(25)).subscribe(show => this.showTopNavBar = show);

        this.ngZone.runOutsideAngular(() => {
            fromEvent(window, 'scroll').pipe(
                auditTime(250),
                takeUntil(this._onDestroy$)
            ).subscribe((event) => {
                this.ngZone.run(() => { this.onScroll(event); });
            });
        });

        this.search$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            filter(keyword => keyword?.length > 0),
            tap(() => this._searching = true),
            switchMap(keyword => this.searchService.searchGoods(keyword, 1, this.goodsRecommendLimit)),
            tap(() => this._searching = false),
            takeUntil(this._onDestroy$),
        ).subscribe(data => this.searchQuickRecommendGoodsList = data);
    }

    ngAfterViewInit() {
        this.router.events
            .pipe(takeUntil(this._onDestroy$))
            .subscribe((event) => {

                if (event instanceof NavigationStart) {
                    this.loading = true;
                } else if (
                    event instanceof NavigationEnd ||
                    event instanceof NavigationCancel
                ) {
                    this.loading = false;
                    this.onActivate(null);
                }
            });


        // this.mainSidenavContent.elementScrolled

        // this.mainSidenavContent.elementScrolled().subscribe((e: Event) => this.onScroll());
        // this.mainSidenavContent.elementScrolled().subscribe((e: Event) => this.isScrolled());
        // document.querySelector('#main-sidenav-content').addEventListener('scroll', () => this.onScroll(), true);
        // document.querySelector('#main-sidenav-content').addEventListener('scroll', () => this.isScrolled(), true);
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
        this._onDestroy$.complete();
    }

    initLang() {
        this.enabledLangList = this.languageSettingService.getEnabledList();
        this.currentLang = this.languageSettingService.current$.value;
    }

    // printDate() {
    //   console.log(this.myDate);
    // }

    toggleSearchBar() {
        this.isShowSearchBar = !this.isShowSearchBar;
        if (this.isShowSearchBar) {
            // this.keywordInput.nativeElement.focus();
            // this.isFocusSearchBar = true;
            setTimeout(() => {
                this.focusKeywordInput();
            });
        }
    }
    closeSearchBar() {
        this.isShowSearchBar = false;
    }
    focusKeywordInput() {
        this.keywordInput.nativeElement.focus();
        // this.isFocusSearchBar = false;
    }

    toggleMobileSearchBar() {
        this.mobileSearch = !this.mobileSearch;
        if (this.mobileSearch) {
            // this.mobileKeywordInput.nativeElement.focus();
            // this.isFocusMobileSearchBar = true;
            setTimeout(() => {
                this.focusMobileKeywordInput();
            });
        }
    }
    focusMobileKeywordInput() {
        this.mobileKeywordInput.nativeElement.focus();
        // this.isFocusMobileSearchBar = false;
    }

    // focusKeywordInput() {
    //     this.keywordInput.nativeElement.focus();
    // }

    search() {
        if (!!this.keyword) {
            console.log('click search, keyword:', this.keyword);
            this.router.navigate(['search/goods'], { queryParams: { keyword: this.keyword } });
            this.keyword = '';
            this.sidenav.close();
        }
    }


    switchLang(lang: string) {
        this.currentLang = lang;
        this.languageSettingService.changeLang(lang);
        this.loadingService.show();
        this.reload();
    }

    async initCurrency() {
        const currencyCode = this.currencySettingService.getCurrent().code;
        const currencySetting = await this.systemSettingService.getCurrencySetting().toPromise();
        this.showCurrencySelector = currencySetting.showCurrencySelector;
        this.currencyCodeList = this.currencySettingService.getCodeList();

        this.changeCurrency(currencyCode);
    }

    changeCurrency(currencyCode: string) {
        console.log('Set currency to:' + currencyCode);
        if (currencyCode) {
            this.currencySettingService.setCurrent(currencyCode);
            this.currentCurrencyCode = currencyCode;
        }
    }

    switchCurrency(currencyCode: string) {
        this.changeCurrency(currencyCode);
        this.showCurrency = false;
    }

    reload() {
        location.reload();
    }

    logout() {
        this.loadingService.show();
        this.logoutService.logout().pipe(finalize(() => this.loadingService.hide())).subscribe(() => {
            this.loadingService.show();
            this.router.navigateByUrl('', { replaceUrl: true })
                .then(() => {
                    this.reload();
                });
        });
    }

    login() {
        this.invalidLogin = null;
        if (this.loginCheck()) {
            this.loading = true;
            this.loginService.login(this.user).pipe(finalize(() => this.loading = false)).subscribe(data => {
                console.log('Login result:', data);
                if (data.success) {
                    this.user.password = '';
                    this.user.id = '';
                    // this.router.navigateByUrl(this.redirectUrl, { replaceUrl: true });
                    this.loadingService.show();
                    this.reload();
                } else {
                    this.user.password = '';
                    this.msg = this.translate.instant(data.msg);
                    this.invalidLogin = true;
                }
            }, error => console.log(error));
        }
    }

    loginCheck() {
        const emailAddress = this.user.id;
        const password = this.user.password;
        let check = null;

        this.emptyEmail = null;
        this.emptyPw = null;
        this.invalidEmailFormat = null;

        if (emailAddress.length === 0) {
            this.emptyEmail = true;
            check = true;
        }
        if (password.length === 0) {
            this.emptyPw = true;
            check = true;
        }
        if (!this.emptyEmail && !(this.emailPattern.test(emailAddress))) {
            this.invalidEmailFormat = true;
            check = true;
        }

        if (check) {
            return false;
        } else {
            return true;
        }
    }

    // loginViaFacebook() {
    //     this.facebookLoginService.login();
    // }

    printConsoleLogo() {
        const base22MessageStyle = `font-size:36px;
        font-weight:200;
        letter-spacing:0.2em;
        line-height:1.4em;
        font-family:helvetica,arial;
        color:rgba(0,0,25,0.5);`;
        console.log('%cMobileTech LTD.', base22MessageStyle);
    }

    onActivate(event: Event) {
        // console.log('trigger onActivate');

        // window.scrollTo(0, 0);
        // document.querySelector('#main-sidenav-content').scroll(0, 0);

        this.scrollToTop();
    }

    goToCart() {
        this.loginService.getStatus().subscribe(data => {
            if (data.isLoggedIn) {
                this.router.navigateByUrl('shopping-cart');
            } else {
                this.router.navigateByUrl('shopping-cart-login');
            }
        });
    }

    queryPaymentMethodList() {
        this.paymentMethodList$ = this.paymentService.queryPaymentMethod();
    }

    getAdvertisement(id: number) {
        return this.advertisementList.find(advertisement => advertisement.id === id);
    }

    // @HostListener('window:scroll', ['$event'])
    onScroll(event: Event) {
        try {

            const scrollOffsetTop = (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop);
            // console.log('scrollOffsetTop', scrollOffsetTop);

            this.showScrollToTop = scrollOffsetTop > this.showScrollHeight;

            this.minHeader = scrollOffsetTop > 100;

            this.scrolled = scrollOffsetTop > 0;

            const topNavDom = this.topNavBar.nativeElement;
            const topNavBarHeight = topNavDom.offsetHeight;
            const topNavBarMinTop = 0 - topNavBarHeight;
            const topNavBarMaxTop = 0;

            const scrolledValue = scrollOffsetTop - this.currentScrollPoistion;

            const cssStyle = topNavDom.style;
            const transformStyle = cssStyle.transform || cssStyle.webkitTransform || cssStyle.mozTransform || '0';
            const translateY = Number(transformStyle.replace(/[^\d\-.]/g, ''));

            let topValue = translateY - scrolledValue;
            if (topValue < topNavBarMinTop) {
                topValue = topNavBarMinTop;
            } else if (topValue > topNavBarMaxTop) {
                topValue = topNavBarMaxTop;
            }

            if (scrollOffsetTop <= 0) {
                // fix wrong position in iOS safari because of scroll bounce
                topValue = 0;
            }

            if (topValue !== translateY) {
                this.renderer.setStyle(topNavDom, 'transform', `translateY(${topValue}px)`);
            }

            const showShadow = this.scrolled && (topValue > topNavBarMinTop);
            if (showShadow) {
                this.renderer.addClass(topNavDom, 'mat-elevation-z4');
            } else {
                this.renderer.removeClass(topNavDom, 'mat-elevation-z4');
            }

            this.currentScrollPoistion = scrollOffsetTop;

            const headerDom = this.header.nativeElement;
            this.headerSizeService.setHeight(headerDom.offsetHeight);

            // console.log('showScrollToTop', this.showScrollToTop);
        } catch (error) {
            console.error(error);
        }
    }

    scrollToTop() {
        (function smoothscroll() {
            const scrollContainer = window;
            const currentScroll = scrollContainer.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                scrollContainer.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        })();
    }

    toggleLoginDialog() {
        this.showLoginDialog = !this.showLoginDialog;
        if (this.showLoginDialog) {
            // console.log('emailLogin', this.emailLogin);
            // this.emailLogin.nativeElement.focus();
            setTimeout(() => {
                this.focusEmailLogin();
            });
            // this.isFocusEmailLogin = true;
        }
    }
    closeLoginDialog() {
        // console.log('closeLoginDetail');

        this.showLoginDialog = false;
    }
    focusEmailLogin() {
        this.emailLogin.nativeElement.focus();
        // this.isFocusEmailLogin = false;
    }

    toggleMemberMenu() {
        this.showMemberMenu = !this.showMemberMenu;
    }
    closeMemberMenu() {
        // console.log('closeMemberDetail');

        this.showMemberMenu = false;
    }

    toggleCart() {
        this.showCart = !this.showCart;
    }
    closeCart() {
        // console.log('closeCart');

        this.showCart = false;
    }

    toggleLang() {
        this.showLang = !this.showLang;
    }
    closeLang() {
        this.showLang = false;
    }

    toggleCurrency() {
        this.showCurrency = !this.showCurrency;
    }
    closeCurrency() {
        this.showCurrency = false;
    }

    closeSearch() {
        this.mobileSearch = false;
    }


    // whatsappFunction() {
    //     // console.log('whatsapp');
    //     window.location.href = 'https://wa.me/85263802973';
    // }

    // messagerFunction() {
    //     console.log('messager');
    // }

    toggleMemberSideMenu() {
        this.showMemberSideMenu = !this.showMemberSideMenu;
    }

    // toggleBrandMenu() {
    //     this.showBrandMenu = !this.showBrandMenu;
    // }

    // toggleCategoryMenu() {
    //     this.showCategoryMenu = !this.showCategoryMenu;
    // }

    // togglePromotionsMenu() {
    //     this.showPromotionsMenu = !this.showPromotionsMenu;
    // }

    // togglePriceMenu() {
    //     this.showPriceMenu = !this.showPriceMenu;
    // }

    toggleSidenav(type) {
        if (type === 'category') {
            this.isCategorySideNav = true;
            // this.initSideNavCategory();
        } else {
            this.isCategorySideNav = false;
        }

        this.sidenav.toggle();
        // this.showSidenav = !this.showSidenav;
    }

    togglePolicy() {
        this.showPolicy = !this.showPolicy;
    }

    toggleCs() {
        this.showCs = !this.showCs;
    }

    toggleHelp() {
        this.showHelp = !this.showHelp;
    }

    isBundlePromotion(cartPromotion) {
        return cartPromotion.actionList.some(action => 'bundle_price_goods_discount' === action.type ||
            'bundle_price_product_variant_discount' === action.type ||
            'bundle_goods_discount' === action.type);
    }

    deleteGoodsOrPromotion(cartGoods) {
        if (cartGoods.bundle) {
            cartGoods.cartPromotionList.forEach(cartGoodsPromotion => {
                if (this.isBundlePromotion(cartGoodsPromotion)) {
                    this.deleteBundlePromotion(cartGoodsPromotion.id);
                }
            });
        } else {
            this.deleteGoods(cartGoods.id);
        }
    }

    deleteGoods(cartGoodsId: number) {
        this.loadingService.show();
        this.shoppingCartService.deleteGoods(cartGoodsId)
            .pipe(
                switchMap(result => {
                    if (result.success) {
                        this.shoppingCartService.refresh();
                        this.shoppingCartService.refreshCount();
                    }
                    // else {
                    // const msgKey = this.errorMap[result.errorCode] || 'error';
                    // this.showError(`${this.translate.instant('error')}: ${this.translate.instant(msgKey)}, ${result.msg}`);
                    // }
                    return EMPTY;
                }),
                finalize(() => this.loadingService.hide())
            )
            .subscribe();
    }

    deleteBundlePromotion(cartPromotionId) {
        this.loadingService.show();
        this.shoppingCartService.deleteBundlePromotion(cartPromotionId).pipe(
            switchMap(result => {
                if (result.success) {
                    this.shoppingCartService.refresh();
                    this.shoppingCartService.refreshCount();
                }
                return EMPTY;
            }),
            finalize(() => this.loadingService.hide())
        ).subscribe();
    }


    hasSubcategory = (_: number, node: any) => !!node.subcategoryList && node.subcategoryList.length > 0;

    isCategoryPathNode(category): boolean {
        return false;
        // return this.categoryRootPath.some(item => item.id === category.id);
    }

    searchBrandFunction() {
        // console.log('searchBrand', this.searchBrand);
        const filterBrandList = this.brandList.filter(brand => brand.name.toLowerCase().includes(this.searchBrand.toLowerCase()));
        this.brandMap = this.groupBy(filterBrandList);
    }

    popUpAdv(popUpAdvertisement) {
        this.dialog.open(AdvPopUpDialogComponent, {
            data: {
                content: popUpAdvertisement.html?.content
            },
            maxWidth: '100vw',
            // minWidth: '600px',
            // minHeight: '600px',
            panelClass: 'adv-pop-up-dialog',
            disableClose: true
        });
    }

    groupBy(xs) {
        return xs.reduce((rv, x) => {
            const key = x.name.substring(0, 1).toUpperCase();

            (rv[key] = rv[key] || []).push(x);
            return rv;
        }, {});
    }

    searchKeywordChange(keyword) {
        if (keyword.length > this.searchQuickRecommendMinLimit) {
            this.search$.next(keyword);
        }
    }

    goToQuickRecommendGoodsPage(goods) {
        if (goods) {
            this.router.navigate([`goods/${goods.id}-${goods.slug}`]);
            // this.searchQuickRecommendGoodsList = [];
            // this.search$.next();
            this.keyword = '';
            this.isShowSearchBar = false;
        }
    }

    // backToPreviousLayer() {
    //     this.sideNavCategoryLayer--;
    //     this.sideNavCategory = this.sideNavCategoryList.pop();
    // }

    // goToNextLayer(parentCategory, category) {
    //     this.sideNavCategoryLayer++;
    //     this.sideNavCategory = category;
    //     this.sideNavCategoryList.push(parentCategory);
    // }

    // initSideNavCategory() {
    //     const initCategory = {name: '', subcategoryList: this.categoryTree};
    //     this.sideNavCategory = initCategory;
    //     this.sideNavCategoryList = [];
    //     this.sideNavCategoryLayer = 0;
    // }

}
