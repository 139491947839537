import { Injectable } from '@angular/core';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { SystemSettingService } from 'src/app/api/system-setting.service';
import { Angulartics2Facebook } from 'angulartics2/facebook';

declare var fbq;

@Injectable({
    providedIn: 'root'
})
export class FacebookPixelService {

    constructor(
        private angulartics2Facebook: Angulartics2Facebook,
        private systemSettingService: SystemSettingService
    ) { }

    init() {

        this.systemSettingService.getWebsiteTracking().subscribe(websiteTracking => {

            const facebookPixelEnabled: boolean = websiteTracking.facebookPixel;
            const facebookPixelId = websiteTracking.facebookPixelId;

            if (facebookPixelEnabled) {
                try {
                    (function (f: any, b, e, v, n, t, s) {
                        if (f.fbq) { return; }
                        n = f.fbq = function () {
                            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue
                                .push(arguments);
                        };
                        if (!f._fbq) { f._fbq = n; }
                        n.push = n;
                        n.loaded = !0;
                        n.version = '2.0';
                        n.queue = [];
                        t = b.createElement(e);
                        t.async = !0;
                        t.src = v;
                        t.defer = true;
                        s = b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t, s);
                    })(window, document, 'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', facebookPixelId);
                    fbq('track', 'PageView');

                    const noScript = `<img height="1" width="1" style="display: none" \
                      src="https://www.facebook.com/tr?id=${facebookPixelId}&ev=PageView&noscript=1" />`;
                    const e = document.createElement('noscript');
                    e.innerHTML = noScript;
                    document.body.appendChild(e);
                } catch (error) {
                    console.error(error);
                }
                this.angulartics2Facebook.startTracking();
            }
        });
    }
}
