import { ApiService } from './api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, EMPTY, of, BehaviorSubject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { RespResult } from './table-api-service';

@Injectable({
    providedIn: 'root'
})
export class ForgotPasswordService extends ApiService {

    constructor(private httpClient: HttpClient) {
        super();
    }

    forgotPassword(emailAddress: string): Observable<RespResult> {
        return this.httpClient.post<RespResult>('member/forgot-password', { 'emailAddress': emailAddress });
    }

    resetPassword(memberId: number, authCode: string, password: string): Observable<RespResult> {
        return this.httpClient.post<RespResult>(`member/${memberId}/password`, { 'authCode': authCode, 'password': password });
    }
}
