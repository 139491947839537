import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BlogArticleCategoryService } from '../api/blog-article-category.service';
import { BlogArticleService } from '../api/blog-article.service';
import { BlogHomeService } from '../api/blog-home.service';
import { CategoryService } from '../api/category.service';
import { SearchService } from '../api/search.service';
import * as moment from 'moment';

@Component({
  selector: 'app-search-blog',
  templateUrl: './search-blog.component.html',
  styleUrls: ['./search-blog.component.scss']
})
export class SearchBlogComponent implements OnInit {

  page = 1;
  limit = 5;
  loading = false;
  noMore = false;

  showCategory;
  showPopularPosts;
  name;
  content;
  popularPostsList = [];
  blogArticleList = [];
  blogArticleCategoryList = [];
  categoryTree;

  searchText = '';
  keyword = '';
  postDateFormat;

  private _onDestroy = new Subject<void>();

  constructor(private blogArticleService: BlogArticleService,
    private blogHomeService: BlogHomeService,
    private blogArticleCategoryService: BlogArticleCategoryService,
    private categoryService: CategoryService,
    private searchService: SearchService,
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.blogHomeService.getDetails().subscribe(data => {
      this.showCategory = data.showCategory;
      this.showPopularPosts = data.showPopularPosts;
      this.name = data.name;
      this.content = data.content;
      this.popularPostsList = data.popularPostsList;
    });

    // this.categoryService.queryTree().subscribe(data => {
    //   this.categoryTree = data;
    // });
    this.blogArticleCategoryService.query().subscribe(data => {
      this.blogArticleCategoryList = data;
    });

    this.postDateFormat = this.translate.instant('blog.article.post.date.format');

    this.route.queryParams.subscribe(params => {
      this.blogArticleList = [];
      this.page = 1;

      console.log('parmas', params);
      this.searchText = params.keyword;
      this.page = params.page || 1;
      console.log('searchText', this.searchText);
      this.continueSearch();
    });
  }

  continueSearch() {
    this.loading = true;
    this.searchService.searchBlogArticle(this.searchText, this.page, this.limit)
      .pipe(finalize(() => this.loading = false))
      .subscribe(data => {
        if (data && data.length) {
          this.blogArticleList.push(...data);
        }

        if (!data || data.length < this.limit) {
          this.noMore = true;
        }
      });
  }

  loadMore() {
    this.page++;
    this.continueSearch();
  }

  search() {
    if (!!this.keyword) {
      console.log('click search, keyword:', this.keyword);
      this.router.navigate(['search/blog'], { queryParams: { keyword: this.keyword } });
      this.keyword = '';
      this.page = 1;
    }
  }

  formatPostDate(postDate) {
    const postDateFormat = this.translate.instant('blog.article.post.date.format');
    return moment(postDate).format(postDateFormat);;
  }
}
