import { Component, OnInit, Inject } from '@angular/core';
import { MemberCouponService } from '../api/member-coupon.service';
import { BasicViewComponent } from '../basic-view-component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FullScreenLoadingService } from '../shared/service/full-screen-loading.service';
import { ShoppingCartService } from '../api/shopping-cart.service';
import { finalize, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss']
})
export class CouponComponent extends BasicViewComponent implements OnInit {

  couponErrorMap = {
    'not_exist': 'coupon.error.not.exist',
    'duplicate_coupon': 'coupon.error.duplicate',
    'expired': 'coupon.error.expired',
    'unavailable': 'coupon.error.unavailable',
    'cart_session_timeout': 'cart.session.timeout'
  };

  couponList = [];

  constructor(private router: Router,
    snackBar: MatSnackBar,
    private memberCouponService: MemberCouponService,
    private loadingService: FullScreenLoadingService,
    private shoppingCartService: ShoppingCartService,
    private translate: TranslateService) {
    super(snackBar);
  }

  ngOnInit(): void {
    this.memberCouponService.queryAvailable().subscribe(data => {
      this.couponList = data;
    });
  }

  addCoupon(couponId) {
    this.loadingService.show();
    this.shoppingCartService.addCoupon(couponId)
      .pipe(
        switchMap(result => {
          if (result.success) {
            this.refreshShoppingCart();
            this.router.navigateByUrl('shopping-cart', { replaceUrl: true });
          } else {
            // const msgKey = this.couponErrorMap[result.errorCode] || 'error';
            // this.showError(`${this.translate.instant('warning')}: ${this.translate.instant(msgKey)}`);
            this.showRespResultError(result, this.couponErrorMap);
            if (result.errorCode === 'cart_session_timeout') {
              this.refreshShoppingCart();
              this.router.navigateByUrl('shopping-cart', { replaceUrl: true });
            }
          }
          return EMPTY;
        }),
        finalize(() => this.loadingService.hide())
      )
      .subscribe();
  }

  refreshShoppingCart() {
    this.shoppingCartService.refreshCount();
    this.shoppingCartService.refresh();
  }

}
