import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericTableApiService } from './generic-table-api.service';
import { QueryBean } from './table-api-service';

@Injectable({
  providedIn: 'root'
})
export class NewsAndEventsPageService extends GenericTableApiService {

  constructor(httpClient: HttpClient) {
      super(httpClient, 'news-and-events-page');
  }

  queryList(id: number, queryBean?: QueryBean): Observable<any> {
    let params = {};
    if (queryBean) {
        params = this.tsObjToJsonObj(queryBean);
    }
    return this.httpClient.get(`news-and-events-page/${id}`, {
      params: params
    });
  }

  getItem(id: number, iId: number): Observable<any> {
    return this.httpClient.get(`news-and-events-page/${id}/item/${iId}`);
  }

  getItemCount(id: number): Observable<any> {
    return this.httpClient.get(`${this.endpoint}/${id}/item/count`, { responseType: 'text' });
  }
}
