import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GoodsSeriesService } from '../api/goods-series.service';
import { CategoryService } from '../api/category.service';
import { Observable, fromEvent, Subject } from 'rxjs';
import { SearchService } from '../api/search.service';
import { finalize, takeUntil, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-search-goods-series',
  templateUrl: './search-goods-series.component.html',
  styleUrls: ['./search-goods-series.component.scss']
})
export class SearchGoodsSeriesComponent implements OnInit, OnDestroy {

  private _onDestroy = new Subject<void>();

  // goodsSeriesList$: Observable<any[]>;
  goodsSeriesList = [];
  keyword = '';

  page = 1;
  limit = 20;
  loading = false;
  noMore = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private searchService: SearchService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.goodsSeriesList = [];
      this.page = 1;

      console.log('parmas', params);
      this.keyword = params.keyword;
      this.page = params.page || 1;
      console.log('keyword', this.keyword);
      this.search();
    });

    fromEvent(window, 'resize').pipe(
      debounceTime(100),
      takeUntil(this._onDestroy),
    ).subscribe(() => this.loadMore());

  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  search() {
    this.loading = true;
    this.searchService.searchGoodsSeries(this.keyword, this.page, this.limit)
      .pipe(finalize(() => this.loading = false))
      .subscribe(data => {
        if (data && data.length) {
          this.goodsSeriesList.push(...data);
        }

        if (!data || data.length < this.limit) {
          this.noMore = true;
        }
      });
  }

  loadMore() {
    this.page++;
    this.search();
  }

}
