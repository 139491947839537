import { trigger, state, style, transition, animate } from '@angular/animations';
import { ChangeDetectorRef, Component, Input, OnInit, Renderer2 } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSidenav } from '@angular/material/sidenav';
import { TranslateService } from '@ngx-translate/core';
import { SystemSettingService } from '../api/system-setting.service';
import { datePickerLocale } from '../shared/import/shared-angular-material-datepicker-import.module';
import { CurrencySettingService } from '../shared/service/currency-setting.service';
import { FullScreenLoadingService } from '../shared/service/full-screen-loading.service';
import { LocalSettingService } from '../shared/service/local-setting.service';

@Component({
  selector: 'app-side-nav-content',
  templateUrl: './side-nav-content.component.html',
  styleUrls: ['./side-nav-content.component.scss'],
  animations: [
    trigger('panelInOut', [
      transition('void => *', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('300ms 0ms ease-in-out', style({ transform: 'translateX(0)', opacity: 1 }))
      ]),
      transition('* => void', [
        animate('300ms 0ms ease-in-out', style({ transform: 'translateX(100%)', opacity: 0 }))
        // style({ transform: 'translateX(100%)', opacity: 0 }),
        // animate(300)
      ])
    ])
  ]
})
export class SideNavContentComponent implements OnInit {

  _categoryTree: any[];

  @Input() set categoryTree(value) {
    this._categoryTree = value;
    this.initCategory();
  }
  get categoryTree() {
    return this._categoryTree;
  }

  @Input() isCategorySideNav: any;
  // @Input() sidenav: MatSidenav;
  @Input() infoPageList: any[];
  @Input() navList: any[];
  @Input() footerNavigation: any;
  @Input() socialNetwork: any;
  @Input() enabledLangList: any[];

  _rippleColor = 'rgba(255,255,255,0.5)';

  // sideNavCategoryLayer = 0;
  // sideNavCategoryList = [];
  sideNavLayer = 0;
  sideNavList = [];

  showCurrencySelector = false;
  currencyCodeList: any[];

  constructor(
    private sidenav: MatSidenav,
    private localSetting: LocalSettingService,
    public translate: TranslateService,
    public dateAdapter: DateAdapter<any>,
    private renderer: Renderer2,
    private loadingService: FullScreenLoadingService,
    private systemSettingService: SystemSettingService,
    private currencySettingService: CurrencySettingService,
  ) {
  }

  ngOnInit() {
    this.initSideNav();

    this.currencyCodeList = this.currencySettingService.getCodeList();
    this.systemSettingService.getCurrencySetting().subscribe(currencySetting => {
      this.showCurrencySelector = currencySetting.showCurrencySelector;
    });
  }

  backToPreviousLayer() {
    this.sideNavLayer--;
    this.sideNavList.pop();
  }

  goToNextNotCategoryLayer(nav) {
    console.log('nav', nav);
    const subNav = { type: 'navItem', subList: nav.subList };
    this.goToNextLayer(nav.name, subNav.subList);
  }

  goToNextCategoryLayer(category) {
    const subNav = { type: 'categories', subcategoryList: category.subcategoryList };
    this.goToNextLayer(category.name, [subNav]);
  }

  goToNextLayer(name, subNavList) {
    this.sideNavLayer++;
    const initElement = { name: name, subNavList: subNavList };
    this.sideNavList.push(initElement);
  }

  // initSideNavCategory() {
  //   const initCategory = { name: '', subcategoryList: this.categoryTree };
  //   // this.sideNavCategory = initCategory;
  //   // this.sideNavCategoryList = [];
  //   this.sideNavCategoryList.push(initCategory);
  //   this.sideNavCategoryLayer = 0;
  // }

  initSideNav() {
    this.initCategory();
    const initNav = { name: '', subNavList: this.navList };
    this.sideNavList.push(initNav);
    this.sideNavLayer = 0;
  }

  switchLang(lang: string) {
    this.changeLang(lang);
    this.loadingService.show();
    this.reload();
  }

  changeLang(lang: string) {
    console.log('Set language to:' + lang);
    if (lang) {
      this.localSetting.setLang(lang);
      // this.currentLang = lang;
      this.translate.use(lang);
      this.dateAdapter.setLocale(lang);
      datePickerLocale.next(lang);

      this.renderer.removeClass(document.body, 'app-lang-en-US');
      this.renderer.removeClass(document.body, 'app-lang-zh-HK');
      this.renderer.removeClass(document.body, 'app-lang-zh-CN');

      this.renderer.addClass(document.body, `app-lang-${lang}`);

      this.renderer.setAttribute(document.documentElement, 'lang', lang);
    }
  }

  changeCurrency(currencyCode: string) {
    console.log('Set currency to:' + currencyCode);
    if (currencyCode) {
      this.currencySettingService.setCurrent(currencyCode);
    }
  }

  switchCurrency(currencyCode: string) {
    this.changeCurrency(currencyCode);
    this.sidenav.close();
  }

  initCategory() {
    this.navList?.forEach(nav => {
      if (nav.type.includes('categories')) {
        nav.subcategoryList = this._categoryTree;
      }
    });
  }

  reload() {
    location.reload();
  }

}
