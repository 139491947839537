import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalSettingService } from 'src/app/shared/service/local-setting.service';

@Component({
  selector: 'app-video-gallery-dialog',
  templateUrl: './video-gallery-dialog.component.html',
  styleUrls: ['./video-gallery-dialog.component.css']
})
export class VideoGalleryDialogComponent implements OnInit {

  playerVars = {
    cc_lang_pref: 'en',
  };
  version = '...';
  private player;
  public ytEvent;
  youtubeId;
  playerWidth = '734';
  playerHeight = '540';

  constructor(private dialogRef: MatDialogRef<VideoGalleryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private localSetting: LocalSettingService,) {
    this.youtubeId = data.youtubeId;
    // const currentLang = this.localSetting.getLang();
    // this.playerVars = {
    //   cc_lang_pref: currentLang,
    // };
  }

  ngOnInit(): void {
  }

  onStateChange(event) {
    this.ytEvent = event.data;
  }

  savePlayer(player) {
    this.player = player;
  }

  playVideo() {
    this.player.playVideo();
  }

  pauseVideo() {
    this.player.pauseVideo();
  }

}
