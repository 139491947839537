import { GoogleLoginProvider, LoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { Injectable, Type } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject, Subject, distinctUntilChanged, switchMap, take, tap } from 'rxjs';
import { LoginService } from 'src/app/api/login.service';
import { MemberService } from 'src/app/api/member.service';
import { SystemSettingService } from 'src/app/api/system-setting.service';
import { RespResult } from 'src/app/api/table-api-service';
import { AppInjector } from './app-injector';
import { TranslateService } from '@ngx-translate/core';
import { ComponentType } from '@angular/cdk/portal';
import { SnackBarComponent } from 'src/app/snack-bar/snack-bar.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class GoogleLoginService {
  provider: GoogleLoginProvider = null;
  providers = [];

  private _enabled$ = new ReplaySubject<boolean>(1);

  // idToken$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  action: 'login' | 'connect' = 'login';

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _loading = false;

  errorMap = {
    'unexpected_exception': 'unexpected.error',
    'invalid_input': 'invalid.input',
    'email_required': 'single.sign.on.login.error.email.required',
  };

  constructor(
    private snackBar: MatSnackBar,
    private loginService: LoginService,
    private systemSettingService: SystemSettingService,
    private memberService: MemberService,
  ) {

  }

  async init() {
    try {
      const singleSignOn = await this.systemSettingService.getSingleSignOn().toPromise();

      const googleLoginEnabled = singleSignOn.google ?? true;
      const googleClientId = singleSignOn.googleClientId ?? '466394149346-2kjvfjag8uijf80hg8d3491d9bcuqemt.apps.googleusercontent.com';

      if (googleLoginEnabled) {
        this.provider = new GoogleLoginProvider(googleClientId, {
          scopes: ['https://www.googleapis.com/auth/userinfo.email', 'https://www.googleapis.com/auth/userinfo.profile'],
          oneTapEnabled: false
        });

        this.providers = [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: this.provider
          }
        ];

        this._enabled$.next(true);
        this._enabled$.complete();

        const authService = AppInjector.get(SocialAuthService);
        // authService.authState
        //   .pipe(
        //     distinctUntilChanged((a, b) => {
        //       return a?.idToken == b?.idToken
        //     }),
        //   ).subscribe(user => this.idToken$.next(user?.idToken ?? null));

        authService.authState.subscribe(async user => {
          // console.log('GoogleLoginService', 'authState', user);

          const idToken = user?.idToken ?? null;
          if (!idToken) {
            return;
          }
          if (this.loading) {
            return;
          }

          this.loading = true;
          if (this.action == 'login') {

            const resp = await this.login(idToken).toPromise();
            if (resp.success) {
              this.reload();
            } else {
              this.showRespResultError(resp, this.errorMap);
            }
          } else {
            const resp = await this.connect(idToken).toPromise();
            if (resp.success) {
              this.reload();
            }
          }
          this.loading = false;
        });

      } else {
        this._enabled$.next(false);
        this._enabled$.complete();
      }
    } catch (error) {
      console.error(error);
      this._enabled$.next(false);
      this._enabled$.complete();
    }

    console.log(' GoogleLoginService.providers', this.providers);
  }

  set loading(loading) {
    this._loading = loading;
    this.loading$.next(loading);
  }

  login(idToken: string): Observable<RespResult> {
    return this.loginService.loginViaGoogle(idToken);
  }

  connect(idToken: string): Observable<RespResult> {
    return this.memberService.connectGoogle(idToken);
  }

  discount(): Observable<RespResult> {
    this.provider.signOut();
    return this.memberService.disconnectGoogle();
  }

  // syncInfo(): Observable<any> {
  //   const resp = new Subject<void>();
  //   // FB.getLoginStatus(response => {
  //   //   console.log('fb response', response);
  //   //   if (response.status === 'connected') {
  //   //     const accessToken = response.authResponse.accessToken;
  //   //     this.memberService.syncFacebook(accessToken).subscribe(data => {
  //   //       resp.next(data);
  //   //       resp.complete();
  //   //     });
  //   //   } else {
  //   //     resp.next();
  //   //     resp.complete();
  //   //   }
  //   // }, true);

  //   return resp;
  // }


  onEnabled(): Observable<boolean> {
    return this._enabled$;
  }

  get onEnabled$(): Observable<boolean> {
    return this._enabled$;
  }


  showError(message: string, component: ComponentType<any> = SnackBarComponent) {
    // this.snackBar.open(message, 'OK', { verticalPosition: 'top', panelClass: ['background-color-warn', 'error'] });
    const snackBar = this.snackBar.openFromComponent(component, {
      // duration: 3000,
      verticalPosition: 'top',
      data: { preClose: () => { snackBar.dismiss(); }, type: 'error', message: message },
      panelClass: ['background-color-warn'],
    });
  }

  showRespResultError(respResult: RespResult, errorMap: { [key: string]: string }, interpolateParams?: any) {
    const translate = AppInjector.get(TranslateService);
    const msgKey = errorMap[respResult.errorCode];
    let msg: string;
    if (msgKey) {
      msg = translate.instant(msgKey, interpolateParams);
    } else {
      msg = translate.instant('common.error.message') + `(${respResult.errorCode})`;
    }
    let respMsg = respResult.msg;
    if (respMsg) {
      respMsg = translate.instant(respResult.msg);
    }
    this.showError(`${msg}. ${respMsg || ''}`);
  }

  reload() {
    location.reload();
  }
}
