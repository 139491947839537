import { Component, OnInit, OnDestroy } from '@angular/core';
import { PaymentService } from '../api/payment.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { FullScreenLoadingService } from '../shared/service/full-screen-loading.service';
import { Location } from '@angular/common';
import { fromEvent, Observable, Subject } from 'rxjs';
import { startWith, finalize, takeUntil } from 'rxjs/operators';
import { PointsRewardService } from '../api/points-reward.service';

@Component({
  selector: 'app-payment-result',
  templateUrl: './payment-result.component.html',
  styleUrls: ['./payment-result.component.scss']
})
export class PaymentResultComponent implements OnInit, OnDestroy {
  success: boolean;
  order: any;

  shoppingCart;

  showGoodsList = false;

  hasPromotion = false;
  hasCoupon = false;

  // showGoodsExpansionPanel = false;

  csEmail = '';

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy$ = new Subject<void>();

  constructor(private router: Router,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private loadingService: FullScreenLoadingService,
    private location: Location,
    private paymentService: PaymentService,
    private pointsRewardService: PointsRewardService) {

    const stateData = this.router.getCurrentNavigation().extras.state;

    // const stateData = {
    //   success: true,
    //   orderId: 10111115,
    //   authCode: 'ovUt94ksMkBLjckXRFbEOAf2Fle0OOxTubMwQig6',
    //   paymentId: 10111115,
    //   csEmail: 'fail@mobiletech.com.hk'
    // };

    if (stateData) {
      this.success = stateData.success;
      const paymentId = stateData.paymentId;
      const authCode = stateData.authCode;
      this.csEmail = stateData.csEmail;


      this.order = {};
      this.order.id = stateData.orderId;

      this.paymentService.getOrder(paymentId, authCode)
        .subscribe(data => {
          this.order = data;
          console.log('data', data);

          this.getReviewPointsReward();

          const promotionList = data.orderPromotionList;
          if (promotionList) {
            this.hasPromotion = promotionList.some(promotion => !promotion.couponCode);
            this.hasCoupon = promotionList.some(promotion => !!promotion.couponCode);
          }

          this.doAnalytics(this.success, this.order);
        });

      // if (this.success) {
      //   this.paymentService.getOrder(paymentId, authCode).subscribe(data => {
      //     this.order = data;
      //   });
      // } else {
      //   this.order = {};
      //   // this.order.id = orderId;
      // }

    } else {
      this.router.navigate([''], { replaceUrl: true });
    }
  }

  ngOnInit() {
    // fromEvent(window, 'resize').subscribe(event => {
    //   this.showGoodsExpansionPanel = window.innerWidth < 600;
    // });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  getReviewPointsReward() {
    const goodsList = this.order.subOrderList.map(subOrder => subOrder.orderGoodsList).flatMap(subOrder => subOrder);
    const goodsIdList = goodsList.map(goods => goods.goodsId);
    this.pointsRewardService.getOrderGoodsReviewReward(goodsIdList).subscribe(data => {
      const goodsReviewReward = data;
      if (goodsReviewReward) {
        const availableGoodsList = goodsList.filter(goods => {
          return goodsReviewReward.availableGoodsIdList.some(goodsId => goodsId === goods.goodsId);
        });
        availableGoodsList.forEach(goods => {
          goods.reviewRewardDescription = goodsReviewReward.orderDescription;
        });
      }
    });
  }

  toggleGoodsList() {
    this.showGoodsList = !this.showGoodsList;
  }

  isPurchasedGoods(goods) {
    return !goods.addOn && !goods.gift && !goods.redemption;
  }


  // getTotalCost(subOrder) {
  //   return subOrder.orderGoodsList.map(data => data.price).reduce((acc, value) => acc + value, this.order.deliveryCharge);
  // }

  // getTotalGoodsCost(subOrder) {
  //   return subOrder.orderGoodsList.map(data => data.price).reduce((acc, value) => acc + value);
  // }

  doAnalytics(success, order) {
    if (success && !order.offlinePayment) {
      (window as any).onPaymentSuccess(order.amount, order.id);
    }
  }

}

