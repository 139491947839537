import { Component, OnInit, OnDestroy } from '@angular/core';
import { MemberService } from '../api/member.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FullScreenLoadingService } from '../shared/service/full-screen-loading.service';
import { TranslateService } from '@ngx-translate/core';
import { Validators, FormGroup, UntypedFormBuilder, ValidationErrors, ValidatorFn } from '@angular/forms';
import { finalize, takeUntil, debounceTime, switchMap } from 'rxjs/operators';
import { Subject, empty, EMPTY } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import { Project } from '../app-config';
import { PageMetaService } from '../shared/service/page-meta.service';
import { LoginService } from '../api/login.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {


  // form: FormGroup;

  // errorMap: {};

  private _destroyed = new Subject<void>();



  // submitted = false;
  // phoneDuplicate = false;
  // emailDuplicate = false;

  // formSubmit = false;

  // yearOfBirthOptionList;

  // sendActivationEmailEnabled: boolean;

  constructor(private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private loadingService: FullScreenLoadingService,
    private memberService: MemberService,
    private title: Title,
    private pageMetaService: PageMetaService,
    private loginService: LoginService) {

    // this.form = fb.group({
    //   phoneNumber: ['', [Validators.required, Validators.maxLength(45)]],
    //   emailAddress: ['', [Validators.required, Validators.email]],
    //   lastName: ['', [Validators.required, Validators.maxLength(45)]],
    //   firstName: ['', [Validators.required, Validators.maxLength(100)]],
    //   password: ['', [Validators.required, Validators.minLength(6), Validators.pattern(/^((.)*([0-9])(.)*)*$/)]],
    //   confirmPassword: ['', Validators.required],
    //   subscription: [false],
    //   title: ['', Validators.required],
    //   monthOfBirth: ['', Validators.required],
    //   yearOfBirth: ['', Validators.required],
    //   gender: ['']
    // }, {
    //   validators: checkIfMatchingPasswords('password', 'confirmPassword')
    // });

    // this.yearOfBirthOptionList = this.rangeForYearOfBirth();

  }

  ngOnInit() {

    const title = this.translate.instant('register') + ' | ' + Project.title;
    this.pageMetaService.generateTags({
      title: title
    });

    // this.form.get('phoneNumber').valueChanges.pipe(debounceTime(500),
    //   switchMap((value, index) => {
    //     if (value) {
    //       return this.memberService.phoneNumberExist(value);
    //     } else {
    //       return EMPTY;
    //     }
    //   }),
    //   takeUntil(this._destroyed)
    // ).subscribe(exist => {
    //   if (exist) {
    //     this.form.get('phoneNumber').setErrors({ 'duplicatePhoneNumber': true });
    //   } else {
    //     this.form.get('phoneNumber').setErrors(null);
    //   }
    // });

    // this.form.get('emailAddress').valueChanges.pipe(
    //   debounceTime(500),
    //   switchMap((value, index) => {
    //     if (value) {
    //       return this.memberService.emailAddressExist(value);
    //     } else {
    //       return EMPTY;
    //     }
    //   }),
    //   takeUntil(this._destroyed)
    // ).subscribe(exist => {
    //   if (exist) {
    //     this.form.get('emailAddress').setErrors({ 'duplicateEmailAddress': true });
    //   } else {
    //     this.form.get('emailAddress').setErrors(null);
    //   }
    // });

    this.loginService.onLoggedIn().subscribe(isLogin => {
      if (isLogin) {
        this.router.navigateByUrl('');
      }
    });
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  // register() {

  //   this.formSubmit = null;

  //   if (this.form.valid) {
  //     this.loadingService.show();
  //     const formData = this.form.value;
  //     delete formData['confirmPassword'];
  //     this.submitted = true;

  //     const api = this.memberService.register(formData);
  //     api.pipe(finalize(() => this.loadingService.hide())).subscribe(result => {
  //       // console.log(result);
  //       // console.log(result.success);
  //       if (result.success) {
  //         if (result.data.requiredActivationByEmail) {
  //           this.router.navigate(['/register-complete'], { state: { emailAddress: formData.emailAddress } });
  //         } else {
  //           const user = {
  //             id: this.form.get('emailAddress').value,
  //             password: this.form.get('confirmPassword').value
  //           };
  //           this.loginService.login(user).pipe(finalize(() => this.loadingService.hide())).subscribe(data => {
  //             console.log('Login result:', data);
  //             // if (data.success) {
  //             this.loadingService.show();
  //             this.router.navigateByUrl('/home', { replaceUrl: true });
  //             // } else {
  //             //   this.invalidLogin = true;
  //             // }
  //           }, error => console.log(error));
  //         }
  //         // alert("success");
  //       } else {
  //         const msgKey = this.errorMap[result.errorCode] || 'error';
  //         this.showError(`${this.translate.instant('error')}: ${this.translate.instant(msgKey)}, ${result.msg}`);
  //       }
  //     });
  //   } else {
  //     this.formSubmit = true;
  //   }
  // }

  // showMessage(message: string) {
  //   this.snackBar.open(message, 'OK', { duration: 3000, verticalPosition: 'top' });
  // }

  // showError(message: string) {
  //   this.snackBar.open(message, 'OK', { verticalPosition: 'top', panelClass: ['background-color-warn'] });
  // }


  // rangeForYearOfBirth(): string[] {
  //   const currentYear = new Date().getUTCFullYear();
  //   return Array.from(new Array(100), (x, i) => (currentYear - i).toString());
  // }
}


// const checkIfMatchingPasswords = (passwordKey: string, passwordConfirmationKey: string): ValidatorFn => {
//   return (group: FormGroup): ValidationErrors => {
//     const passwordInput = group.controls[passwordKey],
//       passwordConfirmationInput = group.controls[passwordConfirmationKey];
//     if (passwordInput.value !== passwordConfirmationInput.value) {
//       passwordConfirmationInput.setErrors({ notEquivalent: true, reason: passwordConfirmationInput });
//     } else {
//       passwordConfirmationInput.setErrors(null);
//     }
//     return null;
//   };
// };

