import { Component, OnInit } from '@angular/core';
import { FullScreenLoadingService } from '../shared/service/full-screen-loading.service';
import { ShoppingCartService } from '../api/shopping-cart.service';
import { switchMap, finalize } from 'rxjs/operators';
import { BasicViewComponent } from '../basic-view-component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EMPTY } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-promotion-code',
  templateUrl: './promotion-code.component.html',
  styleUrls: ['./promotion-code.component.css']
})
export class PromotionCodeComponent extends BasicViewComponent implements OnInit {

  promotionCodeErrorMap = {
    'not_exist': 'promotion.code.error.not.exist',
    'duplicate_code': 'promotion.code.error.duplicate',
    'expired': 'promotion.code.error.expired',
    'unavailable': 'promotion.code.error.unavailable',
    'out_of_quota': 'promotion.code.error.out.of.quota',
    'cart_session_timeout': 'cart.session.timeout'
  };

  constructor(snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private loadingService: FullScreenLoadingService,
    private shoppingCartService: ShoppingCartService) {
      super(snackBar);
    }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const hashedCode = params.code;
      this.addPendingPromotion(hashedCode);
    });
  }

  addPendingPromotion(hashedPromotionCode) {
    console.log('use promotion code:', hashedPromotionCode);
    if (hashedPromotionCode) {
      this.loadingService.show();
      let promotionSlug;
      this.shoppingCartService.addPendingPromotion(hashedPromotionCode)
        .pipe(
          switchMap(async result => {
            if (result.success) {
              this.shoppingCartService.refreshCount();
              this.shoppingCartService.refresh();
              // const msg = await this.translate.get('apply.promotion.code.success').toPromise();
              promotionSlug = result.data?.slug;
              setTimeout(() => {
                this.showMessage(this.translate.instant('apply.promotion.code.success'));
              });
            } else {
              // const msgKey = this.promotionCodeErrorMap[result.errorCode] || 'error';
              // // this.showError(`${this.translate.instant('error')}: ${this.translate.instant(msgKey)}, ${result.msg || ''}`);
              // const warnMsg = await this.translate.get('warning').toPromise();
              // const errorMsg = await this.translate.get(msgKey).toPromise();
              // // this.showError(`${this.translate.instant('warning')}: ${this.translate.instant(msgKey)}`);
              // this.showError(`${warnMsg}: ${errorMsg}`);

              setTimeout(() => {
                this.showRespResultError(result, this.promotionCodeErrorMap);
              });
              if (result.errorCode === 'cart_session_timeout') {
                this.shoppingCartService.refreshCount();
                this.shoppingCartService.refresh();
              }

            }
            return EMPTY;
          }),
          finalize(() => {
            this.loadingService.hide();
            if (promotionSlug) {
              this.router.navigateByUrl(`/promotion/${promotionSlug}`);
            } else {
              this.router.navigateByUrl('/');
            }
          }),
        )
        .subscribe();
    }
  }

}
