import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { VideoGalleryPageService } from '../api/video-gallery-page.service';
import { VideoGalleryDialogComponent } from './video-gallery-dialog/video-gallery-dialog.component';

@Component({
  selector: 'app-video-gallery-page',
  templateUrl: './video-gallery-page.component.html',
  styleUrls: ['./video-gallery-page.component.scss']
})
export class VideoGalleryPageComponent implements OnInit {

  videoGalleryPageItemList = [];
  navigationName;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private videoGalleryPageService: VideoGalleryPageService,
    private dialog: MatDialog, ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.navigationName = params.navigationName;
    });
    
    this.videoGalleryPageService.query().subscribe(data => {
      this.videoGalleryPageItemList = data;
    });
  }

  openVideoDialog(youtubeId) {
    const data = { youtubeId: youtubeId };

    this.dialog.open(VideoGalleryDialogComponent, {
      panelClass: 'video-gallery-dialog',
      data: data
    });
    // maxWidth: '80vw',
    //   minWidth: '50vw',
    //   minHeight: '50vh',
  }

//   .mat-dialog-container {
//     background: transparent;
//     box-shadow: none;
// }

}
