import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { GenericTableApiService } from './generic-table-api.service';

export interface InputField {
  id: number;
  type: string;
  key: string;
  readonly: boolean;
  required: boolean;
  hidden: boolean;
  unique: boolean;
  maxLength: number;
  inputFieldOptionList: InputFieldOption[];
  name: string;
  value: string | string[] | boolean;
  hints: string;
}

export interface InputFieldOption {
  value: string;
  preSelected: boolean;
  name: string;
}

export interface FormInputFieldResult {
  preDefinedFieldInfoList: [InputField];
  customFieldInfoList: [InputField];
}

@Injectable({
  providedIn: 'root'
})

export class FormService extends ApiService {
  endpoint = 'form';

  constructor(protected httpClient: HttpClient) {
    // super(httpClient, 'form');
    super();
  }

  get(id: any): Observable<any> {
    return this.httpClient.get(`${this.endpoint}/${id}`);
  }
  // get(id: any): Observable<FormInputFieldResult> {
  //   return this.httpClient.get<FormInputFieldResult>(`${this.endpoint}/${id}`);
  // }
}
