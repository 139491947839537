import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { RespResult } from './table-api-service';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ShoppingCartService extends ApiService {

    private endPoint = 'shopping-cart';

    private count$ = new BehaviorSubject(0);
    private cart$ = new BehaviorSubject(null);

    private onDeleteGoods$ = new Subject<any>();
    private onDeleteBundlePromotion$ = new Subject<any>();

    constructor(private httpClient: HttpClient) {
        super();
        this.refreshCount();
        this.refresh();
    }

    get(): Observable<any> {
        return this.httpClient.get(`${this.endPoint}`);
    }

    addGoodsWithSeries(goodsSeriesId: number, goodsId: number, qty: number, productId: number, productVariantId: number): Observable<RespResult> {
        const postData = {
            goodsSeriesId: goodsSeriesId,
            goodsId: goodsId,
            qty: qty,

            cartProductList: [{
                productId: productId,
                productVariantId: productVariantId
            }]
        };
        return this.httpClient.post<RespResult>(`${this.endPoint}/goods`, postData);
    }

    addGoods(goodsId: number, qty: number, productList: { productId: number, productVariantId: number }[]): Observable<RespResult> {
        const postData = {
            goodsSeriesId: 0,
            goodsId: goodsId,
            qty: qty,

            cartProductList: productList
        };
        return this.httpClient.post<RespResult>(`${this.endPoint}/goods`, postData);
    }

    addGoodsList(goodsList: { goodsId: number, qty: number, productList: { productId: number, productVariantId: number }[] }[]): any {
        const postData: Array<any> = [];
        for (let i = 0; i < goodsList.length; i++) {
            postData.push({
                goodsSeriesId: 0,
                goodsId: goodsList[i].goodsId,
                qty: goodsList[i].qty,

                cartProductList: goodsList[i].productList
            });
        }
        return this.httpClient.post<RespResult>(`${this.endPoint}/cart-goods-list`, postData);
    }

    addGoodsWithAddOnItem(goodsList: {
        goodsId: number, qty: number, addOn: boolean,
        productList: { productId: number, productVariantId: number }[],
        goodsAddOn: { itemId: number }
    }[]): any {
        const postData: Array<any> = [];
        for (let i = 0; i < goodsList.length; i++) {
            postData.push({
                goodsSeriesId: 0,
                goodsId: goodsList[i].goodsId,
                qty: goodsList[i].qty,
                addOn: goodsList[i].addOn,

                cartProductList: goodsList[i].productList,
                cartGoodsAddOn: {
                    addOnPromotionItemId: goodsList[i].goodsAddOn.itemId
                }
            });
        }
        return this.httpClient.post<RespResult>(`${this.endPoint}/cart-goods/add-on-item`, postData);
    }

    addCartAddOnItem(goodsId: number, qty: number, addOn: boolean,
        productList: { productId: number, productVariantId: number }[], goodsAddOn: { itemId: number }): any {
        const postData = {
            goodsSeriesId: 0,
            goodsId: goodsId,
            qty: qty,
            addOn: addOn,

            cartProductList: productList,
            cartGoodsAddOn: {
                addOnPromotionItemId: goodsAddOn.itemId
            }
        };
        return this.httpClient.post<RespResult>(`${this.endPoint}/add-on-item`, postData);
    }

    addRedemptionGift(itemId: number): Observable<RespResult> {
        const postData = {};
        return this.httpClient.post<RespResult>(`${this.endPoint}/points/redemption/gift/${itemId}`, postData);
    }

    addBundlePriceGoods(goodsList: {
        goodsId: number, qty: number,
        productList: { productId: number, productVariantId: number }[]
    }[],
        promotionId: number): any {
        const postData: Array<any> = [];
        for (let i = 0; i < goodsList.length; i++) {
            postData.push({
                goodsSeriesId: 0,
                goodsId: goodsList[i].goodsId,
                qty: goodsList[i].qty,

                cartProductList: goodsList[i].productList
            });
        }
        return this.httpClient.post<RespResult>(`${this.endPoint}/promotion/${promotionId}/bundle-price-goods`, postData);
    }

    addBundlePriceProductVariant(goodsList: {
        goodsId: number, qty: number,
        productList: { productId: number, productVariantId: number }[]
    }[],
        promotionId: number): any {
        const postData: Array<any> = [];
        for (let i = 0; i < goodsList.length; i++) {
            postData.push({
                goodsSeriesId: 0,
                goodsId: goodsList[i].goodsId,
                qty: goodsList[i].qty,

                cartProductList: goodsList[i].productList
            });
        }

        return this.httpClient.post<RespResult>(`${this.endPoint}/promotion/${promotionId}/bundle-price-product-variant`, postData);
    }

    addBundleGoods(goodsList: {
        goodsId: number, qty: number,
        productList: { productId: number, productVariantId: number }[]
    }[],
        promotionId: number): any {
        const postData: Array<any> = [];
        for (let i = 0; i < goodsList.length; i++) {
            postData.push({
                goodsSeriesId: 0,
                goodsId: goodsList[i].goodsId,
                qty: goodsList[i].qty,

                cartProductList: goodsList[i].productList
            });
        }
        return this.httpClient.post<RespResult>(`${this.endPoint}/promotion/${promotionId}/bundle-goods`, postData);
    }

    updateGoods(cartGoods: any): Observable<RespResult> {
        const id = cartGoods.id;
        return this.httpClient.post<RespResult>(`${this.endPoint}/cart-goods/${id}`,
            { id: id, qty: cartGoods.qty, addOn: cartGoods.addOn, cartGoodsAddOn: cartGoods.cartGoodsAddOn });
    }

    deleteGoods(cartGoodsId: number): Observable<RespResult> {

        return this.httpClient.delete<RespResult>(`${this.endPoint}/cart-goods/${cartGoodsId}`).pipe(tap(_ => {
            this.cart().subscribe(cart => {
                if (cart) {
                    try {
                        const allCartGoodsList: [any] = cart?.subCartList?.map(subCart => subCart.cartGoodsList)
                            .reduce((accum, cartGoods) => accum.concat(cartGoods), []);
                        const deletedCartGoods = allCartGoodsList.find(cartGoods => cartGoods.id === cartGoodsId);
                        if (deletedCartGoods) {
                            this.onDeleteGoods$.next(deletedCartGoods);
                        }
                    } catch (error) {
                        console.error(error);
                    }
                }
            });
        }));
    }

    onDeleteGoods(): Observable<any> {
        return this.onDeleteGoods$;
    }

    updateSubCartDeliveryMethod(subCartId, destinationId, deliveryMethodId): Observable<RespResult> {
        const data = {
            destinationId: destinationId,
            deliveryMethodId: deliveryMethodId
        };
        return this.httpClient.post<RespResult>(`${this.endPoint}/sub-cart/${subCartId}/delivery-method`, data);
    }

    updateBilling(cart: any) {
        return this.httpClient.post<RespResult>(`${this.endPoint}/billing`, cart);
    }

    updateSubCart(subCart: any): Observable<RespResult> {
        const id = subCart.id;
        return this.httpClient.post<RespResult>(`${this.endPoint}/sub-cart/${id}`, subCart);
    }

    getCount(): Observable<number> {
        return this.httpClient.get<number>(`${this.endPoint}/count`);
    }

    refreshCount() {
        this.getCount().subscribe(count => {
            this.count$.next(count);
        });
    }

    count(): Observable<number> {
        return this.count$;
    }

    async refresh() {
        const cart = await this.get().toPromise();
        this.cart$.next(cart);
        return;
    }

    cart(): Observable<any> {
        return this.cart$;
    }

    queryDestination(): Observable<any[]> {
        return this.httpClient.get<any[]>(`${this.endPoint}/destination`);
    }

    queryDestinationGroup(): Observable<any[]> {
        return this.httpClient.get<any[]>(`${this.endPoint}/destination-group`);
    }

    getDeliveryCharge(destinationId, deliveryMethodId): Observable<number> {
        return this.httpClient.get<number>(`${this.endPoint}/destination/${destinationId}/delivery-method/${deliveryMethodId}/charge`);
    }

    getDeliverySurcharge(destinationId, deliveryMethodId): Observable<number> {
        return this.httpClient.get<number>(`${this.endPoint}/destination/${destinationId}/delivery-method/${deliveryMethodId}/surcharge`);
    }

    queryPromotionWithGiftGoods(): Observable<any[]> {
        return this.httpClient.get<any[]>(`${this.endPoint}/gift/goods`);
    }

    queryPromotionWithGiftProductVariant(): Observable<any[]> {
        return this.httpClient.get<any[]>(`${this.endPoint}/gift/product-variant`);
    }

    updateGoodsGift(cartPromotionId: number, cartGoodsId: number, productVariantId: number): Observable<RespResult> {
        return this.httpClient.post<RespResult>(`${this.endPoint}/cart-promotion/${cartPromotionId}/gift/goods/cart-goods/${cartGoodsId}`,
            { productVariantId: productVariantId });
    }

    updateProductVariantGift(cartPromotionId: number, productVariantIdList: number[]): Observable<RespResult> {
        return this.httpClient.post<RespResult>(`${this.endPoint}/cart-promotion/${cartPromotionId}/gift/product-variant`,
            productVariantIdList);
    }

    addPromotion(code: string): Observable<RespResult> {
        return this.httpClient.post<RespResult>(`${this.endPoint}/promotion`, { code: code });
    }

    addPendingPromotion(hashedCode: string): Observable<RespResult> {
        return this.httpClient.post<RespResult>(`${this.endPoint}/pending/promotion`, { code: hashedCode });
    }

    addCoupon(couponId: number): Observable<RespResult> {
        return this.httpClient.post<RespResult>(`${this.endPoint}/coupon/${couponId}`, {});
    }

    deleteCoupon(cartPromotionId): Observable<RespResult> {
        return this.httpClient.delete<RespResult>(`${this.endPoint}/coupon/${cartPromotionId}`);
    }

    deletePendingPromotion(promotionId): Observable<RespResult> {
        return this.httpClient.delete<RespResult>(`${this.endPoint}/pending/promotion/${promotionId}`);
    }

    deleteBundlePromotion(cartPromotionId): Observable<RespResult> {
        return this.httpClient.delete<RespResult>(`${this.endPoint}/bundle/${cartPromotionId}`).pipe(tap(_ => {
            this.cart().subscribe(cart => {
                if (cart) {
                    try {
                        const allCartPromotionList: [any] = cart?.cartPromotionList;
                        const deletedCartPromotion = allCartPromotionList.find(cartPromotion => cartPromotion.id === cartPromotionId);
                        if (deletedCartPromotion) {
                            this.onDeleteBundlePromotion$.next(deletedCartPromotion);
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
            });
        }));
    }

    onDeleteBundlePromotion(): Observable<any> {
        return this.onDeleteBundlePromotion$;
    }

    // updatePromotion(cartPromotionId, disable: boolean): Observable<RespResult> {
    //     return this.httpClient.post<RespResult>(`${this.endPoint}/promotion/${cartPromotionId}`, { disable: disable });
    // }

    updatePointsRedemptionCash(use: number): Observable<RespResult> {
        return this.httpClient.post<RespResult>(`${this.endPoint}/points/redemption/cash`, { use: use });
    }

    deletePointsRedemptionCash(): Observable<RespResult> {
        return this.httpClient.delete<RespResult>(`${this.endPoint}/points/redemption/cash`);
    }

    getOfflinePaymentMethod(): Observable<any[]> {
        return this.httpClient.get<any[]>(`${this.endPoint}/offline-payment-method`);
    }

    queryPaymentMethodForCheckout(): Observable<any> {
        return this.httpClient.get(`${this.endPoint}/payment-method`);
    }
}
