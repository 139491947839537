import { Component, OnInit } from '@angular/core';
import { BlogArticleService } from '../api/blog-article.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { BlogHomeService } from '../api/blog-home.service';
import { CategoryService } from '../api/category.service';
import { Project } from '../app-config';
import { PageMetaService } from '../shared/service/page-meta.service';
import { BlogArticleCategoryService } from '../api/blog-article-category.service';
import * as moment from 'moment';

@Component({
  selector: 'app-blog-article',
  templateUrl: './blog-article.component.html',
  styleUrls: ['./blog-article.component.scss']
})

export class BlogArticleComponent implements OnInit {

  loading = false;

  keyword = '';
  // categoryTree;
  relatedBlogArticleList = [];
  showCategory;
  blogHomeName;
  blogArticle;
  blogArticleId;
  blogArticleCategoryList = [];

  constructor(private blogArticleService: BlogArticleService,
    private blogHomeService: BlogHomeService,
    private categoryService: CategoryService,
    private blogArticleCategoryService: BlogArticleCategoryService,
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private pageMetaService: PageMetaService, ) { }

  ngOnInit() {
    this.blogHomeService.getDetails().subscribe(data => {
      this.showCategory = data.showCategory;
      this.blogHomeName = data.name;
    });

    // this.categoryService.queryTree().subscribe(data => {
    //   this.categoryTree = data;
    // });

    // this.blogArticleService.get(id).subscribe(data => {
    //   this.blogArticle = data;
    // })

    this.blogArticleCategoryService.query().subscribe(data => {
      this.blogArticleCategoryList = data;
    });

    this.route.params.subscribe(params => {
      const paramList = params.idWithSlug.split('-');
      this.blogArticleId = paramList[0];
      const slug = params.idWithSlug.substring(this.blogArticleId.length + 1);

      if (paramList.length < 2) {
        this.redirect404Page();
        return;
      }

      this.blogArticleService.get(this.blogArticleId).subscribe(data => {
        if (data.slug !== slug) {
          this.redirect404Page();
          return;
        }

        this.blogArticle = data;
        this.relatedBlogArticleList = data.relatedBlogArticleList;

        const title = this.blogArticle.name + ' | ' + Project.title;
        this.pageMetaService.generateTags({
          title: title,
          description: this.blogArticle.description,
          slug: slug
        });
      });
    });
  }

  search() {
    if (!!this.keyword) {
      console.log('click search, keyword:', this.keyword);
      this.router.navigate(['search/blog'], { queryParams: { keyword: this.keyword } });
      this.keyword = '';
    }
  }

  redirect404Page() {
    this.router.navigateByUrl('404', { replaceUrl: true, skipLocationChange: true });
  }

  formatPostDate(postDate) {
    const postDateFormat = this.translate.instant('blog.article.post.date.format');
    return moment(postDate).format(postDateFormat);;
  }

}
