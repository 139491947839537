import { Injectable } from '@angular/core';
import { GenericTableApiService } from './generic-table-api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ShopService extends GenericTableApiService {

    constructor(httpClient: HttpClient) {
        super(httpClient, 'shop');
    }

}
