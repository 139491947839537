import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalSettingService {
    lang: string;
    currency: string;
    constructor() {

    }

    get(key: string) {
        return localStorage.getItem(key);
    }
    set(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    getLang() {
        if (!this.lang) {
            this.lang = this.get('lang') || '';
        }
        return this.lang;
    }

    setLang(lang: string) {
        this.set('lang', lang);
        this.lang = lang;
    }

    getLangForApi() {
        return this.getLang().replace('-', '');
    }

    getCurrency() {
        if (!this.currency) {
            this.currency = this.get('currency') || '';
        }
        return this.currency;
    }

    setCurrency(currency: string) {
        this.set('currency', currency);
        this.currency = currency;
    }

}
