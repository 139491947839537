import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-shopping-flow',
  templateUrl: './shopping-flow.component.html',
  styleUrls: ['./shopping-flow.component.scss']
})
export class ShoppingFlowComponent implements OnInit {

  @Input() step = 1;

  constructor() { }

  ngOnInit() {
  }

}
