import { HttpErrorResponse, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError, empty, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { HttpCancelService } from './http-cancel.service';

@Injectable()
export class HttpCancelInterceptor implements HttpInterceptor {
    constructor(private httpCancelService: HttpCancelService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request to add the new header.
        // const authReq = req.clone({ headers: req.headers.set(Cookie.tokenKey, Cookie.getToken()) });
        // catch the error, make specific functions for catching specific errors and you can chain through them with more catch operators

        console.log('insert HttpCancelInterceptor');


        return next.handle(req).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()));
    }

}


