import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { switchMap, finalize, tap, debounceTime, takeUntil } from 'rxjs/operators';
import { EMPTY, Observable, Subject } from 'rxjs';
import { GoodsService } from '../api/goods.service';
import { ShoppingCartService } from '../api/shopping-cart.service';
import { FullScreenLoadingService } from '../shared/service/full-screen-loading.service';
import { LoginService } from '../api/login.service';
import { TranslateService } from '@ngx-translate/core';
import { BasicViewComponent } from '../basic-view-component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CurrencySettingService } from '../shared/service/currency-setting.service';

@Component({
  templateUrl: './shopping-cart-gift-bottom-sheet.component.html',
  styleUrls: ['./shopping-cart-gift-bottom-sheet.component.scss']
})
export class ShoppingCartGiftBottomSheetComponent extends BasicViewComponent implements OnInit {

  select = null;

  goodsGiftPromotion;

  defaultCurrencySymbol;

  errorMap = {
    'not_enough_stock': 'not.enough.stock',
    'out_of_quota': 'out.of.quota',
  };

  constructor(private bottomSheetRef: MatBottomSheetRef<ShoppingCartGiftBottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private router: Router,
    private translate: TranslateService,
    private loadingService: FullScreenLoadingService,
    private shoppingCartService: ShoppingCartService,
    private loginService: LoginService,
    private goodsService: GoodsService,
    snackBar: MatSnackBar,
    private currencySettingService: CurrencySettingService) {
    super(snackBar);
  }

  ngOnInit(): void {
    this.goodsGiftPromotion = this.data.goodsGiftPromotion;
    this.defaultCurrencySymbol = this.currencySettingService.getDefaultSymbol();
  }

  changeGoodsGift(goodsGift: any, option: any, index) {
    // this.goodsGiftList[index].selectedId = option.id;

  }

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  closeBottomSheet(buttonType) {
    console.log('pass data', this.goodsGiftPromotion);
    this.bottomSheetRef.dismiss(this.goodsGiftPromotion);
    if (this.data.type === 'checkout' && buttonType === 'confirm') {
      this.router.navigateByUrl('/checkout');
    }
  }

}
