import { Injectable } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { InformationBottomSheetComponent, InformationBottomSheetModel } from '../component/information-bottom-sheet/information-bottom-sheet.component';

@Injectable({
  providedIn: 'root'
})
export class InformationBottomSheetService {

  private bottomSheetRef; // : MatBottomSheetRef<InformationBottomSheetComponent>

  constructor(private bottomSheet: MatBottomSheet) { }

  open(title: string, content: string) {
    // : MatBottomSheetRef<InformationBottomSheetComponent>
    if (this.bottomSheetRef) {
      this.dismiss();
    }

    const bottomSheetData = new InformationBottomSheetModel(title, content);
    console.log(bottomSheetData);
    this.bottomSheetRef = this.bottomSheet.open(InformationBottomSheetComponent, {
      data: bottomSheetData
    });
    return this.bottomSheetRef;
  }

  dismiss() {
    this.bottomSheetRef.dismiss();
    this.bottomSheetRef = null;
  }
}
