import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GoodsSeriesService } from '../api/goods-series.service';
import { BrandService } from '../api/brand.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-brand-goods-series',
  templateUrl: './brand-goods-series.component.html',
  styleUrls: ['./brand-goods-series.component.scss']
})
export class BrandGoodsSeriesComponent implements OnInit {

  goodsSeriesList$: Observable<any[]>;

  brand: any = {};

  constructor(private router: Router,
    private route: ActivatedRoute,
    private goodsSeriesService: GoodsSeriesService,
    private brandService: BrandService) { }

  ngOnInit() {

    this.route.params.subscribe(params => {
      const brandId = params.id;
      this.goodsSeriesList$ = this.brandService.queryGoodsSeries(brandId, null);
      this.brandService.get(brandId).subscribe(data => this.brand = data);
    });
  }

}
