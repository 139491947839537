import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericTableApiService } from './generic-table-api.service';

@Injectable({
  providedIn: 'root'
})
export class BlogHomeService  extends GenericTableApiService {

  constructor(httpClient: HttpClient) {
      super(httpClient, 'blog-home');
  }

  getDetails(): Observable<any> {
    return this.httpClient.get(`${this.endpoint}`);
  }
}
