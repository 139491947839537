import { Directive, Input, ViewContainerRef, TemplateRef, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CurrencySettingService } from '../service/currency-setting.service';

@Directive({
  selector: '[appReferenceCurrencyContainer]'
})
export class ReferenceCurrencyContainerDirective implements OnInit, OnDestroy {

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(private vcRef: ViewContainerRef, private templateRef: TemplateRef<any>, private currencySettingService: CurrencySettingService) { }

  ngOnInit() {
    this.currencySettingService.current$.pipe(takeUntil(this._onDestroy)).subscribe(currentCurrency => {
      const defaultCurrency = this.currencySettingService.getDefault();
      // const currentCurrency = this.currencySettingService.getCurrent();
      const showReference: boolean = defaultCurrency.code !== currentCurrency;
      if (showReference) {
        this.showView();
      } else {
        this.vcRef.clear();
      }
    });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  showView() {
    this.vcRef.clear();
    this.vcRef.createEmbeddedView(this.templateRef);
  }

}
