import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Currency, CurrencySettingService } from '../service/currency-setting.service';

@Pipe({
  name: 'referenceCurrency',
  pure: true,
})
export class ReferenceCurrencyPipe implements PipeTransform {

  currencyPipe = new CurrencyPipe('en');

  current: Currency;
  constructor(private currencySettingService: CurrencySettingService) {
    this.current = this.currencySettingService.getCurrent();
  }

  transform(value: number | string,
    display = 'symbol',
    local = ''): any {
    const current = this.currencySettingService.getCurrent();

    const code = current.symbolLeft;
    const digites = current.digits;
    const ratio = current.ratio;
    let price: number;
    if (typeof value == 'string') {
      price = parseFloat(value);
    } else {
      price = value;
    }

    const referencePrice = (price * ratio).toFixed(2);

    return this.currencyPipe.transform(referencePrice, code, display, digites, local);
  }

}
