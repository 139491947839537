import { HttpErrorResponse, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError, Subject, EMPTY } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { HttpCancelService } from './http-cancel.service';


@Injectable()
export class LoginInterceptor implements HttpInterceptor {

    constructor(private router: Router, private httpCancelService: HttpCancelService) { }

    handle401Error(err: HttpErrorResponse): Observable<any> {
        console.log('handle401Error:', err);

        if (err instanceof HttpErrorResponse && err.status === 401) {
            console.log('redirect to login page');
            // this.cancelPendingRequest$.complete();
            // this.router.navigateByUrl('login', { replaceUrl: true });
            this.router.navigateByUrl('login', { replaceUrl: true, skipLocationChange: true });
            // setTimeout(() => {

            this.httpCancelService.cancelPendingRequests();
            // }, 0);

            // for avoid rxjs EmptyError: no elements in sequence, wait for angular fix
            // return of(new HttpResponse({ body: err.message }));

            return EMPTY;
        }
        return throwError(err);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request to add the new header.
        // const authReq = req.clone({ headers: req.headers.set(Cookie.tokenKey, Cookie.getToken()) });
        // catch the error, make specific functions for catching specific errors and you can chain through them with more catch operators

        console.log('insert LoginInterceptor');

        return next.handle(req).pipe(
            takeUntil(this.httpCancelService.onCancelPendingRequests()),
            // catchError((err) => this.handle401Error(err)),
            catchError((err) => this.handleError(err)),
        );

        // here use an arrow function,
        //  otherwise you may get "Cannot read property 'navigate' of undefined" on angular 4.4.2/net core 2/webpack 2.70

    }

    private handle500Error (err: HttpErrorResponse) {
        this.router.navigate(['/500']);
    }

    private handle404Error (err: HttpErrorResponse) {
        this.router.navigate(['/404']);
    }

    private handleError(err): Observable<any> {
        if (err.status === 500) {
            this.handle500Error(err);
        } else if (err.status === 404) {
            this.handle404Error(err);
        } else {
            this.handle401Error(err);
        }
        return throwError(err);
    }

}
