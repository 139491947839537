import { ApiService } from './api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, EMPTY, of, BehaviorSubject, ReplaySubject } from 'rxjs';
import { tap, map, distinctUntilChanged, shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    grantedPermissionList = [];

    private isLoggedIn$ = new BehaviorSubject(false);
    private logOut$ = new BehaviorSubject(false);
    public afterCallApi = new ReplaySubject(1);


    constructor(private httpClient: HttpClient) {

    }

    getStatus(): Observable<any> {
        return this.httpClient.get('/login/status').pipe(tap(data => {
            this.setLoggedIn(data.isLoggedIn);
            this.afterCallApi.next(true);
            this.afterCallApi.complete();
        }));
    }

    refreshStatus() {
        this.getStatus().subscribe();
    }

    queryPermission(): Observable<any[]> {
        return this.httpClient.get<any[]>('/login/permission');
    }

    cacheGrantedPermission(): Observable<any[]> {
        return this.queryPermission().pipe(tap(data => this.grantedPermissionList = data));
    }

    getIp(): Observable<any> {
        return this.httpClient.get('/login/ip');
    }

    login(adminUser: { id: string, password: string }): Observable<any> {
        if (adminUser.id !== '' && adminUser.password !== '') {
            return this.httpClient.post('/login', adminUser).pipe(tap(result => {
                this.setLoggedIn(result.success);
            }));
        }
        return EMPTY;
    }

    setLoggedIn(isLoggedIn: boolean) {
        console.log('isLoggedIn', isLoggedIn);

        this.isLoggedIn$.next(isLoggedIn);
    }

    onLoggedIn() {
        // refresh login status after each routing, trigger subscription when login staus change only
        return this.isLoggedIn$.pipe(distinctUntilChanged(), shareReplay(1));
    }

    hasPermission(permission: number[] | number): boolean {

        let checkingList;
        if (!Array.isArray(permission)) {
            checkingList = [permission];
        } else {
            checkingList = permission;
        }
        console.log('granted permission list:', this.grantedPermissionList);
        console.log(`permission:`, checkingList);


        return checkingList.map(item => item > 0 && this.grantedPermissionList.includes(item)).every(pass => pass);
    }

    loginViaFacebook(accessToken: string): Observable<any> {
        if (accessToken) {
            return this.httpClient.post('/login/facebook', { 'accessToken': accessToken })
                .pipe(tap(result => {
                    this.setLoggedIn(result.success);
                }));
        }
        return EMPTY;
    }

    loginViaGoogle(idToken: string): Observable<any> {
        if (idToken) {
            return this.httpClient.post('/login/google', { 'idToken': idToken })
                .pipe(tap(result => {
                    this.setLoggedIn(result.success);
                }));
        }
        return EMPTY;
    }

    onLogOut() {
        return this.logOut$.pipe(distinctUntilChanged(), shareReplay(1));
    }
}
