import { Component, Injectable } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Injectable({
  providedIn: 'root'
})
export class FullScreenLoadingService {

  private spinnerRef = this.createSpinner();

  constructor(private overlay: Overlay) { }

  private createSpinner(): OverlayRef {
    return this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically()
    });
  }

  public show() {
    setTimeout(() => {
      this.spinnerRef.hasAttached() || this.spinnerRef.attach(new ComponentPortal(ProgressSpinnerComponent));
    });
  }

  public hide() {
    setTimeout(() => {
      this.spinnerRef.hasAttached() && this.spinnerRef.detach();
    });
  }

  public hasAttached() {
    return this.spinnerRef.hasAttached();
  }
}

@Component({
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
  ],
  selector: 'app-progress-spinner',
  template: '<mat-spinner></mat-spinner>'
})
export class ProgressSpinnerComponent {

}

