import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { CategoryService } from '../api/category.service';
import { Router, ActivatedRoute } from '@angular/router';
import { combineLatest, concat, Observable, of, scheduled, Subject, timer } from 'rxjs';
import { catchError, concatAll, switchMap, takeUntil, tap, toArray } from 'rxjs/operators';
import { BrandService } from '../api/brand.service';
import { BlogArticleService } from '../api/blog-article.service';
import { GoodsSeriesService } from '../api/goods-series.service';
import { QueryBean, Pagination } from '../api/table-api-service';
import { AdvertisementService } from '../api/advertisement.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GoodsDialogComponent } from '../goods-dialog/goods-dialog.component';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  // goodsSeriesList$: Observable<any[]>;
  // brandList$: Observable<any[]>;
  // blogArticleList$: Observable<any[]>;
  advertisementList = [];
  // advertisement20;
  advertisement30;

  advertisementShowcaseList = [];

  showHyperLinkType = ['goods', 'goods_in_category', 'goods_in_brand', 'goods_in_tag', 'top_sales', 'new_goods'];

  loading = false;

  // starColor: StarRatingColor = StarRatingColor.accent;
  // starColorP: StarRatingColor = StarRatingColor.primary;
  // starColorW: StarRatingColor = StarRatingColor.warn;

  constructor(private router: Router,
    private blogArticleService: BlogArticleService,
    private advertisementService: AdvertisementService,
    public dialog: MatDialog) {

  }

  ngOnInit() {
    // const pagination: Pagination = new Pagination();
    // pagination.limit = 5;
    // this.goodsSeriesList$ = this.goodsSeriesService.query(pagination).pipe(
    //   tap((data) => {
    //     console.log('home goods series', data);

    //   })
    // );
    // this.goodsSeriesList$ = this.categoryService.queryGoodsSeries(1)
    //   .pipe(
    //     tap((data) => {
    //       console.log('category goods series', data);

    //     })
    //   );

    // this.brandList$ = this.brandService.query();

    // this.blogArticleList$ = this.blogArticleService.query();

    this.loading = true;
    this.advertisementList = [];

    this.advertisementService.queryById([30]).subscribe(data => {
      this.advertisementList.push(...data);
      this.advertisement30 = this.getAdvertisement(30);
    });
  }

  ngAfterViewInit() {

    // Lazy query adv one by one for imporve performance
    const advIdPairList = [[40, 41], [42, 43], [44, 45], [46, 47], [48, 49, 50]];
    const advQueryList = advIdPairList.map(advIdList => {
      return this.advertisementService.queryById(advIdList)
        .pipe(
          tap(data => {
            this.advertisementList.push(...data);
            this.advertisementShowcaseList.push(...data);
            return;
          }),
          catchError((error, obs) => obs)
        );
    });
    concat(...advQueryList)
      .pipe(toArray())
      .subscribe(() => {
        console.log('finish query adv');
        this.loading = false;
      });

  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getAdvertisement(id: number) {
    return this.advertisementList.find(advertisement => advertisement.id === id);
  }

  showHyperLink(type) {
    return this.showHyperLinkType.includes(type);
  }

}
