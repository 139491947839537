import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FileDownloadPageService } from '../api/file-download-page.service';
import { FileService } from '../api/file.service';
import { PageMetaService } from '../shared/service/page-meta.service';

@Component({
  selector: 'app-file-download-page',
  templateUrl: './file-download-page.component.html',
  styleUrls: ['./file-download-page.component.scss']
})
export class FileDownloadPageComponent implements OnInit {

  loading = false;
  
  id: number;
  fileDownloadPageId;
  slug;

  fileDownloadPage; // : any = {}
  navigationName;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private fileDownloadPageService: FileDownloadPageService,
    private fileService: FileService,
    private pageMetaService: PageMetaService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService, ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const paramList = params.idWithSlug.split('-');
      this.fileDownloadPageId = paramList[0];
      this.slug = params.idWithSlug.substring(this.fileDownloadPageId.length + 1);

      if (paramList.length < 2) {
        this.redirect404Page();
        return;
      }

      this.fileDownloadPageService.get(this.fileDownloadPageId).subscribe(data => {
        this.fileDownloadPage = data;
      });
    });

    this.route.queryParams.subscribe(params => {
      this.navigationName = params.navigationName;
    });
  }

  downloadFile(fId: number, fileName: string) { // itemFg: FormGroup
    // const itemFgValue = itemFg.value;
    // const fId = itemFgValue.id;
    // const fileName = itemFgValue.name + '.' + itemFgValue.fileNameExtension;

    this.fileService.downloadFile(fId).subscribe(blob => {

      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }

  redirect404Page() {
    this.router.navigateByUrl('404', { replaceUrl: true, skipLocationChange: true });
  }

}
