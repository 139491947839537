import { Component, OnInit, NgModule, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { BlogArticleCategoryService } from '../api/blog-article-category.service';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { BlogArticleService } from '../api/blog-article.service';
import { BlogHomeService } from '../api/blog-home.service';
import { Project } from '../app-config';
import { PageMetaService } from '../shared/service/page-meta.service';
import * as moment from 'moment';

@Component({
  selector: 'app-blog-category',
  templateUrl: './blog-category.component.html',
  styleUrls: ['./blog-category.component.scss']
})

export class BlogCategoryComponent implements OnInit {

  page = 1;
  limit = 5;
  loading = false;
  noMore = false;

  showCategory;
  showPopularPosts;
  name;
  content;
  popularPostsList = [];
  blogArticleList = [];
  // categoryTree;
  blogArticleCategoryList = [];
  keyword = '';
  id;
  blogArticleCategory;
  postDateFormat;

  constructor(private blogHomeService: BlogHomeService,
    private blogArticleCategoryService: BlogArticleCategoryService,
    public translate: TranslateService,
    private pageMetaService: PageMetaService,
    private route: ActivatedRoute,
    private router: Router, ) { }

  ngOnInit() {
    this.blogHomeService.getDetails().subscribe(data => {
      this.showCategory = data.showCategory;
      this.showPopularPosts = data.showPopularPosts;
      this.popularPostsList = data.popularPostsList;
    });

    this.blogArticleCategoryService.query().subscribe(data => {
      this.blogArticleCategoryList = data;
    });

    this.route.params.subscribe(params => {
      const paramList = params.idWithSlug.split('-');
      this.id = paramList[0];
      const slug = params.idWithSlug.substring(this.id.length + 1);

      if (paramList.length < 2) {
        this.redirect404Page();
        return;
      }

      this.blogArticleCategoryService.get(this.id).subscribe(data => {
        if (data.slug !== slug) {
          this.redirect404Page();
          return;
        }
        this.blogArticleCategory = data;

        const title = this.blogArticleCategory.name + ' | ' + Project.title;
        this.pageMetaService.generateTags({
          title: title,
          description: this.blogArticleCategory.description,
          slug: slug
        });

        this.name = data.name;
        this.content = data.content;
      });
    });

    this.postDateFormat = this.translate.instant('blog.article.post.date.format');

    this.queryBlogArticleList();
  }

  loadMore() {
    this.page++;
    this.queryBlogArticleList();
  }

  queryBlogArticleList() {
    this.loading = true;
    this.blogArticleCategoryService.queryBlogArticleList(this.id, this.page, this.limit)
      .pipe(finalize(() => this.loading = false))
      .subscribe(data => {
        if (data && data.length) {
          this.blogArticleList.push(...data);
        }

        if (!data || data.length < this.limit) {
          this.noMore = true;
        }
      });
  }

  search() {
    if (!!this.keyword) {
      console.log('click search, keyword:', this.keyword);
      this.router.navigate(['search/blog'], { queryParams: { keyword: this.keyword } });
      this.keyword = '';
    }
  }

  redirect404Page() {
    this.router.navigateByUrl('404', { replaceUrl: true, skipLocationChange: true });
  }

  formatPostDate(postDate) {
    const postDateFormat = this.translate.instant('blog.article.post.date.format');
    return moment(postDate).format(postDateFormat);;
  }
}
