import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SearchField, QueryBean, RespResult, TableApiService } from './table-api-service';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable()
export class GenericTableApiService extends ApiService implements TableApiService {

    constructor(protected httpClient: HttpClient, protected endpoint: string) {
        super();
    }

    // getHeader(): Observable<TableColumn[]> {
    //     return this.httpClient.get<TableColumn[]>(`${this.endpoint}/header`);
    // }

    getSearchField(): Observable<SearchField[]> {
        return this.httpClient.get<SearchField[]>(`${this.endpoint}/search-field`);
    }

    getBody(queryBean: QueryBean): Observable<any[]> {
        const params = this.tsObjToJsonObj(queryBean);
        return this.httpClient.get<any[]>(`${this.endpoint}/body`, {
            params: params
        });
    }

    getBodyCount(queryBean: QueryBean): Observable<number> {
        const params = this.tsObjToJsonObj(queryBean);
        return this.httpClient.get<any>(`${this.endpoint}/body/count`, {
            params: params
        }).pipe(map(respData => respData.count));
    }

    query(queryBean?: QueryBean): Observable<any[]> {
        let params = {};
        if (queryBean) {
            params = this.tsObjToJsonObj(queryBean);
        }
        return this.httpClient.get<any[]>(`${this.endpoint}`, {
            params: params
        });
    }

    add(data: any): Observable<RespResult> {
        const postBody = this.tsObjToJsonObj(data);
        return this.httpClient.post<RespResult>(`${this.endpoint}`, postBody);
    }
    get(id: any): Observable<any> {
        return this.httpClient.get(`${this.endpoint}/${id}`);
    }
    update(id: any, data: any): Observable<RespResult> {
        const postBody = this.tsObjToJsonObj(data);
        return this.httpClient.post<RespResult>(`${this.endpoint}/${id}`, postBody);
    }
    delete(id: any): Observable<RespResult> {
        return this.httpClient.delete<RespResult>(`${this.endpoint}/${id}`);
    }
    exists(id: any): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.endpoint}/${id}/exists`);
    }

}
