import { Injectable } from '@angular/core';
import { SystemSettingService } from 'src/app/api/system-setting.service';
import { Project } from 'src/app/app-config';

@Injectable({
  providedIn: 'root'
})
export class FaviconImageService {
  // favIconList = document.querySelectorAll("link[rel='icon']") as HTMLCollectionOf<HTMLLinkElement>;
  // favPng: HTMLLinkElement = document.querySelector("link[rel='shortcut icon']");
  // favXIcon: HTMLLinkElement = document.querySelector("link[rel='shortcut icon']");

  constructor(private systemSettingService: SystemSettingService) { }

  init() {
    const favShortCutIcon: HTMLLinkElement = document.querySelector("link[rel='shortcut icon']");
    this.systemSettingService.getStoreBasicInfo().subscribe(data => {
      const url = data.websiteIconImageUrl;
      if (url) {
        // this.favIconList.forEach(favIcon => {
        //   favIcon.href = url;
        // });
        // this.favPng.href = url;
        // this.favXIcon.href = url;
        favShortCutIcon.href = url;
      }
    });
  }
}
