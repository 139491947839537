import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { timer } from 'rxjs';
import { AdvertisementService } from '../api/advertisement.service';

@Component({
  selector: 'app-text-swiper',
  templateUrl: './text-swiper.component.html',
  styleUrls: ['./text-swiper.component.scss']
})
export class TextSwiperComponent implements OnInit {

  isDismiss = false;
  @Input() textList: any;

  constructor() { }

  ngOnInit(): void {

    // setInterval(() => {
    //   this.autoplay = true;
    //   console.log('autoplay', this.autoplay);
    // }, 5000);
  }

  dismiss() {
    this.isDismiss = true;
  }

}
