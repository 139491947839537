import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, filter, skip, switchMap, take, tap } from 'rxjs/operators';
import { AppInjector } from 'src/app/shared/service/app-injector';
import { LoginService } from '../login.service';
import { ShoppingCartService } from '../shopping-cart.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request to add the new header.
        // const authReq = req.clone({ headers: req.headers.set(Cookie.tokenKey, Cookie.getToken()) });
        // catch the error, make specific functions for catching specific errors and you can chain through them with more catch operators

        console.log('TokenInterceptor request');

        const url = req.url;
        console.log(`url: ${url}`);

        if (url.endsWith('/login/status') || url.endsWith('/login/status/')) {
            return next.handle(req);
        }

        const loginService = AppInjector.get(LoginService);
        return loginService.afterCallApi
            .pipe(
                tap(() => {
                    console.log('---------------TokenInterceptor afterCallApi---------');
                }),
                switchMap(() => {
                    return next.handle(req);
                }),
            );
    }

}
