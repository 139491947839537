import { CheckboxRequiredValidator, FormBuilder, UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { InputField, InputFieldOption } from 'src/app/api/form.service';
import { ReactiveFormUtil } from './reactive-form-util';

export class CustomFieldUtil {

  public static assignValidators(fc: UntypedFormControl, inputField: InputField, basicValidators: ValidatorFn[] = []) {
    const validatorList: ValidatorFn[] = [...basicValidators];
    if (inputField.required) {
      if (inputField.type === 'single_choice') {
        validatorList.push(Validators.requiredTrue);
      } else {
        validatorList.push(Validators.required);
      }
    }
    const maxLength = inputField.maxLength;
    if (maxLength && maxLength > 0) {
      validatorList.push(Validators.maxLength(maxLength));
    }

    fc.setValidators(validatorList);
    fc.updateValueAndValidity();
  }

  public static buildCustomField(inputField: InputField): UntypedFormControl {

    let value: string | string[] = null;
    if (inputField.type === 'multiple_choice') {
      const inputFieldOptionList = inputField.inputFieldOptionList ?? [];
      const preSelectedInputFieldOptionList = inputFieldOptionList.filter(inputFieldOption => inputFieldOption.preSelected);

      value = preSelectedInputFieldOptionList.map(inputFieldOption => inputFieldOption.value);
    } else {

      const inputFieldOptionList: InputFieldOption[] = inputField.inputFieldOptionList ?? [];
      const preSelectedInputFieldOption = inputFieldOptionList.find(inputFieldOption => inputFieldOption.preSelected);
      value = preSelectedInputFieldOption?.value ?? '';

    }

    const fc = new UntypedFormControl(value, []);
    CustomFieldUtil.assignValidators(fc, inputField);
    return fc;
  }
}
