import { Observable } from 'rxjs';
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { InfoPageService } from '../api/info-page.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {

  loading;

  infoPage: { name, content };

  constructor(private router: Router) { }

  ngOnInit() {
  }

}
