import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { GoodsService } from '../api/goods.service';
import { tap, takeUntil, filter } from 'rxjs/operators';
import { deepMerge } from '../shared/util/util';
import { ShoppingCartService } from '../api/shopping-cart.service';

@Component({
  selector: 'app-bundle-promotion',
  templateUrl: './bundle-promotion.component.html',
  styleUrls: ['./bundle-promotion.component.css']
})
export class BundlePromotionComponent implements OnInit, OnDestroy {

  @Input() type = 'bundlePriceGoods';
  @Input() goods: any = {};

  promotionList = [];

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(private goodsService: GoodsService,
    private shoppingCartService: ShoppingCartService) { }

  ngOnInit(): void {
    this.init();

    this.shoppingCartService.onDeleteBundlePromotion()
    .pipe(
      filter(bundlePromotion => this.promotionList.some(promotion => promotion.promotionId === bundlePromotion.promotionId)),
      takeUntil(this._onDestroy)
    ).subscribe(_ => this.refresh());
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  refresh() {
    if (this.type === 'bundlePriceGoods') {
      this.goodsService.queryBundlePriceGoodsPromotion(this.goods.id).subscribe(bundlePriceGoodsPromotionList => {
        // const newPromotionList = this.promotionList.filter(promotion => {
        //   return bundlePriceGoodsPromotionList.some(bundlePromotion => bundlePromotion.id === promotion.id);
        // });
        // this.promotionList = deepMerge(newPromotionList || [], bundlePriceGoodsPromotionList);
        this.promotionList = bundlePriceGoodsPromotionList;
      });
    }
    if (this.type === 'bundlePriceProductVariant') {
      this.goodsService.queryBundlePriceProductVariantPromotion(this.goods.id).subscribe(bundlePriceProductVariantPromotionList => {
        // const newPromotionList = this.promotionList.filter(promotion => {
        //   return bundlePriceProductVariantPromotionList.some(bundlePromotion => bundlePromotion.id === promotion.id);
        // });
        // this.promotionList = deepMerge(newPromotionList || [], bundlePriceProductVariantPromotionList);
        this.promotionList = bundlePriceProductVariantPromotionList;
      });
    }
    if (this.type === 'bundleGoods') {
      this.goodsService.queryBundleGoodsPromotion(this.goods.id).subscribe(bundleGoodsPromotionList => {
        this.promotionList = bundleGoodsPromotionList;
      });
    }
  }

  init() {
    this.shoppingCartService.cart().pipe(
      takeUntil(this._onDestroy)
    ).subscribe(_ => this.refresh());
  }

}
