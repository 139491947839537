import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil, startWith, map, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss']
})
export class SelectSearchComponent implements OnInit, OnDestroy {

  private _onDestroy$ = new Subject<void>();

  private _dataList: any[] = [];
  /** source data list */
  @Input()
  get dataList(): any[] {
    return this._dataList;
  }
  set dataList(dataList: any[]) {
    this._dataList = dataList || [];
    if (this._filterCtrl.value) {
      this._filterCtrl.updateValueAndValidity();
    } else {
      this.filtered.next(this._dataList);
    }
  }

  @Input() comparator: (searchKeyword: any, item: any, index?: any, array?: any[]) => boolean = this.defaultComparator;

  /** list of data filtered by search keyword */
  @Output() filtered: EventEmitter<any[]> = new EventEmitter<any[]>();


  /** control for the MatSelect filter keyword */
  public _filterCtrl: UntypedFormControl = new UntypedFormControl();

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {

    // Fix ExpressionChangedAfterItHasBeenCheckedError, tabindex 0 to -1, because of ngx-mat-select-search set option disable
    this.cdr.detectChanges();

    // listen for search field value changes
    this._filterCtrl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(300),
        map(keyword => this.filterList(keyword)),
        takeUntil(this._onDestroy$)
      )
      .subscribe((filteredList) => this.filtered.next(filteredList));
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  filterList(keyword): any[] {
    const searchKeyword = keyword ? keyword.toLowerCase() : '';
    return this._dataList.filter(item => this.comparator(searchKeyword, item));
  }

  defaultComparator(searchKeyword, item) {
    return item.name.toLowerCase().includes(searchKeyword);
  }

}
