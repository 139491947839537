import { Component, OnInit, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { finalize, min, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SwiperOptions } from 'swiper';
import { CategoryService } from '../api/category.service';
import { GoodsService } from '../api/goods.service';
import { LoginService } from '../api/login.service';
import { MemberWishService } from '../api/member-wish-service';
import { ShoppingCartService } from '../api/shopping-cart.service';
import { VisitLogService } from '../api/visit-log.service';
import { WarehouseService } from '../api/warehouse.service';
import { BasicViewComponent } from '../basic-view-component';
import { GoodsReviewDialogComponent } from '../goods-review-dialog/goods-review-dialog.component';
import { FullScreenLoadingService } from '../shared/service/full-screen-loading.service';
import { combinationsOf2dArray, deepMerge } from '../shared/util/util';
import { SnackBarComponent } from '../snack-bar/snack-bar.component';
import { AddCartSnackBarComponent } from 'src/app/add-cart-snack-bar/add-cart-snack-bar.component';
import { SystemSettingService } from '../api/system-setting.service';
import { InformationDialogService } from '../shared/service/information-dialog.service';

@Component({
  selector: 'app-goods-dialog',
  templateUrl: './goods-dialog.component.html',
  styleUrls: ['./goods-dialog.component.scss']
})
export class GoodsDialogComponent extends BasicViewComponent implements OnInit, OnDestroy {

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  errorMap = { 'not_enough_stock': 'Not enough Stock' };

  loading = false;

  goods: any = {};
  warehouseList;

  isLogin;

  showRestockNotification = false;

  enableProductsImageZoom;

  supportedPaymentMethodList;

  defaultProductVariantId;

  swiperConfig: SwiperOptions = {
    observer: true,
    initialSlide: 0,
    slidesPerView: 1,
    // centeredSlides: true,
    zoom: false,
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
      loadOnTransitionStart: true,
    },
    watchSlidesVisibility: true,
    // loop: true,
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev',
    //   hideOnClick: true
    // },
    mousewheel: {

    },
    resizeObserver: true,
  };


  relatedPromotionList;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<GoodsDialogComponent>,
    snackBar: MatSnackBar,
    private translate: TranslateService,
    private loadingService: FullScreenLoadingService,
    private dialog: MatDialog,
    private router: Router,
    private goodsService: GoodsService,
    private categoryService: CategoryService,
    private warehouseService: WarehouseService,
    private shoppingCartService: ShoppingCartService,
    private memberWishService: MemberWishService,
    private visitLogService: VisitLogService,
    private loginService: LoginService,
    public systemSettingService: SystemSettingService,
    private informationDialogService: InformationDialogService
  ) {

    super(snackBar);

  }


  ngOnInit() {

    const id = this.data.id;
    this.loading = true;

    // Visit log
    try {
      this.visitLogService.log('goods', `${id}`);
    } catch (error) {
      console.error(error);
    }

    this.getGoods(id).pipe(
      tap(goods => {
        this.goods = goods;
        // const imageList = goods.imageList;
        const imageList = goods.imageSetList;
        // Select fist option of attribute in product by default
        goods.productList.forEach(product => {
          // product.attributeList.forEach(attribute => {
          //   // attribute.optionList[0].selected = true;
          //   // this.checkAttibuteOptionOutOfStock(product, attribute);
          //   const firstHaveStockOption = attribute.optionList.find(option => !option.outOfStock);
          //   if (firstHaveStockOption) {
          //     firstHaveStockOption.selected = true;
          //   } else {
          //     attribute.optionList[0].selected = true;
          //   }
          // });
          // const productVariantList = product.productVariantList;
          // productVariantList.forEach(productVariant => {
          //   let pvImageList = productVariant.imageSetList;
          //   pvImageList = pvImageList.filter(pvImg => imageList.every(img => img.thumbnail.url !== pvImg.thumbnail.url));
          //   imageList.push(...pvImageList);
          // });
          // this.checkAttributeOptionaNoProductVariant(product);
          // this.checkAttributeOptionOutOfStock(product);
          // this.onAttributeChanged(product);
          const productVariantList = product.productVariantList;


          productVariantList.forEach(productVariant => {
            let pvImageList = productVariant.imageSetList;
            pvImageList = pvImageList?.filter(pvImg => imageList.every(img => img.thumbnail.url !== pvImg.thumbnail.url));
            imageList.push(...pvImageList);
          });

          let defaultAttributeOptionIdList = [];
          if (this.defaultProductVariantId) {
            const defaultProductVariant = productVariantList.find(pv => pv.id === this.defaultProductVariantId);
            defaultAttributeOptionIdList = defaultProductVariant.attributeOptionIdList;
          }

          if (defaultAttributeOptionIdList?.length === 0) {
            const firstHaveStockProductVariant = this.findFirstHaveStockProductVariant(product);
            if (firstHaveStockProductVariant) {
              defaultAttributeOptionIdList = firstHaveStockProductVariant.attributeOptionIdList;
            }
          }

          const attributeList = product.attributeList;

          attributeList.forEach(attribute => {
            if (defaultAttributeOptionIdList && defaultAttributeOptionIdList.length) {
              attribute.optionList.forEach(option => {
                option.selected = defaultAttributeOptionIdList.includes(option.id);
              });
            } else {
              // const firstHaveStockOption = attribute.optionList.find(option => !option.outOfStock);
              // if (firstHaveStockOption) {
              //   firstHaveStockOption.selected = true;
              // } else {
              attribute.optionList[0].selected = true;
              // }
            }
          });

          this.checkAttributeOptionaNoProductVariant(product);
          this.checkAttributeOptionOutOfStock(product);
          this.onAttributeChanged(product);
        });
      }),
      finalize(() => this.loading = false)
    ).subscribe();

    this.warehouseService.query().subscribe(data => {
      this.warehouseList = data;
    });

    this.router.events
      .pipe(takeUntil(this._onDestroy))
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.close('', false);
        }
      });

    this.loginService.onLoggedIn()
      .subscribe(isLogin => {
        this.isLogin = isLogin;
      });

    this.systemSettingService.getRestockNotificationSetting().subscribe(data => {
      this.showRestockNotification = data.showRestockNotification;
    });

    this.systemSettingService.getImageSetting().subscribe(data => {
      this.enableProductsImageZoom = data.enableProductsImageZoom;
    });

    this.goodsService.querySupportPaymentMethod(id).subscribe(data => {
      this.supportedPaymentMethodList = data;
    });

    this.goodsService.queryRelatedPromotion(id)
      .subscribe(data => {
        this.relatedPromotionList = data;
      });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  close(goodsName, success) {
    this.dialogRef.close({ data: { 'goodsName': goodsName, 'success': success } });
  }



  getGoods(goodsId): Observable<any> {
    return this.goodsService.get(goodsId);
  }

  // refreshGoods() {
  //   const goodsId = this.data.id;
  //   this.getGoods(goodsId).subscribe(goods => {
  //     this.goods = deepMerge(this.goods, goods);
  //     this.goods.productList.forEach(product => this.onAttributeChanged(product));
  //   });
  // }

  minusQty(goods) {
    if (this.canMinusQty(goods)) {
      goods.qty--;
    }
  }

  plusQty(goods) {
    if (this.canPlusQty(goods)) {
      goods.qty++;
    }
  }

  canMinusQty(goods) {
    return goods.qty > 1 && (!goods.qtyLimitPerOrder || (goods.qty > goods.minQty));
  }

  canPlusQty(goods) {
    return (!goods.qtyLimitPerOrder || (goods.qty < goods.maxQty)) && !goods.lastStock;
  }

  onGoodsQtyInputBlur(goods) {
    if (goods.qty === null) {
      goods.qty = 1;
    }
    // console.log('goods.qty', goods.qty);
    // console.log('goods.qtyLimitPerOrder', goods.qtyLimitPerOrder);
    // console.log('goods.minQtyPerOrder', goods.minQtyPerOrder);
    // console.log('goods.maxQtyPerOrder', goods.maxQtyPerOrder);
    // console.log('goods.qtyInCart', goods.qtyInCart);
    // console.log('goods.minQty', goods.minQty);
    // console.log('goods.maxQty', goods.maxQty);

    if (goods.qtyLimitPerOrder) {
      if (goods.qty < goods.minQty) {
        goods.qty = goods.minQtyr;
      } else if (goods.qty > goods.maxQty) {
        goods.qty = goods.maxQty;
      }
    }
  }

  findStock(warehouseId: number, stockList: [any]) {
    return stockList.find(d => d.warehouseId === warehouseId);
  }

  // isOutOfStock(stockList: [any]): boolean {
  //   return stockList.every(d => d.stock <= 0);
  // }

  addGoods(goToCart = false) {
    this.loadingService.show();
    const productList = this.goods.productList.map(product => {
      const productVariant = this.findSelectedProductVariant(product);
      return { productId: product.id, productVariantId: productVariant.id };
    });
    const goodsId = this.goods.id;
    const qty = this.goods.qty;
    this.shoppingCartService.addGoods(goodsId, qty, productList)
      .subscribe({
        next: result => {
          if (result.success) {
            // this.showMessage(this.translate.instant('add.product.successfully'));
            // this.showMessage(this.goods.name + ' ' + this.translate.instant('added.to.the.cart'));
            this.showMessage(this.goods.name + ' ' + this.translate.instant('added.to.the'), AddCartSnackBarComponent);
            this.shoppingCartService.refreshCount();
            this.shoppingCartService.refresh();
            this.close(this.goods.name, result.success);

            if (goToCart) {
              this.router.navigateByUrl('/shopping-cart');
            }

          } else {
            // const msgKey = this.errorMap[result.errorCode] || 'error';
            // const message = this.translate.instant(msgKey, { goodsName: this.goods.name });
            // this.showError(`${this.translate.instant('warning')}: ${message}`);
            this.showRespResultError(result, this.errorMap, { goodsName: this.goods.name });
          }
        },
        complete: () => this.loadingService.hide()
      });
  }

  // oneClickCheckout() {
  //   this.addGoods();
  //   this.router.navigateByUrl('/shopping-cart');
  // }

  range(start, end): number[] {
    return Array.from(new Array(end - start + 1), (x, i) => start + i);
  }

  rangeByGoods(goods): number[] {
    let start = 1, end = 5;
    if (goods.qtyLimitPerOrder) {
      start = goods.minQty;
      end = goods.maxQty;
    }
    return this.range(start, end);
  }

  changeAttributeOption(product, attribute, option) {
    attribute.optionList.forEach(o => o.selected = false);
    option.selected = true;
    // this.onAttributeChanged(product);

    this.checkAttributeOptionaNoProductVariant(product);
    this.checkAttributeOptionOutOfStock(product);
    this.selectAvailabelAttributeOption(product, attribute);
    this.onAttributeChanged(product);
  }

  onAttributeChanged(product) {
    const matchedProductVariant = this.findSelectedProductVariant(product);
    console.log('matchedProductVariant', matchedProductVariant);
    if (matchedProductVariant) {
      this.goods.price = matchedProductVariant.price;
      this.goods.listPrice = matchedProductVariant.listPrice;
      this.goods.outOfStock = matchedProductVariant.outOfStock;
      this.goods.canRestockNotify = matchedProductVariant.canRestockNotify;
      this.goods.hasRestockNotify = matchedProductVariant.hasRestockNotify;
      this.goods.barcode = matchedProductVariant.barcode;
      this.goods.lowStock = matchedProductVariant.lowStock;
      this.goods.lastStock = matchedProductVariant.lastStock;
      this.goods.qtyLimitPerOrder = matchedProductVariant.qtyLimitPerOrder;
      this.goods.minQtyPerOrder = matchedProductVariant.minQtyPerOrder;
      this.goods.maxQtyPerOrder = matchedProductVariant.maxQtyPerOrder;
      this.goods.qtyInCart = matchedProductVariant.qtyInCart;

      let initQty = this.goods.qty || 1;
      if (this.goods.qtyLimitPerOrder) {

        const qtyInCart = this.goods.qtyInCart;
        let maxQty = this.goods.maxQtyPerOrder - qtyInCart;
        let minQty = this.goods.minQtyPerOrder - qtyInCart;

        if (maxQty < 0) {
          maxQty = 0;
        }

        if (minQty < 1) {
          minQty = maxQty > 0 ? 1 : 0;
        }

        this.goods.minQty = minQty;
        this.goods.maxQty = maxQty;

        if (minQty > 0) {
          initQty = minQty;
        }
        if (initQty > maxQty) {
          initQty = maxQty;
        }
      }
      this.goods.qty = initQty;

      console.log('matchedProductVariant.imageSetList', matchedProductVariant.imageSetList);

      if (matchedProductVariant.imageSetList && matchedProductVariant.imageSetList.length > 0) {
        const imageUrl = matchedProductVariant.imageSetList[0].thumbnail.url;
        console.log('imageUrl', imageUrl);

        const matchedImageIndex = this.findMatchedImageIndex(imageUrl);
        if (matchedImageIndex > -1) {
          this.goods.imageIndex = matchedImageIndex;
        }
      }
    } else {
      this.goods.price = 0;
      this.goods.listPrice = 0;
      this.goods.outOfStock = true;
      this.goods.lowStock = false;
      this.goods.lastStock = false;
    }
  }
  generateAttributeMatchKey(optionIdList: string[]) {
    console.log('optionIdList', optionIdList);

    return optionIdList.sort().join('-');
  }
  findSelectedProductVariant(product) {
    const optionIdList = product.attributeList
      .flatMap(attribute => attribute.optionList.filter(option => option.selected))
      .map(option => option.id);
    const optionIdKey = this.generateAttributeMatchKey(optionIdList);
    console.log('optionIdKey', optionIdKey);

    const selectedProductVariant = product.productVariantList.find(productVariant =>
      this.generateAttributeMatchKey(productVariant.attributeOptionIdList) === optionIdKey);
    return selectedProductVariant;
  }

  findMatchedImageIndex(imageUrl) {
    return this.goods.imageSetList.findIndex(image => image.thumbnail.url === imageUrl);
  }

  openReviewDialog() {
    const dialogRef = this.dialog.open(GoodsReviewDialogComponent, { data: this.goods });
    dialogRef.afterClosed().subscribe(success => {
      if (success) {

      }
    });
  }

  toggleWish() {
    if (!this.isLogin) {
      this.router.navigate(['login']);
      return;
    }
    const api = this.goods.wish ? this.memberWishService.unwishGoods(this.goods.id) : this.memberWishService.wishGoods(this.goods.id);
    api.pipe(finalize(() => this.loading = false)).subscribe(result => {
      if (result.success) {
        this.goods.wish = !this.goods.wish;
      } else {
        // this.showError(`Error:  ${result.msg}`); // ${result.errorCode},
        this.showRespResultError(result, this.errorMap);
      }
    });
  }

  toggleStockNotify() {
    this.goods.productList.map(product => {
      const productVariant = this.findSelectedProductVariant(product);
      const api = this.goods.hasRestockNotify ?
        this.goodsService.cancelStockNotify(this.goods.id, productVariant.id) :
        this.goodsService.stockNotify(this.goods.id, productVariant.id);
      api.pipe(finalize(() => this.loading = false)).subscribe(result => {
        if (result.success) {
          this.goods.hasRestockNotify = !this.goods.hasRestockNotify;
        } else {
          // this.showError(`Error: ${result.msg}`); //  ${result.errorCode},
          this.showRespResultError(result, this.errorMap);
        }
      });

    });
  }

  openShippingDialog() {
    const dialogRef = this.informationDialogService.open(this.translate.instant('shipping'), this.goods.deliveryDescription);
  }

  openSupportedPaymentMethodDialog() {
    const dialogRef = this.informationDialogService.open(this.translate.instant('payment.methods'), this.supportedPaymentMethodList);
  }

  // checkAttibuteOptionOutOfStock(product, attribute) {
  //   const attributeList = product.attributeList;
  //   if (attributeList.length > 1) {
  //     const attributeWithoutLastList = attributeList.slice(0, attributeList.length - 1);
  //     const lastAttribute = attributeList.slice(attributeList.length - 1, attributeList.length);
  //     if (attributeWithoutLastList.includes(attribute)) {
  //       const optionIdList = attributeWithoutLastList
  //         .flatMap(attr => attr.optionList.filter(option => option.selected))
  //         .map(option => option.id);

  //       lastAttribute[0].optionList.forEach(option => {
  //         const checkOutOfStockOptionIdList = [...optionIdList, option.id];
  //         const optionIdKey = this.generateAttributeMatchKey(checkOutOfStockOptionIdList);
  //         // console.log('attribute.optionIdKey', optionIdKey);

  //         const matchedProductVariant = product.productVariantList
  //           .find(productVariant => this.generateAttributeMatchKey(productVariant.attributeOptionIdList) === optionIdKey);
  //         // console.log('attribute.optionList.matchedProductVariant', matchedProductVariant);
  //         if (matchedProductVariant) {
  //           option.hidden = false;
  //           option.outOfStock = matchedProductVariant.outOfStock;
  //         } else {
  //           option.hidden = true;
  //         }
  //         // console.log('option', option);
  //       });
  //     }
  //   } else {
  //     attribute.optionList.forEach(opt => {
  //       const checkOutOfStockOptionIdList = [opt.id];
  //       const optionIdKey = this.generateAttributeMatchKey(checkOutOfStockOptionIdList);

  //       const matchedProductVariant = product.productVariantList
  //         .find(productVariant => this.generateAttributeMatchKey(productVariant.attributeOptionIdList) === optionIdKey);
  //       opt.outOfStock = matchedProductVariant.outOfStock;
  //     });
  //   }

  //   const attributeNextToOwnList = attributeList.slice(attributeList.indexOf(attribute) + 1, attributeList.length);
  //   attributeNextToOwnList.forEach(attr => {
  //     const optionList = attr.optionList;
  //     optionList.forEach(o => o.selected = false);
  //     const optionFilterList = optionList.filter(option => !option.hidden);
  //     optionFilterList[0].selected = true;
  //   });
  // }
  checkAttributeOptionaNoProductVariant(product) {
    const attributeList = product.attributeList;
    if (attributeList?.length > 0) {
      const attributeWithoutLastList = attributeList.slice(0, attributeList.length - 1);
      const mainOptionIdList = attributeWithoutLastList
        .flatMap(attribute => attribute.optionList.filter(option => option.selected))
        .map(option => option.id);

      const lastAttribute = attributeList[attributeList.length - 1];
      lastAttribute.optionList.forEach(option => {
        const optionIdList = [...mainOptionIdList, option.id];
        const matchedProductVariant = this.findMatchedProductVariant(product, optionIdList);
        option.hidden = !matchedProductVariant;
      });
    }
  }

  checkAttributeOptionOutOfStock(product) {
    const attributeList = product.attributeList;
    if (attributeList?.length > 0) {
      const attributeWithoutLastList = attributeList.slice(0, attributeList.length - 1);
      const mainOptionIdList = attributeWithoutLastList
        .flatMap(attribute => attribute.optionList.filter(option => option.selected))
        .map(option => option.id);

      const lastAttribute = attributeList[attributeList.length - 1];
      lastAttribute.optionList.forEach(option => {
        const optionIdList = [...mainOptionIdList, option.id];
        const matchedProductVariant = this.findMatchedProductVariant(product, optionIdList);
        option.outOfStock = !matchedProductVariant || matchedProductVariant?.outOfStock;
      });
    }
  }

  findMatchedProductVariant(product, optionIdList) {
    const optionIdKey = this.generateAttributeMatchKey(optionIdList);
    console.log('optionIdKey', optionIdKey);

    const matchedProductVariant = product.productVariantList.find(productVariant =>
      this.generateAttributeMatchKey(productVariant.attributeOptionIdList) === optionIdKey);
    return matchedProductVariant;
  }

  findFirstHaveStockProductVariant(product) {
    const allAttributeOptionIdList: [][] = product.attributeList.map(attribute => attribute.optionList.map(option => option.id));
    const attributeOptionIdCombinationsList = combinationsOf2dArray(allAttributeOptionIdList);

    for (let index = 0; index < attributeOptionIdCombinationsList.length; index++) {
      const optionIdList = attributeOptionIdCombinationsList[index];
      const matchedProductVariant = this.findMatchedProductVariant(product, optionIdList);
      if (matchedProductVariant) {
        const hasStock = !matchedProductVariant.outOfStock;
        if (hasStock) {
          return matchedProductVariant;
        }
      }
    }
  }

  selectAvailabelAttributeOption(product, currentAttribute) {
    const attributeList = product.attributeList;

    console.log('currentAttribute', currentAttribute);

    const attributeIndex = attributeList.findIndex(item => item === currentAttribute) + 1;
    console.log('attributeIndex', attributeIndex);
    const childrenAttributeList = attributeList.slice(attributeIndex, attributeList.length);

    childrenAttributeList.forEach(attribute => {
      attribute.optionList.forEach(option => option.selected = false);
      let availableOption = attribute.optionList.find(option => !option.hidden && !option.outOfStock);

      if (!availableOption) {
        availableOption = attribute.optionList.find(option => !option.hidden);
      }

      if (!availableOption) {
        availableOption = attribute.optionList[0];
      }

      if (availableOption) {
        availableOption.selected = true;
      }
    });

  }

}
