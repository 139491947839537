import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
// import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { Project } from 'src/app/app-config';
import { HtmlLinkTagService, LinkDefinition } from './html-link-tag.service';
import { LanguageService } from 'src/app/api/language.service';

@Injectable({
  providedIn: 'root'
})
export class HreflangTagService {
  constructor(private htmlLink: HtmlLinkTagService,
    // private router: Router,
    @Inject(DOCUMENT) private document: Document,
    // @Inject(WEBSITE_BASE_URL) private baseUrl: string,
    private languageService: LanguageService,
  ) { }

  private generateHref(href: string, queryParams: { [key: string]: string }): string {
    const url = new URL(href);
    const urlParams = url.searchParams;

    for (const key in queryParams) {
      const value = queryParams[key];
      if (value == null) {
        urlParams.delete(key);
      } else {
        urlParams.set(key, value);
      }
    }
    return url.toString();
  }

  public async setHreflangTags() {
    this.document.querySelectorAll('link[rel="alternate"][hreflang]').forEach((link) => {
      link.parentNode.removeChild(link);
    });

    const href = window.location.href;

    const enabledLangList: any[] = await this.languageService.queryEnabled().toPromise();

    const hreflangMap = { 'zhHK': 'zh-Hant', 'zhCN': 'zh-Hans', 'enUS': 'en', 'jaJP': 'jp-JP', 'koKR': 'ko-KR' }
    const linkDefinitionList: LinkDefinition[] = enabledLangList.map(enabledLang => {
      const langCode = enabledLang.code;
      const hreflang = hreflangMap[langCode] ?? this.insertStr(langCode, 2, '-');
      return {
        rel: 'alternate',
        hreflang: hreflang,
        href: this.generateHref(href, { 'lang': langCode })
      };
    });

    this.htmlLink.addTags([
      {
        rel: 'alternate',
        hreflang: 'x-default',
        href: this.generateHref(href, { 'lang': null })
      },
      ...linkDefinitionList
    ]);
  }

  private insertStr(orginalStr, insertToIndex, stringToAdd) {
    return orginalStr.slice(0, insertToIndex) + stringToAdd + orginalStr.slice(insertToIndex);
  }
}


