import { Injectable } from '@angular/core';
import { GenericTableApiService } from './generic-table-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { RespResult } from './table-api-service';

@Injectable({
    providedIn: 'root'
})
export class MemberService extends GenericTableApiService {

    constructor(httpClient: HttpClient) {
        super(httpClient, 'member');
    }

    register(member: any): Observable<RespResult> {
        return this.httpClient.post<RespResult>(`${this.endpoint}`, member);
    }

    getProfile(): Observable<any> {
        return this.httpClient.get<any>(`${this.endpoint}/profile`);
    }

    updateProfile(member: any): Observable<RespResult> {
        return this.httpClient.post<RespResult>(`${this.endpoint}/profile`, member);
    }

    changePassword(currentPwd: string, newPwd: string): Observable<RespResult> {
        return this.httpClient.post<RespResult>(`${this.endpoint}/change-password`, { 'currentPwd': currentPwd, 'newPwd': newPwd });
    }

    getAddressBook(): Observable<any> {
        return this.httpClient.get<any>(`${this.endpoint}/address-book`);
    }

    updateAddressBook(addressBook: any): Observable<RespResult> {
        return this.httpClient.post<RespResult>(`${this.endpoint}/address-book`, addressBook);
    }

    phoneNumberExist(phoneNumber: string): Observable<any> {
        return this.httpClient.get(`member/exist/phone-number`, { params: { 'phoneNumber': phoneNumber } });
    }

    emailAddressExist(emailAddress: string): Observable<any> {
        return this.httpClient.get(`member/exist/email-address`, { params: { 'emailAddress': emailAddress } });
    }

    activate(id: number, data: any): Observable<RespResult> {
        return this.httpClient.post<RespResult>(`${this.endpoint}/${id}/active`, data);
    }

    getSingleSignOnInfoList(): Observable<any> {
        return this.httpClient.get<any>(`${this.endpoint}/single-sign-on-info`);
    }

    connectFacebook(accessToken: string): Observable<any> {
        if (accessToken) {
            return this.httpClient.post(`${this.endpoint}/facebook/connection`, { 'accessToken': accessToken });
        }
        return EMPTY;
    }

    disconnectFacebook(): Observable<any> {
        return this.httpClient.delete(`${this.endpoint}/facebook/disconnection`);
    }

    syncFacebook(accessToken: string): Observable<any> {
        if (accessToken) {
            return this.httpClient.post(`${this.endpoint}/facebook/sync`, { 'accessToken': accessToken });
        }
        return EMPTY;
    }

    connectGoogle(idToken: string): Observable<any> {
        if (idToken) {
            return this.httpClient.post(`${this.endpoint}/google/connection`, { 'idToken': idToken });
        }
        return EMPTY;
    }

    disconnectGoogle(): Observable<any> {
        return this.httpClient.delete(`${this.endpoint}/google/disconnection`);
    }

    checkGoodsReviewEnable(): Observable<any> {
        return this.httpClient.get(`${this.endpoint}/goods-review/enable`, {});
    }

    verifyAuthCode(id: number, authCode: string): Observable<any> {
        return this.httpClient.get(`${this.endpoint}/${id}/auth-code/verify`, { headers: { 'authCode': authCode } });
    }
}
