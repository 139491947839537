import { Injectable, TemplateRef } from '@angular/core';
import { MatDialogRef, MatDialog, MatDialogState } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { InformationDialogComponent, InformationDialogModel } from '../component/information-dialog/information-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class InformationDialogService {

  private dialogRef: MatDialogRef<any, any>;

  constructor(private dialog: MatDialog, private translate: TranslateService) { }

  open(title: string, content?: string | string[]): MatDialogRef<any, any> {
    if (this.dialogRef && this.dialogRef.getState() === MatDialogState.OPEN) {
      this.close();
    }

    const dialogData = new InformationDialogModel(title, content);
    console.log(dialogData);
    this.dialogRef = this.dialog.open(InformationDialogComponent, {
      maxWidth: '80vw',
      minWidth: 'calc(min(600px, 80vw))',
      data: dialogData
    });
    return this.dialogRef;
  }

  close() {
    this.dialogRef.close(false);
    this.dialogRef = null;
  }
}
