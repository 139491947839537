import { Injectable } from '@angular/core';
import { GenericTableApiService } from './generic-table-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { QueryBean } from './table-api-service';

@Injectable({
    providedIn: 'root'
})
export class CategoryService extends GenericTableApiService {

    constructor(httpClient: HttpClient) {
        super(httpClient, 'category');
    }

    queryGoodsSeries(categoryId: number, page, limit): Observable<any[]> {
        return this.httpClient.get<any[]>(`${this.endpoint}/${categoryId}/goods-series`, {
            params: { page: page, limit: limit }
        });
    }

    queryGoods(categoryId: number,
        filter: { brandIdList: any[], tagIdList: any[], hasDiscount: boolean, isHot: boolean, priceRange: { min: number, max: number } },
        orderBy: { field, ascending }, page, limit): Observable<any[]> {
        const queryBean: QueryBean = new QueryBean();
        queryBean.page = page;
        queryBean.limit = limit;
        if (filter) {
            if (filter.brandIdList && filter.brandIdList.length) {
                queryBean.fieldList.push('brandIdList');
                queryBean.operatorList.push('in');
                queryBean.keywordList.push(filter.brandIdList.join(','));
            }

            if (filter.tagIdList && filter.tagIdList.length) {
                queryBean.fieldList.push('tagIdList');
                queryBean.operatorList.push('in');
                queryBean.keywordList.push(filter.tagIdList.join(','));
            }

            if (filter.hasDiscount) {
                queryBean.fieldList.push('hasDiscount');
                queryBean.operatorList.push('=');
                queryBean.keywordList.push(`${filter.hasDiscount}`);
            }

            if (filter.isHot) {
                queryBean.fieldList.push('isHot');
                queryBean.operatorList.push('=');
                queryBean.keywordList.push(`${filter.isHot}`);
            }

            if (filter.priceRange?.min != null) {
                queryBean.fieldList.push('minPrice');
                queryBean.operatorList.push('>=');
                queryBean.keywordList.push(`${filter.priceRange?.min}`);
            }

            if (filter.priceRange?.max != null) {
                queryBean.fieldList.push('maxPrice');
                queryBean.operatorList.push('<=');
                queryBean.keywordList.push(`${filter.priceRange?.max}`);
            }
        }

        if (orderBy) {
            queryBean.orderByField = orderBy.field;
            queryBean.orderByAscending = orderBy.ascending;
        }


        const params = this.tsObjToJsonObj(queryBean);
        return this.httpClient.get<any[]>(`${this.endpoint}/${categoryId}/goods`, {
            params: params
        });
    }

    queryRootPath(categoryId: number): Observable<any[]> {
        return this.httpClient.get<any[]>(`${this.endpoint}/${categoryId}/root-path`);
    }

    queryTree(): Observable<any[]> {
        return this.httpClient.get<any[]>(`${this.endpoint}/tree`);
    }

    getGoodsFilterOptions(categoryId: number) {
        return this.httpClient.get<any>(`${this.endpoint}/${categoryId}/goods/filter-options`);
    }
}
