import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-category-showcase-item',
  templateUrl: './category-showcase-item.component.html',
  styleUrls: ['./category-showcase-item.component.scss']
})
export class CategoryShowcaseItemComponent implements OnInit {

  @Input() category: { id: number, icon: { url }, name, slug: string };

  constructor() { }

  ngOnInit(): void {
  }

}
