import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { NewsAndEventsPageService } from 'src/app/api/news-and-events-page.service';
import { Project } from 'src/app/app-config';
import { PageMetaService } from 'src/app/shared/service/page-meta.service';

@Component({
  selector: 'app-news-and-events-page-list',
  templateUrl: './news-and-events-page-list.component.html',
  styleUrls: ['./news-and-events-page-list.component.scss']
})
export class NewsAndEventsPageListComponent implements OnInit, OnDestroy, AfterViewInit {

  loading = false;
  
  id: number;
  newsAndEventsPageId;
  slug;

  newsAndEventsPage: any = {};

  itemList = [];
  itemTotal;
  hasMore = false;
  manualPage: number;
  pageNumbers: number[] = [];
  noOfItemsPerPage;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  searchKeyword;
  searchMode = false;

  DATE_FORMAT = 'YYYY-MM-dd';

  private _onDestroy$ = new Subject<void>();

  constructor(private router: Router,
    private route: ActivatedRoute,
    private newsAndEventsPageService: NewsAndEventsPageService,
    private pageMetaService: PageMetaService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService, ) {

      this.noOfItemsPerPage = 10;
    }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const paramList = params.idWithSlug.split('-');
      this.newsAndEventsPageId = paramList[0];
      this.slug = params.idWithSlug.substring(this.newsAndEventsPageId.length + 1);

      if (paramList.length < 2) {
        this.redirect404Page();
        return;
      }
    });
  }

  async ngAfterViewInit() {
    this.itemTotal = await this.newsAndEventsPageService.getItemCount(this.newsAndEventsPageId).toPromise();

    if (this.itemTotal > 0) {
      this.cdr.detectChanges();
      const pageChange$ = this.paginator.page.pipe(tap(pageEvent => {
        this.manualPage = pageEvent.pageIndex + 1;
        this.pageNumbers = this.range(this.paginator.getNumberOfPages());
        const newPageSize = pageEvent.pageSize;
        if (newPageSize !== this.noOfItemsPerPage) {
          this.noOfItemsPerPage = newPageSize;
        }
      }));

      pageChange$
        .pipe(
          switchMap(() => {
            return this.queryItem();
          }),
          tap(data => {
            if (data.slug !== this.slug) {
              this.redirect404Page();
              return;
            }

            this.itemList = data.newsAndEventsPageItemList;
            this.hasMore = this.itemList.length < this.itemTotal;
            this.newsAndEventsPage = data;

            const title = this.translate.instant('search') + ' ' + data.name + ' | ' + Project.title;
            this.pageMetaService.generateTags({
              title: title
            });
          }),
          takeUntil(this._onDestroy$)
        ).subscribe();
      this.updateManualPage(1);
    }
  }

  queryItem(): Observable<any> {
    this.loading = true;
    return this.newsAndEventsPageService.queryList(this.newsAndEventsPageId, { page: this.paginator.pageIndex + 1, limit: this.noOfItemsPerPage })
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      );
  }

  updateManualPage(pageNumber: number) {
    this.manualPage = pageNumber;
    this.paginator.pageIndex = pageNumber - 1;

    this.triggerPaginatorPageObservable();
  }

  triggerPaginatorPageObservable() {
    // trigger paginator page observable for generate go to page selection option
    setTimeout(() => {
      this.paginator.page.next({
        pageIndex: this.paginator.pageIndex,
        pageSize: this.paginator.pageSize,
        length: this.paginator.length
      });
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  redirect404Page() {
    this.router.navigateByUrl('404', { replaceUrl: true, skipLocationChange: true });
  }

  range(max: number): number[] {
    if (isNaN(max)) {
      return [];
    }
    return Array(max).fill(0).map((x, i) => i + 1);
  }

  search() {
    if (!!this.searchKeyword) {
      console.log('click search, keyword:', this.searchKeyword);
      this.router.navigate(['search/news-and-events-page'], { queryParams: { keyword: this.searchKeyword } });
      this.searchKeyword = '';
    }
}

}
