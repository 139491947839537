import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PointsRewardService extends ApiService {

    constructor(private httpClient: HttpClient) {
        super();
    }

    getGoodsReviewReward(goodsId): Observable<any> {
        return this.httpClient.get(`points-reward/goods-review/${goodsId}`);
    }

    getOrderGoodsReviewReward(goodsIdList: any): Observable<any> {
        let data = {
            'goodsIdList': goodsIdList
        }
        const queryParams = new HttpParams({ fromObject: data });
        return this.httpClient.get(`points-reward/order/goods-review`, { params: queryParams });
    }

}
