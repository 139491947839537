import { Injectable } from '@angular/core';
import { GenericTableApiService } from './generic-table-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GoodsSeriesService extends GenericTableApiService {

    constructor(httpClient: HttpClient) {
        super(httpClient, 'goods-series');
    }

    queryDestination(id: number): Observable<any[]> {
        return this.httpClient.get<any[]>(`${this.endpoint}/${id}/destination`);
    }
    queryDestinationGroup(id: number): Observable<any[]> {
        return this.httpClient.get<any[]>(`${this.endpoint}/${id}/destination-group`);
    }
}
