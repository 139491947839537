import { Component, OnInit, ViewChild, ElementRef, OnDestroy, HostListener, Renderer2, QueryList, ViewChildren } from '@angular/core';
import { Form, UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, ValidationErrors, AbstractControl, FormArray, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FullScreenLoadingService } from '../shared/service/full-screen-loading.service';
import { BasicViewComponent } from '../basic-view-component';
import { validateBasis } from '@angular/flex-layout';
import { PaymentService } from '../api/payment.service';
import { debounceTime, distinctUntilChanged, finalize, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ShoppingCartService } from '../api/shopping-cart.service';
import { config, Observable, Subject, Subscription } from 'rxjs';
import { MemberService } from '../api/member.service';
import { LoginService } from '../api/login.service';
import { CountryService } from '../api/country.service';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomValidators } from 'ngx-custom-validators';
import { integer } from '../shared/validator/integer';
import { RespResult } from '../api/table-api-service';
import { combineLatest } from 'rxjs-compat/operator/combineLatest';
import { forkJoin } from 'rxjs';
import { merge } from 'rxjs';
import { SystemSettingService } from '../api/system-setting.service';
import { PaymentMethodService } from '../api/payment-method.service';
import { TopNavBarManagerService } from '../shared/service/top-nav-bar-manager.service';
import { NavigationService } from '../api/navigation.service';
import { Project } from '../app-config';
import { InformationBottomSheetService } from '../shared/service/information-bottom-sheet.service';
import { OrderNavigationTopBarComponent } from '../order-navigation-top-bar/order-navigation-top-bar.component';
import { ReactiveFormUtil } from '../shared/util/reactive-form-util';
import { FormService, InputField } from '../api/form.service';
import { InputFieldId } from '../shared/enum/input-field-id.enum';
import { CustomFieldUtil } from '../shared/util/custom-field-util';
import { FormId } from '../shared/enum/form-id.enum';
import { Moment } from 'moment';
import * as moment from 'moment';
import { matExpansionAnimations } from '@angular/material/expansion';
import { HeaderSizeService } from '../shared/service/header-size.service';
import { CurrencySettingService } from '../shared/service/currency-setting.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  animations: [matExpansionAnimations.bodyExpansion]
})
export class CheckoutComponent extends BasicViewComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  billingForm: UntypedFormGroup;
  checkoutForm: UntypedFormGroup;
  errorMap = {
    'invalid_cart': 'invalid.cart',
    'invalid_input': 'invalid.input',
    'cart_session_timeout': 'cart.session.timeout'
  };

  pointsRedemptionCashFc: UntypedFormControl;

  // acceptTNC = false;

  shoppingCart: any;
  shoppingCartCount$: Observable<number>;

  // currentDestinationGroupId: number;
  destinationGroupList: any[];
  destinationList: any[];

  // paymentMethodList = [];
  deliveryMethodList = [];

  pickupPointGroupList = [];
  pickupPointList = [];
  pickupPoint;

  countryList: any[];

  deliveryCharge = 0;

  loadingDeliveryCharge = false;

  deliverySurcharge = 0;
  loadingDeliverySurcharge = false;

  isLogin: boolean;

  showProductList = false;
  showDeliveryMethod = true;
  showReceipentInfo = true;
  showBillingInfo = true;

  deliveryMethodMessage = '';
  // pickupLocationMessage = '';

  overweighted = false;
  creditNotEnough = false;
  creditOverMaxLimit = false;

  cartPointsRedemption: any;

  isRedeemPoints = false;

  fixedPostalCode = false;

  showPostalCode = false;

  paymentMethodId = 0;

  offlinePaymentMethodList = [];

  currentOfflinePaymentMethod;
  currentPaymentMethod;

  offlinePaymentRequired = false;

  DATE_FORMAT = 'YYYY-MM-DD';
  arrivalDateFilter;
  arrivalTimeList = [];
  mandatoryToFill = false;
  specificDeliveryDate = false;
  specificTimeSlot = false;
  selectOnlinePaymentMethodInMPay = false;
  paymentMethodsForNotMPay;
  paymentMethodLogos;
  deliveryMethodType;

  enableStorefrontStorePoints;
  enableStorefrontGiftRedemption;

  pointsToCashRate;

  needFilterDates = [];
  startDay = this.formatDate(new Date());
  endDay = this.formatDate(new Date());

  showCheckoutMessage = false;
  checkoutMessage;

  termsAndConditionReferencePage;

  defaultCurrencySymbol;

  currentScrollPoistion = 0;

  navBarHeight;

  @ViewChild('paymentForm') paymentForm: ElementRef<HTMLFormElement>;

  // @ViewChild('pickupPointGroup') pickupPointGroup: MatSelect;

  pickupPointGroupFc: UntypedFormControl = new UntypedFormControl('pickupPointGroup');

  @ViewChild(OrderNavigationTopBarComponent, { read: ElementRef }) public topBar;

  @ViewChildren('deliveryFormRef') deliveryFormRefList: QueryList<FormGroupDirective>;
  @ViewChild('billingFormRef') billingFormRef: FormGroupDirective;
  @ViewChild('checkoutFormRef') checkoutFormRef: FormGroupDirective;

  _tmpFormControl: { [key: string]: AbstractControl } = {};

  private _onDestroy = new Subject<void>();

  inputFieldId = InputFieldId;

  billingPreDefinedFieldList = [];
  billingCustomFieldList = [];

  billingPredefinedId2FormControlConfigMap = new Map([
    [InputFieldId.IdNoPassportNo, [
      { name: 'idNoPassportNo', value: '', validators: [] }
    ]],
    [InputFieldId.PhoneNumber, [
      { name: 'billingPhoneNumber', value: '', validators: [] }
    ]],
    [InputFieldId.Address, [
      { name: 'billingAddress', value: '', validators: [] }
    ]],
    [InputFieldId.FullName, [
      { name: 'billingLastName', value: '', validators: [] },
      { name: 'billingFirstName', value: '', validators: [] }
    ]],
    [InputFieldId.EmailAddress, [
      { name: 'emailAddress', value: '', validators: [Validators.email] }
    ]]
  ]);

  recipientPreDefinedFieldList = [];
  recipientCustomFieldList = [];

  recipientPredefinedId2FormControlConfigMap = new Map([
    [InputFieldId.IdNoPassportNo, [
      { name: 'idNoPassportNo', value: '', validators: [] }
    ]],
    [InputFieldId.PhoneNumber, [
      { name: 'destinationPhoneNumber', value: '', validators: [] }
    ]],
    [InputFieldId.Address, [
      { name: 'destinationAddress', value: '', validators: [Validators.minLength(5)] }
    ]],
    [InputFieldId.FullName, [
      { name: 'destinationLastName', value: '', validators: [] },
      { name: 'destinationFirstName', value: '', validators: [] }
    ]],
    [InputFieldId.EmailAddress, [
      { name: 'emailAddress', value: '', validators: [Validators.email] }
    ]]
  ]);

  checkoutPreDefinedFieldList = [];
  checkoutCustomerFieldList = [];

  checkoutPredefinedId2FormControlConfigMap = new Map([
    [InputFieldId.Remarks, [
      { name: 'personalRemark', value: '', validators: [Validators.maxLength(500)] }
    ]],
    [InputFieldId.TacCheckout, [
      { name: 'agreeTermAndCondition', value: false, validators: [Validators.requiredTrue] }
    ]],
    [InputFieldId.BecomeAMember, []]
  ]);

  telephoneCountryCodeList = [];

  headerSize$: Observable<number>;

  constructor(private router: Router,
    protected snackBar: MatSnackBar,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private loadingService: FullScreenLoadingService,
    private paymentService: PaymentService,
    private shoppingCartService: ShoppingCartService,
    private memberService: MemberService,
    private loginService: LoginService,
    private countryService: CountryService,
    private systemSettingService: SystemSettingService,
    private paymentMethodService: PaymentMethodService,
    private headerSizeService: HeaderSizeService,
    private topNavBarManagerService: TopNavBarManagerService,
    private navigationService: NavigationService,
    private informationBottomSheetService: InformationBottomSheetService,
    private renderer: Renderer2,
    private formService: FormService,
    private currencySettingService: CurrencySettingService,
  ) {
    super(snackBar);

    this.headerSize$ = this.headerSizeService.getHeight();

    this.form = fb.group({
      destinationGroupId: [null, Validators.required],
      destinationId: [null, Validators.required],
      deliveryMethodId: [null, Validators.required],
      pickupPointId: [null],
      destinationLastName: [''],  // , [Validators.required, Validators.maxLength(200)]
      destinationFirstName: [''], // , [Validators.required, Validators.maxLength(200)]
      emailAddress: ['', []], // , Validators.required, Validators.maxLength(200)
      destinationAddress: ['', []], // Validators.required, Validators.maxLength(200), Validators.minLength(5)
      destinationCity: ['', [Validators.maxLength(100)]], // Validators.required,
      destinationCountryId: [0],
      // destinationZipCode: [''], // , Validators.required
      destinationPostalCode: [''],
      destinationPhoneNumber: [''], // , [Validators.required, Validators.maxLength(100)]
      // defaultSetting: [false],
      // postalCode: ['', Validators.required],
      saveInfoForNextTime: [false],
      subscription: [false],
      // agreeTermAndCondition: [false, Validators.requiredTrue],
      // billingLastName: ['', [Validators.required, Validators.maxLength(200)]],
      // billingFirstName: ['', [Validators.required, Validators.maxLength(200)]],
      // billingEmailAddress: ['', [Validators.required, Validators.email, Validators.maxLength(200)]],
      // billingPhoneNumber: ['', [Validators.required, Validators.maxLength(100)]],
      // remark: [''],
      sameAsBilling: [false],
      personalRemark: ['', []], // Validators.maxLength(500)
      targetArrivalDate: [''],
      targetArrivalTimeSlotId: [''],
      specificDeliveryDate: [false],
      specificDeliveryTime: [false],

      idNoPassportNo: [''],
      // customFieldList: fb.array([]),
      customField: fb.group({}),
    });

    this.billingForm = fb.group({
      billingLastName: [''], // , [Validators.required, Validators.maxLength(200)]
      billingFirstName: [''], // , [Validators.required, Validators.maxLength(200)]
      emailAddress: ['', [Validators.email]], // , Validators.required, Validators.maxLength(200)
      billingPhoneNumber: [''], // , [Validators.required, Validators.maxLength(100)]

      idNoPassportNo: [''], // , [Validators.required, Validators.maxLength(200)]
      billingAddress: [''], // , [Validators.required, Validators.maxLength(500)]
      // customFieldList: fb.array([]),
      customField: fb.group({}),
    });

    this.checkoutForm = fb.group({
      agreeTermAndCondition: [false, []],
      personalRemark: [''], // , Validators.maxLength(500)
      // becomeAMember: [false],
      // customFieldList: fb.array([]),
      customField: fb.group({}),
    });

    this.pointsRedemptionCashFc = fb.control(null);

    this.systemSettingService.getTelephoneCountryCode().subscribe(data => this.telephoneCountryCodeList = data);
  }

  ngOnInit() {
    this.loadingService.show();

    this.shoppingCartService.refreshCount();
    this.shoppingCartCount$ = this.shoppingCartService.count();

    this.shoppingCartService.refresh();

    const emptyCartCheckingPipe = this.shoppingCartService.cart()
      .pipe(
        skip(1),
        tap({
          next: data => {
            console.log('shoppingcart data', data);
            this.loadingService.hide();
            this.shoppingCart = data;
            const noGoods = !this.shoppingCart
              || !this.shoppingCart.subCartList?.length
              || !this.shoppingCart.subCartList[0].cartGoodsList?.length;
            if (noGoods) {
              // this.router.navigate(['shopping-cart'], { replaceUrl: true });
              this.routeToShoppingCart();
              return;
            }
            return this.shoppingCart;
          },
          error: error => this.loadingService.hide(),
          complete: () => { }
        })
      );

    // init with cart
    emptyCartCheckingPipe.pipe(
      take(1),
      takeUntil(this._onDestroy)
    ).subscribe({
      next: shoppingCart => {
        let subCart = this.shoppingCart.subCartList[0];


        // recovery delivery data
        let deliveryData = {
          destinationId: subCart.destinationId,
          deliveryMethodId: subCart.deliveryMethodId,
          pickupPointId: subCart.pickupPointId
        };

        if (!subCart.destinationId || !subCart.deliveryMethodId || !subCart.pickupPointId) {

          const formData = this.form.value;
          deliveryData = {
            ...deliveryData,
            ...{
              destinationId: formData.destinationId,
              deliveryMethodId: formData.deliveryMethodId,
              pickupPointId: formData.pickupPointId
            }
          };

          subCart = {
            ...subCart,
            ...{
              destinationId: formData.destinationId,
              deliveryMethodId: formData.deliveryMethodId,
              pickupPointId: formData.pickupPointId
            }
          };
        }

        this.form.patchValue(deliveryData);

        // trigger delivery data update
        const currentDestinationGroup = this.findDestinationGroupByDestinationId(this.destinationGroupList, subCart.destinationId);
        if (currentDestinationGroup) {
          this.destinationGroupChange(currentDestinationGroup.id);
        }

        // recovery form data from local
        console.log('start recovery form data from local');
        let recoveryFormSuccess = this.recoveryBillingForm() && this.recoveryDestinationForm();
        console.log('recovery form data from local result', recoveryFormSuccess);
        if (!recoveryFormSuccess) {
          // recovery form data from shopping cart
          console.log('start recovery form data from cart');
          const hasFormDataInCart = subCart.destinationLastName && subCart.destinationFirstNam;
          if (hasFormDataInCart) {
            this.form.patchValue(subCart);

            this.billingForm.patchValue({
              billingLastName: this.shoppingCart.billingLastName,
              billingFirstName: this.shoppingCart.billingFirstName,
              emailAddress: this.shoppingCart.emailAddress,
              billingPhoneNumber: this.shoppingCart.billingPhoneNumber,

              billingAddress: this.shoppingCart.billingAddress,
              idNoPassportNo: this.shoppingCart.idNoPassportNo,
            });

            recoveryFormSuccess = true;
          }

          console.log('recovery form data from cart result', recoveryFormSuccess);
        }

        if (!recoveryFormSuccess) {
          // recovery form data from member info
          this.loginService.onLoggedIn()
            .pipe(
              take(1),
              takeUntil(this._onDestroy)
            )
            .subscribe(isLogin => {
              if (isLogin) {
                console.log('start recovery form data from member info');
                this.memberService.getAddressBook().subscribe(addressBook => {
                  if (addressBook && addressBook.lastName && addressBook.firstName) {
                    console.log('addressBook', addressBook);
                    this.form.patchValue({
                      destinationLastName: addressBook.lastName,
                      destinationFirstName: addressBook.firstName,
                      emailAddress: addressBook.emailAddress,
                      destinationAddress: addressBook.address,
                      destinationCity: addressBook.city,
                      destinationCountryId: addressBook.countryId,
                      destinationPostalCode: addressBook.postalCode,
                      destinationPhoneNumber: addressBook.phoneNumber,
                      idNoPassportNo: addressBook.idNoPassportNo,
                      customField: addressBook.customField,
                    });

                    console.log('recovery form data from member address book');
                  }
                });

                this.memberService.getProfile().subscribe(profile => {
                  console.log('profile', profile);
                  if (profile && profile.lastName && profile.firstName) {
                    this.billingForm.patchValue({
                      billingLastName: profile.lastName,
                      billingFirstName: profile.firstName,
                      emailAddress: profile.emailAddress,
                      billingPhoneNumber: profile.phoneNumber,
                      billingAddress: profile.address,
                      idNoPassportNo: profile.idNoPassportNo,
                      customField: profile.customField,
                    });

                    console.log('recovery form data from member profile');
                  }

                });
              }
            });
        }
      },
      error: () => this.loadingService.hide(),
    });

    // listen cart change
    emptyCartCheckingPipe.pipe(
      takeUntil(this._onDestroy),
    ).subscribe({
      next: shoppingCart => {
        this.cartPointsRedemption = this.shoppingCart.cartPointsRedemption;
        if (this.cartPointsRedemption?.freeToUse) {
          const pointsRedemptionCashMaxLimit = this.cartPointsRedemption.maxLimit;
          this.pointsRedemptionCashFc.setValidators([Validators.min(0), Validators.max(pointsRedemptionCashMaxLimit), integer]);
        }

        if (this.cartPointsRedemption?.use > 0) {
          this.isRedeemPoints = true;
        }

        console.log('deliveryMethodId', this.form.get('deliveryMethodId').value);
        console.log('pickupPointId', this.form.get('pickupPointId').value);

        this.shoppingCart.cartPromotionList.forEach(cartPromotion => {
          cartPromotion.promotedGoodsList = this.getCartPromotionGoodsList(cartPromotion.id);
        });
      },
      error: () => this.loadingService.hide(),
    });


    // this.shoppingCartService.cart()
    //   .pipe(
    //     skip(1),
    //     tap({
    //       next: data => {
    //         console.log('shoppingcart data', data);
    //         this.loadingService.hide();
    //         this.shoppingCart = data;
    //         const noGoods = !this.shoppingCart
    //           || !this.shoppingCart.subCartList?.length
    //           || !this.shoppingCart.subCartList[0].cartGoodsList?.length;
    //         if (noGoods) {
    //           // this.router.navigate(['shopping-cart'], { replaceUrl: true });
    //           this.routeToShoppingCart();
    //           return;
    //         }


    //         // this.recoveryDestinationForm();
    //         // this.shoppingCart.cartPointsRedemption = {freeToUse: true, balance: 10, use: 5, maxLimit: 20}


    //       },
    //       error: error => this.loadingService.hide(),
    //       complete: () => { }
    //     }),

    //   ).subscribe();


    this.loginService.onLoggedIn().pipe(takeUntil(this._onDestroy)).subscribe(isLogin => this.isLogin = isLogin);

    // this.count = this.shoppingCartService.count();

    // this.paymentService.queryPaymentMethod().subscribe(data => {
    //   this.paymentMethodList = data;
    // });

    // this.shoppingCartService.queryDestination().subscribe(data => {
    //   this.destinationList = data;
    //   if (this.destinationList && this.destinationList.length > 0) {
    //     const destinationId = this.destinationList[0].id;
    //     this.form.get('destinationId').setValue(destinationId);
    //     this.destinationChange(destinationId);
    //   }
    // });

    this.shoppingCartService.queryDestinationGroup().subscribe(data => {
      this.destinationGroupList = data;
      if (this.destinationGroupList && this.destinationGroupList.length > 0) {
        const currentDestinationId = this.form.get('destinationId').value;
        const currentDestinationGroup = this.findDestinationGroupByDestinationId(this.destinationGroupList, currentDestinationId)
          || this.destinationGroupList[0];
        const currentDestinationGroupId = currentDestinationGroup.id;
        this.form.get('destinationGroupId').setValue(currentDestinationGroupId);
        this.destinationGroupChange(currentDestinationGroupId);
      }
    });

    this.countryService.query().subscribe(data => {
      this.countryList = data;
    });

    this.shoppingCartService.getOfflinePaymentMethod().subscribe(data => {
      this.offlinePaymentMethodList = data;
      if (this.offlinePaymentMethodList?.length > 0) {
        this.currentOfflinePaymentMethod = this.offlinePaymentMethodList[0];
        this.paymentMethodId = this.currentOfflinePaymentMethod?.id;
      }
    });

    // this.form.get('deliveryMethodId').valueChanges.subscribe(() => {
    //   this.deliveryMethodMessage = 'Delivery Method Message'; // just for demo
    // });

    // this.form.get('pickupPointId').valueChanges.subscribe(() => {
    //   this.pickupLocationMessage = 'Pickup Location Message'; // just for demo
    // });

    // this.shoppingCart = this.demoShoppingCart;

    this.form.get('sameAsBilling').valueChanges.subscribe(checked => {
      if (checked) {
        // this.form.get('destinationLastName').setValue(this.billingForm.get('billingLastName').value, {emitEvent: false});
        // this.form.get('destinationFirstName').setValue(this.billingForm.get('billingFirstName').value, {emitEvent: false});
        // this.form.get('emailAddress').setValue(this.billingForm.get('emailAddress').value, {emitEvent: false});
        // this.form.get('destinationPhoneNumber').setValue(this.billingForm.get('billingPhoneNumber').value, {emitEvent: false});
        this.checkedSameAsBilling();
      }
    });

    const destinationLastName$ = this.form.get('destinationLastName').valueChanges;
    const destinationFirstName$ = this.form.get('destinationFirstName').valueChanges;
    // const destinationEmailAddress$ = this.form.get('emailAddress').valueChanges;
    const destinationPhoneNumber$ = this.form.get('destinationPhoneNumber').valueChanges;
    const destinationAddress$ = this.form.get('destinationAddress').valueChanges;
    const personalRemark$ = this.form.get('personalRemark').valueChanges;
    merge(destinationLastName$, destinationFirstName$, destinationPhoneNumber$)  // , destinationEmailAddress$
      .pipe(debounceTime(500))
      .subscribe(() => {
        // console.log('merge');
        this.uncheckedSameAsBilling();
      });

    const billingLastName$ = this.billingForm.get('billingLastName').valueChanges;
    const billingFirstName$ = this.billingForm.get('billingFirstName').valueChanges;
    const billingEmailAddress$ = this.billingForm.get('emailAddress').valueChanges;
    const billingPhoneNumber$ = this.billingForm.get('billingPhoneNumber').valueChanges;
    merge(billingLastName$, billingFirstName$, billingEmailAddress$, billingPhoneNumber$)
      // .pipe(debounceTime(500))
      .subscribe(() => {
        if (this.form.get('sameAsBilling').value) {
          this.checkedSameAsBilling();
        }
      });

    merge(billingLastName$, billingFirstName$, billingEmailAddress$, billingPhoneNumber$)
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.saveBillingForm();
      });

    const destinationGroupId$ = this.form.get('destinationGroupId').valueChanges;
    const destinationId$ = this.form.get('destinationId').valueChanges;
    const deliveryMethodId$ = this.form.get('deliveryMethodId').valueChanges;
    const pickupPointId$ = this.form.get('pickupPointId').valueChanges;
    merge(destinationLastName$, destinationFirstName$, destinationPhoneNumber$, destinationAddress$,
      personalRemark$, destinationGroupId$, destinationId$, deliveryMethodId$, pickupPointId$) // , destinationEmailAddress$
      .pipe(debounceTime(500))
      .subscribe(() => {

        this.saveDestinationForm();
      });

    this.systemSettingService.getPaymentMethodSetting().subscribe(data => {
      const selectOnlinePaymentMethodInMPay = data.selectOnlinePaymentMethodInMPay;
      this.selectOnlinePaymentMethodInMPay = selectOnlinePaymentMethodInMPay;

      if (!selectOnlinePaymentMethodInMPay) {
        this.shoppingCartService.queryPaymentMethodForCheckout().subscribe(pmData => {
          this.paymentMethodsForNotMPay = pmData;
          if (this.paymentMethodsForNotMPay) {
            this.currentPaymentMethod = this.paymentMethodsForNotMPay[0];
            this.paymentMethodId = this.currentPaymentMethod.id;
          }
        });
      }
    });

    this.paymentMethodService.getAllPaymentMethodLogo().subscribe(data => {
      this.paymentMethodLogos = data;
    });

    this.systemSettingService.getPointsSetting().subscribe(pointsSetting => {
      this.enableStorefrontStorePoints = pointsSetting.enableStorefrontStorePoints;
      this.enableStorefrontGiftRedemption = pointsSetting.enableStorefrontGiftRedemption;
    });

    this.systemSettingService.getShoppingCartAndCheckoutSetting().subscribe(data => {
      this.showCheckoutMessage = data.checkoutShowMessage;
      this.checkoutMessage = data.checkoutMessage;
    });

    this.navigationService.getTermsAndCondition().subscribe(data => {
      this.termsAndConditionReferencePage = data;
    });

    this.topNavBarManagerService.hide();

    this.defaultCurrencySymbol = this.currencySettingService.getDefaultSymbol();

    setTimeout(() => {
      this.navBarHeight = this.topBar.nativeElement.querySelector('app-navigation-bar').offsetHeight;
    });

    this.shoppingCartService.onDeleteGoods()
      .pipe(
        takeUntil(this._onDestroy)
      ).subscribe(_ => this.router.navigate(['/shopping-cart']));
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();

    this.topNavBarManagerService.show();
  }

  destinationGroupChange(destinationGroupId) {
    console.log('destinationGroupChange');
    const destinationGroup = this.destinationGroupList.find(d => d.id === destinationGroupId);
    this.destinationList = destinationGroup?.destinationList ?? [];
    if (this.destinationList && this.destinationList.length > 0) {
      const currentDestinationId = this.form.get('destinationId').value;
      const destination = this.destinationList.find(d => d.id === currentDestinationId) || this.destinationList[0];

      const destinationPostalCode = this.form.get('destinationPostalCode');

      console.log('destination', destination);
      const destinationId = destination.id;
      this.form.get('destinationId').setValue(destinationId);

      if (destination.postalCode) {
        let postalCodePattern = '';
        if (destination.postalCode.includes('^')) {
          postalCodePattern = destination.postalCode;
        } else {
          destinationPostalCode.setValue(destination.postalCode);
          postalCodePattern = '^' + destination.postalCode + '$';
          this.fixedPostalCode = true;
        }
        destinationPostalCode.setValidators([Validators.required, Validators.pattern(postalCodePattern)]);
        this.showPostalCode = true;
      } else {
        this.showPostalCode = false;
      }

      this.destinationChange(destinationId);
    }
  }

  destinationChange(destinationId) {
    console.log('destinationChange');
    const destination = this.destinationList.find(d => d.id === destinationId);
    this.deliveryMethodList = destination?.deliveryMethodList ?? [];
    if (this.deliveryMethodList && this.deliveryMethodList.length > 0) {
      const currentDeliveryMethodId = this.form.get('deliveryMethodId').value;
      const deliveryMethod = this.deliveryMethodList.find(d => d.id === currentDeliveryMethodId) || this.deliveryMethodList[0];

      console.log('deliveryMethod', deliveryMethod);
      const deliveryMethodId = deliveryMethod.id;
      this.form.get('deliveryMethodId').setValue(deliveryMethodId);

      this.deliveryMethodChange(deliveryMethodId);
    }
  }

  async deliveryMethodChange(deliveryMethodId) {
    console.log('deliveryMethodChange');
    const deliveryMethod = this.deliveryMethodList.find(dm => dm.id === deliveryMethodId);

    // let refreshDeliveryCharge = true;
    if (deliveryMethod) {

      this.pickupPointGroupList = deliveryMethod.pickupPointGroupList;
      console.log('this.pickupPointGroupList', this.pickupPointGroupList);


      // const destinationAddressFc = this.form.get('destinationAddress');

      this.deliveryMethodType = deliveryMethod?.type;

      if (this.pickupPointGroupList) {
        // this.form.get('pickupPointGroupId').setValue(this.pickupPointGroupList[0].id);
        // this.pickupPointGroup.writeValue(this.pickupPointGroupList[0].id);
        // this.pickupPointList = [];
        if (this.pickupPointGroupList.length > 0 && this.pickupPointGroupFc) {
          this.pickupPointGroupFc.setValue(this.pickupPointGroupList[0].id);
        }
        // destinationAddressFc.setValidators([]);
        // destinationAddressFc.setErrors(null);
        // this.disableFormControl('destinationAddress');
      } else {
        this.pickupPointList = null;
        if (this.pickupPointGroupFc) {
          this.pickupPointGroupFc.setValue('');
        }
        this.form.get('pickupPointId').setValue(0);
        // destinationAddressFc.setValidators([Validators.required, Validators.maxLength(200), Validators.minLength(5)]);
        // destinationAddressFc.updateValueAndValidity();
        // this.enableFormControl('destinationAddress');
      }

      this.pickupPointGroupChange(this.pickupPointGroupFc?.value);

      this.mandatoryToFill = deliveryMethod.mandatoryToFill;
      this.specificDeliveryDate = deliveryMethod.specificDeliveryDate;
      this.form.get('specificDeliveryDate').patchValue(this.specificDeliveryDate);
      this.specificTimeSlot = deliveryMethod.specificTimeSlot;

      this.deliveryMethodMessage = deliveryMethod.description;
      this.arrivalTimeList = deliveryMethod.arrivalTimeList ?? [];
      if (this.arrivalTimeList.length > 0) {
        this.form.get('targetArrivalTimeSlotId').patchValue(this.arrivalTimeList[0].id);
      }

      // const needFilterDates = deliveryMethod.excludedDateList;
      // const startDay = deliveryMethod.startDay;
      // const endDay = deliveryMethod.endDay;

      // this.arrivalDateFilter = (d): boolean => {
      //   const now = new Date();
      //   if (d == null) {
      //     d = now;
      //   } else {
      //     d = d.toDate();
      //   }
      //   const date = this.formatDate((d || now));
      //   return !needFilterDates.includes(date) && date >= startDay && date < endDay;
      // };

      this.needFilterDates = deliveryMethod.excludedDateList;
      this.startDay = deliveryMethod.startDay;
      this.endDay = deliveryMethod.endDay;
      this.updateTargetArrivalDate();

      const refreshShoppingCart = await this.updateSubCartDeliveryMethod();
      // refreshDeliveryCharge = refreshShoppingCart;

      const customFieldData = deliveryMethod.fieldInfo;
      this.updateCustomFieldData(customFieldData);

    }

    // if (refreshDeliveryCharge) {
    this.refreshDeliveryCharge();
    // }
  }

  async updateSubCartDeliveryMethod(): Promise<boolean> {
    const subCart = this.shoppingCart.subCartList[0];
    const subCartId = subCart.id;
    const destinationId = this.form.get('destinationId').value;
    const deliveryMethodId = this.form.get('deliveryMethodId').value;
    const result: any = await this.shoppingCartService.updateSubCartDeliveryMethod(subCartId, destinationId, deliveryMethodId).toPromise();
    let refreshShoppingCart = false;
    if (result.success) {
      if (result.data?.changed) {
        await this.refreshShoppingCart();
        refreshShoppingCart = true;
      }
    } else {
      this.showRespResultError(result, this.errorMap);
      if (['cart_session_timeout'].includes(result.errorCode)) {
        await this.refreshShoppingCart();
        this.routeToShoppingCart();
        refreshShoppingCart = true;
      }
    }

    return Promise.resolve(refreshShoppingCart);
  }

  pickupPointGroupChange(pickupPointGroupId) {
    const pickupPointGroup = this.pickupPointGroupList?.find(ppg => ppg.id === pickupPointGroupId);
    this.pickupPointList = pickupPointGroup?.pickupPointList;
    const pickupPointIdFc = this.form.get('pickupPointId');

    if (this.pickupPointList && this.pickupPointList.length > 0) {
      pickupPointIdFc.setValidators([Validators.required]);
      pickupPointIdFc.setValue(this.pickupPointList[0].id);
    } else {
      pickupPointIdFc.setValidators([]);
      pickupPointIdFc.setValue('');
    }
    pickupPointIdFc.updateValueAndValidity();
    this.pickupPointChange(pickupPointIdFc.value);
  }

  pickupPointChange(pickupPointId) {
    this.pickupPoint = this.pickupPointList?.find(pp => pp.id === pickupPointId);
    this.updateTargetArrivalDate();
  }

  refreshDeliveryCharge() {
    const destinationId = this.form.get('destinationId').value;
    const deliveryMethodId = this.form.get('deliveryMethodId').value;
    if (destinationId && deliveryMethodId && !this.shoppingCart?.freeDelivery) {
      this.loadingDeliveryCharge = true;
      this.shoppingCartService.getDeliveryCharge(destinationId, deliveryMethodId)
        .pipe(finalize(() => {
          this.loadingDeliveryCharge = false;
        }))
        .subscribe(data => {
          this.deliveryCharge = data;
        });

      this.loadingDeliverySurcharge = true;
      this.shoppingCartService.getDeliverySurcharge(destinationId, deliveryMethodId)
        .pipe(finalize(() => {
          this.loadingDeliverySurcharge = false;
        }))
        .subscribe(data => {
          this.deliverySurcharge = data;
        });
    } else {
      this.deliveryCharge = 0;
      this.deliverySurcharge = 0;
    }
  }

  pay(paymentMethodId) {
    this.loadingService.show();
    console.log('this.form.get(\'saveInfoForNextTime\').value', this.form.get('saveInfoForNextTime').value);

    if (this.form.get('saveInfoForNextTime').value && this.isLogin) {
      this.updateAddressBook();
    }

    // if (this.checkoutForm.get('becomeAMember').value && !this.isLogin) {
    //   this.register();
    // }

    this.updateBilling().then((result) => {
      if (result.success) {
        return this.updateSubCart();
      } else {
        // show error
        // const msgKey = this.errorMap[result.errorCode] || '';
        // this.showError(`${this.translate.instant('error')}: ${this.translate.instant(msgKey)}. ${result.msg || ''}`);
        this.showRespResultError(result, this.errorMap);
        if (['cart_session_timeout'].includes(result.errorCode)) {
          this.refreshShoppingCart();
          this.routeToShoppingCart();
        }
      }
      return Promise.reject();
    }).then((result) => {
      if (result.success) {
        this.createPayment(paymentMethodId);
      } else {
        // const msgKey = this.errorMap[result.errorCode] || 'error';
        // this.showError(`${this.translate.instant('error')}: ${this.translate.instant(msgKey)}`);
        this.showRespResultError(result, this.errorMap);
        if (['cart_session_timeout'].includes(result.errorCode)) {
          this.refreshShoppingCart();
          this.routeToShoppingCart();
        }
      }
    }).catch(() => {
      this.loadingService.hide();
    });

    // , error =>{

    // }

    // this.updateSubCart().then((result) => {
    //   if (result.success) {
    //     this.createPayment();
    //   } else {
    //     const msgKey = this.errorMap[result.errorCode] || 'error';
    //     this.showError(`${this.translate.instant('error')}: ${this.translate.instant(msgKey)}, ${result.msg || ''}`);
    //   }
    // }).catch(() => {
    //   this.loadingService.hide();
    // });
  }

  updateAddressBook() {
    const formData = this.form.value;
    const addressBook = {
      lastName: formData.destinationLastName,
      firstName: formData.destinationFirstName,
      emailAddress: formData.emailAddress,
      address: formData.destinationAddress,
      city: formData.destinationCity,
      country: formData.destinationCountry,
      // zipCode: formData.destinationZipCode,
      // postalCode: formData.destinationPostalCode,
      postalCode: formData.destinationPostalCode,
      phoneNumber: formData.destinationPhoneNumber,
      countryId: formData.destinationCountryId
    };

    this.memberService.updateAddressBook(addressBook).subscribe();
  }

  updateSubCart() {
    let subCart = this.shoppingCart.subCartList[0];
    if (!this.specificTimeSlot) {
      this.form.get('targetArrivalTimeSlotId').setValue(0);
    }
    this.form.get('personalRemark').patchValue(this.checkoutForm.get('personalRemark').value);
    // this.assignCheckoutFormCustomFieldDataToForm();
    // const formData = this.form.value;
    const { customField, ...formData } = this.form.value;
    // delete formData['postalCode'];
    delete formData['destinationGroupId'];
    delete formData['saveInfoForNextTime'];
    delete formData['subscription'];
    delete formData['agreeTermAndCondition'];
    delete formData['sameAsBilling'];
    delete formData['specificDeliveryDate'];
    delete formData['specificDeliveryTime'];

    // const customField = this.billingForm.value.customField;
    subCart.customForm = {
      billing: this.billingForm.value.customField,
      recipient: this.form.value.customField,
      checkout: this.checkoutForm.value.customField
    };

    subCart = Object.assign(subCart, formData);
    return this.shoppingCartService.updateSubCart(subCart).toPromise();
  }

  updateBilling() {
    const shoppingCart = this.shoppingCart;
    // const formData = this.billingForm.value;
    // shoppingCart = Object.assign(shoppingCart, formData);
    // return this.shoppingCartService.updateBilling(shoppingCart).toPromise();
    const { customField, ...formData } = this.billingForm.value;
    return this.shoppingCartService.updateBilling(formData).toPromise();
  }

  createPayment(paymentMethodId) {

    this.loadingService.show();
    const api = this.paymentService.createPayment(paymentMethodId);

    api.pipe(finalize(() => this.loadingService.hide()))
      .subscribe(result => {
        if (result.success) {
          const paymentData = result.data;

          if (paymentData.paymentMethodType === 'web_offline') {
            this.router.navigate([`payment-result/${paymentData.type}`],
              {
                replaceUrl: true,
                state: {
                  'success': paymentData.success,
                  'paymentId': paymentData.paymentId,
                  'authCode': paymentData.authCode,
                  'csEmail': paymentData.csEmail
                }
              });
          } else {
            this.submitToMpay(paymentData.mPayData);
          }
        } else {
          this.showRespResultError(result, this.errorMap);
          if (['invalid_cart', 'cart_session_timeout'].includes(result.errorCode)) {
            this.refreshShoppingCart();
            this.routeToShoppingCart();
          }
        }
      });
  }

  submitToMpay(paymentData) {
    const form = this.paymentForm.nativeElement;
    form.method = 'POST';
    form.action = paymentData.paymentUrl;
    form.target = '_self';

    let entries = Object.entries(paymentData);
    entries = entries.filter(([key, value]) => key !== 'paymentUrl');
    for (const [key, value] of entries) {
      const elem = document.createElement('input');
      elem.type = 'hidden';
      elem.name = key;
      elem.value = String(value);
      form.appendChild(elem);
    }
    form.submit();
  }

  toggleProductList() {
    this.showProductList = !this.showProductList;
    this.scrollToTop();
  }

  toggleDeliveryMethod() {
    this.showDeliveryMethod = !this.showDeliveryMethod;
  }

  toggleReceipentInfo() {
    this.showReceipentInfo = !this.showReceipentInfo;
  }

  deleteUsingCreditPoints() {
    console.log('deleteUsingCreditPoints');
  }

  redeemPoints(points: number) {
    console.log('Redeem Points', points);
    const cartPointsRedemption = this.shoppingCart.cartPointsRedemption;
    let redeemCash = points / cartPointsRedemption?.pointsToCashRate;
    redeemCash = Math.trunc(redeemCash);
    const usePoints = redeemCash * cartPointsRedemption?.pointsToCashRate;

    this.pointsRedemptionCashFc.reset();
    this.loadingService.show();
    this.shoppingCartService.updatePointsRedemptionCash(usePoints)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(result => {
        if (result.success) {
          this.shoppingCartService.refresh();
        } else {
          // const msgKey = this.errorMap[result.errorCode] || 'error';
          // this.showError(`${this.translate.instant('error')}: ${result.msg || ''}`); //  ${this.translate.instant(msgKey)},
          this.showRespResultError(result, this.errorMap);
          if (['cart_session_timeout'].includes(result.errorCode)) {
            this.refreshShoppingCart();
            this.routeToShoppingCart();
          }
        }
      });
  }

  continueToPayment(paymentMethodId) {
    this.form.markAllAsTouched();
    this.checkoutForm.markAllAsTouched();


    console.log('this.form', ReactiveFormUtil.collectErrors(this.form));
    console.log('this.billingForm', ReactiveFormUtil.collectErrors(this.billingForm));
    console.log('this.checkoutForm', ReactiveFormUtil.collectErrors(this.checkoutForm));

    if (this.form.valid && this.billingForm.valid && this.checkoutForm.valid) {
      // this.router.navigateByUrl('/payment-result/order');
      this.pay(paymentMethodId);
    } else {
      if (this.checkoutFormRef && this.checkoutForm.invalid) {
        this.checkoutFormRef.onSubmit(undefined);
      }
      if (this.billingFormRef && this.billingForm.invalid) {
        this.billingFormRef.onSubmit(undefined);
      }
      if (this.deliveryFormRefList?.length && this.form.invalid) {
        this.deliveryFormRefList.toArray().reverse().forEach(deliveryFormRef => {
          deliveryFormRef.onSubmit(undefined);
        });
      }

      this.showDeliveryMethod = true;
      this.showReceipentInfo = true;
    }
  }

  continueToOfflinePayment(paymentMethodId) {
    this.form.markAllAsTouched();
    if (paymentMethodId > 0) {
      this.continueToPayment(paymentMethodId);
    } else {
      this.offlinePaymentRequired = true;
    }
  }

  findDestinationGroupByDestinationId(destinationGroupList, destinationId) {
    return destinationGroupList?.find(destinationGroup =>
      destinationGroup.destinationList?.some(destination => destination.id === destinationId)
    );
  }

  isRedeemPointsChange(isRedeemPoints: boolean) {
    this.isRedeemPoints = isRedeemPoints;
    const cartPointsRedemption = this.shoppingCart.cartPointsRedemption;
    if (isRedeemPoints && cartPointsRedemption.freeToUse) {
      return;
    }

    if (!isRedeemPoints && cartPointsRedemption.use === 0) {
      return;
    }

    this.loadingService.show();
    let api: Observable<RespResult>;
    if (isRedeemPoints) {
      api = this.shoppingCartService.updatePointsRedemptionCash(cartPointsRedemption.maxLimit);
    } else {
      api = this.shoppingCartService.deletePointsRedemptionCash();
    }
    api.pipe(
      finalize(() => this.loadingService.hide())
    ).subscribe(result => {
      if (result.success) {
        this.shoppingCartService.refresh();
      } else {
        // const msgKey = this.errorMap[result.errorCode] || 'error';
        // this.showError(`${this.translate.instant('error')}: ${result.msg || ''}`); //  ${this.translate.instant(msgKey)},
        this.showRespResultError(result, this.errorMap);
        if (['cart_session_timeout'].includes(result.errorCode)) {
          this.refreshShoppingCart();
          this.routeToShoppingCart();
        }
      }
    });
  }

  calculateTotalAmount(): Number {
    return Number(this.shoppingCart.amount) + Number(this.deliveryCharge) + Number(this.deliverySurcharge);
  }

  uncheckedSameAsBilling() {
    const sameAsBillingFc = this.form.get('sameAsBilling');
    const sameAsBilling = sameAsBillingFc.value;
    if (sameAsBilling) {
      sameAsBillingFc.setValue(false);
    }
  }

  checkedSameAsBilling() {
    const copyFieldList = {
      destinationLastName: 'billingLastName',
      destinationFirstName: 'billingFirstName',
      emailAddress: 'emailAddress',
      destinationPhoneNumber: 'billingPhoneNumber',
      destinationAddress: 'billingAddress',
      customField: 'customField'
    };

    for (const [source, target] of Object.entries(copyFieldList)) {
      if (this.form.contains(source) && this.billingForm.contains(target)) {
        const sourceFc = this.form.get(source);
        const targetValue = this.billingForm.get(target).value;

        if (targetValue) {
          sourceFc.setValue(targetValue, { emitEvent: false });
        }

        sourceFc.markAsTouched();
      }
    }

    this.saveDestinationForm();
  }

  scrollToTop() {
    (function smoothscroll() {
      const scrollContainer = window;
      const currentScroll = scrollContainer.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        scrollContainer.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    })();
  }

  routeToShoppingCart() {
    this.router.navigate(['shopping-cart'], { replaceUrl: true });
  }

  async refreshShoppingCart() {
    this.shoppingCartService.refreshCount();
    await this.shoppingCartService.refresh();
  }

  offlinePaymentMethodChange(offlinePaymentMethodId) {
    this.paymentMethodId = offlinePaymentMethodId;
    this.currentOfflinePaymentMethod = this.offlinePaymentMethodList.find(pm => pm.id === offlinePaymentMethodId);
  }

  paymentMethodChange(paymentMethodId) {
    this.paymentMethodId = paymentMethodId;
    this.currentPaymentMethod = this.paymentMethodsForNotMPay.find(pm => pm.id === paymentMethodId);
  }

  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  recoveryDestinationForm(): boolean {
    const sessionDestiantionData = sessionStorage.getItem('destiantionForm');
    // console.log('sessionDestiantionData', sessionDestiantionData);
    let sessionDestiantionForm = JSON.parse(sessionDestiantionData);

    if (sessionDestiantionForm?.destinationLastName && sessionDestiantionForm?.destinationFirstName) {
      // this.form.patchValue({
      //   destinationLastName: sessionDestiantionForm.destinationLastName,
      //   destinationFirstName: sessionDestiantionForm.destinationFirstName,
      //   emailAddress: sessionDestiantionForm.emailAddress,
      //   destinationAddress: sessionDestiantionForm.destinationAddress,
      //   destinationPhoneNumber: sessionDestiantionForm.destinationPhoneNumber,
      //   personalRemark: sessionDestiantionForm.personalRemark
      // });

      // console.log('sessionDestiantionForm', sessionDestiantionForm);
      const formData = this.form.value;
      // console.log('formData', formData);
      if (formData.destinationGroupId || formData.destinationId || formData.deliveryMethodId || formData.pickupPointId) {
        sessionDestiantionForm = {
          ...sessionDestiantionForm,
          ...{
            destinationGroupId: formData.destinationGroupId,
            destinationId: formData.destinationId,
            deliveryMethodId: formData.deliveryMethodId,
            pickupPointId: formData.pickupPointId,
          }
        };
      }
      // console.log('sessionDestiantionForm', sessionDestiantionForm);
      this.form.patchValue(sessionDestiantionForm);
      return true;
    } else {
      return false;
    }
  }

  recoveryBillingForm(): boolean {
    const sessionBillingData = sessionStorage.getItem('billingForm');
    const sessionBillingForm = JSON.parse(sessionBillingData);

    if (sessionBillingForm?.billingLastName && sessionBillingForm?.billingFirstName) {
      this.billingForm.patchValue({
        billingLastName: sessionBillingForm.billingLastName,
        billingFirstName: sessionBillingForm.billingFirstName,
        emailAddress: sessionBillingForm.emailAddress,
        billingPhoneNumber: sessionBillingForm.billingPhoneNumber,
        idNoPassportNo: sessionBillingForm.idNoPassportNo,
        billingAddress: sessionBillingForm.billingAddress,

        customField: sessionBillingForm.customField,
      });

      return true;
    } else {
      return false;
    }
  }

  saveDestinationForm() {
    // const { targetArrivalDate, ...formData } = this.form.value;
    const formValue = this.form.value;
    const formData = {
      destinationLastName: formValue.destinationLastName,
      destinationFirstName: formValue.destinationFirstName,
      emailAddress: formValue.emailAddress,
      destinationPhoneNumber: formValue.destinationPhoneNumber,
      destinationAddress: formValue.destinationAddress,
      idNoPassportNo: formValue.idNoPassportNo,
      customField: formValue.customField
    };
    sessionStorage.setItem('destiantionForm', JSON.stringify(formData));
  }

  saveBillingForm() {
    // sessionStorage.setItem('billingForm', JSON.stringify(this.billingForm.value));
    const formValue = this.billingForm.value;
    const formData = {
      billingLastName: formValue.billingLastName,
      billingFirstName: formValue.billingFirstName,
      emailAddress: formValue.emailAddress,
      billingPhoneNumber: formValue.billingPhoneNumber,
      idNoPassportNo: formValue.idNoPassportNo,
      billingAddress: formValue.billingAddress,
      customField: formValue.customField
    };
    sessionStorage.setItem('billingForm', JSON.stringify(formData));
  }

  updateTargetArrivalDate() {
    const workingDays = this.pickupPoint ? this.pickupPoint.workingDays : [1, 2, 3, 4, 5, 6, 7];
    this.arrivalDateFilter = (d: Moment): boolean => {

      if (!this.form.get('specificDeliveryDate').value) {
        return true;
      }

      if (d == null) {
        return false;
      }

      const date: Date = d.toDate();
      const day = date.getDay();
      const formattedDate = this.formatDate(date);
      const startDate = moment(this.startDay, 'YYYY-MM-DD');
      const endDate = moment(this.endDay, 'YYYY-MM-DD');

      const isExcluded = this.needFilterDates.includes(formattedDate);
      const inRange = d.isSameOrAfter(startDate) && d.isBefore(endDate);
      const isWorkingDay = workingDays.includes(day);
      const valid = !isExcluded && inRange && isWorkingDay;

      return valid;
    };
  }

  // goTermsAndCondition() {
  //   const url = this.router.serializeUrl(
  //     this.router.createUrlTree([Project.url + '/info-page/' + this.termsAndConditionReferencePage.referencePageId +
  //  '-' + this.termsAndConditionReferencePage.slug])
  //   );

  //   window.open(url, '_blank');
  // }

  getCartPromotionGoodsList(cartPromotionId) {
    const cartGoodsListWithPromotion = this.shoppingCart.subCartList
      .flatMap(subCart => subCart.cartGoodsList)
      .filter(cartGoods => cartGoods.cartPromotionList.length > 0);

    const matchedCartGoodsList = cartGoodsListWithPromotion.filter(cartGoods => {
      const cartGoodsPromotionList = cartGoods.cartPromotionList;
      return cartGoodsPromotionList.some(cartGoodsPromotion => cartGoodsPromotion.id === cartPromotionId);
    });
    return matchedCartGoodsList;
  }

  isBundlePromotion(cartPromotion) {
    return cartPromotion.actionList.some(action => 'bundle_price_goods_discount' === action.type ||
      'bundle_price_product_variant_discount' === action.type ||
      'bundle_goods_discount' === action.type);
  }

  // getFormValidationErrors() {
  //   let resp = '';
  //   Object.keys(this.form.controls).forEach(key => {

  //   const controlErrors: ValidationErrors = this.form.get(key).errors;
  //   if (controlErrors != null) {
  //         Object.keys(controlErrors).forEach(keyError => {
  //           // console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
  //           // resp += 'Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError];
  //           resp += key + ': ' + keyError + '                   ';
  //         });
  //       }
  //     });

  //     return resp;
  //   }

  viewCheckoutMessage() {
    this.informationBottomSheetService.open(this.checkoutMessage.title, this.checkoutMessage.content);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    const scrollOffsetTop = (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop);

    const topBarDom = this.topBar.nativeElement;
    // const topBarHeight = topBarDom.offsetHeight;
    // const topBarMinTop = 0 - topBarHeight;
    // const topBarMaxTop = 0;

    // const scrolledValue = scrollOffsetTop - this.currentScrollPoistion;

    // const cssStyle = topBarDom.style;
    // const transformStyle = cssStyle.transform || cssStyle.webkitTransform || cssStyle.mozTransform || '0';
    // const translateY = Number(transformStyle.replace(/[^\d\-.]/g, ''));

    // let topValue = translateY - scrolledValue;
    // if (topValue < topBarMinTop) {
    //   topValue = topBarMinTop;
    // } else if (topValue > topBarMaxTop) {
    //   topValue = topBarMaxTop;
    // }

    // if (scrollOffsetTop <= 0) {
    //   topValue = 0;
    // }

    // if (topValue !== translateY) {
    //   this.renderer.setStyle(topBarDom, 'transform', `translateY(${topValue}px)`);
    // }

    // this.currentScrollPoistion = scrollOffsetTop;
    const navBarDom = topBarDom.querySelector('app-navigation-bar');
    const navBarHeight = this.navBarHeight;
    const navBarMinHeight = 0;
    const navBarMaxHeight = navBarHeight;

    const scrolledValue = scrollOffsetTop - this.currentScrollPoistion;

    const cssStyle = navBarDom.style;
    const cssHeight = Number(cssStyle.height.replace(/[^\d\-.]/g, ''));

    let height = cssHeight - scrolledValue;
    if (scrolledValue > 0) {
      if (height < navBarMinHeight) {
        height = navBarMinHeight;
      } else if (height > navBarMaxHeight) {
        height = navBarMaxHeight;
      }
    }
    if (scrolledValue <= 0) {
      height = navBarMaxHeight;
    }

    this.renderer.setStyle(navBarDom, 'height', `${height}px`);

    this.currentScrollPoistion = scrollOffsetTop;
  }

  enableFormControl(formControlPath: string) {
    const formControl = this._tmpFormControl[formControlPath];
    ReactiveFormUtil.addFormControl(this.form, formControlPath, formControl);
  }

  disableFormControl(formControlPath: string) {
    const formControl = ReactiveFormUtil.removeFormControl(this.form, formControlPath);
    if (formControl) {
      this._tmpFormControl[formControlPath] = formControl;
    }
  }

  addCustomField(inputField, form) {
    const customField = CustomFieldUtil.buildCustomField(inputField);
    // (form.get('customFieldList') as FormArray).push(customField);
    (form.get('customField') as UntypedFormGroup).addControl(inputField.key, customField);
    return customField;
  }

  // register() {
  //   console.log('register');
  // }

  assignValidators(fc: UntypedFormControl, inputField: InputField, basicValidators = []) {
    CustomFieldUtil.assignValidators(fc, inputField, basicValidators);
  }

  optionValueChange(key, value, event, form) {
    const checked = event.checked;
    const customFieldFc = form.get('customField.' + key);
    const cfValue = customFieldFc.value;
    if (checked) {
      customFieldFc.value.push(value);
      customFieldFc.patchValue(cfValue);
    } else {
      cfValue.splice(cfValue.indexOf(value), 1);
    }
  }

  updateCustomFieldData(customFieldData) {
    console.log('update billing form cutstom field');
    // Billing Form
    const billingInfo = customFieldData.billing;
    // Pre-defined
    const billingPreDefinedFieldList = billingInfo.preDefinedFieldInfoList;
    this.billingPreDefinedFieldList = billingPreDefinedFieldList;

    // update pre-defined form control
    this.billingPredefinedId2FormControlConfigMap.forEach((formControlConfigList, inputFieldId) => {
      const inputField = billingPreDefinedFieldList.find(field => `${field.id}` === `${inputFieldId}`);

      formControlConfigList.forEach(formControlConfig => {
        const formControlName = formControlConfig.name;
        const fc = this.billingForm.get(formControlName) as UntypedFormControl;
        if (inputField) {
          // show pre-defined form control and update validators
          fc.enable({ onlySelf: true });
          fc.clearValidators();
          this.assignValidators(fc, inputField, formControlConfig.validators);
        } else {
          // hidden pre-defined form control
          fc.disable({ onlySelf: true });
        }
        fc.updateValueAndValidity();
      });
    });


    // Custom
    // clean old custom form control
    this.billingCustomFieldList.forEach(customField => {
      (this.billingForm.get('customField') as UntypedFormGroup).removeControl(customField.key);
    });

    this.billingCustomFieldList = billingInfo.customFieldInfoList;

    // add new custom form control
    billingInfo.customFieldInfoList.forEach(inputField => {
      this.addCustomField(inputField, this.billingForm);
    });

    console.log('update recipient form cutstom field');
    // Recipient Form
    const recipientInfo = customFieldData.recipient;
    // Pre-defined
    const recipientPreDefinedFieldList = recipientInfo.preDefinedFieldInfoList;
    this.recipientPreDefinedFieldList = recipientPreDefinedFieldList;

    // update pre-defined form control
    this.recipientPredefinedId2FormControlConfigMap.forEach((formControlConfigList, inputFieldId) => {
      const inputField = recipientPreDefinedFieldList.find(field => `${field.id}` === `${inputFieldId}`);

      formControlConfigList.forEach(formControlConfig => {
        const formControlName = formControlConfig.name;
        const fc = this.form.get(formControlName) as UntypedFormControl;
        if (inputField) {
          // show pre-defined form control and update validators
          fc.enable({ onlySelf: true });
          fc.clearValidators();
          this.assignValidators(fc, inputField, formControlConfig.validators);
        } else {
          // hidden pre-defined form control
          fc.disable({ onlySelf: true });
        }
        fc.updateValueAndValidity();
      });
    });

    // Custom
    // clean old custom form control
    this.recipientCustomFieldList.forEach(customField => {
      (this.form.get('customField') as UntypedFormGroup).removeControl(customField.key);
    });

    this.recipientCustomFieldList = recipientInfo.customFieldInfoList;

    // add new custom form control
    recipientInfo.customFieldInfoList.forEach(inputField => {
      this.addCustomField(inputField, this.form);
    });


    console.log('update checkout form cutstom field');
    // Checkout Form
    const checkoutInfo = customFieldData.checkout;

    // Pre-defined
    const checkoutPreDefinedFieldList = checkoutInfo.preDefinedFieldInfoList;
    this.checkoutPreDefinedFieldList = checkoutPreDefinedFieldList.filter(d => d.id !== InputFieldId.Remarks);

    // update pre-defined form control
    this.checkoutPredefinedId2FormControlConfigMap.forEach((formControlConfigList, inputFieldId) => {
      const inputField = checkoutPreDefinedFieldList.find(field => `${field.id}` === `${inputFieldId}`);

      formControlConfigList.forEach(formControlConfig => {
        const formControlName = formControlConfig.name;
        const fc = this.checkoutForm.get(formControlName) as UntypedFormControl;
        if (inputField) {
          // show pre-defined form control and update validators
          fc.enable({ onlySelf: true });
          fc.clearValidators();
          this.assignValidators(fc, inputField, formControlConfig.validators);
        } else {
          // hidden pre-defined form control
          fc.disable({ onlySelf: true });
        }
        fc.updateValueAndValidity();
      });
    });

    // Custom
    // clean old custom form control
    this.checkoutCustomerFieldList.forEach(customField => {
      (this.checkoutForm.get('customField') as UntypedFormGroup).removeControl(customField.key);
    });

    this.checkoutCustomerFieldList = checkoutInfo.customFieldInfoList;

    // add new custom form control
    checkoutInfo.customFieldInfoList.forEach(inputField => {
      this.addCustomField(inputField, this.checkoutForm);
    });
  }

  get isShowArrivalTimeInput() { return this.arrivalTimeList.length > 0 && this.specificTimeSlot; }
}
