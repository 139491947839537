import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { GoodsService } from '../api/goods.service';
import { BasicViewComponent } from '../basic-view-component';
import { FullScreenLoadingService } from '../shared/service/full-screen-loading.service';
import { PointsRewardService } from '../api/points-reward.service';

@Component({
  selector: 'app-goods-review-dialog',
  templateUrl: './goods-review-dialog.component.html',
  styleUrls: ['./goods-review-dialog.component.scss']
})
export class GoodsReviewDialogComponent extends BasicViewComponent implements OnInit {

  goods;

  review = {
    rating: 0,
    comment: ''
  };

  goodsReviewPointsReward;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<GoodsReviewDialogComponent>,
    private goodsService: GoodsService,
    private loadingService: FullScreenLoadingService,
    protected snackBar: MatSnackBar,
    private translate: TranslateService,
    private pointsRewardService: PointsRewardService
  ) {
    super(snackBar);
  }

  ngOnInit() {
    this.goods = this.data;
    this.goods.rating = this.goods.rating || 0;

    this.pointsRewardService.getGoodsReviewReward(this.goods.id).subscribe(data => {
      this.goodsReviewPointsReward = data;
    });
  }

  submit() {
    this.loadingService.show();
    this.goodsService.addReview(this.goods.id, this.review)
      .pipe(
        finalize(() => this.loadingService.hide())
      )
      .subscribe(
        result => {
          if (result.success) {
            if (result.data?.rewardPoints) {
              const message = this.translate.instant('goods.review.point.reward.message', { points: result.data?.rewardPoints });
              this.showMessage(message);
            } else {
              this.showMessage(this.translate.instant('success'));
            }
            this.dialogRef.close(true);
          } else {
            // const msgKey = result.errorCode || 'error';
            // this.showError(`${this.translate.instant('error')}:  ${result.msg || ''}`); // ${this.translate.instant(msgKey)},
            this.showRespResultError(result, {});
          }
        });
  }

}
