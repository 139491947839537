import { Directive, HostBinding, Input, HostListener, OnInit, ElementRef } from '@angular/core';

@Directive({
  selector: '[appDefaultImage]',
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    '[src]': 'src'
  }
})
export class DefaultImageDirective implements OnInit {

  // @Input() src: string;

  // tslint:disable-next-line:no-input-rename
  @Input('appDefaultImage') default;

  // @HostBinding('attr.src') attrSrc;
  @Input() src: string;
  @HostBinding('class') className;

  constructor() {
  }

  ngOnInit() {
    // console.log(`input src:${this.src}`);
    // console.log(`attr src:${this.attrSrc}`);
    // this.attrSrc = this.inputSrc;
  }

  @HostListener('error') onError() {
    // console.log('error');
    this.src = this.default || 'assets/img/empty.png';
    // this.attrSrc = this.default || 'assets/img/empty.png';
    // this.ngsrc = 'assets/img/mt50.png';
  }

  @HostListener('load') load() {
    this.className = 'image-loaded';
  }

}
