export const API = {
    rootPath: 'https://sit.mobiletech.com.hk/godwell-web/api',
};

export const Project = {
    title: 'Godwell Website UAT',
    // baseHref: '/godwell-web/'
    url: 'https://sit.mobiletech.com.hk/godwell-web/'
};

export const Facebook = {
    appId: ''
};
