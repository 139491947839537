import { Injectable } from '@angular/core';
import { GenericTableApiService } from './generic-table-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { QueryBean, RespResult } from './table-api-service';

@Injectable({
    providedIn: 'root'
})
export class GoodsService extends GenericTableApiService {
    constructor(httpClient: HttpClient) {
        super(httpClient, 'goods');
    }

    queryDestination(id: number): Observable<any[]> {
        return this.httpClient.get<any[]>(`${this.endpoint}/${id}/destination`);
    }
    queryDestinationGroup(id: number): Observable<any[]> {
        return this.httpClient.get<any[]>(`${this.endpoint}/${id}/destination-group`);
    }


    queryDelieryMethod(id: number): Observable<any[]> {
        return this.httpClient.get<any[]>(`${this.endpoint}/${id}/delivery-method`);
    }
    queryDelieryMethodGroup(id: number): Observable<any[]> {
        return this.httpClient.get<any[]>(`${this.endpoint}/${id}/delivery-method-group`);
    }

    queryReview(id: number, queryBean?: QueryBean): Observable<any[]> {
        let params = {};
        if (queryBean) {
            params = this.tsObjToJsonObj(queryBean);
        }
        return this.httpClient.get<any[]>(`${this.endpoint}/${id}/review`, {
            params: params
        });
    }
    addReview(id: number, review: { rating: number, comment: string }): Observable<RespResult> {
        return this.httpClient.post<RespResult>(`${this.endpoint}/${id}/review`, { goodsId: id, ...review });
    }

    getReviewSummary(id: number) {
        return this.httpClient.get<any>(`${this.endpoint}/${id}/review/summary`);
    }

    stockNotify(id: number, pvId: number): Observable<RespResult> {
        return this.httpClient.put<RespResult>(`${this.endpoint}/${id}/product-variant/${pvId}/restock-notify`, {});
    }

    cancelStockNotify(id: number, pvId: number): Observable<RespResult> {
        return this.httpClient.delete<RespResult>(`${this.endpoint}/${id}/product-variant/${pvId}/restock-notify`);
    }

    queryBundlePriceGoodsPromotion(id: number): Observable<any[]> {
        return this.httpClient.get<any[]>(`${this.endpoint}/${id}/promotion/bundle-price-goods`);
    }

    queryBundlePriceProductVariantPromotion(id: number): Observable<any[]> {
        return this.httpClient.get<any[]>(`${this.endpoint}/${id}/promotion/bundle-price-product-variant`);
    }

    queryBundleGoodsPromotion(id: number): Observable<any[]> {
        return this.httpClient.get<any[]>(`${this.endpoint}/${id}/promotion/bundle-goods`);
    }

    querySupportPaymentMethod(id: number): Observable<any[]> {
        return this.httpClient.get<any[]>(`${this.endpoint}/${id}/support-payment-method`);
    }

    queryRelatedGoods(id: number): Observable<any[]> {
        return this.httpClient.get<any[]>(`${this.endpoint}/${id}/related-goods`);
    }

    queryRelatedPromotion(id: number): Observable<any[]> {
        return this.httpClient.get<any[]>(`${this.endpoint}/${id}/related-promotion`);
    }
}
