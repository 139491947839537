import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InfoPageService } from '../api/info-page.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-retail-store',
  templateUrl: './retail-store.component.html',
  styleUrls: ['./retail-store.component.scss']
})
export class RetailStoreComponent implements OnInit {
  constructor(private router: Router) { }

  ngOnInit() {
  }

}
