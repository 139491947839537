import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-product-showcase',
  templateUrl: './product-showcase.component.html',
  styleUrls: ['./product-showcase.component.scss']
})
export class ProductShowcaseComponent implements OnInit {

  @Input() productList: [{ id: number, imageList: [{ url }], name, description, rating, price }];

  constructor() { }

  ngOnInit() {
  }

}
