import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericTableApiService } from './generic-table-api.service';

@Injectable({
  providedIn: 'root'
})
export class FileService extends GenericTableApiService {

  constructor(httpClient: HttpClient) {
      super(httpClient, 'file');
  }

  downloadFile(id: number): Observable<Blob> {
    return this.httpClient.get(`${this.endpoint}/${id}`, {
      responseType: 'blob'
    });
  }
}
