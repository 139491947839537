import { Injectable } from '@angular/core';

import { LoginService } from '../api/login.service';
import { Observable } from 'rxjs';
import { tap, map, finalize } from 'rxjs/operators';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class CacheGrantedPermissionGuard implements CanActivate, CanActivateChild {

    constructor(private loginService: LoginService) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.cache();
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.cache();
    }


    cache(): Observable<boolean> {
        console.log('CacheGrantedPermissionGuard');


        // // for avoid rxjs EmptyError: no elements in sequence, wait for angular fix
        // return new Observable((sev) => {

        //     this.loginService.cacheGrantedPermission().pipe(finalize(() => {
        //         sev.next(true);
        //         sev.complete();
        //     })).subscribe(() => {
        //         sev.next(true);
        //         sev.complete();
        //     });
        // });


        return this.loginService.cacheGrantedPermission().pipe(map(() => true));
    }
}
