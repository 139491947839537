import { Injectable } from '@angular/core';
import { GenericTableApiService } from './generic-table-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MemberCouponService extends GenericTableApiService {

    constructor(httpClient: HttpClient) {
        super(httpClient, 'member/coupon');
    }

    getCount(): Observable<any> {
        return this.httpClient.get(`${this.endpoint}/count`, { responseType: 'text' });
    }

    checkCoupon(couponId): Observable<any> {
        return this.httpClient.get(`${this.endpoint}/${couponId}/check`);
    }

    queryAvailable(): Observable<any> {
        return this.httpClient.get(`${this.endpoint}/available`);
    }

}
