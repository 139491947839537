import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API } from '../../app-config';
import { LocalSettingService } from '../../shared/service/local-setting.service';

@Injectable()
export class CommonInterceptor implements HttpInterceptor {
    constructor(private localSetting: LocalSettingService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request to add the new header.
        // const authReq = req.clone({ headers: req.headers.set(Cookie.tokenKey, Cookie.getToken()) });
        // catch the error, make specific functions for catching specific errors and you can chain through them with more catch operators

        console.log('CommonInterceptor request');

        let params = {};

        let url = req.url;
        console.log(`before url: ${url}`);

        if (!url.startsWith('http') && !url.startsWith('assets/') && !url.startsWith('/assets/') && !url.startsWith('./assets/')) {
            url = API.rootPath + (url.startsWith('/') ? '' : '/') + url;

            params = { 'lang': this.localSetting.getLangForApi() };
        }

        console.log(`after url: ${url}`);

        req = req.clone({
            url: url,
            withCredentials: true,
            setParams: params
        });


        return next.handle(req);
    }

}
