// Merge a `source` object to a `target` recursively
export function deepMerge(target, source) {
    // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
    for (const key of Object.keys(source)) {
        if (source[key] instanceof Object && target[key]) { Object.assign(source[key], deepMerge(target[key], source[key])); }
    }

    // Join `target` and modified `source`
    Object.assign(target || {}, source);
    return target;
}

// Generate combinations from 2D array
export function combinationsOf2dArray(list: any[][], n = 0, result = [], current = []): [][] {
    if (n === list.length) {
        result.push(current);
    } else {
        list[n].forEach(item => combinationsOf2dArray(list, n + 1, result, [...current, item]));
    }
    return result;
}

// Get datetime string in ISO 8601 (yyyy-MM-dd'T'HH:mm:ss.SSSZ) format. e.g. 2023-10-12T10:50:01.086+08:0
export function toISOLocal(d) {
    const z = n => ('0' + n).slice(-2);
    let off = d.getTimezoneOffset();
    const sign = off < 0 ? '+' : '-';
    off = Math.abs(off);
    return new Date(d.getTime() - (d.getTimezoneOffset() * 60000)).toISOString().slice(0, -1) + sign + z(off / 60 | 0) + ':' + z(off % 60);
}