import { ComponentRef, Directive } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';

// https://medium.com/@timofeybm/3-methods-to-save-catalogue-state-and-scroll-position-in-angular-1a46ae4eadca


export interface OnAttach {
  /**
   * A callback method that is invoked when the RouteReuseStrategy instructs
   * to re-attach a previously detached component / subtree
   */
  onAttach(activatedRoute: ActivatedRoute): void;
}
export interface OnDetach {
  /**
   * A callback method that is invoked when the RouteReuseStrategy instructs
   * to detach component / subtree
   */
  onDetach(): void;
}


@Directive({
  selector: '[appRouterOutlet]'
})
export class AppRouterOutletDirective extends RouterOutlet {

  detach(): ComponentRef<any> {
    // console.log('AppRouterOutletDirective detach');

    const instance: any = this.component;
    if (instance && typeof instance.onDetach === 'function') {
      instance.onDetach();
    }
    return super.detach();
  }
  attach(ref: ComponentRef<any>, activatedRoute: ActivatedRoute): void {

    // console.log('AppRouterOutletDirective attach');

    super.attach(ref, activatedRoute);
    if (ref.instance && typeof ref.instance.onAttach === 'function') {
      ref.instance.onAttach(activatedRoute);
    }
  }
}
