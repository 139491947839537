import { ChangeDetectionStrategy, Component, ContentChild, forwardRef, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { SwiperSlideDirective } from 'swiper/angular';

@Component({
  selector: 'app-showcase-swiper-slide',
  templateUrl: './showcase-swiper-slide.component.html',
  styleUrls: ['./showcase-swiper-slide.component.scss'],
  host: { 'class': 'swiper-slide' }
})
export class ShowcaseSwiperSlideComponent implements OnInit {

  @ViewChild('slideContent', { read: TemplateRef }) public template: TemplateRef<any>;

  constructor() { }

  ngOnInit() {
  }

}
