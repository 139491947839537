import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericTableApiService } from './generic-table-api.service';

@Injectable({
  providedIn: 'root'
})
export class PhotoGalleryPageService extends GenericTableApiService {

  constructor(httpClient: HttpClient) {
      super(httpClient, 'photo-gallery-page');
  }

  get(id: number): Observable<any> {
      return this.httpClient.get(`photo-gallery-page/${id}`);
  }
}
